var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.tableShow === "batch" ? _c("onlyGoodsTable", {
    on: {
      "child-go": _vm.goChild
    }
  }) : _vm._e(), _vm.tableShow === "details" ? _c("onlyGoodsDetailsTable", {
    attrs: {
      current: _vm.current
    },
    on: {
      "child-go-batch": _vm.goBatch,
      "child-go": _vm.goChild
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };