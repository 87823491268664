export default (function (_ref) {
  var request = _ref.request;
  return {
    breedAdd: function breedAdd(data) {
      return request({
        url: '/breed',
        method: 'post',
        data: data
      });
    },
    breedModify: function breedModify(data) {
      return request({
        url: "/breed/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    breedDel: function breedDel(id) {
      return request({
        url: "/breed/".concat(id),
        method: 'delete'
      });
    },
    breedQuery: function breedQuery(data) {
      return request({
        url: '/breed',
        method: 'get',
        params: data
      });
    },
    breedDetail: function breedDetail(id) {
      return request({
        url: "/breed/".concat(id),
        method: 'get'
      });
    }
  };
});