// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./iconfont.woff2?t=1684830242022");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./iconfont.woff?t=1684830242022");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./iconfont.ttf?t=1684830242022");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "@font-face {\r\n  font-family: \"iconfont-farming\"; /* Project id 4082300 */\r\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2'),\r\n       url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff'),\r\n       url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('truetype');\n}\n.iconfont-farming {\r\n  font-family: \"iconfont-farming\" !important;\r\n  font-size: 16px;\r\n  font-style: normal;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\n}\n.icon-farming-dapeng:before {\r\n  content: \"\\e634\";\n}\n.icon-farming-qitaleiyangzhi:before {\r\n  content: \"\\e9de\";\n}\n.icon-farming-wheat__easyic:before {\r\n  content: \"\\e63b\";\n}\n.icon-farming-xie:before {\r\n  content: \"\\e63e\";\n}\n.icon-farming-haican:before {\r\n  content: \"\\e608\";\n}\n.icon-farming-xia:before {\r\n  content: \"\\e612\";\n}\n.icon-farming-daxia:before {\r\n  content: \"\\e60f\";\n}\n.icon-farming-yang:before {\r\n  content: \"\\e628\";\n}\n.icon-farming-yu:before {\r\n  content: \"\\e68d\";\n}\n.icon-farming-haiyangyuyuyeb:before {\r\n  content: \"\\e991\";\n}\n.icon-farming-niu:before {\r\n  content: \"\\e64c\";\n}\n.icon-farming-beike-cu:before {\r\n  content: \"\\e746\";\n}\n.icon-farming-yangzhihu:before {\r\n  content: \"\\e62f\";\n}\n.icon-farming-linye:before {\r\n  content: \"\\e692\";\n}\n.icon-farming-changfang:before {\r\n  content: \"\\e60e\";\n}\n.icon-farming-xushuichi:before {\r\n  content: \"\\e62e\";\n}\n.icon-farming-tubiao_dikuai:before {\r\n  content: \"\\e601\";\n}\n.icon-farming-yuanqu:before {\r\n  content: \"\\e7fd\";\n}\r\n\r\n", ""]);
// Exports
module.exports = exports;
