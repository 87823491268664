import _createForOfIteratorHelper from "D:/work/code/Halov-China-Fishery-V2/halov-web/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.number.constructor.js";
export default {
  name: 'iot_unmanned_ship',
  data: function data() {
    return {
      detail: {},
      searchForm: [],
      record: [],
      recordOptions: {},
      mapCenter: {
        lat: 29.552987,
        lng: 119.060105
      },
      iot_sensor_state: ['自动状态', '遥控手动', '遥控一键返回', '检修'],
      iot_sensor_lift: ['终止', '升起', '下落'],
      iot_sensor_RFID: ['终止', '收回', '展开'],
      remote: ['遥控器控制', '远程服务器控制']
    };
  },
  mounted: function mounted() {
    var _this = this;
    // this.getConfig()

    this.timer = setInterval(function () {
      _this.getLastRecord();
    }, 2 * 60 * 1000);
  },
  destroyed: function destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    getDetail: function getDetail() {
      var _this2 = this;
      this.$api['deviceDetail'](this.Unmanned_Ship_Device_No).then(function (res) {
        _this2.detail = res.data;
      });
    },
    getLastRecord: function getLastRecord() {
      var _this3 = this;
      this.$api['deviceItemLastRecordQuery'](this.Unmanned_Ship_Device_No).then(function (res) {
        _this3.record = res.data;
        _this3.recordOptions = {};
        var _iterator = _createForOfIteratorHelper(res.data),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var item = _step.value;
            _this3.recordOptions[item.type] = item;
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      });
    } // 获取无人船
    ,
    getConfig: function getConfig() {
      var _this4 = this;
      this.$api['configsCache']('Unmanned_Ship_Device_No').then(function (res) {
        if (res.data) {
          _this4.Unmanned_Ship_Device_No = res.data.Unmanned_Ship_Device_No;
          _this4.getDetail();
          _this4.getLastRecord();
        }
      });
    } // 按下
    ,
    operatingDown: function operatingDown(str) {
      var _this5 = this;
      // console.log('operatingDown')
      return;
      var contentParam = '';
      if (str == '左前') {
        contentParam = '4f70743c0523002300230023003e';
      } else if (str == '左旋') {
        contentParam = '4f70743c0323002300230023003e';
      } else if (str == '前进') {
        contentParam = '4f70743c0123002300230023003e';
      } else if (str == '后退') {
        contentParam = '4f70743c0223002300230023003e';
      } else if (str == '右前') {
        contentParam = '4f70743c0623002300230023003e';
      } else if (str == '右旋') {
        contentParam = '4f70743c0423002300230023003e';
      } else if (str == '夹具升降') {
        if (Number(this.recordOptions['iot_sensor_lift'].lastValue) == 1) {
          //夹具升起
          contentParam = '4f70743c0023022300230023003e';
        } else if (Number(this.recordOptions['iot_sensor_lift'].lastValue) == 2) {
          //夹具下落
          contentParam = '4f70743c0023012300230023003e';
        }
      } else if (str == '无线收合') {
        if (Number(this.recordOptions['iot_sensor_RFID'].lastValue) == 1) {
          //天线收回
          contentParam = '4f70743c0023002302230023003e';
        } else if (Number(this.recordOptions['iot_sensor_RFID'].lastValue) == 2) {
          //天线展开
          contentParam = '4f70743c0023002301230023003e';
        }
      }

      // 夹具状态this.recordOptions['iot_sensor_lift']
      this.$api['mqttSendHex'](this.Unmanned_Ship_Device_No, {
        contentParam: contentParam
      }).then(function (res) {
        if (res.code == 200) {
          _this5.$message.success(str + '执行成功！');
        } else {
          _this5.$message.error(res.msg);
        }
      });
    } // 松开
    ,
    operatingUp: function operatingUp() {
      var _this6 = this;
      return;
      // console.log('operatingUp')
      this.$api['mqttSendHex'](this.Unmanned_Ship_Device_No, {
        contentParam: '4f70743c0523002300230023003e'
      }).then(function (res) {
        if (res.code != 200) {
          _this6.$message.error(res.msg);
        }
      });
    },
    operatingUpOther: function operatingUpOther() {
      var _this7 = this;
      return;
      // console.log('operatingUp')
      this.$api['mqttSendHex'](this.Unmanned_Ship_Device_No, {
        contentParam: '4f70743c0023002300230023003e'
      }).then(function (res) {
        if (res.code != 200) {
          _this7.$message.error(res.msg);
        }
      });
    }
  }
};