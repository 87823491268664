import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.number.constructor.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "container"
  }, [_c("div", {
    staticClass: "left"
  }, [_c("div", {
    staticClass: "left-item"
  }, [_vm._m(0), _c("pre", {
    staticClass: "introduce"
  }, [_vm._v(_vm._s(_vm.remark || ""))])])]), _c("div", {
    staticClass: "center"
  }, [_c("baidu-map", {
    staticStyle: {
      height: "100%",
      width: "100%"
    },
    attrs: {
      center: {
        lng: 116.404,
        lat: 39.92
      },
      zoom: 16,
      "scroll-wheel-zoom": true,
      mapType: "BMAP_SATELLITE_MAP"
    }
  })], 1), _c("div", {
    staticClass: "right"
  }, [_c("div", {
    staticClass: "atmosphere"
  }, [_vm._m(1), _c("div", {
    staticClass: "atmosphere-item-list"
  }, _vm._l(_vm.deviceItem_tableData, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "atmosphere-item"
    }, [_c("div", {
      staticClass: "icon"
    }, [_c("img", {
      staticClass: "icon-bg",
      attrs: {
        src: require("@/assets/screen-icon/iconbg.png"),
        alt: ""
      }
    }), item.typeCode == "DO" ? _c("img", {
      staticClass: "icon-img",
      attrs: {
        src: require("@/assets/screen-icon/icon-rongjieyang@2x.png"),
        alt: ""
      }
    }) : _vm._e(), item.typeCode == "T" ? _c("img", {
      staticClass: "icon-img",
      attrs: {
        src: require("@/assets/screen-icon/icon-zhuodu@2x.png"),
        alt: ""
      }
    }) : _vm._e(), item.typeCode == "PH" ? _c("img", {
      staticClass: "icon-img",
      attrs: {
        src: require("@/assets/screen-icon/icon-ph@2x.png"),
        alt: ""
      }
    }) : _vm._e(), item.typeCode == "WL" ? _c("img", {
      staticClass: "icon-img",
      attrs: {
        src: require("@/assets/screen-icon/icon-yewei@2x.png"),
        alt: ""
      }
    }) : _vm._e(), item.typeCode == "Salinity" ? _c("img", {
      staticClass: "icon-img",
      attrs: {
        src: require("@/assets/screen-icon/icon-yandu@2x.png"),
        alt: ""
      }
    }) : _vm._e(), item.typeCode == "Temperature" ? _c("img", {
      staticClass: "icon-img",
      attrs: {
        src: require("@/assets/screen-icon/icon-wendu@2x.png"),
        alt: ""
      }
    }) : _vm._e()]), _c("div", {
      staticClass: "atmosphere-name"
    }, [_vm._v(_vm._s(item.typeName))]), _c("div", {
      staticClass: "atmosphere-value"
    }, [_vm._v(_vm._s(item.lastValue ? Number(item.lastValue).toFixed(2) : "-")), item.unit ? _c("span", [_vm._v(" " + _vm._s(item.unit))]) : _vm._e()])]);
  }), 0)]), _c("div", {
    staticClass: "atmosphere table-container"
  }, [_vm._m(2), _c("div", {
    staticClass: "atmosphere-item-list"
  }, [_c("el-table", {
    staticStyle: {
      width: "100%",
      height: "100%",
      overflow: "auto"
    },
    attrs: {
      data: _vm.warn_tableData,
      stripe: "",
      "row-key": "id",
      "tooltip-effect": "light",
      "element-loading-text": "正在努力加载中..."
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "时间",
      width: "160",
      align: "center",
      "header-align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(_vm.dateFormat2Str(scope.row.createTime)))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "deviceName",
      label: "设备",
      width: "100",
      "show-overflow-tooltip": "",
      align: "center",
      "header-align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", {
          staticClass: "one-overflow"
        }, [_vm._v(_vm._s(scope.row.deviceName))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "warnLevel",
      label: "告警等级",
      width: "80",
      align: "center",
      "header-align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.warnLevel == "Seriousness" ? _c("div", {
          staticClass: "text-collapse"
        }, [_vm._v("严重")]) : _vm._e(), scope.row.warnLevel == "Exigency" ? _c("div", {
          staticStyle: {
            color: "#FFA500"
          }
        }, [_vm._v("紧急")]) : _vm._e(), scope.row.warnLevel == "Normal" ? _c("div", {
          staticStyle: {
            color: "#fff"
          }
        }, [_vm._v("一般")]) : _vm._e()];
      }
    }])
  })], 1)], 1)])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "title"
  }, [_c("img", {
    attrs: {
      src: require("../unmanned/imgs/Slice 13@2x.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "title-text active"
  }, [_vm._v("介绍")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "title"
  }, [_c("img", {
    attrs: {
      src: require("../unmanned/imgs/Slice 13@2x.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "title-text active"
  }, [_vm._v("水质监测")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "title"
  }, [_c("img", {
    attrs: {
      src: require("../unmanned/imgs/Slice 13@2x.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "title-text active"
  }, [_vm._v("告警信息")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };