import _createForOfIteratorHelper from "D:/work/code/Halov-China-Fishery-V2/halov-web/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import dayjs from 'dayjs';
export default {
  name: 'deviceItem_page',
  props: {
    current: {
      type: Object,
      default: {}
    },
    prev: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      dayjs: dayjs,
      pageConfig: {
        editPageName: '历史记录',
        queryFunName: 'deviceItemLogQueryJoinItem',
        detailFunName: 'deviceItemLogDetail',
        dicTreeFunName: 'dictionaryCache'
      },
      searchForm: {
        page: 1,
        limit: 10,
        searchKey: null,
        deviceId: null,
        deviceItemId: null,
        sortField: 'create_time',
        starDate: null,
        endDate: null
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      dicData: {
        typeUpperCode: 'iot_sensor_item',
        configTypes: [],
        configTypeOptions: {}
      }
    };
  },
  mounted: function mounted() {
    var _this = this;
    console.log('history_logs_page this.current', this.current);
    if (this.current.deviceId) {
      this.searchForm.deviceId = this.current.deviceId || '';
      this.searchForm.deviceItemId = this.current.id || '';
    } else {
      this.searchForm.deviceId = this.current.id || '';
    }
    this.$api[this.pageConfig.dicTreeFunName](this.dicData.typeUpperCode).then(function (res) {
      if (res.data) {
        _this.dicData.configTypes = res.data;
        var _iterator = _createForOfIteratorHelper(res.data),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var item = _step.value;
            _this.dicData.configTypeOptions[item.code] = item.name;
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        _this.query();
      }
    });
  },
  methods: {
    back: function back() {
      if (this.current.deviceId) {
        this.$emit('child-go', this.prev, 'deviceItem');
      } else {
        this.$emit('child-go-device');
      }
    },
    exportClick: function exportClick() {
      var url = "".concat(process.env.VUE_APP_API, "/deviceItemLog/exportJoinItem?page=").concat(this.searchForm.page, "&limit=").concat(this.searchForm.limit, "&searchKey=").concat(this.searchForm.searchKey || '', "&Authorization=").concat(localStorage.getItem('token'), "&deviceId=").concat(this.searchForm.deviceId || '', "&deviceItemId=").concat(this.searchForm.deviceItemId || '', "&starDate=").concat(this.searchForm.starDate || '', "&endDate=").concat(this.searchForm.endDate || '');
      window.open(url);
    },
    query: function query() {
      var _this2 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this2.tableData = res.data || [];
        _this2.totalCount = res.count;
        _this2.queryLoading = false;
      });
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : '^sort';
      this.query();
    }
  }
};