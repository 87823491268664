import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "container self_diagnosis_breed"
  }, [_c("el-radio-group", {
    model: {
      value: _vm.breed_id,
      callback: function callback($$v) {
        _vm.breed_id = $$v;
      },
      expression: "breed_id"
    }
  }, _vm._l(_vm.dicData.configTypeOptions, function (item, key) {
    return _c("div", {
      key: item.id,
      staticClass: "item"
    }, [_c("div", {
      staticClass: "section"
    }, [_c("div", {
      staticClass: "type-name"
    }, [_vm._v(_vm._s(_vm.dicData.configTypeLabels[key]))]), _c("div", {
      staticClass: "water-type-list"
    }, [_vm.dicData.configTypeOptions[key].SeaWater.length ? _c("div", {
      staticClass: "water-type"
    }, [_c("div", {
      staticClass: "name"
    }, [_vm._v("海水类")]), _c("div", {
      staticClass: "breed-list"
    }, _vm._l(_vm.dicData.configTypeOptions[key].SeaWater, function (opt) {
      return _c("el-radio", {
        key: opt.id,
        attrs: {
          label: opt.id
        }
      }, [_vm._v(_vm._s(opt.name))]);
    }), 1)]) : _vm._e(), _vm.dicData.configTypeOptions[key].FreshWater.length ? _c("div", {
      staticClass: "water-type"
    }, [_c("div", {
      staticClass: "name"
    }, [_vm._v("淡水类")]), _c("div", {
      staticClass: "breed-list"
    }, _vm._l(_vm.dicData.configTypeOptions[key].FreshWater, function (opt) {
      return _c("el-radio", {
        key: opt.id,
        attrs: {
          label: opt.id
        }
      }, [_vm._v(_vm._s(opt.name))]);
    }), 1)]) : _vm._e()])])]);
  }), 0), _c("div", {
    staticClass: "btn",
    on: {
      click: _vm.sel
    }
  }, [_vm._v("开始诊断")])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };