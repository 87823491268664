export default (function (_ref) {
  var request = _ref.request;
  return {
    deviceItemLogQueryJoinItem: function deviceItemLogQueryJoinItem(data) {
      return request({
        url: '/deviceItemLog/queryJoinItem',
        method: 'get',
        params: data
      });
    },
    deviceItemLogQuery: function deviceItemLogQuery(data) {
      return request({
        url: '/deviceItemLog',
        method: 'get',
        params: data
      });
    },
    deviceItemLogDetail: function deviceItemLogDetail(id) {
      return request({
        url: "/deviceItemLog/".concat(id),
        method: 'get'
      });
    }
  };
});