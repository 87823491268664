export default (function (_ref) {
  var request = _ref.request;
  return {
    /**
     * @description 登录
     * @param {Object} data 登录携带的信息
     */
    loginByAdmin: function loginByAdmin() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      // 接口请求
      return request({
        url: '/userAuth/login/admin',
        method: 'post',
        data: data
      });
    },
    loginByPhone: function loginByPhone() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      // 接口请求
      return request({
        url: '/userAuth/login/adminByPhone',
        method: 'post',
        data: data
      });
    },
    refreshToken: function refreshToken() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return request({
        url: '/userAuth/token/refresh',
        method: 'put',
        params: data
      });
    }
  };
});