export default (function (_ref) {
  var request = _ref.request;
  return {
    breedFeedMethodAdd: function breedFeedMethodAdd(data) {
      return request({
        url: '/breedFeedMethod',
        method: 'post',
        data: data
      });
    },
    breedFeedMethodModify: function breedFeedMethodModify(data) {
      return request({
        url: "/breedFeedMethod/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    breedFeedMethodDel: function breedFeedMethodDel(id) {
      return request({
        url: "/breedFeedMethod/".concat(id),
        method: 'delete'
      });
    },
    breedFeedMethodQuery: function breedFeedMethodQuery(data) {
      return request({
        url: '/breedFeedMethod',
        method: 'get',
        params: data
      });
    },
    breedFeedMethodDetail: function breedFeedMethodDetail(id) {
      return request({
        url: "/breedFeedMethod/".concat(id),
        method: 'get'
      });
    }
  };
});