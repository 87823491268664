import _createForOfIteratorHelper from "D:/work/code/Halov-China-Fishery-V2/halov-web/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _objectSpread from "D:/work/code/Halov-China-Fishery-V2/halov-web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import d2MenuSide from '@/layout/header-aside/components/menu-side';
import d2MenuHeader from '@/layout/header-aside/components/menu-header';
import d2HeaderFullscreen from '@/layout/header-aside/components/header-fullscreen';
import d2HeaderSize from '@/layout/header-aside/components/header-size';
import d2HeaderTheme from '@/layout/header-aside/components/header-theme';
import d2HeaderUser from '@/layout/header-aside/components/header-user';
import d2HeaderColor from '@/layout/header-aside/components/header-color';
import { mapState, mapGetters, mapActions } from 'vuex';
import util from '@/libs/util';
import echarts from 'echarts';
export default {
  name: 'd2-layout-header-aside',
  components: {
    d2MenuSide: d2MenuSide,
    d2MenuHeader: d2MenuHeader,
    d2HeaderFullscreen: d2HeaderFullscreen,
    d2HeaderSize: d2HeaderSize,
    d2HeaderTheme: d2HeaderTheme,
    d2HeaderUser: d2HeaderUser,
    d2HeaderColor: d2HeaderColor
  },
  data: function data() {
    return {
      // [侧边栏宽度] 正常状态
      asideWidth: '200px'
      // [侧边栏宽度] 折叠状态
      ,
      asideWidthCollapse: '0px',
      sysIndexLeftLogo: '',
      sysIndexLeftFoldLogo: '',
      halo6AdminUrl: '',
      ShowRemind: false,
      noticeCount: 0,
      show: false,
      currentUser: localStorage.getItem('userId'),
      tableData: [],
      table_height: 0,
      atmosphere_deviceItem_tableData: [],
      water_deviceItem_tableData: [],
      dicData: {
        typeUpperCode: 'iot_sensor_item',
        configTypes: [],
        configTypeOptions: {}
      },
      zoneInfo: {}
    };
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState('d2admin', {
    keepAlive: function keepAlive(state) {
      return state.page.keepAlive;
    },
    grayActive: function grayActive(state) {
      return state.gray.active;
    },
    transitionActive: function transitionActive(state) {
      return state.transition.active;
    },
    asideCollapse: function asideCollapse(state) {
      return state.menu.asideCollapse;
    },
    asideTransition: function asideTransition(state) {
      return state.menu.asideTransition;
    }
  })), mapGetters('d2admin', {
    themeActiveSetting: 'theme/activeSetting'
  })), {}, {
    routerViewKey: function routerViewKey() {
      // 默认情况下 key 类似 __transition-n-/foo
      // 这里的字符串操作是为了最终 key 的格式和原来相同 类似 __transition-n-__stamp-time-/foo
      var stamp = this.$route.meta["__stamp-".concat(this.$route.path)] || '';
      return "".concat(stamp ? "__stamp-".concat(stamp, "-") : '').concat(this.$route.path);
    }
    /**
     * @description 最外层容器的背景图片样式
     */
    ,
    styleLayoutMainGroup: function styleLayoutMainGroup() {
      return this.themeActiveSetting.backgroundImage ? {
        backgroundImage: "url('".concat(this.$baseUrl).concat(this.themeActiveSetting.backgroundImage, "')")
      } : {};
    }
  }),
  created: function created() {
    if (!this.asideCollapse) {
      this.asideCollapseToggle();
    }
    this.getSwitch();
    this.getLogo();
    this.startRefreshToken();
    this.getzoneInfo();

    // 设置侧边栏菜单
    var menu = JSON.parse(localStorage.getItem('menu'));
    // console.log('menu',menu)
    menu = util.changeMenu(menu);
    this.$store.commit('d2admin/menu/asideSet', []);
    this.$store.commit('d2admin/menu/headerSet', menu);
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.table_height = (document.documentElement.clientHeight - 92) / 4 - 40;
      _this.chartsInit();
    });
  },
  methods: _objectSpread(_objectSpread(_objectSpread({}, mapActions('d2admin/menu', ['asideCollapseToggle'])), mapActions('d2admin/account', ['refreshToken'])), {}, {
    handleToggleAside: function handleToggleAside() {
      this.asideCollapseToggle();
    },
    handleOpen: function handleOpen(val) {
      if (!this.menu_id || this.menu_id == val) {
        this.asideCollapseToggle();
      }
      this.menu_id = val;
    }
    /**
     * 定时查询token是否濒临过期，刷新token
     */
    ,
    startRefreshToken: function startRefreshToken() {
      var _this2 = this;
      var refreshTimer = setInterval(function () {
        _this2.refreshToken();
      }, 3000);
      localStorage.setItem('refreshTimer', refreshTimer);
    } // 产业园信息
    ,
    getzoneInfo: function getzoneInfo() {
      var _this3 = this;
      this.$api['zoneInfoQuery']({
        page: 1,
        limit: 1
      }).then(function (res) {
        if (res.data && res.data.length) {
          _this3.$api['zoneInfoDetail'](res.data[0].id).then(function (detail) {
            if (detail.data) {
              _this3.zoneInfo = detail.data;
              _this3.getDeviceItem();
            }
          });
        }
      });
    } // 获取logo
    ,
    getLogo: function getLogo() {
      var _this4 = this;
      this.$api['aboutsCache']('sys_index_left_logo,sys_index_left_fold_logo').then(function (res) {
        if (res.data) {
          _this4.sysIndexLeftLogo = res.data.sys_index_left_logo;
          _this4.sysIndexLeftFoldLogo = res.data.sys_index_left_fold_logo;
        } else {
          _this4.$message.error(res.msg);
        }
      });
      this.$api['configsCache']('Halov_Admin_URL').then(function (res) {
        if (res.data) {
          _this4.halo6AdminUrl = res.data.Halo6_Admin_URL;
        } else {
          _this4.$message.error(res.msg);
        }
      });
    },
    getSwitch: function getSwitch() {
      var _this5 = this;
      this.$api['switchCache']('Show_Remind').then(function (res) {
        if (res.data) {
          _this5.ShowRemind = res.data;
          if (res.data) {
            // this.getUnNotice()
            var refreshTime = process.env.VUE_APP_REFRESHTIME;
            _this5.timer = setInterval(function () {
              // this.getUnNotice()
            }, refreshTime * 1000);
          }
        }
      });
    },
    outlinkClick: function outlinkClick() {
      window.open(this.halo6AdminUrl, '_blank');
    },
    chartsInit: function chartsInit() {
      this.$api['sourceUniqueDetailStat']().then(function (res) {
        if (res.data) {
          echarts.init(document.getElementById('chart-bar')).setOption({
            // 设置图表的标题
            title: {
              show: false
            },
            grid: {
              bottom: 30,
              left: 90,
              right: 0,
              top: 10
            },
            // 设置X轴上显示的数据
            xAxis: {
              type: 'value',
              axisLabel: {
                //y轴文字的配置
                textStyle: {
                  color: "#fff",
                  //Y轴内容文字颜色
                  fontSize: 15,
                  fontFamily: 'Bahnschrift'
                }
              },
              axisLine: {
                show: false
              }
            },
            // 设置Y轴上显示的数据, 如果没有设置会根据数据自动填充
            yAxis: {
              type: 'category',
              data: ["新建二维码\n数量", "已用二维码\n数量", "剩余二维码\n数量"],
              axisLabel: {
                //y轴文字的配置
                textStyle: {
                  color: "#fff",
                  //Y轴内容文字颜色
                  fontSize: 16,
                  align: 'center'
                }
              },
              axisTick: {
                show: false
              },
              axisLine: {
                lineStyle: {
                  color: "rgba(255, 255, 255, .3)"
                }
              },
              offset: 40
            },
            // 设置图表的数据
            series: [{
              type: 'bar',
              data: [res.data.totalAmount, res.data.useAmount, res.data.totalAmount - res.data.useAmount],
              barWidth: 29,
              // 柱子宽度
              itemStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                    offset: 1,
                    color: '#60b4ff'
                  }, {
                    offset: 0,
                    color: '#45f0ff'
                  }]),
                  barBorderRadius: [0, 4, 4, 0]
                }
              }
            }]
          });
        }
      });
    },
    getDeviceItem: function getDeviceItem() {
      var _this6 = this;
      this.$api['dictionaryCache']('iot_sensor_item').then(function (res) {
        if (res.data) {
          _this6.dicData.configTypes = res.data;
          var _iterator = _createForOfIteratorHelper(res.data),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var item = _step.value;
              _this6.dicData.configTypeOptions[item.code] = item;
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
          if (_this6.zoneInfo.souceAirDeviceId) {
            //气象设备
            _this6.$api['deviceItemLastRecordQuery'](_this6.zoneInfo.souceAirDeviceId).then(function (res) {
              _this6.atmosphere_deviceItem_tableData = res.data || [];
            });
          }
          if (_this6.zoneInfo.souceWaterDeviceId) {
            //水质设备
            _this6.$api['deviceItemLastRecordQuery'](_this6.zoneInfo.souceWaterDeviceId).then(function (res) {
              _this6.water_deviceItem_tableData = res.data || [];
            });
          }
        }
      });
    }
  })
};