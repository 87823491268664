export default (function (_ref) {
  var request = _ref.request;
  return {
    articleSimpleAdd: function articleSimpleAdd(data) {
      return request({
        url: '/articleSimple',
        method: 'post',
        data: data
      });
    },
    articleSimpleModify: function articleSimpleModify(data) {
      return request({
        url: "/articleSimple/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    articleSimpleDel: function articleSimpleDel(id) {
      return request({
        url: "/articleSimple/".concat(id),
        method: 'delete'
      });
    },
    articleSimpleQuery: function articleSimpleQuery(data) {
      return request({
        url: '/articleSimple',
        method: 'get',
        params: data
      });
    },
    articleSimpleDetail: function articleSimpleDetail(id) {
      return request({
        url: "/articleSimple/".concat(id),
        method: 'get'
      });
    },
    articleSimpleDetailByCode: function articleSimpleDetailByCode(code) {
      return request({
        url: "/articleSimple/cache/".concat(code),
        method: 'get'
      });
    },
    articleSimpleOnline: function articleSimpleOnline(id) {
      return request({
        url: "/articleSimple/".concat(id, "/online/"),
        method: 'put'
      });
    },
    articleSimpleUnOnline: function articleSimpleUnOnline(id) {
      return request({
        url: "/articleSimple/".concat(id, "/unOnline/"),
        method: 'put'
      });
    },
    articleSimpleClearCache: function articleSimpleClearCache(code) {
      return request({
        url: "/articleSimple/cache/clear?code=".concat(code || ''),
        method: 'delete'
      });
    }
  };
});