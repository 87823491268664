export default (function (_ref) {
  var request = _ref.request;
  return {
    breedSegmentAdd: function breedSegmentAdd(data) {
      return request({
        url: '/breedSegment',
        method: 'post',
        data: data
      });
    },
    breedSegmentModify: function breedSegmentModify(data) {
      return request({
        url: "/breedSegment/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    breedSegmentDel: function breedSegmentDel(id) {
      return request({
        url: "/breedSegment/".concat(id),
        method: 'delete'
      });
    },
    breedSegmentQuery: function breedSegmentQuery(data) {
      return request({
        url: '/breedSegment',
        method: 'get',
        params: data
      });
    },
    breedSegmentDetail: function breedSegmentDetail(id) {
      return request({
        url: "/breedSegment/".concat(id),
        method: 'get'
      });
    }
  };
});