export default (function (_ref) {
  var request = _ref.request;
  return {
    roleAdd: function roleAdd(data) {
      return request({
        url: '/role',
        method: 'post',
        data: data
      });
    },
    roleModify: function roleModify(data) {
      return request({
        url: "/role/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    roleDel: function roleDel(id) {
      return request({
        url: "/role/".concat(id),
        method: 'delete'
      });
    },
    roleQuery: function roleQuery(data) {
      return request({
        url: '/role',
        method: 'get',
        params: data
      });
    },
    roleQueryAll: function roleQueryAll(data) {
      return request({
        url: '/role/all',
        method: 'get',
        params: data
      });
    },
    roleDetail: function roleDetail(id) {
      return request({
        url: "/role/".concat(id),
        method: 'get'
      });
    },
    roleDisable: function roleDisable(id) {
      return request({
        url: "/role/".concat(id, "/disable"),
        method: 'put'
      });
    },
    roleUnDisable: function roleUnDisable(id) {
      return request({
        url: "/role/".concat(id, "/unDisable"),
        method: 'put'
      });
    }
  };
});