export default (function (_ref) {
  var request = _ref.request;
  return {
    illnessWarningAdd: function illnessWarningAdd(data) {
      return request({
        url: '/illnessWarning',
        method: 'post',
        data: data
      });
    },
    illnessWarningModify: function illnessWarningModify(data) {
      return request({
        url: "/illnessWarning/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    illnessWarningDel: function illnessWarningDel(id) {
      return request({
        url: "/illnessWarning/".concat(id),
        method: 'delete'
      });
    },
    illnessWarningQuery: function illnessWarningQuery(data) {
      return request({
        url: '/illnessWarning',
        method: 'get',
        params: data
      });
    },
    illnessWarningQueryMonth: function illnessWarningQueryMonth(month) {
      return request({
        url: "/illnessWarning/".concat(month, "/all"),
        method: 'get'
      });
    },
    illnessWarningQueryAll: function illnessWarningQueryAll() {
      return request({
        url: '/illnessWarning/all',
        method: 'get'
      });
    },
    illnessWarningDetail: function illnessWarningDetail(id) {
      return request({
        url: "/illnessWarning/".concat(id),
        method: 'get'
      });
    }
  };
});