export default (function (_ref) {
  var request = _ref.request;
  return {
    deviceWarnLogDel: function deviceWarnLogDel(id) {
      return request({
        url: "/deviceWarnLog/".concat(id),
        method: 'delete'
      });
    },
    deviceWarnLogQuery: function deviceWarnLogQuery(data) {
      return request({
        url: '/deviceWarnLog',
        method: 'get',
        params: data
      });
    },
    deviceWarnLogDetail: function deviceWarnLogDetail(id) {
      return request({
        url: "/deviceWarnLog/".concat(id),
        method: 'get'
      });
    },
    deviceWarnLogHandle: function deviceWarnLogHandle(id, data) {
      return request({
        url: "/deviceWarnLog/".concat(id, "/handle"),
        method: 'post',
        data: data
      });
    }
  };
});