export default (function (_ref) {
  var request = _ref.request;
  return {
    dictionaryAdd: function dictionaryAdd(data) {
      return request({
        url: '/dictionary',
        method: 'post',
        data: data
      });
    },
    dictionaryModify: function dictionaryModify(data) {
      return request({
        url: "/dictionary/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    dictionaryDel: function dictionaryDel(id) {
      return request({
        url: "/dictionary/".concat(id),
        method: 'delete'
      });
    },
    dictionaryQuery: function dictionaryQuery(data) {
      return request({
        url: '/dictionary',
        method: 'get',
        params: data
      });
    },
    dictionaryDetail: function dictionaryDetail(id) {
      return request({
        url: "/dictionary/".concat(id),
        method: 'get'
      });
    },
    dictionaryDetailByCode: function dictionaryDetailByCode(code) {
      return request({
        url: "/dictionary/code/".concat(code),
        method: 'get'
      });
    },
    dictionaryCache: function dictionaryCache(code) {
      return request({
        url: "/dictionary/cache/".concat(code),
        method: 'get'
      });
    },
    dictionaryClearCache: function dictionaryClearCache() {
      return request({
        url: '/dictionary/cache/clear',
        method: 'delete'
      });
    }
  };
});