import _defineProperty from "D:/work/code/Halov-China-Fishery-V2/halov-web/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.concat.js";
export default (function (_ref) {
  var _ref3;
  var request = _ref.request;
  return _ref3 = {
    projectLabAdd: function projectLabAdd(data) {
      return request({
        url: '/mng/projectLab',
        method: 'post',
        data: data
      });
    },
    projectLabModify: function projectLabModify(data) {
      return request({
        url: "/mng/projectLab/".concat(data.id),
        method: 'PUT',
        data: data
      });
    },
    projectLabDel: function projectLabDel(id) {
      return request({
        url: "/mng/projectLab/".concat(id),
        method: 'delete'
      });
    },
    projectLabQuery: function projectLabQuery(data) {
      return request({
        url: '/mng/projectLab',
        method: 'get',
        params: data
      });
    }
  }, _defineProperty(_ref3, "projectLabQuery", function projectLabQuery(data) {
    return request({
      url: '/mng/projectLab',
      method: 'get',
      params: data
    });
  }), _defineProperty(_ref3, "projectLabQueryForStructures", function projectLabQueryForStructures(_ref2) {
    var projectId = _ref2.projectId,
      join = _ref2.join,
      data = _ref2.data;
    return request({
      url: "/mng/projectLab/queryForStructures/".concat(projectId || '', "/").concat(join || true),
      method: 'get',
      params: data
    });
  }), _defineProperty(_ref3, "projectLabMap", function projectLabMap(data) {
    return request({
      url: "/mng/projectLab/map",
      method: 'get',
      params: data
    });
  }), _ref3;
});