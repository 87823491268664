export default (function (_ref) {
  var request = _ref.request;
  return {
    labAdd: function labAdd(data) {
      return request({
        url: '/org/lab',
        method: 'post',
        data: data
      });
    },
    labModify: function labModify(data) {
      return request({
        url: "/org/lab/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    labDel: function labDel(id) {
      return request({
        url: "/org/lab/".concat(id),
        method: 'delete'
      });
    },
    labQuery: function labQuery(data) {
      return request({
        url: '/org/lab',
        method: 'get',
        params: data
      });
    },
    labDetail: function labDetail(id) {
      return request({
        url: "/org/lab/".concat(id),
        method: 'get'
      });
    },
    labWeather: function labWeather(districtId) {
      return request({
        url: "/common/priceTrend/weather/".concat(districtId),
        method: 'post'
      });
    }
  };
});