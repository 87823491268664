import { render, staticRenderFns } from "./disease_page.vue?vue&type=template&id=3fce467d&scoped=true&"
import script from "./disease_page.vue?vue&type=script&lang=js&"
export * from "./disease_page.vue?vue&type=script&lang=js&"
import style0 from "./disease_page.vue?vue&type=style&index=0&id=3fce467d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fce467d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\work\\code\\Halov-China-Fishery-V2\\halov-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3fce467d')) {
      api.createRecord('3fce467d', component.options)
    } else {
      api.reload('3fce467d', component.options)
    }
    module.hot.accept("./disease_page.vue?vue&type=template&id=3fce467d&scoped=true&", function () {
      api.rerender('3fce467d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/knowledge/disease_page.vue"
export default component.exports