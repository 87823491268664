import "core-js/modules/es.array.concat.js";
import layoutHeaderAside from '@/layout/header-aside';

// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

/**
 * 在主框架内显示
 */
var frameIn = [{
  path: '/',
  redirect: {
    name: 'index'
  },
  component: layoutHeaderAside,
  children: [
  // ///////// //
  //    首页   //
  // ///////// //
  {
    path: 'index',
    name: 'index',
    meta: {
      title: '首页',
      auth: true
    },
    component: _import('system/index')
  }
  // ///////// //
  // 机构服务 //
  // //////// //
  , {
    path: 'lab_mng',
    name: 'lab_mng',
    meta: {
      title: '企业管理',
      auth: true
    },
    component: _import('org/lab_page')
  }, {
    path: 'lab_structures_mng',
    name: 'lab_structures_mng',
    meta: {
      title: '建筑物管理',
      auth: true
    },
    component: _import('org/lab_structures_page')
  }

  // ///////// //
  // 设备管理 //
  // //////// //
  , {
    path: 'device_mng',
    name: 'device_mng',
    meta: {
      title: '设备管理',
      auth: true
    },
    component: _import('iot/device_page_mng')
  }, {
    path: 'video_mng',
    name: 'video_mng',
    meta: {
      title: '视频管理',
      auth: true
    },
    component: _import('iot/video_page')
  }, {
    path: 'deviceItem_page',
    name: 'deviceItem_page',
    meta: {
      title: '设备参数',
      auth: true
    },
    component: _import('iot/deviceItem_page')
  }, {
    path: 'history_logs',
    name: 'history_logs',
    meta: {
      title: '历史记录',
      auth: true
    },
    component: _import('iot/history_logs_page')
  }, {
    path: 'product_page',
    name: 'product_page',
    meta: {
      title: '产品管理',
      auth: true
    },
    component: _import('iot/product_page_mng')
  }, {
    path: 'warn_info',
    name: 'warn_info',
    meta: {
      title: '预警处置',
      auth: true
    },
    component: _import('iot/warn_log_page')
  }, {
    path: 'water_monitoring',
    name: 'water_monitoring',
    meta: {
      title: '水质监测',
      auth: true
    },
    component: _import('iot/water_monitoring_page')
  }, {
    path: 'price_quotation',
    name: 'price_quotation',
    meta: {
      title: '价格行情',
      auth: true
    },
    component: _import('iot/price_quotation_page')
  }, {
    path: 'without_control',
    name: 'without_control',
    meta: {
      title: '外部设备控制',
      auth: true
    },
    component: _import('iot/without_control_page')
  }, {
    path: 'liangping_aquaponics',
    name: 'liangping_aquaponics',
    meta: {
      title: '梁平鱼菜共生',
      auth: false
    },
    component: _import('visualization/liangping_aquaponics')
  }, {
    path: 'Beijing_suburb',
    name: 'Beijing_suburb',
    meta: {
      title: '水质检测',
      auth: true
    },
    component: _import('iot/Beijing_suburb')
  }

  // ///////// //
  // 无人装备 //
  // //////// //
  , {
    path: 'iot_unmanned_ship',
    name: 'iot_unmanned_ship',
    meta: {
      title: '无人船',
      auth: false
    },
    component: _import('unmanned/iot_unmanned_ship')
  }

  // ///////// //
  // 知识库 //
  // //////// //
  , {
    path: 'cms_breed',
    name: 'cms_breed',
    meta: {
      title: '品种管理',
      auth: true
    },
    component: _import('knowledge/breed_page_mng')
  }, {
    path: 'cms_diseases',
    name: 'cms_diseases',
    meta: {
      title: '疾病管理',
      auth: true
    },
    component: _import('knowledge/disease_page')
  }, {
    path: 'cms_diseases_symptom',
    name: 'cms_diseases_symptom',
    meta: {
      title: '症状管理',
      auth: true
    },
    component: _import('knowledge/disease_symptom_page')
  }, {
    path: 'self_diagnosis',
    name: 'self_diagnosis',
    meta: {
      title: '自助诊断',
      auth: true
    },
    component: _import('knowledge/self_diagnosis_mng')
  }, {
    path: 'cms_knowledge',
    name: 'cms_knowledge',
    meta: {
      title: '养殖知识',
      auth: true
    },
    component: _import('knowledge/knowledge_page_mng.vue')
  }, {
    path: 'diseases_common',
    name: 'diseases_common',
    meta: {
      title: '常见疾病',
      auth: true
    },
    component: _import('knowledge/diseases_common_page.vue')
  }, {
    path: 'standardization_planting',
    name: 'standardization_planting',
    meta: {
      title: '标准化种植',
      auth: false
    },
    component: _import('farming/standardization_planting_mng.vue')
  }

  // ///////// //
  // 渔业养殖 //
  // //////// //
  , {
    path: 'project_mng',
    name: 'project_mng',
    meta: {
      title: '示范项目管理',
      auth: true
    },
    component: _import('knowledge/project_page_mng')
  }, {
    path: 'project_relationship',
    name: 'project_relationship',
    meta: {
      title: '项目关系',
      auth: true
    },
    component: _import('knowledge/project_relationship_page')
  }, {
    path: 'programme_mng',
    name: 'programme_mng',
    meta: {
      title: '规划管理',
      auth: true
    },
    component: _import('knowledge/programme_page_mng')
  }, {
    path: 'task_mng',
    name: 'task_mng',
    meta: {
      title: '任务管理',
      auth: true
    },
    component: _import('fishery/task_page')
  }

  // ///////// //
  // 疾病医疗 //
  // //////// //
  , {
    path: 'illness_warning',
    name: 'illness_warning',
    meta: {
      title: '疾病预警',
      auth: true
    },
    component: _import('illness/illness_warning_page')
  }, {
    path: 'illness_medicine',
    name: 'illness_medicine',
    meta: {
      title: '药物管理',
      auth: true
    },
    component: _import('illness/illness_medicine_page')
  }, {
    path: 'illness_collect',
    name: 'illness_collect',
    meta: {
      title: '模型数据采集',
      auth: true
    },
    component: _import('illness/illness_collect_page')
  }
  // ///////// //
  // 质量追溯 //
  // //////// //
  , {
    path: 'source_report',
    name: 'source_report',
    meta: {
      title: '溯源报告',
      auth: true
    },
    component: _import('source/source_report_page')
  }, {
    path: 'source_batch',
    name: 'source_batch',
    meta: {
      title: '溯源批次',
      auth: true
    },
    component: _import('source/source_batch_page')
  }, {
    path: 'only_goods',
    name: 'only_goods',
    meta: {
      title: '一物一码',
      auth: true
    },
    component: _import('source/only_goods_mng')
  }
  // ///////// //
  // 大屏数据配置 //
  // //////// //
  , {
    path: 'factory_farming',
    name: 'factory_farming',
    meta: {
      title: '工厂化养殖',
      auth: true
    },
    component: _import('visualization/factory_farming')
  }, {
    path: 'yield_page',
    name: 'yield_page',
    meta: {
      title: '产量服务',
      auth: true
    },
    component: _import('visualizationData/yield_page')
  }, {
    path: 'breed_area',
    name: 'breed_area',
    meta: {
      title: '全国水产产量',
      auth: true
    },
    component: _import('visualizationData/breed_area_page')
  }, {
    path: 'lab_gdp',
    name: 'lab_gdp',
    meta: {
      title: '企业产值服务',
      auth: true
    },
    component: _import('visualizationData/lab_gdp_page')
  }, {
    path: 'labPark_breedAreaYield',
    name: 'labPark_breedAreaYield',
    meta: {
      title: '品种产量服务',
      auth: true
    },
    component: _import('visualizationData/labPark_breedAreaYield_page')
  }, {
    path: 'aquatic_products',
    name: 'aquatic_products',
    meta: {
      title: '园区优质水产品',
      auth: true
    },
    component: _import('visualizationData/aquatic_products_page')
  }, {
    path: 'mode_area',
    name: 'mode_area',
    meta: {
      title: '养殖模式产量',
      auth: true
    },
    component: _import('visualizationData/mode_area_page')
  }, {
    path: 'zoneInfo',
    name: 'zoneInfo',
    meta: {
      title: '产业园信息服务',
      auth: true
    },
    component: _import('visualizationData/zoneInfo_form')
  }, {
    path: 'labYield',
    name: 'labYield',
    meta: {
      title: '企业产量管理',
      auth: true
    },
    component: _import('visualizationData/labYield')
  }

  // ///////// //
  // 智能模型 //
  // //////// //
  , {
    path: 'ai_model',
    name: 'ai_model',
    meta: {
      title: '智能模型',
      auth: true
    },
    component: _import('model/ai_model_mng')
  }

  // ///////// //
  // 用户权限 //
  // //////// //
  , {
    path: 'user_menu',
    name: 'user_menu',
    meta: {
      title: '菜单管理',
      auth: true
    },
    component: _import('user/menu_page')
  }, {
    path: 'user_role',
    name: 'user_role',
    meta: {
      title: '角色管理',
      auth: true
    },
    component: _import('user/role_page')
  }, {
    path: 'mng_admin',
    name: 'mng_admin',
    meta: {
      title: '用户管理',
      auth: true
    },
    component: _import('user/admin_page')
  }
  // ///////// //
  // 系统管理 //
  // //////// //
  , {
    path: 'sys_dictionary',
    name: 'sys_dictionary',
    meta: {
      title: '字典管理',
      auth: true
    },
    component: _import('sys/dictionary_page')
  }, {
    path: 'sys_config',
    name: 'sys_config',
    meta: {
      title: '配置管理',
      auth: true
    },
    component: _import('sys/config_page')
  }, {
    path: 'sys_switch',
    name: 'sys_switch',
    meta: {
      title: '开关管理',
      auth: true
    },
    component: _import('sys/switch_page')
  }, {
    path: 'sys_about',
    name: 'sys_about',
    meta: {
      title: '关于系统',
      auth: true
    },
    component: _import('sys/about_page')
  }, {
    path: 'sys_file',
    name: 'sys_file',
    meta: {
      title: '文件管理',
      auth: true
    },
    component: _import('sys/file_page')
  }, {
    path: 'sys_lbs',
    name: 'sys_lbs',
    meta: {
      title: '行政区划',
      auth: true
    },
    component: _import('sys/lbs_page')
  }
  // ///////// //
  // 内容管理 //
  // //////// //
  , {
    path: 'cms_article_simple',
    name: 'cms_article_simple',
    meta: {
      title: '文章管理',
      auth: true
    },
    component: _import('cms/article_simple_page')
  }, {
    path: 'cms_notice',
    name: 'cms_notice',
    meta: {
      title: '公告管理',
      auth: true
    },
    component: _import('cms/notice_page')
  }, {
    path: 'cms_advertising',
    name: 'cms_advertising',
    meta: {
      title: '广告管理',
      auth: true
    },
    component: _import('cms/advertising_page')
  }, {
    path: 'cms_carousel',
    name: 'cms_carousel',
    meta: {
      title: '轮播管理',
      auth: true
    },
    component: _import('cms/carousel_page')
  }, {
    path: 'cms_feedback',
    name: 'cms_feedback',
    meta: {
      title: '反馈管理',
      auth: true
    },
    component: _import('cms/feedback_page')
  }, {
    path: 'cms_work_order',
    name: 'cms_work_order',
    meta: {
      title: '在线诊断',
      auth: true
    },
    component: _import('cms/work_order_page')
  }
  // /////// //
  // 官网管理 //
  // ////// //
  , {
    path: 'website_navigation_article',
    name: 'website_navigation_article',
    meta: {
      title: '导航文章',
      auth: true
    },
    component: _import('website/navigation_article/navigation_article_mng_page')
  }, {
    path: 'website_outlink',
    name: 'website_outlink',
    meta: {
      title: '外链管理',
      auth: true
    },
    component: _import('website/outlink_page')
  }
  // ///////// //
  // 记录管理 //
  // //////// //
  , {
    path: 'app_version',
    name: 'app_version',
    meta: {
      title: 'APP版本',
      auth: true
    },
    component: _import('record/app_version_page')
  }, {
    path: 'record_log',
    name: 'record_log',
    meta: {
      title: '日志管理',
      auth: true
    },
    component: _import('record/log_page')
  }, {
    path: 'db_bak',
    name: 'db_bak',
    meta: {
      title: '数据库备份',
      auth: true
    },
    component: _import('record/db_bak_page')
  }
  // /////// //
  // 示例功能 //
  // ////// //
  , {
    path: 'demo_echarts',
    name: 'demo_echarts',
    meta: {
      title: 'Echarts',
      auth: true
    },
    component: _import('demo/echarts')
  }, {
    path: 'demo_bmap',
    name: 'demo_bmap',
    meta: {
      title: 'BMap',
      auth: true
    },
    component: _import('demo/bmap')
  }
  // /////// //
  // 其他功能 //
  // ////// //
  , {
    // 系统 前端日志
    path: 'log',
    name: 'log',
    meta: {
      title: '前端日志',
      auth: true
    },
    component: _import('system/log')
  }, {
    // 刷新页面 必须保留
    path: 'refresh',
    name: 'refresh',
    hidden: true,
    component: _import('system/function/refresh')
  }, {
    // 页面重定向 必须保留
    path: 'redirect/:route*',
    name: 'redirect',
    hidden: true,
    component: _import('system/function/redirect')
  }]
}];

/**
 * 在主框架之外显示
 */
var frameOut = [
// 登录
{
  path: '/login',
  name: 'login',
  component: _import('system/login')
}, {
  path: '/login_code',
  name: 'login_code',
  meta: {
    title: '微信登录',
    auth: false
  },
  component: _import('system/login/login_code')
}, {
  path: '/bind_code',
  name: 'bind_code',
  meta: {
    title: '绑定微信',
    auth: false
  },
  component: _import('system/login/bind_code')
}, {
  path: '/listening_camera',
  name: 'listening_camera',
  meta: {
    title: '摄像头视频播放',
    auth: false
  },
  component: _import('system/listening_camera')
}, {
  path: '/source_screen',
  name: 'source_screen',
  meta: {
    title: '质量追溯',
    auth: false
  },
  component: _import('visualization/source_screen')
}, {
  path: '/china_screen',
  name: 'china_screen',
  meta: {
    title: '国渔大屏',
    auth: true
  },
  component: _import('visualization/dongying_screen')
}];

/**
 * 错误页面
 */
var errorPage = [{
  path: '*',
  name: '404',
  component: _import('system/error/404')
}, {
  path: '*',
  name: '411',
  component: _import('system/error/411')
}];

// 导出需要显示菜单的
export var frameInRoutes = frameIn;

// 重新组织后导出
export default [].concat(frameIn, frameOut, errorPage);