export default (function (_ref) {
  var request = _ref.request;
  return {
    userRoles: function userRoles(userId) {
      return request({
        url: "/usersRoles/".concat(userId, "/roles"),
        method: 'get'
      });
    }
  };
});