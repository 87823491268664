import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "name"
  }, [_c("div", {
    staticClass: "colorBar",
    style: {
      background: _vm.data.color || "rgba(247, 167, 71, 0.8)"
    }
  }), _c("div", {
    staticStyle: {
      height: "100%",
      width: "calc(100% - 10px)",
      display: "flex",
      "align-items": "center",
      "justify-content": "space-between",
      "padding-right": "5px"
    },
    style: {
      background: _vm.data.color || "#fef6ec"
    }
  }, [_c("div", {
    staticStyle: {
      width: "calc(100% - 45px)",
      display: "flex",
      "align-items": "center"
    }
  }, [_vm.data.icon ? _c("img", {
    attrs: {
      src: _vm.data.icon,
      alt: ""
    }
  }) : _vm._e(), _c("div", {
    staticClass: "carId"
  }, [_vm._v(_vm._s(_vm.data.name))])]), _c("el-dropdown", {
    on: {
      command: _vm.moreButton
    }
  }, [_c("span", {
    staticClass: "el-dropdown-link"
  }, [_vm._v(" 操作"), _c("i", {
    staticClass: "el-icon-arrow-down el-icon--right"
  })]), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    attrs: {
      command: _vm.beforeCommand(_vm.data, "addDetails")
    }
  }, [_vm._v("新增细则")]), _c("el-dropdown-item", {
    attrs: {
      command: _vm.beforeCommand(_vm.data, "edit")
    }
  }, [_vm._v("编辑")]), _c("el-dropdown-item", {
    attrs: {
      command: _vm.beforeCommand(_vm.data, "del")
    }
  }, [_c("span", {
    staticClass: "text-collapse"
  }, [_vm._v("删除")])])], 1)], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };