var cityLatlng = {
  新疆维吾尔自治区: [86.61, 40.79],
  西藏自治区: [89.13, 30.66],
  黑龙江省: [128.34, 47.05],
  吉林省: [126.32, 43.38],
  辽宁省: [123.42, 41.29],
  内蒙古自治区: [112.17, 42.81],
  北京市: [116.40, 40.40],
  宁夏回族自治区: [106.27, 36.76],
  山西省: [111.95, 37.65],
  河北省: [115.21, 38.44],
  天津市: [117.04, 39.52],
  青海省: [97.07, 35.62],
  甘肃省: [103.82, 36.05],
  山东省: [118.01, 36.37],
  陕西省: [108.94, 34.46],
  河南省: [113.46, 34.25],
  安徽省: [117.28, 31.86],
  江苏省: [120.26, 32.54],
  上海市: [121.46, 31.28],
  四川省: [103.36, 30.65],
  湖北省: [112.29, 30.98],
  浙江省: [120.15, 29.28],
  重庆市: [107.51, 29.63],
  湖南省: [112.08, 27.79],
  江西省: [115.89, 27.97],
  贵州省: [106.91, 26.67],
  福建省: [118.31, 26.07],
  云南省: [101.71, 24.84],
  台湾: [121.01, 23.54],
  广西壮族自治区: [108.67, 23.68],
  广东省: [113.98, 22.82],
  海南: [110.03, 19.33],
  澳门: [113.54, 22.19],
  香港: [114.17, 22.32]
};
export default cityLatlng;