import dayjs from 'dayjs';
export default {
  name: 'deviceItem_page',
  props: {
    current: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      dayjs: dayjs,
      pageConfig: {
        editPageName: '历史记录',
        queryFunName: 'deviceWarnLogQuery',
        detailFunName: 'deviceWarnLogDetail'
      },
      searchForm: {
        page: 1,
        limit: 10,
        sortField: 'create_time',
        deviceId: this.current.id
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      dicData: {
        typeUpperCode: 'iot_sensor_item',
        configTypes: [],
        configTypeOptions: {}
      }
    };
  },
  mounted: function mounted() {
    // console.log('history_logs_page this.current',this.current)
    this.query();
  },
  methods: {
    back: function back() {
      this.$emit('child-go-device');
    },
    query: function query() {
      var _this = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this.tableData = res.data || [];
        _this.totalCount = res.count;
        _this.queryLoading = false;
      });
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : '^sort';
      this.query();
    }
  }
};