import _createForOfIteratorHelper from "D:/work/code/Halov-China-Fishery-V2/halov-web/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.function.name.js";
export default {
  name: 'device_mng',
  components: {
    bindSel: function bindSel() {
      return import('./place_bind_sel.vue');
    },
    productSel: function productSel() {
      return import('./product_sel.vue');
    }
  },
  data: function data() {
    return {
      pageConfig: {
        editPageName: '设备管理',
        addFunName: 'deviceAdd',
        modifyFunName: 'deviceModify',
        queryFunName: 'deviceQueryJoinLab',
        detailFunName: 'deviceDetail',
        delFunName: 'deviceDel',
        unbindFunName: 'deviceUnbind',
        dicTreeFunName: 'dictionaryCache'
      },
      searchForm: {
        page: 1,
        limit: 10,
        searchKey: null,
        sortField: 'create_time'
      },
      formData: {
        interactiveModel: 'Aliyun',
        interactiveProtocol: 'JSON',
        onlineState: 'online'
      },
      rules: {
        deviceName: [{
          required: true,
          message: '请输入设备名称',
          trigger: 'blur'
        }],
        id: [{
          required: true,
          message: '请输入设备编号',
          trigger: 'blur'
        }],
        deviceType: [{
          required: true,
          message: '请选择设备类型',
          trigger: 'blur'
        }],
        interactiveModel: [{
          required: true,
          message: '请选择通信模式',
          trigger: 'blur'
        }],
        interactiveProtocol: [{
          required: true,
          message: '请选择交互协议',
          trigger: 'blur'
        }],
        comModel: [{
          required: true,
          message: '请选择通讯模式',
          trigger: 'blur'
        }],
        simSn: [{
          required: true,
          message: '请输入SIM编号',
          trigger: 'blur'
        }]
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      editDialogShow: false,
      editDialogTitle: '',
      formLabelWidth: '100px',
      emptyFormData: {},
      bindShow: false,
      product_show: false,
      dicData: {
        typeUpperCode: 'iot_product_type',
        configTypes: [],
        configTypeOptions: {}
      }
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$api[this.pageConfig.dicTreeFunName](this.dicData.typeUpperCode).then(function (res) {
      if (res.data) {
        _this.dicData.configTypes = res.data;
        var _iterator = _createForOfIteratorHelper(res.data),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var item = _step.value;
            _this.dicData.configTypeOptions[item.code] = item.name;
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        _this.emptyFormData = JSON.parse(JSON.stringify(_this.formData));
        _this.query();
      }
    });
  },
  methods: {
    beforeCommand: function beforeCommand(index, row, command) {
      return {
        index: index,
        row: row,
        command: command
      };
    },
    moreButton: function moreButton(command) {
      switch (command.command) {
        case 'bind':
          // 绑定
          this.bind(command.row);
          break;
        case 'unbind':
          // 解绑
          this.unbind(command.row);
          break;
        case 'del':
          // 删除
          this.del(command.row.id);
          break;
        case 'askCmd':
          // 询问命令
          this.askCmd(command.row);
          break;
        case 'item':
          // 监测参数
          this.item(command.row);
          break;
        case 'warn':
          // 告警规则
          this.warn(command.row);
          break;
        case 'edit':
          // 设备编辑
          this.edit(command.row);
          break;
      }
    },
    initFormData: function initFormData(data) {
      var paramData = JSON.parse(JSON.stringify(data));
      if (!paramData.onlineState) {
        paramData.onlineState = 'online';
      }
      this.formData = paramData;
      if (typeof this.$refs['formDataVerify'] !== 'undefined') {
        this.$refs['formDataVerify'].clearValidate();
      }
    },
    saveFormDataProcess: function saveFormDataProcess(formData) {
      var saveFormData = JSON.parse(JSON.stringify(formData));
      return saveFormData;
    },
    add: function add() {
      this.editDialogTitle = this.pageConfig.editPageName + '新增';
      this.initFormData(this.emptyFormData);
      this.editDialogShow = true;
    },
    edit: function edit(data) {
      this.initFormData(data);
      this.editDialogTitle = this.pageConfig.editPageName + '维护';
      this.editDialogShow = true;
      // this.$api[this.pageConfig.detailFunName](id).then(async(res) => {
      //   this.initFormData(res.data)

      //   this.editDialogTitle = this.pageConfig.editPageName + '维护'
      //   this.editDialogShow = true

      //   if (res.data.lat && res.data.lon) {
      //     this.mapCenter = this.markLocation = { lat: res.data.lat, lng: res.data.lon }
      //   }

      //   this.$set(this.formData, 'registerDate', res.data.registerDate + '')
      // })
    },
    productSel: function productSel(data) {
      if (data) {
        this.formData.productId = data.id;
        this.$set(this.formData, 'productModel', data.model);
        this.$set(this.formData, 'interactiveModel', data.interactiveModel);
        this.$set(this.formData, 'comModel', data.comModel);
        this.$set(this.formData, 'deviceType', data.type);
        this.$set(this.formData, 'conf', data.conf);
      }
      this.product_show = false;
    },
    del: function del(id) {
      var _this2 = this;
      this.$confirm('确定要删除吗').then(function () {
        _this2.$api[_this2.pageConfig.delFunName](id).then(function (res) {
          if (res.data) {
            _this2.$message.success('删除成功');
            _this2.query();
          } else {
            _this2.$message.error('删除失败');
          }
        });
      });
    } // 绑定企业
    ,
    bind: function bind(data) {
      this.initFormData(data);
      this.bindShow = true;
    },
    bindSel: function bindSel(val) {
      if (val) {
        this.query();
      }
      this.bindShow = false;
      this.initFormData(this.emptyFormData);
    } // 解绑
    ,
    unbind: function unbind(data) {
      var _this3 = this;
      this.$api[this.pageConfig.unbindFunName](data.id).then(function (res) {
        if (res.data) {
          _this3.$message.success('解绑成功');
          _this3.query();
        } else {
          _this3.$message.error('解绑失败');
        }
      });
    } // 参数
    ,
    item: function item(course) {
      this.$emit('child-go', course, 'deviceItem');
    } // 告警
    ,
    warn: function warn(course) {
      this.$emit('child-go', course, 'warn');
    } // 历史记录
    ,
    history: function history(course) {
      this.$emit('child-go', course, 'history');
    } // 告警历史
    ,
    warnHistory: function warnHistory(course) {
      this.$emit('child-go', course, 'warnHistory');
    } // 历史分析
    ,
    analysis: function analysis(course) {
      this.$emit('child-go', course, 'analysis');
    } // 询问命令
    ,
    askCmd: function askCmd(course) {
      this.$emit('child-go', course, 'askCmd');
    },
    query: function query() {
      var _this4 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this4.tableData = res.data || [];
        _this4.totalCount = res.count;
        _this4.queryLoading = false;
      });
    },
    submitFormData: function submitFormData() {
      var _this5 = this;
      this.$refs['formDataVerify'].validate(function (valid) {
        if (valid) {
          if (_this5.formData.createTime) {
            _this5.$api[_this5.pageConfig.modifyFunName](_this5.saveFormDataProcess(_this5.formData)).then(function (res) {
              if (res.data) {
                _this5.$message.success('编辑成功');
                _this5.initFormData(_this5.emptyFormData);
                _this5.editDialogShow = false;
                _this5.query();
              } else {
                _this5.$message.error('编辑失败');
              }
            });
          } else {
            _this5.$api[_this5.pageConfig.addFunName](_this5.saveFormDataProcess(_this5.formData)).then(function (res) {
              if (res.data) {
                _this5.$message.success('新增成功');
                _this5.initFormData(_this5.emptyFormData);
                _this5.editDialogShow = false;
                _this5.query();
              } else {
                _this5.$message.error('新增失败');
              }
            });
          }
        }
      });
    },
    closeEditDialogShow: function closeEditDialogShow() {
      this.editDialogTitle = '';
      this.editDialogShow = false;
      this.initFormData(this.emptyFormData);
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};