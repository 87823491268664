export default (function (_ref) {
  var request = _ref.request;
  return {
    feedbackAdd: function feedbackAdd(data) {
      return request({
        url: '/feedback',
        method: 'post',
        data: data
      });
    },
    feedbackDel: function feedbackDel(id) {
      return request({
        url: "/feedback/".concat(id),
        method: 'delete'
      });
    },
    feedbackQueryJoinUser: function feedbackQueryJoinUser(data) {
      return request({
        url: '/feedback/joinUser',
        method: 'get',
        params: data
      });
    },
    feedbackDetail: function feedbackDetail(id) {
      return request({
        url: "/feedback/".concat(id),
        method: 'get'
      });
    },
    feedbackRead: function feedbackRead(id) {
      return request({
        url: "/feedback/".concat(id, "/read"),
        method: 'post'
      });
    }
  };
});