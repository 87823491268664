export default {
  name: "TestLeft",
  props: {
    data: Object
  },
  mounted: function mounted() {
    // console.log('data',data)
  },
  methods: {
    editClick: function editClick(data) {
      this.$emit('edit', data);
    },
    del: function del(id) {
      this.$emit('del', id);
    },
    addDetails: function addDetails(data) {
      this.$emit('addDetails', data);
    },
    beforeCommand: function beforeCommand(row, command) {
      return {
        row: row,
        command: command
      };
    },
    moreButton: function moreButton(command) {
      switch (command.command) {
        case 'del':
          // 删除
          this.del(command.row.id);
          break;
        case 'edit':
          // 编辑
          this.editClick(command.row);
          break;
        case 'addDetails':
          // 编辑
          this.addDetails(command.row);
          break;
      }
    }
  }
};