import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  name: 'programme_page_mng',
  components: {
    programmeTable: function programmeTable() {
      return import('./programme_page');
    }
  },
  data: function data() {
    return {
      tableShow: 'plan',
      current: {}
    };
  },
  methods: {
    goBreed: function goBreed() {
      this.tableShow = 'plan';
    },
    goChild: function goChild(data, tableName) {
      this.current = data;
      this.tableShow = tableName;
    }
  }
};