export default (function (_ref) {
  var request = _ref.request;
  return {
    modelParamsAdd: function modelParamsAdd(data) {
      return request({
        url: '/modelParams',
        method: 'post',
        data: data
      });
    },
    modelParamsModify: function modelParamsModify(data) {
      return request({
        url: "/modelParams/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    modelParamsDel: function modelParamsDel(id) {
      return request({
        url: "/modelParams/".concat(id),
        method: 'delete'
      });
    },
    modelParamsQuery: function modelParamsQuery(data) {
      return request({
        url: '/modelParams',
        method: 'get',
        params: data
      });
    },
    modelParamsDetail: function modelParamsDetail(id) {
      return request({
        url: "/modelParams/".concat(id),
        method: 'get'
      });
    }
  };
});