export default (function (_ref) {
  var request = _ref.request;
  return {
    carouselAdd: function carouselAdd(data) {
      return request({
        url: '/carousel',
        method: 'post',
        data: data
      });
    },
    carouselModify: function carouselModify(data) {
      return request({
        url: "/carousel/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    carouselDel: function carouselDel(id) {
      return request({
        url: "/carousel/".concat(id),
        method: 'delete'
      });
    },
    carouselQuery: function carouselQuery(data) {
      return request({
        url: "/carousel",
        method: 'get',
        params: data
      });
    },
    carouselDetail: function carouselDetail(id) {
      return request({
        url: "/carousel/".concat(id),
        method: 'get'
      });
    }
  };
});