import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
export default {
  name: 'lab_park_sel',
  props: {
    formData: {
      type: Object,
      default: {}
    },
    funName: {
      type: String,
      default: ''
    }
  },
  components: {
    labSel: function labSel() {
      return import('../org/lab_sel_page.vue');
    },
    structuresSel: function structuresSel() {
      return import('../org/structures_sel.vue');
    }
  },
  data: function data() {
    return {
      formLabelWidth: '110px',
      enterprise_show: false,
      structures_show: false,
      rules: {}
    };
  },
  methods: {
    sel: function sel(data) {
      this.$emit('child-bind-sel', data);
    } // 选择企业
    ,
    labSel: function labSel(data) {
      if (data) {
        this.formData.labId = data.id;
        this.$set(this.formData, 'labName', data.name);
        this.$set(this.formData, 'structuresId', '');
        this.$set(this.formData, 'structuresName', '');
      }
      this.enterprise_show = false;
    } // 选择建筑物
    ,
    structuresSel: function structuresSel(data) {
      if (data) {
        this.$set(this.formData, 'structuresId', data.id);
        this.$set(this.formData, 'structuresName', data.name);
      }
      this.structures_show = false;
    },
    saveFormDataProcess: function saveFormDataProcess(formData) {
      var saveFormData = JSON.parse(JSON.stringify(formData));
      return saveFormData;
    },
    submitFormData: function submitFormData() {
      var _this = this;
      this.$refs['formDataVerify'].validate(function (valid) {
        if (valid) {
          _this.$api[_this.funName](_this.formData.id, _this.saveFormDataProcess({
            labId: _this.formData.labId,
            structuresId: _this.formData.structuresId
          })).then(function (res) {
            if (res.data) {
              _this.$message.success('绑定成功');
              _this.sel(true);
            } else {
              _this.$message.error('绑定失败');
            }
          });
        }
      });
    }
  }
};