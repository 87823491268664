export default (function (_ref) {
  var request = _ref.request;
  return {
    sourceBatchAdd: function sourceBatchAdd(data) {
      return request({
        url: '/sourceBatch',
        method: 'post',
        data: data
      });
    },
    sourceBatchModify: function sourceBatchModify(data) {
      return request({
        url: "/sourceBatch/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    sourceBatchDel: function sourceBatchDel(id) {
      return request({
        url: "/sourceBatch/".concat(id),
        method: 'delete'
      });
    },
    sourceBatchQuery: function sourceBatchQuery(data) {
      return request({
        url: '/sourceBatch',
        method: 'get',
        params: data
      });
    },
    sourceBatchDetail: function sourceBatchDetail(id) {
      return request({
        url: "/sourceBatch/".concat(id),
        method: 'get'
      });
    },
    sourceBatchDownQrCods: function sourceBatchDownQrCods(id) {
      return request({
        url: "/sourceUniqueBatch/".concat(id, "/downQrCods"),
        method: 'get'
      });
    }
  };
});