import { render, staticRenderFns } from "./labPark_breedAreaYield_page.vue?vue&type=template&id=48287a0f&scoped=true&"
import script from "./labPark_breedAreaYield_page.vue?vue&type=script&lang=js&"
export * from "./labPark_breedAreaYield_page.vue?vue&type=script&lang=js&"
import style0 from "./labPark_breedAreaYield_page.vue?vue&type=style&index=0&id=48287a0f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48287a0f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\work\\code\\Halov-China-Fishery-V2\\halov-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('48287a0f')) {
      api.createRecord('48287a0f', component.options)
    } else {
      api.reload('48287a0f', component.options)
    }
    module.hot.accept("./labPark_breedAreaYield_page.vue?vue&type=template&id=48287a0f&scoped=true&", function () {
      api.rerender('48287a0f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/visualizationData/labPark_breedAreaYield_page.vue"
export default component.exports