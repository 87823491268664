export default (function (_ref) {
  var request = _ref.request;
  return {
    sourceUniqueDetailAdd: function sourceUniqueDetailAdd(data) {
      return request({
        url: '/sourceUniqueDetail',
        method: 'post',
        data: data
      });
    },
    sourceUniqueDetailCancel: function sourceUniqueDetailCancel(id) {
      return request({
        url: "/sourceUniqueDetail/".concat(id, "/cancel"),
        method: 'put'
      });
    },
    sourceUniqueDetailUse: function sourceUniqueDetailUse(id) {
      return request({
        url: "/sourceUniqueDetail/".concat(id, "/use"),
        method: 'put'
      });
    },
    sourceUniqueDetailDel: function sourceUniqueDetailDel(id) {
      return request({
        url: "/sourceUniqueDetail/".concat(id),
        method: 'delete'
      });
    },
    sourceUniqueDetailBatchDel: function sourceUniqueDetailBatchDel(data) {
      return request({
        url: "/sourceUniqueDetail",
        method: 'delete',
        data: data
      });
    },
    sourceUniqueDetailBatchUse: function sourceUniqueDetailBatchUse(data) {
      return request({
        url: "/sourceUniqueDetail/uses",
        method: 'put',
        data: data
      });
    },
    sourceUniqueDetailBatchCancel: function sourceUniqueDetailBatchCancel(data) {
      return request({
        url: "/sourceUniqueDetail/cancels",
        method: 'put',
        data: data
      });
    },
    sourceUniqueDetailQuery: function sourceUniqueDetailQuery(data) {
      return request({
        url: '/sourceUniqueDetail',
        method: 'get',
        params: data
      });
    },
    sourceUniqueDetailDetail: function sourceUniqueDetailDetail(id) {
      return request({
        url: "/sourceUniqueDetail/".concat(id),
        method: 'get'
      });
    },
    sourceUniqueDetailStat: function sourceUniqueDetailStat() {
      return request({
        url: "/sourceUniqueDetail/stat",
        method: 'get'
      });
    }
  };
});