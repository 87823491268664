import "core-js/modules/es.json.stringify.js";
export default {
  name: 'deviceAskcmd_page',
  props: {
    current: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      pageConfig: {
        editPageName: '参数',
        addFunName: 'deviceAskcmdAdd',
        modifyFunName: 'deviceAskcmdModify',
        delFunName: 'deviceAskcmdDel',
        askFunName: 'deviceAskcmdAsk',
        queryFunName: 'deviceAskcmdQuery',
        detailFunName: 'deviceAskcmdDetail',
        dicTreeFunName: 'dictionaryCache'
      },
      searchForm: {
        page: 1,
        limit: 10,
        searchKey: null,
        deviceId: this.current.id,
        sortField: 'create_time',
        type: ''
      },
      formData: {
        deviceId: this.current.id,
        interactiveProtocol: 'Modbus_RTU',
        askFrequency: 600
      },
      rules: {
        name: [{
          required: true,
          message: '请输入名称',
          trigger: 'blur'
        }],
        slave: [{
          required: true,
          message: '请输入从机',
          trigger: 'blur'
        }],
        command: [{
          required: true,
          message: '请输入命令',
          trigger: 'blur'
        }],
        askFrequency: [{
          required: true,
          message: '请输入频率',
          trigger: 'blur'
        }]
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      editDialogShow: false,
      editDialogTitle: '',
      formLabelWidth: '120px',
      emptyFormData: {},
      dicData: {
        typeUpperCode: 'Modbus_RTU',
        configTypes: [],
        configTypeOptions: {}
      }
    };
  },
  mounted: function mounted() {
    this.emptyFormData = JSON.parse(JSON.stringify(this.formData));
    this.query();
  },
  methods: {
    back: function back() {
      this.$emit('child-go-device');
    },
    initFormData: function initFormData(data) {
      var paramData = JSON.parse(JSON.stringify(data));
      this.formData = paramData;
      if (!this.formData.interactiveProtocol) {
        this.formData.interactiveProtocol = 'Modbus_RTU';
      }
      if (typeof this.$refs['formDataVerify'] !== 'undefined') {
        this.$refs['formDataVerify'].clearValidate();
      }
    },
    saveFormDataProcess: function saveFormDataProcess(formData) {
      var saveFormData = JSON.parse(JSON.stringify(formData));
      return saveFormData;
    },
    add: function add() {
      this.editDialogTitle = this.pageConfig.editPageName + '新增';
      this.editDialogShow = true;
      this.formData.upperCode = this.searchForm.upperCode;
    },
    ask: function ask(id) {
      var _this = this;
      this.$confirm('确定要询问吗').then(function () {
        _this.$api[_this.pageConfig.askFunName](id).then(function (res) {
          if (res.data) {
            _this.$message.success('询问成功');
            _this.query();
          } else {
            _this.$message.error('询问失败');
          }
        });
      });
    },
    edit: function edit(id) {
      var _this2 = this;
      this.$api[this.pageConfig.detailFunName](id).then(function (res) {
        _this2.initFormData(res.data);
        _this2.editDialogTitle = _this2.pageConfig.editPageName + '维护';
        _this2.editDialogShow = true;
      });
    },
    del: function del(id) {
      var _this3 = this;
      this.$confirm('确定要删除吗').then(function () {
        _this3.$api[_this3.pageConfig.delFunName](id).then(function (res) {
          if (res.data) {
            _this3.$message.success('删除成功');
            _this3.query();
          } else {
            _this3.$message.error('删除失败');
          }
        });
      });
    } // 历史记录
    ,
    history: function history(course) {
      // console.log('参数跳转历史记录')
      this.$emit('child-go', course, 'history', this.current);
    },
    query: function query() {
      var _this4 = this;
      this.queryLoading = true;
      this.searchForm.deviceId = this.current.id;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this4.tableData = res.data || [];
        _this4.totalCount = res.count;
        _this4.queryLoading = false;
      });
    },
    submitFormData: function submitFormData() {
      var _this5 = this;
      this.$refs['formDataVerify'].validate(function (valid) {
        if (valid) {
          _this5.formData.deviceId = _this5.current.id;
          if (_this5.formData.id) {
            _this5.$api[_this5.pageConfig.modifyFunName](_this5.saveFormDataProcess(_this5.formData)).then(function (res) {
              if (res.data) {
                _this5.$message.success('编辑成功');
                _this5.initFormData(_this5.emptyFormData);
                _this5.editDialogShow = false;
                _this5.query();
              } else {
                _this5.$message.error('编辑失败');
              }
            });
          } else {
            _this5.$api[_this5.pageConfig.addFunName](_this5.saveFormDataProcess(_this5.formData)).then(function (res) {
              if (res.data) {
                _this5.$message.success('新增成功');
                _this5.initFormData(_this5.emptyFormData);
                _this5.editDialogShow = false;
                _this5.query();
              } else {
                _this5.$message.error('新增失败');
              }
            });
          }
        }
      });
    },
    closeEditDialogShow: function closeEditDialogShow() {
      this.editDialogTitle = '';
      this.editDialogShow = false;
      this.initFormData(this.emptyFormData);
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : '^sort';
      this.query();
    }
  }
};