var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "container"
  }, [_vm._m(0), _c("div", {
    staticClass: "center"
  }, [_c("div", {
    staticClass: "item"
  }, [_vm._m(1), _c("div", {
    staticClass: "item-content"
  }, [_c("baidu-map", {
    ref: "location-lib",
    staticStyle: {
      height: "100%",
      width: "100%"
    },
    attrs: {
      center: _vm.mapCenter,
      zoom: 12,
      "scroll-wheel-zoom": true,
      mapType: "BMAP_SATELLITE_MAP"
    }
  }, [_vm.recordOptions["iot_sensor_lat"] && _vm.recordOptions["iot_sensor_lng"] ? _c("bm-marker", {
    attrs: {
      position: {
        lat: _vm.recordOptions["iot_sensor_lat"].lastValue,
        lng: _vm.recordOptions["iot_sensor_lng"].lastValue
      },
      icon: {
        url: "/ship.png",
        size: {
          width: 48,
          height: 48
        }
      },
      "data-index": "0"
    }
  }) : _vm._e()], 1)], 1)])]), _c("div", {
    staticClass: "left"
  }, [_c("div", {
    staticClass: "item"
  }, [_vm._m(2), _c("div", {
    staticClass: "item-content operating"
  }, [_vm._m(3), _c("div", {
    staticClass: "btns"
  }, [_c("div", [_c("div", {
    staticClass: "btn",
    on: {
      mousedown: function mousedown($event) {
        return _vm.operatingDown("左前");
      },
      mouseup: _vm.operatingUp
    }
  }, [_vm._v("左前")]), _c("div", {
    staticClass: "btn",
    staticStyle: {
      "margin-bottom": "0"
    },
    on: {
      mousedown: function mousedown($event) {
        return _vm.operatingDown("左旋");
      },
      mouseup: _vm.operatingUp
    }
  }, [_vm._v("左旋")])]), _c("div", [_c("div", {
    staticClass: "btn",
    on: {
      mousedown: function mousedown($event) {
        return _vm.operatingDown("前进");
      },
      mouseup: _vm.operatingUp
    }
  }, [_vm._v("前进")]), _c("div", {
    staticClass: "btn",
    on: {
      mousedown: function mousedown($event) {
        return _vm.operatingDown("夹具升降");
      },
      mouseup: _vm.operatingUpOther
    }
  }, [_vm._v("夹具升降")]), _c("div", {
    staticClass: "btn",
    on: {
      mousedown: function mousedown($event) {
        return _vm.operatingDown("无线收合");
      },
      mouseup: _vm.operatingUpOther
    }
  }, [_vm._v("无线收合")]), _c("div", {
    staticClass: "btn",
    staticStyle: {
      "margin-bottom": "0"
    },
    on: {
      mousedown: function mousedown($event) {
        return _vm.operatingDown("后退");
      },
      mouseup: _vm.operatingUp
    }
  }, [_vm._v("后退")])]), _c("div", [_c("div", {
    staticClass: "btn",
    on: {
      mousedown: function mousedown($event) {
        return _vm.operatingDown("右前");
      },
      mouseup: _vm.operatingUp
    }
  }, [_vm._v("右前")]), _c("div", {
    staticClass: "btn",
    staticStyle: {
      "margin-bottom": "0"
    },
    on: {
      mousedown: function mousedown($event) {
        return _vm.operatingDown("右旋");
      },
      mouseup: _vm.operatingUp
    }
  }, [_vm._v("右旋")])])])])]), _c("div", {
    staticClass: "item list-item"
  }, [_vm._m(4), _c("div", {
    staticClass: "item-content"
  }, [_c("div", {
    staticClass: "select"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v("航线设计")]), _c("el-select", {
    attrs: {
      size: "mini",
      placeholder: "请选择",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.readState,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "readState", $$v);
      },
      expression: "searchForm.readState"
    }
  }, [_c("el-option", {
    attrs: {
      label: "未读",
      value: false
    }
  })], 1)], 1), _c("div", {
    staticClass: "table"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v("排队任务")]), _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: [],
      stripe: "",
      border: "",
      "row-key": "id",
      "element-loading-text": "正在努力加载中..."
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "序号",
      type: "index",
      width: "50"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "readState",
      label: "起止时间",
      width: "75"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "readState",
      label: "上湖航线",
      width: "75"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "readState",
      label: "联络航线",
      width: "75"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "readState",
      label: "下湖航线",
      width: "75"
    }
  })], 1)], 1), _c("div", {
    staticClass: "table"
  }, [_c("div", {
    staticClass: "label"
  }, [_vm._v("完成任务")]), _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: [],
      stripe: "",
      border: "",
      "row-key": "id",
      "element-loading-text": "正在努力加载中..."
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "序号",
      type: "index",
      width: "50"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "readState",
      label: "起止时间",
      width: "75"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "readState",
      label: "上湖航线",
      width: "75"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "readState",
      label: "联络航线",
      width: "75"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "readState",
      label: "下湖航线",
      width: "75"
    }
  })], 1)], 1)])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "right"
  }, [_c("div", {
    staticClass: "item"
  }, [_c("div", {
    staticClass: "title"
  }, [_c("img", {
    attrs: {
      src: require("./imgs/Slice 13@2x.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "title-text active"
  }, [_vm._v("设备介绍")])]), _c("div", {
    staticClass: "item-content equipment-introduction"
  }, [_c("img", {
    staticClass: "fish-img",
    attrs: {
      src: "https://jsoss.lotezk.com/19901001/20231123/1700721222450.jpg",
      alt: ""
    }
  }), _c("div", [_c("div", {
    staticClass: "text"
  }, [_vm._v("序号：001")]), _c("div", {
    staticClass: "text"
  }, [_vm._v("功能：巡检")]), _c("div", {
    staticClass: "text"
  }, [_vm._v("电量：80")]), _c("div", {
    staticClass: "text"
  }, [_vm._v("状态：遥控手动")])])])]), _c("div", {
    staticClass: "item"
  }, [_c("div", {
    staticClass: "title"
  }, [_c("img", {
    attrs: {
      src: require("./imgs/Slice 13@2x.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "title-text active"
  }, [_vm._v("船载摄像头")])]), _c("div", {
    staticClass: "item-content"
  }, [_c("img", {
    staticClass: "fish-img",
    attrs: {
      src: "https://jsoss.lotezk.com/19901001/20230908/1694141916966.jpg",
      alt: ""
    }
  })])]), _c("div", {
    staticClass: "item",
    staticStyle: {
      "padding-right": "0"
    }
  }, [_c("div", {
    staticClass: "title"
  }, [_c("img", {
    attrs: {
      src: require("./imgs/Slice 13@2x.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "title-text active"
  }, [_vm._v("水质参数")])]), _c("div", {
    staticClass: "item-content params-list",
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", {
    staticClass: "text"
  }, [_c("span", [_vm._v("溶氧(mg/L)")]), _vm._v(" 8.40 ")]), _c("div", {
    staticClass: "text"
  }, [_c("span", [_vm._v("水温/℃")]), _vm._v(" 21.55 ")]), _c("div", {
    staticClass: "text"
  }, [_c("span", [_vm._v("电导率(μS/cm)")]), _vm._v(" 0.00 ")]), _c("div", {
    staticClass: "text"
  }, [_c("span", [_vm._v("pH")]), _vm._v(" 6.74 ")]), _c("div", {
    staticClass: "text"
  }, [_c("span", [_vm._v("叶绿素(ug/L)")]), _vm._v(" 0.00 ")]), _c("div", {
    staticClass: "text"
  }, [_c("span", [_vm._v("浊度(NTU)")]), _vm._v(" 0.10 ")]), _c("div", {
    staticClass: "text"
  }, [_c("span", [_vm._v("COD(mg/L)")]), _vm._v(" 2346.00 ")]), _c("div", {
    staticClass: "text"
  }, [_c("span", [_vm._v("TOC(mg/L)")]), _vm._v(" 938.00 ")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "title"
  }, [_c("img", {
    attrs: {
      src: require("./imgs/Slice 13@2x.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "title-text active"
  }, [_vm._v("一图总览")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "title"
  }, [_c("img", {
    attrs: {
      src: require("./imgs/Slice 13@2x.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "title-text active"
  }, [_vm._v("实时操作区")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "text"
  }, [_vm._v("工作模式：遥控器控制")]), _c("div", {
    staticClass: "text"
  }, [_vm._v("夹具状态：升起")]), _c("div", {
    staticClass: "text"
  }, [_vm._v("天线状态：展开")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "title"
  }, [_c("img", {
    attrs: {
      src: require("./imgs/Slice 13@2x.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "title-text active"
  }, [_vm._v("任务清单")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };