import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  name: 'ai_model_mng',
  components: {
    aiModelTable: function aiModelTable() {
      return import('./ai_model_page');
    },
    paramsTable: function paramsTable() {
      return import('./model_params_page');
    },
    resultTable: function resultTable() {
      return import('./model_result_page');
    }
  },
  data: function data() {
    return {
      tableShow: 'aiModel',
      current: {}
    };
  },
  methods: {
    goModel: function goModel() {
      this.tableShow = 'aiModel';
    },
    goChild: function goChild(data, tableName) {
      this.current = data;
      this.tableShow = tableName;
    }
  }
};