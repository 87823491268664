export default (function (_ref) {
  var request = _ref.request;
  return {
    regionYieldAdd: function regionYieldAdd(data) {
      return request({
        url: '/regionYield',
        method: 'post',
        data: data
      });
    },
    regionYieldModify: function regionYieldModify(data) {
      return request({
        url: "/regionYield/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    regionYieldDel: function regionYieldDel(id) {
      return request({
        url: "/regionYield/".concat(id),
        method: 'delete'
      });
    },
    regionYieldQuery: function regionYieldQuery(data) {
      return request({
        url: '/regionYield',
        method: 'get',
        params: data
      });
    },
    regionYieldDetail: function regionYieldDetail(id) {
      return request({
        url: "/regionYield/".concat(id),
        method: 'get'
      });
    }
  };
});