export default (function (_ref) {
  var request = _ref.request;
  return {
    diseasesAdd: function diseasesAdd(data) {
      return request({
        url: '/diseases',
        method: 'post',
        data: data
      });
    },
    diseasesModify: function diseasesModify(data) {
      return request({
        url: "/diseases/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    diseasesDel: function diseasesDel(id) {
      return request({
        url: "/diseases/".concat(id),
        method: 'delete'
      });
    },
    diseasesQueryJoinBreedTypeAndDiseasesCategory: function diseasesQueryJoinBreedTypeAndDiseasesCategory(data) {
      return request({
        url: '/diseases/queryJoinBreedTypeAndDiseasesCategory',
        method: 'get',
        params: data
      });
    },
    diseasesQueryForBreedJoinDiseasesCategory: function diseasesQueryForBreedJoinDiseasesCategory(data) {
      return request({
        url: '/diseases/queryForBreedJoinDiseasesCategory',
        method: 'get',
        params: data
      });
    },
    diseasesDetail: function diseasesDetail(id) {
      return request({
        url: "/diseases/".concat(id),
        method: 'get'
      });
    },
    diseasesSelfDiagnose: function diseasesSelfDiagnose(breedId, data) {
      return request({
        url: "/diseases/".concat(breedId, "/selfDiagnose"),
        method: 'get',
        params: data
      });
    }
  };
});