var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("programmeTable", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tableShow === "plan",
      expression: "tableShow === 'plan'"
    }],
    on: {
      "child-go": _vm.goChild
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };