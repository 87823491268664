export default (function (_ref) {
  var request = _ref.request;
  return {
    fisheryTaskBreedAdd: function fisheryTaskBreedAdd(data) {
      return request({
        url: '/fisheryTaskBreed',
        method: 'post',
        data: data
      });
    },
    fisheryTaskBreedModify: function fisheryTaskBreedModify(data) {
      return request({
        url: "/fisheryTaskBreed/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    fisheryTaskBreedDel: function fisheryTaskBreedDel(id) {
      return request({
        url: "/fisheryTaskBreed/".concat(id),
        method: 'delete'
      });
    },
    fisheryTaskBreedQuery: function fisheryTaskBreedQuery(data) {
      return request({
        url: '/fisheryTaskBreed/queryJoinBreedAndPlace',
        method: 'get',
        params: data
      });
    },
    fisheryTaskBreedDetail: function fisheryTaskBreedDetail(id) {
      return request({
        url: "/fisheryTaskBreed/".concat(id),
        method: 'get'
      });
    }
  };
});