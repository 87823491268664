var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "page"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("div", {
    staticClass: "line-chart",
    attrs: {
      id: "line-chart"
    }
  })])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };