import _toConsumableArray from "D:/work/code/Halov-China-Fishery-V2/halov-web/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.concat.js";
import dayjs from 'dayjs';
export default {
  name: 'ecological_water_surface',
  data: function data() {
    return {
      dayjs: dayjs,
      dicData: {
        typeUpperCode: 'iot_sensor_item',
        configTypes: [],
        configTypeOptions: {}
      },
      deviceItem_tableData: [],
      remark: "",
      warn_tableData: []
    };
  },
  mounted: function mounted() {
    this.getDeviceData();
    this.getDetail();
    this.getWarn();
  },
  methods: {
    getDeviceData: function getDeviceData() {
      var _this = this;
      // 监测参数
      this.$api['deviceItemLastRecordQuery']('S3200948S00002').then(function (res) {
        _this.deviceItem_tableData = [].concat(_toConsumableArray(_this.deviceItem_tableData), _toConsumableArray(res.data));
      });
      this.$api['deviceItemLastRecordQuery']('S3201412S00445').then(function (res) {
        _this.deviceItem_tableData = [].concat(_toConsumableArray(_this.deviceItem_tableData), _toConsumableArray(res.data));
        console.log('this.deviceItem_tableData', _this.deviceItem_tableData);
      });
    },
    getDetail: function getDetail() {
      var _this2 = this;
      this.$api['labDetail']('1729022255815118848').then(function (res) {
        _this2.remark = res.data.remark || '';
      });
    } // 获取告警信息
    ,
    getWarn: function getWarn() {
      var _this3 = this;
      this.$api['deviceWarnLogQuery']({
        page: 1,
        limit: 10,
        deviceId: 'S3200948S00002,S3201412S00445'
      }).then(function (res) {
        _this3.warn_tableData = res.data || [];
      });
    }
  }
};