var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "container"
  }, [_c("video", {
    staticClass: "video-js vjs-default-skin",
    attrs: {
      id: "valveVideogj",
      autoplay: "",
      muted: ""
    },
    domProps: {
      muted: true
    }
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };