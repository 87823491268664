import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  name: 'self_diagnosis_mng',
  components: {
    breedTable: function breedTable() {
      return import('./self_diagnosis_breed');
    },
    symptomTable: function symptomTable() {
      return import('./self_diagnosis_symptom');
    }
  },
  data: function data() {
    return {
      tableShow: 'breed',
      current: null,
      checked: false,
      step: [1],
      breedId: '',
      breed: {}
    };
  },
  methods: {
    // 选择品种
    breedSel: function breedSel(val) {
      this.step = [1, 2];
      this.breedId = val.id;
      this.breed = val;
    } // 选择症状
    ,
    symptomSel: function symptomSel(val) {
      console.log('symptomSel', val);
      if (val) {
        this.step = [1, 2, 3];
      } else {
        this.step = [1, 2];
      }
    },
    symptomBack: function symptomBack() {
      this.step = [1];
    }
  }
};