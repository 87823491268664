import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  name: 'product_mng',
  components: {
    onlyGoodsTable: function onlyGoodsTable() {
      return import('./only_goods_page');
    },
    onlyGoodsDetailsTable: function onlyGoodsDetailsTable() {
      return import('./only_goods_details');
    }
  },
  data: function data() {
    return {
      tableShow: 'batch',
      current: {}
    };
  },
  methods: {
    goBatch: function goBatch() {
      this.tableShow = 'batch';
    },
    goChild: function goChild(data, tableName) {
      this.current = data;
      this.tableShow = tableName;
    }
  }
};