export default (function (_ref) {
  var request = _ref.request;
  return {
    breedJoinDiseasesSymptomAdd: function breedJoinDiseasesSymptomAdd(data) {
      return request({
        url: '/breedJoinDiseasesSymptom',
        method: 'post',
        data: data
      });
    },
    breedJoinDiseasesSymptomDel: function breedJoinDiseasesSymptomDel(id) {
      return request({
        url: "/breedJoinDiseasesSymptom/".concat(id),
        method: 'delete'
      });
    },
    breedJoinDiseasesSymptomQuery: function breedJoinDiseasesSymptomQuery(data) {
      return request({
        url: '/breedJoinDiseasesSymptom',
        method: 'get',
        params: data
      });
    },
    breedJoinDiseasesSymptomDetail: function breedJoinDiseasesSymptomDetail(id) {
      return request({
        url: "/breedJoinDiseasesSymptom/".concat(id),
        method: 'get'
      });
    }
  };
});