export default (function (_ref) {
  var request = _ref.request;
  return {
    outlinkAdd: function outlinkAdd(data) {
      return request({
        url: '/outlink',
        method: 'post',
        data: data
      });
    },
    outlinkModify: function outlinkModify(data) {
      return request({
        url: "/outlink/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    outlinkDel: function outlinkDel(id) {
      return request({
        url: "/outlink/".concat(id),
        method: 'delete'
      });
    },
    outlinkQuery: function outlinkQuery(data) {
      return request({
        url: "/outlink/cache",
        method: 'get',
        params: data
      });
    },
    outlinkDetail: function outlinkDetail(id) {
      return request({
        url: "/outlink/".concat(id),
        method: 'get'
      });
    },
    outlinkClearCache: function outlinkClearCache() {
      return request({
        url: '/outlink/cache/clear',
        method: 'delete'
      });
    }
  };
});