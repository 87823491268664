import _createForOfIteratorHelper from "D:/work/code/Halov-China-Fishery-V2/halov-web/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.function.name.js";
export default {
  name: 'breed_segment_sel',
  props: {
    breedType: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      pageConfig: {
        editPageName: '疾病管理',
        queryFunName: 'diseasesQueryJoinBreedTypeAndDiseasesCategory',
        dicTreeFunName: 'dictionaryCache'
      },
      searchForm: {
        page: 1,
        limit: 7,
        searchKey: null,
        breedType: this.breedType
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      dicData: {
        typeUpperCode: 'Fishery_Breed_Type',
        configTypes: [],
        configTypeOptions: {},
        diseasesUpperCode: 'Fishery_Diseases_Type',
        configDiseases: [],
        configDiseasesOptions: {}
      }
    };
  },
  mounted: function mounted() {
    var _this = this;
    Promise.all([new Promise(function (resolve, reject) {
      _this.$api[_this.pageConfig.dicTreeFunName](_this.dicData.typeUpperCode).then(function (res) {
        if (res.data) {
          _this.dicData.configTypes = res.data;
          var _iterator = _createForOfIteratorHelper(res.data),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var item = _step.value;
              _this.dicData.configTypeOptions[item.code] = item.name;
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
        }
        resolve(res);
      });
    }), new Promise(function (resolve, reject) {
      _this.$api[_this.pageConfig.dicTreeFunName](_this.dicData.diseasesUpperCode).then(function (res) {
        if (res.data) {
          _this.dicData.configDiseases = res.data;
          var _iterator2 = _createForOfIteratorHelper(res.data),
            _step2;
          try {
            for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
              var item = _step2.value;
              _this.dicData.configDiseasesOptions[item.code] = item.name;
            }
          } catch (err) {
            _iterator2.e(err);
          } finally {
            _iterator2.f();
          }
        }
        resolve(res);
      });
    })]).then(function (result) {
      _this.query();
    });
  },
  methods: {
    sel: function sel(data) {
      this.$emit('child-disease-sel', data);
    },
    query: function query() {
      var _this2 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this2.tableData = res.data || [];
        _this2.totalCount = res.count;
        _this2.queryLoading = false;
      });
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};