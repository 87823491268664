import _objectSpread from "D:/work/code/Halov-China-Fishery-V2/halov-web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import dayjs from 'dayjs';
export default {
  name: 'warn_log_page',
  components: {
    userSel: function userSel() {
      return import('../user/user_sel.vue');
    }
  },
  data: function data() {
    return {
      dayjs: dayjs,
      pageConfig: {
        editPageName: '预警处置',
        queryFunName: 'deviceWarnLogQuery',
        detailFunName: 'deviceWarnLogDetail',
        delFunName: 'deviceWarnLogDel',
        handleFunName: 'deviceWarnLogHandle'
      },
      searchForm: {
        page: 1,
        limit: 10,
        sortField: 'create_time'
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      editDialogShow: false,
      editDialogTitle: '',
      formLabelWidth: '100px',
      emptyFormData: {},
      formData: {},
      user_show: false,
      handleDialogShow: false,
      handleFormData: {}
    };
  },
  mounted: function mounted() {
    this.query();
  },
  methods: {
    del: function del(id) {
      var _this = this;
      this.$confirm('确定要删除吗').then(function () {
        _this.$api[_this.pageConfig.delFunName](id).then(function (res) {
          if (res.data) {
            _this.$message.success('删除成功');
            _this.query();
          } else {
            _this.$message.error('删除失败');
          }
        });
      });
    },
    detail: function detail(id, data) {
      // this.$api[this.pageConfig.detailFunName](id).then(async(res) => {
      //   this.editDialogShow = true
      //   this.formData = res.data
      // })
      this.formData = data;
      this.editDialogShow = true;
    } // 选择处置人
    ,
    userSel: function userSel(data) {
      if (data) {
        this.formData.handleUser = data.id;
        this.$set(this.formData, 'handleUserNickname', data.nickname);
      }
      this.user_show = false;
    },
    handle: function handle(id) {
      this.handle_id = id;
      this.handleFormData = {};
      this.$set(this.handleFormData, 'handleUser', localStorage.getItem('userId'));
      this.$set(this.handleFormData, 'handleUserNickname', localStorage.getItem('userNickname'));
      this.$set(this.handleFormData, 'handleTime', dayjs().format('YYYYMMDDHHmmss'));
      console.log('handleFormData', this.handleFormData);
      this.handleDialogShow = true;
    },
    submitHandleFormData: function submitHandleFormData(handleState) {
      var _this2 = this;
      this.$refs['formDataVerify'].validate(function (valid) {
        if (valid) {
          _this2.$api[_this2.pageConfig.handleFunName](_this2.handle_id, _objectSpread(_objectSpread({}, _this2.handleFormData), {}, {
            handleState: handleState
          })).then(function (res) {
            if (res.data) {
              _this2.handleDialogShow = false;
              _this2.query();
            } else {
              _this2.$message.error('操作失败');
            }
          });
        }
      });
    },
    query: function query() {
      var _this3 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this3.tableData = res.data || [];
        _this3.totalCount = res.count;
        _this3.queryLoading = false;
      });
    },
    closeEditDialogShow: function closeEditDialogShow() {
      this.editDialogTitle = '';
      this.editDialogShow = false;
      this.initFormData(this.emptyFormData);
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};