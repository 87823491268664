export default (function (_ref) {
  var request = _ref.request;
  return {
    aquaticProductsAdd: function aquaticProductsAdd(data) {
      return request({
        url: '/aquaticProducts',
        method: 'post',
        data: data
      });
    },
    aquaticProductsModify: function aquaticProductsModify(data) {
      return request({
        url: "/aquaticProducts/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    aquaticProductsDel: function aquaticProductsDel(id) {
      return request({
        url: "/aquaticProducts/".concat(id),
        method: 'delete'
      });
    },
    aquaticProductsQuery: function aquaticProductsQuery(data) {
      return request({
        url: '/aquaticProducts',
        method: 'get',
        params: data
      });
    },
    aquaticProductsDetail: function aquaticProductsDetail(id) {
      return request({
        url: "/aquaticProducts/".concat(id),
        method: 'get'
      });
    }
  };
});