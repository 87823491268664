export default (function (_ref) {
  var request = _ref.request;
  return {
    labYieldAdd: function labYieldAdd(data) {
      return request({
        url: '/labYield',
        method: 'post',
        data: data
      });
    },
    labYieldModify: function labYieldModify(data) {
      return request({
        url: "/labYield/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    labYieldDel: function labYieldDel(id) {
      return request({
        url: "/labYield/".concat(id),
        method: 'delete'
      });
    },
    labYieldQuery: function labYieldQuery(data) {
      return request({
        url: '/labYield',
        method: 'get',
        params: data
      });
    },
    labYieldDetail: function labYieldDetail(id) {
      return request({
        url: "/labYield/".concat(id),
        method: 'get'
      });
    }
  };
});