export default (function (_ref) {
  var request = _ref.request;
  return {
    deviceAdd: function deviceAdd(data) {
      return request({
        url: '/iot/device',
        method: 'post',
        data: data
      });
    },
    deviceModify: function deviceModify(data) {
      return request({
        url: "/iot/device/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    deviceBind: function deviceBind(id, data) {
      return request({
        url: "/iot/device/".concat(id, "/bind"),
        method: 'POST',
        data: data
      });
    },
    deviceUnbind: function deviceUnbind(id) {
      return request({
        url: "/iot/device/".concat(id, "/unbind"),
        method: 'delete'
      });
    },
    deviceDel: function deviceDel(id) {
      return request({
        url: "/iot/device/".concat(id),
        method: 'delete'
      });
    },
    deviceQuery: function deviceQuery(data) {
      return request({
        url: '/iot/device',
        method: 'get',
        params: data
      });
    },
    deviceQueryJoinLab: function deviceQueryJoinLab(data) {
      return request({
        url: '/iot/device/queryJoinLab',
        method: 'get',
        params: data
      });
    },
    deviceDetail: function deviceDetail(id) {
      return request({
        url: "/iot/device/".concat(id),
        method: 'get'
      });
    },
    deviceReportAmount: function deviceReportAmount(params) {
      return request({
        url: "/iot/device/reportAmount",
        method: 'get',
        params: params
      });
    }
  };
});