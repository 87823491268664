import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "container self_diagnosis_symptom"
  }, [_c("div", {
    staticClass: "left"
  }, [_c("div", {
    staticClass: "title"
  }, [_c("i", {
    staticClass: "el-icon-back"
  }), _c("div", {
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: _vm.back
    }
  }, [_vm._v("返回上一步")]), _c("div", {
    staticClass: "line"
  }), _c("div", [_vm._v(_vm._s(_vm.breed.name) + "症状")])]), _c("div", {
    staticClass: "left-content"
  }, [_c("el-checkbox-group", {
    model: {
      value: _vm.checked,
      callback: function callback($$v) {
        _vm.checked = $$v;
      },
      expression: "checked"
    }
  }, [_c("el-tabs", {
    staticStyle: {
      height: "100%"
    },
    attrs: {
      "tab-position": "left"
    }
  }, _vm._l(_vm.dicData.configTypeOptions, function (item, key) {
    return _c("el-tab-pane", {
      key: key,
      attrs: {
        label: _vm.dicData.configTypeLabels[key]
      }
    }, [_c("div", {
      staticClass: "symptom-list"
    }, _vm._l(item, function (opt) {
      return _c("el-checkbox", {
        key: opt.diseasesSymptomId,
        attrs: {
          label: opt.diseasesSymptomId
        }
      }, [_vm._v(_vm._s(opt.diseasesSymptomName))]);
    }), 1)]);
  }), 1)], 1)], 1)]), _c("div", {
    staticClass: "center"
  }, [_c("div", {
    staticClass: "btn",
    on: {
      click: _vm.sel
    }
  }, [_vm._v("开始诊断")]), _c("div", {
    staticClass: "btn",
    staticStyle: {
      "margin-top": "24px"
    },
    on: {
      click: _vm.reset
    }
  }, [_vm._v("重置症状")])]), _vm.list.length ? _c("div", {
    staticClass: "right"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("诊断结果")]), _c("div", {
    staticClass: "right-list"
  }, _vm._l(_vm.list, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "item"
    }, [_c("div", [_c("div", {
      staticClass: "symptom"
    }, [_c("div", {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.name))]), _c("div", {
      staticClass: "num"
    }, [_vm._v(_vm._s(item.weights || 0) + "%")]), _c("div", {
      staticClass: "label"
    }, [_vm._v("概率")])]), _c("div", {
      staticClass: "btn",
      on: {
        click: function click($event) {
          return _vm.lookClick(item);
        }
      }
    }, [_c("i", {
      staticClass: "el-icon-search"
    }), _vm._v("查看")])])]);
  }), 0)]) : _vm._e(), _c("el-dialog", {
    attrs: {
      visible: _vm.tip_show,
      title: _vm.result.name
    },
    on: {
      close: function close($event) {
        _vm.tip_show = false;
      }
    }
  }, [_c("div", {
    staticClass: "dialog-content",
    domProps: {
      innerHTML: _vm._s(_vm.result.content)
    }
  })])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };