export default (function (_ref) {
  var request = _ref.request;
  return {
    modelResultAdd: function modelResultAdd(data) {
      return request({
        url: '/modelResult',
        method: 'post',
        data: data
      });
    },
    modelResultModify: function modelResultModify(data) {
      return request({
        url: "/modelResult/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    modelResultDel: function modelResultDel(id) {
      return request({
        url: "/modelResult/".concat(id),
        method: 'delete'
      });
    },
    modelResultQuery: function modelResultQuery(data) {
      return request({
        url: '/modelResult',
        method: 'get',
        params: data
      });
    },
    modelResultDetail: function modelResultDetail(id) {
      return request({
        url: "/modelResult/".concat(id),
        method: 'get'
      });
    }
  };
});