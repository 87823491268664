export default (function (_ref) {
  var request = _ref.request;
  return {
    fisheryTaskCatchAdd: function fisheryTaskCatchAdd(data) {
      return request({
        url: '/fisheryTaskCatch',
        method: 'post',
        data: data
      });
    },
    fisheryTaskCatchModify: function fisheryTaskCatchModify(data) {
      return request({
        url: "/fisheryTaskCatch/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    fisheryTaskCatchDel: function fisheryTaskCatchDel(id) {
      return request({
        url: "/fisheryTaskCatch/".concat(id),
        method: 'delete'
      });
    },
    fisheryTaskCatchQuery: function fisheryTaskCatchQuery(data) {
      return request({
        url: '/fisheryTaskCatch/queryJoinBreedAndPlace',
        method: 'get',
        params: data
      });
    },
    fisheryTaskCatchDetail: function fisheryTaskCatchDetail(id) {
      return request({
        url: "/fisheryTaskCatch/".concat(id),
        method: 'get'
      });
    },
    fisheryTaskCatchSeedDate: function fisheryTaskCatchSeedDate(id) {
      return request({
        url: "/fisheryTaskCatch/".concat(id, "/seedDate"),
        method: 'get'
      });
    }
  };
});