export default (function (_ref) {
  var request = _ref.request;
  return {
    breedModeAdd: function breedModeAdd(data) {
      return request({
        url: '/breedMode',
        method: 'post',
        data: data
      });
    },
    breedModeModify: function breedModeModify(data) {
      return request({
        url: "/breedMode/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    breedModeDel: function breedModeDel(id) {
      return request({
        url: "/breedMode/".concat(id),
        method: 'delete'
      });
    },
    breedModeQuery: function breedModeQuery(data) {
      return request({
        url: '/breedMode',
        method: 'get',
        params: data
      });
    },
    breedModeDetail: function breedModeDetail(id) {
      return request({
        url: "/breedMode/".concat(id),
        method: 'get'
      });
    }
  };
});