var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("productTable", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tableShow === "product",
      expression: "tableShow === 'product'"
    }],
    on: {
      "child-go": _vm.goChild
    }
  }), _vm.tableShow === "productItem" ? _c("productItemTable", {
    attrs: {
      current: _vm.current
    },
    on: {
      "child-go-product": _vm.goProduct,
      "child-go": _vm.goChild
    }
  }) : _vm._e(), _vm.tableShow === "askCmd" ? _c("askCmdTable", {
    attrs: {
      current: _vm.current
    },
    on: {
      "child-go-product": _vm.goProduct,
      "child-go": _vm.goChild
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };