export default (function (_ref) {
  var request = _ref.request;
  return {
    noticeAdd: function noticeAdd(data) {
      return request({
        url: '/notice',
        method: 'post',
        data: data
      });
    },
    noticeModify: function noticeModify(data) {
      return request({
        url: "/notice/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    noticeDel: function noticeDel(id) {
      return request({
        url: "/notice/".concat(id),
        method: 'delete'
      });
    },
    noticeQueryJoinUser: function noticeQueryJoinUser(data) {
      return request({
        url: '/notice/joinUser',
        method: 'get',
        params: data
      });
    },
    noticeDetail: function noticeDetail(id) {
      return request({
        url: "/notice/".concat(id),
        method: 'get'
      });
    },
    noticeSend: function noticeSend(id) {
      return request({
        url: "/notice/".concat(id, "/send"),
        method: 'post'
      });
    }
  };
});