export default {
  data: function data() {
    return {
      sys_name: ''
    };
  },
  mounted: function mounted() {
    this.getName();
  },
  methods: {
    getName: function getName() {
      var _this = this;
      this.$api['aboutsCache']('sys_name').then(function (res) {
        if (res.data) {
          _this.sys_name = res.data.sys_name;
        } else {
          _this.$message.error(res.msg);
        }
      });
    }
  }
};