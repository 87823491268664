import "core-js/modules/es.number.constructor.js";
import dayjs from "dayjs";
var NOW_PLAN = '#D5F8EA';
var FUTHER_PLAN = '#BFF2FE';
var PAST_PLAN = '#F2F2F2';
export default {
  name: "Test",
  props: {
    data: Object,
    item: Object,
    currentTime: dayjs,
    updateTimeLines: Function,
    cellHeight: Number,
    startTimeOfRenderArea: Number
  },
  data: function data() {
    return {
      dayjs: dayjs
    };
  },
  mounted: function mounted() {
    console.log('data', this.data);
    console.log('item', this.item);
  },
  computed: {
    statusColor: function statusColor() {
      var item = this.item,
        currentTime = this.currentTime;
      var start = dayjs(this.dateFormat2Str(item.startDate));
      var end = dayjs(this.dateFormat2Str(item.endDate));
      if (start.isBefore(currentTime) && end.isAfter(currentTime)) {
        return NOW_PLAN; // NOW
      } else if (end.isBefore(currentTime)) {
        return PAST_PLAN; // PAST
      } else {
        return FUTHER_PLAN; // Future
      }
    }
  },

  methods: {
    onClick: function onClick() {
      this.updateTimeLines(this.item.start, this.item.end);
    },
    edit: function edit(data) {
      this.$emit('edit', data);
    },
    del: function del(id) {
      this.$emit('del', id);
    }
  }
};