import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.json.stringify.js";
import dayjs from 'dayjs';
export default {
  name: 'breed_mode_page',
  props: {
    current: {
      type: Object,
      default: {}
    }
  },
  components: {
    segmentSel: function segmentSel() {
      return import('./breed_segment_sel');
    },
    modeSel: function modeSel() {
      return import('./breed_mode_sel');
    },
    formulaSel: function formulaSel() {
      return import('./breed_formula_sel');
    }
  },
  data: function data() {
    return {
      pageConfig: {
        editPageName: '投喂方法',
        addFunName: 'breedFeedMethodAdd',
        modifyFunName: 'breedFeedMethodModify',
        delFunName: 'breedFeedMethodDel',
        queryFunName: 'breedFeedMethodQuery',
        detailFunName: 'breedFeedMethodDetail'
      },
      searchForm: {
        page: 1,
        limit: 10,
        breedId: this.current.id,
        sortField: '^sort'
      },
      formData: {
        breedId: this.current.id
      },
      rules: {
        breedModeId: [{
          required: true,
          message: '请选择养殖模式',
          trigger: 'blur'
        }],
        breedSegmentId: [{
          required: true,
          message: '请选择养殖环节',
          trigger: 'blur'
        }],
        breedFormulaId: [{
          required: true,
          message: '请选择养殖饲料',
          trigger: 'blur'
        }]
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      editDialogShow: false,
      editDialogTitle: '',
      formLabelWidth: '100px',
      emptyFormData: {},
      segment_show: false,
      mode_show: false,
      formula_show: false
    };
  },
  mounted: function mounted() {
    this.emptyFormData = JSON.parse(JSON.stringify(this.formData));
    this.query();
  },
  methods: {
    back: function back() {
      this.$emit('child-go-breed');
    },
    initFormData: function initFormData(data) {
      var paramData = JSON.parse(JSON.stringify(data));
      this.formData = paramData;
      this.formData.breedId = this.current.id;
      if (typeof this.$refs['formDataVerify'] !== 'undefined') {
        this.$refs['formDataVerify'].clearValidate();
      }
    },
    saveFormDataProcess: function saveFormDataProcess(formData) {
      var saveFormData = JSON.parse(JSON.stringify(formData));
      return saveFormData;
    },
    add: function add() {
      this.editDialogTitle = this.pageConfig.editPageName + '新增';
      this.editDialogShow = true;
      this.formData.upperCode = this.searchForm.upperCode;
    },
    edit: function edit(data) {
      //   this.$api[this.pageConfig.detailFunName](id).then((res) => {
      //     this.initFormData(res.data)

      //     this.editDialogTitle = this.pageConfig.editPageName + '维护'
      //     this.editDialogShow = true
      //   })
      this.initFormData(data);
      this.editDialogTitle = this.pageConfig.editPageName + '维护';
      this.editDialogShow = true;
    } // 选择配方饲料
    ,
    formulaSel: function formulaSel(data) {
      if (data) {
        this.$set(this.formData, 'breedFormulaId', data.id);
        this.$set(this.formData, 'material', data.material);
      }
      this.formula_show = false;
    } // 选择养殖模式
    ,
    modeSel: function modeSel(data) {
      if (data) {
        this.$set(this.formData, 'breedModeId', data.id);
        this.$set(this.formData, 'mode', data.mode);
      }
      this.mode_show = false;
    } // 选择养殖环节
    ,
    segmentSel: function segmentSel(data) {
      if (data) {
        this.$set(this.formData, 'breedSegmentId', data.id);
        this.$set(this.formData, 'segment', data.segment);
      }
      this.segment_show = false;
    },
    del: function del(id) {
      var _this = this;
      this.$confirm('确定要删除吗').then(function () {
        _this.$api[_this.pageConfig.delFunName](id).then(function (res) {
          if (res.data) {
            _this.$message.success('删除成功');
            _this.query();
          } else {
            _this.$message.error('删除失败');
          }
        });
      });
    },
    query: function query() {
      var _this2 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this2.tableData = res.data || [];
        _this2.totalCount = res.count;
        _this2.queryLoading = false;
      });
    },
    submitFormData: function submitFormData() {
      var _this3 = this;
      this.$refs['formDataVerify'].validate(function (valid) {
        if (valid) {
          if (_this3.formData.id) {
            _this3.$api[_this3.pageConfig.modifyFunName](_this3.saveFormDataProcess(_this3.formData)).then(function (res) {
              if (res.data) {
                _this3.$message.success('编辑成功');
                _this3.initFormData(_this3.emptyFormData);
                _this3.editDialogShow = false;
                _this3.query();
              } else {
                _this3.$message.error('编辑失败');
              }
            });
          } else {
            _this3.$api[_this3.pageConfig.addFunName](_this3.saveFormDataProcess(_this3.formData)).then(function (res) {
              if (res.data) {
                _this3.$message.success('新增成功');
                _this3.initFormData(_this3.emptyFormData);
                _this3.editDialogShow = false;
                _this3.query();
              } else {
                _this3.$message.error('新增失败');
              }
            });
          }
        }
      });
    },
    closeEditDialogShow: function closeEditDialogShow() {
      this.editDialogTitle = '';
      this.editDialogShow = false;
      this.initFormData(this.emptyFormData);
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : '^sort';
      this.query();
    }
  }
};