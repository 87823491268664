export default (function (_ref) {
  var request = _ref.request;
  return {
    workOrderQuery: function workOrderQuery(data) {
      return request({
        url: '/workorder/joinUser',
        method: 'get',
        params: data
      });
    },
    workOrderRead: function workOrderRead(id) {
      return request({
        url: "/workorder/".concat(id, "/read"),
        method: 'put'
      });
    },
    workOrderAdd: function workOrderAdd(data) {
      return request({
        url: "/workorder",
        method: 'POST',
        data: data
      });
    }
  };
});