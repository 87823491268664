export default (function (_ref) {
  var request = _ref.request;
  return {
    breedWaterManageAdd: function breedWaterManageAdd(data) {
      return request({
        url: '/breedWaterManage',
        method: 'post',
        data: data
      });
    },
    breedWaterManageModify: function breedWaterManageModify(data) {
      return request({
        url: "/breedWaterManage/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    breedWaterManageDel: function breedWaterManageDel(id) {
      return request({
        url: "/breedWaterManage/".concat(id),
        method: 'delete'
      });
    },
    breedWaterManageQuery: function breedWaterManageQuery(data) {
      return request({
        url: '/breedWaterManage',
        method: 'get',
        params: data
      });
    },
    breedWaterManageDetail: function breedWaterManageDetail(id) {
      return request({
        url: "/breedWaterManage/".concat(id),
        method: 'get'
      });
    }
  };
});