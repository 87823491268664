export default (function (_ref) {
  var request = _ref.request;
  return {
    fisheryTaskCustomAdd: function fisheryTaskCustomAdd(data) {
      return request({
        url: '/fisheryTaskCustom',
        method: 'post',
        data: data
      });
    },
    fisheryTaskCustomModify: function fisheryTaskCustomModify(data) {
      return request({
        url: "/fisheryTaskCustom/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    fisheryTaskCustomDel: function fisheryTaskCustomDel(id) {
      return request({
        url: "/fisheryTaskCustom/".concat(id),
        method: 'delete'
      });
    },
    fisheryTaskCustomQuery: function fisheryTaskCustomQuery(data) {
      return request({
        url: '/fisheryTaskCustom/queryJoinBreedAndPlace',
        method: 'get',
        params: data
      });
    },
    fisheryTaskCustomDetail: function fisheryTaskCustomDetail(id) {
      return request({
        url: "/fisheryTaskCustom/".concat(id),
        method: 'get'
      });
    }
  };
});