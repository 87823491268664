export default (function (_ref) {
  var request = _ref.request;
  return {
    videoAdd: function videoAdd(data) {
      return request({
        url: '/iot/video',
        method: 'post',
        data: data
      });
    },
    videoModify: function videoModify(data) {
      return request({
        url: "/iot/video/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    videoDel: function videoDel(id) {
      return request({
        url: "/iot/video/".concat(id),
        method: 'delete'
      });
    },
    videoBind: function videoBind(id, data) {
      return request({
        url: "/iot/video/".concat(id, "/bind"),
        method: 'POST',
        data: data
      });
    },
    videoUnbind: function videoUnbind(id) {
      return request({
        url: "/iot/video/".concat(id, "/unbind"),
        method: 'delete'
      });
    },
    videoQuery: function videoQuery(data) {
      return request({
        url: '/iot/video',
        method: 'get',
        params: data
      });
    },
    videoQueryJoinLab: function videoQueryJoinLab(data) {
      return request({
        url: '/iot/video/queryJoinLab',
        method: 'get',
        params: data
      });
    },
    videoDetail: function videoDetail(id) {
      return request({
        url: "/iot/video/".concat(id),
        method: 'get'
      });
    }
  };
});