import "core-js/modules/es.json.stringify.js";
export default {
  name: 'productItem_page',
  props: {
    current: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      pageConfig: {
        editPageName: '参数',
        addFunName: 'productItemAdd',
        modifyFunName: 'productItemModify',
        delFunName: 'productItemDel',
        queryFunName: 'productItemQuery',
        detailFunName: 'productItemDetail',
        dicTreeFunName: 'dictionaryCache'
      },
      searchForm: {
        page: 1,
        limit: 10,
        searchKey: null,
        productId: this.current.id,
        sortField: 'create_time',
        type: ''
      },
      formData: {
        productId: this.current.id,
        remark: null,
        type: null,
        unit: null,
        valuePath: null,
        valueTimePath: null,
        valueType: 'Integer',
        valueMultiply: 1,
        valueOffset: 0,
        interactiveProtocol: 'Modbus_RTU',
        endianMode: 'Default'
      },
      rules: {
        valueType: [{
          required: true,
          message: '请选择值类型',
          trigger: 'blur'
        }],
        typeName: [{
          required: true,
          message: '请输入检测项目名称',
          trigger: 'blur'
        }],
        typeCode: [{
          required: true,
          message: '请输入检测项目代码',
          trigger: 'blur'
        }],
        unitName: [{
          required: true,
          message: '请输入计量单位名称',
          trigger: 'blur'
        }],
        unit: [{
          required: true,
          message: '请输入计量单位',
          trigger: 'blur'
        }],
        slave: [{
          required: true,
          message: '请输入从机地址',
          trigger: 'blur'
        }],
        valueStartLocation: [{
          required: true,
          message: '请输入取值起始',
          trigger: 'blur'
        }],
        valueEndLocation: [{
          required: true,
          message: '请输入取值结束',
          trigger: 'blur'
        }],
        valuePath: [{
          required: true,
          message: '请输入值路径',
          trigger: 'blur'
        }],
        valueTimePath: [{
          required: true,
          message: '请输入时间路径',
          trigger: 'blur'
        }],
        valueMultiply: [{
          required: true,
          message: '请输入倍增量',
          trigger: 'blur'
        }],
        valueOffset: [{
          required: true,
          message: '请输入偏移量',
          trigger: 'blur'
        }]
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      editDialogShow: false,
      editDialogTitle: '',
      formLabelWidth: '120px',
      emptyFormData: {}
    };
  },
  watch: {
    'formData.valueType': {
      handler: function handler(to) {
        if (to == 'Float' && this.formData.interactiveProtocol == 'JSON') {
          this.$set(this.formData, 'endianMode', 'Default');
        }
      },
      deep: true
    },
    'formData.interactiveProtocol': {
      handler: function handler(to) {
        if (this.formData.valueType == 'Float' && to == 'JSON') {
          this.$set(this.formData, 'endianMode', 'Default');
        }
      },
      deep: true
    }
  },
  mounted: function mounted() {
    // console.log('productItem_page this.current',this.current)
    // this.productId = this.current.id
    this.emptyFormData = JSON.parse(JSON.stringify(this.formData));
    this.query();
  },
  methods: {
    back: function back() {
      this.$emit('child-go-product');
    },
    initFormData: function initFormData(data) {
      var paramData = JSON.parse(JSON.stringify(data));
      this.formData = paramData;
      if (!this.formData.valueType) {
        this.formData.valueType = 'Integer';
      }
      if (!this.formData.endianMode) {
        this.formData.endianMode = 'Default';
      }
      if (typeof this.$refs['formDataVerify'] !== 'undefined') {
        this.$refs['formDataVerify'].clearValidate();
      }
    },
    saveFormDataProcess: function saveFormDataProcess(formData) {
      var saveFormData = JSON.parse(JSON.stringify(formData));
      return saveFormData;
    },
    add: function add() {
      this.editDialogTitle = this.pageConfig.editPageName + '新增';
      this.editDialogShow = true;
      this.formData.upperCode = this.searchForm.upperCode;
    },
    edit: function edit(id) {
      var _this = this;
      this.$api[this.pageConfig.detailFunName](id).then(function (res) {
        _this.initFormData(res.data);
        _this.editDialogTitle = _this.pageConfig.editPageName + '维护';
        _this.editDialogShow = true;
      });
    },
    del: function del(id) {
      var _this2 = this;
      this.$confirm('确定要删除吗').then(function () {
        _this2.$api[_this2.pageConfig.delFunName](id).then(function (res) {
          if (res.data) {
            _this2.$message.success('删除成功');
            _this2.query();
          } else {
            _this2.$message.error('删除失败');
          }
        });
      });
    },
    query: function query() {
      var _this3 = this;
      this.queryLoading = true;
      this.searchForm.productId = this.current.id;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this3.tableData = res.data || [];
        _this3.totalCount = res.count;
        _this3.queryLoading = false;
      });
    },
    submitFormData: function submitFormData() {
      var _this4 = this;
      this.$refs['formDataVerify'].validate(function (valid) {
        if (valid) {
          _this4.formData.productId = _this4.current.id;
          if (_this4.formData.id) {
            _this4.$api[_this4.pageConfig.modifyFunName](_this4.saveFormDataProcess(_this4.formData)).then(function (res) {
              if (res.data) {
                _this4.$message.success('编辑成功');
                _this4.initFormData(_this4.emptyFormData);
                _this4.editDialogShow = false;
                _this4.query();
              } else {
                _this4.$message.error('编辑失败');
              }
            });
          } else {
            _this4.$api[_this4.pageConfig.addFunName](_this4.saveFormDataProcess(_this4.formData)).then(function (res) {
              if (res.data) {
                _this4.$message.success('新增成功');
                _this4.initFormData(_this4.emptyFormData);
                _this4.editDialogShow = false;
                _this4.query();
              } else {
                _this4.$message.error('新增失败');
              }
            });
          }
        }
      });
    },
    closeEditDialogShow: function closeEditDialogShow() {
      this.editDialogTitle = '';
      this.editDialogShow = false;
      this.initFormData(this.emptyFormData);
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : '^sort';
      this.query();
    }
  }
};