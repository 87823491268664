import _createForOfIteratorHelper from "D:/work/code/Halov-China-Fishery-V2/halov-web/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.function.name.js";
import { number } from 'echarts/lib/export';
export default {
  name: 'disease_symptom_set',
  props: {
    breedJoinDiseasesId: {
      type: String,
      default: ''
    },
    weights: {
      type: number,
      default: 0
    }
  },
  data: function data() {
    return {
      pageConfig: {
        editPageName: '疾病症状',
        queryFunName: 'diseasesSymptomQueryForBreedDiseasesJoinCategory',
        addFunName: 'breedJoinDiseasesSymptomAdd',
        dicTreeFunName: 'dictionaryCache'
      },
      searchForm: {
        page: 1,
        limit: 7,
        searchKey: null,
        breedJoinDiseasesId: this.breedJoinDiseasesId
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      dicData: {
        typeUpperCode: 'Fishery_Symptom_Type',
        configTypes: [],
        configTypeOptions: {}
      }
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$api[this.pageConfig.dicTreeFunName](this.dicData.typeUpperCode).then(function (res) {
      if (res.data) {
        _this.dicData.configTypes = res.data;
        var _iterator = _createForOfIteratorHelper(res.data),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var item = _step.value;
            _this.dicData.configTypeOptions[item.code] = item.name;
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      }
      _this.query();
    });
  },
  methods: {
    sel: function sel(data) {
      this.$emit('child-symptom-set', data);
    },
    set: function set(data, index) {
      var _this2 = this;
      console.log('set', data, index);
      this.index = index;
      this.$prompt('权重', '设置权重', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValidator: this.inputValidator
      }).then(function (_ref) {
        var value = _ref.value;
        _this2.$api[_this2.pageConfig.addFunName]({
          breedJoinDiseasesId: _this2.breedJoinDiseasesId,
          diseasesSymptomId: data.id,
          weights: value
        }).then(function (res) {
          if (res.data) {
            _this2.$message.success('新增成功');
            _this2.sel(true);
          } else {
            _this2.$message.error('新增失败');
          }
        });
      }).catch(function () {});
    },
    inputValidator: function inputValidator(val) {
      val = val * 1;
      console.log('inputValidator val', val, this.weights, this.tableData[this.index].symptomWeights);
      if (val < 0) {
        return '请输入正整数';
      }
      if (this.weights + (val - this.tableData[this.index].symptomWeights) > 100) {
        return '权重之和不能大于100';
      }
    },
    query: function query() {
      var _this3 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this3.tableData = res.data || [];
        _this3.totalCount = res.count;
        _this3.queryLoading = false;
      });
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};