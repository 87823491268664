import { render, staticRenderFns } from "./zoneInfo_form.vue?vue&type=template&id=3858c244&scoped=true&"
import script from "./zoneInfo_form.vue?vue&type=script&lang=js&"
export * from "./zoneInfo_form.vue?vue&type=script&lang=js&"
import style0 from "./zoneInfo_form.vue?vue&type=style&index=0&id=3858c244&lang=scss&scoped=true&"
import style1 from "./zoneInfo_form.vue?vue&type=style&index=1&id=3858c244&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3858c244",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\work\\code\\Halov-China-Fishery-V2\\halov-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3858c244')) {
      api.createRecord('3858c244', component.options)
    } else {
      api.reload('3858c244', component.options)
    }
    module.hot.accept("./zoneInfo_form.vue?vue&type=template&id=3858c244&scoped=true&", function () {
      api.rerender('3858c244', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/visualizationData/zoneInfo_form.vue"
export default component.exports