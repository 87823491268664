export default (function (_ref) {
  var request = _ref.request;
  return {
    fisheryTaskCleanAdd: function fisheryTaskCleanAdd(data) {
      return request({
        url: '/fisheryTaskClean',
        method: 'post',
        data: data
      });
    },
    fisheryTaskCleanModify: function fisheryTaskCleanModify(data) {
      return request({
        url: "/fisheryTaskClean/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    fisheryTaskCleanDel: function fisheryTaskCleanDel(id) {
      return request({
        url: "/fisheryTaskClean/".concat(id),
        method: 'delete'
      });
    },
    fisheryTaskCleanQuery: function fisheryTaskCleanQuery(data) {
      return request({
        url: '/fisheryTaskClean/queryJoinBreedAndPlace',
        method: 'get',
        params: data
      });
    },
    fisheryTaskCleanDetail: function fisheryTaskCleanDetail(id) {
      return request({
        url: "/fisheryTaskClean/".concat(id),
        method: 'get'
      });
    }
  };
});