export default (function (_ref) {
  var request = _ref.request;
  return {
    deviceAskcmdAdd: function deviceAskcmdAdd(data) {
      return request({
        url: '/deviceAskcmd',
        method: 'post',
        data: data
      });
    },
    deviceAskcmdModify: function deviceAskcmdModify(data) {
      return request({
        url: "/deviceAskcmd/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    deviceAskcmdDel: function deviceAskcmdDel(id) {
      return request({
        url: "/deviceAskcmd/".concat(id),
        method: 'delete'
      });
    },
    deviceAskcmdAsk: function deviceAskcmdAsk(id) {
      return request({
        url: "/deviceAskcmd/".concat(id, "/ask"),
        method: 'get'
      });
    },
    deviceAskcmdQuery: function deviceAskcmdQuery(data) {
      return request({
        url: '/deviceAskcmd',
        method: 'get',
        params: data
      });
    },
    deviceAskcmdDetail: function deviceAskcmdDetail(id) {
      return request({
        url: "/deviceAskcmd/".concat(id),
        method: 'get'
      });
    }
  };
});