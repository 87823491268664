export default (function (_ref) {
  var request = _ref.request;
  return {
    diseasesSymptomAdd: function diseasesSymptomAdd(data) {
      return request({
        url: '/diseasesSymptom',
        method: 'post',
        data: data
      });
    },
    diseasesSymptomModify: function diseasesSymptomModify(data) {
      return request({
        url: "/diseasesSymptom/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    diseasesSymptomDel: function diseasesSymptomDel(id) {
      return request({
        url: "/diseasesSymptom/".concat(id),
        method: 'delete'
      });
    },
    diseasesSymptomQueryForBreedDiseasesJoinCategory: function diseasesSymptomQueryForBreedDiseasesJoinCategory(data) {
      return request({
        url: '/diseasesSymptom/queryForBreedDiseasesJoinCategory',
        method: 'get',
        params: data
      });
    },
    diseasesSymptomQueryJoinCategory: function diseasesSymptomQueryJoinCategory(data) {
      return request({
        url: '/diseasesSymptom/queryJoinCategory',
        method: 'get',
        params: data
      });
    },
    diseasesSymptomDetail: function diseasesSymptomDetail(id) {
      return request({
        url: "/diseasesSymptom/".concat(id),
        method: 'get'
      });
    }
  };
});