import "core-js/modules/es.function.name.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "page knowledge_details"
  }, [_c("template", {
    slot: "header"
  }, [_c("div", {
    staticClass: "details-container"
  }, [_c("div", {
    staticClass: "title"
  }, [_c("i", {
    staticClass: "el-icon-back"
  }), _c("div", {
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: _vm.back
    }
  }, [_vm._v("返回")]), _c("div", {
    staticClass: "line"
  }), _c("div", [_vm._v(_vm._s(_vm.current.name))])]), _c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_vm.current.image ? _c("img", {
    staticClass: "img",
    attrs: {
      src: _vm.current.image,
      alt: ""
    }
  }) : _c("div", {
    staticClass: "img"
  }, [_vm._v("暂无图片")]), _c("div", [_c("div", [_vm._v("品种编号：" + _vm._s(_vm.current.alias))]), _c("div", [_vm._v("品种分类：" + _vm._s(_vm.dicData.configTypeOptions[_vm.current.breedType]))]), _c("div", [_vm._v("品种别名：" + _vm._s(_vm.current.alias))]), _c("div", [_c("span", [_vm._v("简介：")]), _c("span", {
    domProps: {
      innerHTML: _vm._s(_vm.current.description)
    }
  })])])])])]), _c("div", {
    staticClass: "container"
  }, [_c("div", {
    staticClass: "top-tabs"
  }, [_c("div", {
    staticClass: "tab-first",
    class: {
      "tab-active": _vm.tab_active == 0
    }
  }), _vm._l(_vm.tab_list, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "tab",
      class: {
        "tab-active": _vm.tab_active == index
      },
      on: {
        click: function click($event) {
          _vm.tab_active = index;
        }
      }
    }, [_c("div", [_vm._v(_vm._s(item))])]);
  })], 2), _c("div", {
    staticClass: "content"
  }, [_vm.tab_active == 2 || _vm.tab_active == 4 || _vm.tab_active == 6 ? _c("div", {
    staticClass: "left"
  }, _vm._l(this.type_list, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "left-item",
      class: {
        "left-active": _vm.searchForm.mode == item.mode && _vm.searchForm.waterType == item.waterType
      },
      style: _vm.active == index + 1 ? "border-bottom:none" : "",
      on: {
        click: function click($event) {
          return _vm.leftClick(item, index);
        }
      }
    }, [item.waterType == "SeaWater" ? _c("span", [_vm._v("海水")]) : _vm._e(), item.waterType == "FreshWater" ? _c("span", [_vm._v("淡水")]) : _vm._e(), item.mode == "Factory" ? _c("span", [_vm._v("工厂")]) : _vm._e(), item.mode == "Pond" ? _c("span", [_vm._v("池塘")]) : _vm._e(), item.mode == "Cage" ? _c("span", [_vm._v("网箱")]) : _vm._e(), item.mode == "Enclosure" ? _c("span", [_vm._v("大围网")]) : _vm._e(), _vm._v("化养殖 ")]);
  }), 0) : _vm._e(), _c("div", {
    staticClass: "right"
  }, [_vm.tab_active == 0 ? _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.queryLoading,
      expression: "queryLoading"
    }],
    staticStyle: {
      width: "100%",
      "margin-bottom": "20px"
    },
    attrs: {
      data: _vm.tableData,
      stripe: "",
      border: "",
      "row-key": "id",
      "element-loading-text": "正在努力加载中..."
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "序号",
      type: "index",
      width: "50"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "mode",
      label: "养殖模式",
      width: "250"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", {
          staticStyle: {
            display: "flex"
          }
        }, [scope.row.waterType == "SeaWater" ? _c("div", [_vm._v("海水")]) : _vm._e(), scope.row.waterType == "FreshWater" ? _c("div", [_vm._v("淡水")]) : _vm._e(), scope.row.mode == "Factory" ? _c("div", [_vm._v("工厂化")]) : _vm._e(), scope.row.mode == "Pond" ? _c("div", [_vm._v("池塘")]) : _vm._e(), scope.row.mode == "Cage" ? _c("div", [_vm._v("网箱")]) : _vm._e(), scope.row.mode == "Enclosure" ? _c("div", [_vm._v("大围网")]) : _vm._e(), _vm._v(" 养殖 ")])];
      }
    }], null, false, 3600559076)
  }), _c("el-table-column", {
    attrs: {
      prop: "description",
      label: "说明",
      "show-overflow-tooltip": ""
    }
  })], 1) : _vm._e(), _vm.tab_active == 1 ? _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.queryLoading,
      expression: "queryLoading"
    }],
    staticStyle: {
      width: "100%",
      "margin-bottom": "20px"
    },
    attrs: {
      data: _vm.tableData,
      stripe: "",
      border: "",
      "row-key": "id",
      "element-loading-text": "正在努力加载中..."
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "序号",
      type: "index",
      width: "50"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "segment",
      label: "养殖环节",
      width: "150"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "period",
      label: "阶段",
      width: "150"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "dateRange",
      label: "时间范围",
      width: "200"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "description",
      label: "说明",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "density",
      label: "放养密度",
      width: "200"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "remarks",
      label: "备注",
      "show-overflow-tooltip": ""
    }
  })], 1) : _vm._e(), _vm.tab_active == 2 ? _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.queryLoading,
      expression: "queryLoading"
    }],
    staticStyle: {
      width: "100%",
      "margin-bottom": "20px"
    },
    attrs: {
      data: _vm.tableData,
      stripe: "",
      border: "",
      "row-key": "id",
      "element-loading-text": "正在努力加载中..."
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "序号",
      type: "index",
      width: "50"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "segment",
      label: "养殖环节",
      "min-width": "100",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "period",
      label: "阶段",
      "min-width": "80",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      label: "水质范围",
      "min-width": "100",
      "header-align": "center"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "doMin",
      label: "溶解氧",
      "min-width": "70"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.doMin) + " - " + _vm._s(scope.row.doMax))])];
      }
    }], null, false, 973958191)
  }), _c("el-table-column", {
    attrs: {
      prop: "temperatureMin",
      label: "水温",
      "min-width": "60"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.temperatureMin) + " - " + _vm._s(scope.row.temperatureMax))])];
      }
    }], null, false, 81888175)
  }), _c("el-table-column", {
    attrs: {
      prop: "nh3Min",
      label: "PH",
      "min-width": "60"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.phMin) + " - " + _vm._s(scope.row.phMax))])];
      }
    }], null, false, 1640081263)
  }), _c("el-table-column", {
    attrs: {
      prop: "ecMin",
      label: "电导率",
      "min-width": "70"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.ecMin) + " - " + _vm._s(scope.row.ecMax))])];
      }
    }], null, false, 318761263)
  }), _c("el-table-column", {
    attrs: {
      prop: "tdMin",
      label: "浊度",
      "min-width": "60"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.tdMin) + " - " + _vm._s(scope.row.tdMax))])];
      }
    }], null, false, 3030067311)
  }), _c("el-table-column", {
    attrs: {
      prop: "wlMin",
      label: "液位",
      "min-width": "60"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.wlMin) + " - " + _vm._s(scope.row.wlMax))])];
      }
    }], null, false, 3708672047)
  }), _c("el-table-column", {
    attrs: {
      prop: "chlMin",
      label: "叶绿素",
      "min-width": "70"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.chlMin) + " - " + _vm._s(scope.row.chlMax))])];
      }
    }], null, false, 3472195407)
  }), _c("el-table-column", {
    attrs: {
      prop: "nh3Min",
      label: "NH3",
      "min-width": "70"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.nh3Min) + " - " + _vm._s(scope.row.nh3Max))])];
      }
    }], null, false, 3625455119)
  }), _c("el-table-column", {
    attrs: {
      prop: "saltMin",
      label: "盐度",
      "min-width": "60"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.saltMin) + " - " + _vm._s(scope.row.saltMax))])];
      }
    }], null, false, 2148744239)
  }), _c("el-table-column", {
    attrs: {
      prop: "transparencyMin",
      label: "透明度",
      "min-width": "60"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.transparencyMin) + " - " + _vm._s(scope.row.transparencyMax))])];
      }
    }], null, false, 3917655599)
  }), _c("el-table-column", {
    attrs: {
      prop: "nano2Min",
      label: "亚硝酸盐",
      "min-width": "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.nano2Min) + " - " + _vm._s(scope.row.nano2Max))])];
      }
    }], null, false, 118080559)
  })], 1)], 1) : _vm._e(), _vm.tab_active == 3 ? _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.queryLoading,
      expression: "queryLoading"
    }],
    staticStyle: {
      width: "100%",
      "margin-bottom": "20px"
    },
    attrs: {
      data: _vm.tableData,
      stripe: "",
      border: "",
      "row-key": "id",
      "element-loading-text": "正在努力加载中..."
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "序号",
      type: "index",
      width: "50"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "material",
      label: "原料说明",
      width: "300",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "nutrition",
      label: "营养说明",
      width: "300",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "brand",
      label: "常见品牌",
      width: "200",
      "show-overflow-tooltip": ""
    }
  })], 1) : _vm._e(), _vm.tab_active == 4 ? _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.queryLoading,
      expression: "queryLoading"
    }],
    staticStyle: {
      width: "100%",
      "margin-bottom": "20px"
    },
    attrs: {
      data: _vm.tableData,
      stripe: "",
      border: "",
      "row-key": "id",
      "element-loading-text": "正在努力加载中..."
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "序号",
      type: "index",
      width: "50"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "segment",
      label: "养殖环节",
      "min-width": "100",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "period",
      label: "阶段",
      "min-width": "80",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "material",
      label: "饲料配方",
      "min-width": "100",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "feedMethod",
      label: "投喂方法",
      "min-width": "100",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "feedAmount",
      label: "投喂量",
      "min-width": "100",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "feedTime",
      label: "投喂时间",
      "min-width": "100",
      "show-overflow-tooltip": ""
    }
  })], 1) : _vm._e(), _vm.tab_active == 5 ? _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.queryLoading,
      expression: "queryLoading"
    }],
    staticStyle: {
      width: "100%",
      "margin-bottom": "20px"
    },
    attrs: {
      data: _vm.tableData,
      stripe: "",
      border: "",
      "row-key": "id",
      "element-loading-text": "正在努力加载中..."
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "序号",
      type: "index",
      width: "50"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "diseasesType",
      label: "疾病类别",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(_vm.dicData.configDiseasesOptions[scope.row.diseasesType]))])];
      }
    }], null, false, 2899465733)
  }), _c("el-table-column", {
    attrs: {
      prop: "diseasesName",
      label: "疾病名称",
      width: "300"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "diseasesSymptoms",
      label: "关联症状",
      "min-width": "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.diseasesSymptoms && scope.row.diseasesSymptoms.length ? _c("el-table", {
          attrs: {
            data: scope.row.diseasesSymptoms,
            stripe: "",
            border: "",
            "row-key": "id"
          }
        }, [_c("el-table-column", {
          attrs: {
            prop: "diseasesSymptomType",
            label: "类别",
            width: "100"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(scope) {
              return [_c("div", [_vm._v(_vm._s(_vm.dicData.configSymptomOptions[scope.row.diseasesSymptomType]))])];
            }
          }], null, true)
        }), _c("el-table-column", {
          attrs: {
            prop: "diseasesSymptomName",
            label: "症状"
          }
        }), _c("el-table-column", {
          attrs: {
            prop: "weights",
            label: "权重",
            width: "100"
          }
        })], 1) : _vm._e()];
      }
    }], null, false, 1663460052)
  })], 1) : _vm._e(), _vm.tab_active == 6 ? _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.queryLoading,
      expression: "queryLoading"
    }],
    staticStyle: {
      width: "100%",
      "margin-bottom": "20px"
    },
    attrs: {
      data: _vm.tableData,
      stripe: "",
      border: "",
      "row-key": "id",
      "element-loading-text": "正在努力加载中..."
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "序号",
      type: "index",
      width: "50"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "workType",
      label: "工作类型",
      width: "150",
      "show-overflow-tooltip": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.workType == "ClearPond" ? _c("div", [_vm._v("清塘")]) : _vm._e(), scope.row.workType == "PatrolPond" ? _c("div", [_vm._v("巡塘")]) : _vm._e(), scope.row.workType == "ChangeWater" ? _c("div", [_vm._v("换水")]) : _vm._e(), scope.row.workType == "UseDrug" ? _c("div", [_vm._v("消毒/用药")]) : _vm._e()];
      }
    }], null, false, 1049410445)
  }), _c("el-table-column", {
    attrs: {
      prop: "segment",
      label: "养殖环节",
      width: "150",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "period",
      label: "阶段",
      width: "150",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "frequency",
      label: "频率",
      width: "150",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "operation",
      label: "操作规程",
      "min-width": "150",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "target",
      label: "目标",
      "min-width": "150",
      "show-overflow-tooltip": ""
    }
  })], 1) : _vm._e(), _c("el-pagination", {
    attrs: {
      background: "",
      align: "center",
      layout: "prev, pager, next, sizes, total",
      "page-sizes": [10, 20, 50, 100],
      "current-page": _vm.searchForm.page,
      "page-size": _vm.searchForm.limit,
      total: _vm.totalCount
    },
    on: {
      "current-change": _vm.pageChange,
      "size-change": _vm.sizeChange
    }
  })], 1)])])], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };