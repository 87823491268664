export default (function (_ref) {
  var request = _ref.request;
  return {
    dbBakQuery: function dbBakQuery(data) {
      return request({
        url: '/dbBak',
        method: 'get',
        params: data
      });
    }
  };
});