import "core-js/modules/es.json.stringify.js";
import dayjs from 'dayjs';
export default {
  name: 'model_params_page',
  props: {
    current: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      dayjs: dayjs,
      pageConfig: {
        editPageName: '智能模型参数',
        addFunName: 'modelParamsAdd',
        modifyFunName: 'modelParamsModify',
        queryFunName: 'modelParamsQuery',
        detailFunName: 'modelParamsDetail',
        delFunName: 'modelParamsDel'
      },
      searchForm: {
        page: 1,
        limit: 10,
        sortField: 'create_time',
        modelId: this.current.id
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      formData: {
        category: 'Header',
        modelId: this.current.id
      },
      editDialogShow: false,
      editDialogTitle: '',
      formLabelWidth: '80px',
      emptyFormData: {},
      rules: {
        name: [{
          required: true,
          message: '请输入名称',
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: '请输入类型',
          trigger: 'blur'
        }],
        explains: [{
          required: true,
          message: '请输入说明',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted: function mounted() {
    // console.log('history_logs_page this.current',this.current)
    this.query();
  },
  methods: {
    back: function back() {
      this.$emit('child-go-model');
    },
    query: function query() {
      var _this = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this.tableData = res.data || [];
        _this.totalCount = res.count;
        _this.queryLoading = false;
      });
    },
    initFormData: function initFormData(data) {
      var paramData = JSON.parse(JSON.stringify(data));
      if (!paramData.category) {
        paramData.category = 'Header';
      }
      if (!paramData.modelId) {
        paramData.modelId = this.current.id;
      }
      this.formData = paramData;
      if (typeof this.$refs['formDataVerify'] !== 'undefined') {
        this.$refs['formDataVerify'].clearValidate();
      }
    },
    saveFormDataProcess: function saveFormDataProcess(formData) {
      var saveFormData = JSON.parse(JSON.stringify(formData));
      return saveFormData;
    },
    add: function add() {
      this.editDialogTitle = this.pageConfig.editPageName + '新增';
      this.editDialogShow = true;
    },
    edit: function edit(data) {
      this.initFormData(data);
      this.editDialogTitle = this.pageConfig.editPageName + '维护';
      this.editDialogShow = true;
    },
    del: function del(id) {
      var _this2 = this;
      this.$confirm('确定要删除吗').then(function () {
        _this2.$api[_this2.pageConfig.delFunName](id).then(function (res) {
          if (res.data) {
            _this2.$message.success('删除成功');
            _this2.query();
          } else {
            _this2.$message.error('删除失败');
          }
        });
      });
    },
    submitFormData: function submitFormData() {
      var _this3 = this;
      this.$refs['formDataVerify'].validate(function (valid) {
        if (valid) {
          if (_this3.formData.id) {
            _this3.$api[_this3.pageConfig.modifyFunName](_this3.saveFormDataProcess(_this3.formData)).then(function (res) {
              if (res.data) {
                _this3.$message.success('编辑成功');
                _this3.initFormData(_this3.emptyFormData);
                _this3.editDialogShow = false;
                _this3.query();
              } else {
                _this3.$message.error('编辑失败');
              }
            });
          } else {
            _this3.$api[_this3.pageConfig.addFunName](_this3.saveFormDataProcess(_this3.formData)).then(function (res) {
              if (res.data) {
                _this3.$message.success('新增成功');
                _this3.initFormData(_this3.emptyFormData);
                _this3.editDialogShow = false;
                _this3.query();
              } else {
                _this3.$message.error('新增失败');
              }
            });
          }
        }
      });
    },
    closeEditDialogShow: function closeEditDialogShow() {
      this.editDialogTitle = '';
      this.editDialogShow = false;
      this.initFormData(this.emptyFormData);
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : '^sort';
      this.query();
    }
  }
};