export default (function (_ref) {
  var request = _ref.request;
  return {
    fileAdd: function fileAdd(data) {
      return request({
        url: '/file',
        method: 'post',
        data: data
      });
    },
    fileModify: function fileModify(data) {
      return request({
        url: "/file/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    fileDel: function fileDel(id) {
      return request({
        url: "/file/".concat(id),
        method: 'delete'
      });
    },
    fileQueryJoinUser: function fileQueryJoinUser(data) {
      return request({
        url: '/file/joinUser',
        method: 'get',
        params: data
      });
    },
    fileDetail: function fileDetail(id) {
      return request({
        url: "/file/".concat(id),
        method: 'get'
      });
    }
  };
});