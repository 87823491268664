import _createForOfIteratorHelper from "D:/work/code/Halov-China-Fishery-V2/halov-web/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/web.atob.js";
import "core-js/modules/web.dom-exception.constructor.js";
import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/web.dom-exception.to-string-tag.js";
import "core-js/modules/es.array-buffer.slice.js";
import "core-js/modules/es.typed-array.uint8-array.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.copy-within.js";
import "core-js/modules/es.typed-array.every.js";
import "core-js/modules/es.typed-array.fill.js";
import "core-js/modules/es.typed-array.filter.js";
import "core-js/modules/es.typed-array.find.js";
import "core-js/modules/es.typed-array.find-index.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import "core-js/modules/es.typed-array.for-each.js";
import "core-js/modules/es.typed-array.includes.js";
import "core-js/modules/es.typed-array.index-of.js";
import "core-js/modules/es.typed-array.iterator.js";
import "core-js/modules/es.typed-array.join.js";
import "core-js/modules/es.typed-array.last-index-of.js";
import "core-js/modules/es.typed-array.map.js";
import "core-js/modules/es.typed-array.reduce.js";
import "core-js/modules/es.typed-array.reduce-right.js";
import "core-js/modules/es.typed-array.reverse.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.slice.js";
import "core-js/modules/es.typed-array.some.js";
import "core-js/modules/es.typed-array.sort.js";
import "core-js/modules/es.typed-array.subarray.js";
import "core-js/modules/es.typed-array.to-locale-string.js";
import "core-js/modules/es.typed-array.to-string.js";
import "core-js/modules/esnext.typed-array.to-reversed.js";
import "core-js/modules/esnext.typed-array.to-sorted.js";
import "core-js/modules/esnext.typed-array.with.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.array.push.js";
import dayjs from 'dayjs';
export default {
  name: 'only_goods_page',
  components: {
    sourceBatchSel: function sourceBatchSel() {
      return import('./source_batch_sel.vue');
    }
  },
  props: {
    current: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      dayjs: dayjs,
      pageConfig: {
        editPageName: '一物一码',
        addFunName: 'sourceUniqueDetailAdd',
        cancelFunName: 'sourceUniqueDetailCancel',
        useFunName: 'sourceUniqueDetailUse',
        queryFunName: 'sourceUniqueDetailQuery',
        detailFunName: 'sourceUniqueDetailDetail',
        delFunName: 'sourceUniqueDetailDel',
        delBatchFunName: 'sourceUniqueDetailBatchDel',
        useBatchFunName: 'sourceUniqueDetailBatchUse',
        cancelBatchFunName: 'sourceUniqueDetailBatchCancel',
        systemQrcode: 'systemQrcode'
      },
      searchForm: {
        page: 1,
        limit: 10,
        searchKey: null,
        sortField: 'create_time',
        uniqueBatchId: this.current.id,
        sourceBatchId: this.current.sourceBatchId
      },
      formData: {
        limitScan: 'No_Limit',
        uniqueBatchId: this.current.id
      },
      rules: {
        scanAmount: [{
          required: true,
          message: '请输入扫描次数',
          trigger: 'blur'
        }],
        sourceBatchId: [{
          required: true,
          message: '请选择溯源批次',
          trigger: 'blur'
        }],
        validTime: [{
          required: true,
          message: '请选择有效期截止',
          trigger: 'blur'
        }]
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      editDialogShow: false,
      detailDialogShow: false,
      detailData: {},
      editDialogTitle: '',
      formLabelWidth: '100px',
      emptyFormData: {},
      batch_show: false,
      multipleSelection: []
    };
  },
  mounted: function mounted() {
    this.emptyFormData = JSON.parse(JSON.stringify(this.formData));
    this.query();
  },
  methods: {
    initFormData: function initFormData(data) {
      var paramData = JSON.parse(JSON.stringify(data));
      this.formData = paramData;
      if (!this.formData.limitScan) {
        this.$set(this.formData, 'limitScan', 'No_Limit');
      }
      if (typeof this.$refs['formDataVerify'] !== 'undefined') {
        this.$refs['formDataVerify'].clearValidate();
      }
    },
    back: function back() {
      this.$emit('child-go-batch');
    },
    saveFormDataProcess: function saveFormDataProcess(formData) {
      var saveFormData = JSON.parse(JSON.stringify(formData));
      return saveFormData;
    },
    beforeCommand: function beforeCommand(index, row, command) {
      return {
        index: index,
        row: row,
        command: command
      };
    },
    moreButton: function moreButton(command) {
      switch (command.command) {
        case 'download':
          // 下载
          this.download(command.row.id);
          break;
        case 'cancel':
          // 作废
          this.cancel(command.row.id);
          break;
        case 'use':
          // 使用
          this.use(command.row.id);
          break;
        case 'del':
          // 删除
          this.del(command.row.id);
          break;
      }
    },
    add: function add() {
      this.editDialogTitle = this.pageConfig.editPageName + '新增';
      this.editDialogShow = true;
    },
    detail: function detail(data) {
      this.detailData = data;
      this.$set(this.detailData, 'createTime', data.createTime + '');
      this.$set(this.detailData, 'validTime', data.validTime + '');
      this.detailDialogShow = true;
      // this.$api[this.pageConfig.detailFunName](id).then((res) => {
      //     this.detailData = res.data
      //     this.$set(this.detailData, 'createTime', res.data.createTime+'')
      //     this.$set(this.detailData, 'validTime', res.data.validTime+'')
      //     this.detailDialogShow = true
      // })
    },
    cancel: function cancel(id) {
      var _this = this;
      this.$confirm('确定要作废吗').then(function () {
        _this.$api[_this.pageConfig.cancelFunName](id).then(function (res) {
          if (res.data) {
            _this.$message.success('已作废');
            _this.query();
          } else {
            _this.$message.error('作废失败');
          }
        });
      });
    },
    use: function use(id) {
      var _this2 = this;
      this.$confirm('确定要使用吗').then(function () {
        _this2.$api[_this2.pageConfig.useFunName](id).then(function (res) {
          if (res.data) {
            _this2.$message.success('已使用');
            _this2.query();
          } else {
            _this2.$message.error('使用失败');
          }
        });
      });
    },
    download: function download(id) {
      var _this3 = this;
      this.$api[this.pageConfig.systemQrcode]({
        url: "".concat(process.env.VUE_APP_H5_URL, "/?sourceUniqueDetailId=").concat(id)
      }).then(function (res) {
        if (res.data) {
          _this3.codeUrl = res.data;
          // window.open(this.codeUrl)
          var base64ToBlob = function base64ToBlob(code) {
            var parts = code.split(';base64,');
            var contentType = parts[0].split(':')[1];
            var raw = window.atob(parts[1]);
            var rawLength = raw.length;
            var uInt8Array = new Uint8Array(rawLength);
            for (var i = 0; i < rawLength; ++i) {
              uInt8Array[i] = raw.charCodeAt(i);
            }
            return new Blob([uInt8Array], {
              type: contentType
            });
          };
          var aLink = document.createElement('a');
          var blob = base64ToBlob(_this3.codeUrl); //new Blob([content]);
          aLink.download = dayjs().format('YYYYMMDDhhmmss') + '.png';
          aLink.href = URL.createObjectURL(blob);
          aLink.click();
        }
      });
    } // 批量选择
    ,
    handleSelectionChange: function handleSelectionChange(val) {
      // console.log('handleSelectionChange',val)
      this.multipleSelection = val;
    } // 批量删除
    ,
    deletionBatch: function deletionBatch() {
      var _this4 = this;
      if (!this.multipleSelection.length) {
        this.$message.info('请先选择操作数据！');
        return;
      }
      var ids = [];
      var _iterator = _createForOfIteratorHelper(this.multipleSelection),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          ids.push(item.id);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      this.$confirm('确定要批量删除吗').then(function () {
        _this4.$api[_this4.pageConfig.delBatchFunName](ids).then(function (res) {
          if (res.data) {
            _this4.$message.success('删除成功');
            _this4.query();
          } else {
            _this4.$message.error('删除失败');
          }
        });
      });
    } // 批量使用
    ,
    useBatch: function useBatch() {
      var _this5 = this;
      if (!this.multipleSelection.length) {
        this.$message.info('请先选择操作数据！');
        return;
      }
      var ids = [];
      var _iterator2 = _createForOfIteratorHelper(this.multipleSelection),
        _step2;
      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var item = _step2.value;
          ids.push(item.id);
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
      this.$confirm('确定要批量使用吗').then(function () {
        _this5.$api[_this5.pageConfig.useBatchFunName](ids).then(function (res) {
          if (res.data) {
            _this5.$message.success('使用成功');
            _this5.query();
          } else {
            _this5.$message.error('使用失败');
          }
        });
      });
    } // 批量作废
    ,
    cancelBatch: function cancelBatch() {
      var _this6 = this;
      if (!this.multipleSelection.length) {
        this.$message.info('请先选择操作数据！');
        return;
      }
      var ids = [];
      var _iterator3 = _createForOfIteratorHelper(this.multipleSelection),
        _step3;
      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var item = _step3.value;
          ids.push(item.id);
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }
      this.$confirm('确定要批量作废吗').then(function () {
        _this6.$api[_this6.pageConfig.cancelBatchFunName](ids).then(function (res) {
          if (res.data) {
            _this6.$message.success('作废成功');
            _this6.query();
          } else {
            _this6.$message.error('作废失败');
          }
        });
      });
    },
    sourceBatchSel: function sourceBatchSel(val) {
      if (val) {
        this.$set(this.formData, 'sourceBatchId', val.id);
        this.$set(this.formData, 'productName', val.productName);
      }
      this.batch_show = false;
    },
    del: function del(id) {
      var _this7 = this;
      this.$confirm('确定要删除吗').then(function () {
        _this7.$api[_this7.pageConfig.delFunName](id).then(function (res) {
          if (res.data) {
            _this7.$message.success('删除成功');
            _this7.query();
          } else {
            _this7.$message.error('删除失败');
          }
        });
      });
    },
    query: function query() {
      var _this8 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this8.tableData = res.data || [];
        _this8.totalCount = res.count;
        _this8.queryLoading = false;
      });
    },
    submitFormData: function submitFormData() {
      var _this9 = this;
      this.$refs['formDataVerify'].validate(function (valid) {
        if (valid) {
          _this9.formData.uniqueBatchId = _this9.current.id;
          _this9.$api[_this9.pageConfig.addFunName](_this9.saveFormDataProcess(_this9.formData)).then(function (res) {
            if (res.data) {
              _this9.$message.success('新增成功');
              _this9.initFormData(_this9.emptyFormData);
              _this9.editDialogShow = false;
              _this9.query();
            } else {
              _this9.$message.error('新增失败');
            }
          });
        }
      });
    },
    closeEditDialogShow: function closeEditDialogShow() {
      this.editDialogTitle = '';
      this.editDialogShow = false;
      this.initFormData(this.emptyFormData);
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};