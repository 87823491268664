import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.function.name.js";
export default {
  name: 'cms_feedback',
  data: function data() {
    return {
      pageConfig: {
        editPageName: '反馈',
        delFunName: 'feedbackDel',
        queryFunName: 'feedbackQueryJoinUser',
        detailFunName: 'feedbackDetail',
        readFunName: 'feedbackRead',
        dicTreeFunName: 'dictionaryCache'
      },
      dicData: {
        upperCode: 'cms_feedback_category',
        configCategorys: [],
        configCategoryOptions: {}
      },
      searchForm: {
        page: 1,
        limit: 10,
        category: null,
        searchKey: null,
        sortField: 'create_time'
      },
      formData: {
        id: null,
        userId: null,
        title: null,
        category: null,
        contact: null,
        contactPhone: null,
        content: null,
        readState: null,
        readUser: null,
        userAccount: null,
        userNickname: null,
        userPhone: null,
        readUserAccount: null,
        readUserNickname: null
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      editDialogShow: false,
      editDialogTitle: '',
      formLabelWidth: '100px',
      emptyFormData: {}
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.emptyFormData = JSON.parse(JSON.stringify(this.formData));
    this.$api[this.pageConfig.dicTreeFunName](this.dicData.upperCode).then(function (res) {
      if (res.data) {
        _this.dicData.configCategorys = res.data;
        for (var i = 0; i < res.data.length; i++) {
          _this.dicData.configCategoryOptions[res.data[i].code] = res.data[i].name;
        }
      }
      _this.query();
    });
  },
  methods: {
    initFormData: function initFormData(data) {
      var paramData = JSON.parse(JSON.stringify(data));
      this.formData = paramData;
      if (typeof this.$refs['formDataVerify'] !== 'undefined') {
        this.$refs['formDataVerify'].clearValidate();
      }
    },
    detail: function detail(tableDataRow) {
      var _this2 = this;
      this.$api[this.pageConfig.detailFunName](tableDataRow.id).then(function (res) {
        _this2.initFormData(res.data);
        _this2.formData.userNickname = tableDataRow.userNickname;
        _this2.formData.userAccount = tableDataRow.userAccount;
        _this2.formData.userPhone = tableDataRow.userPhone;
        _this2.editDialogTitle = _this2.pageConfig.editPageName + '明细';
        _this2.editDialogShow = true;
        if (!_this2.formData.readState) {
          _this2.$api[_this2.pageConfig.readFunName](tableDataRow.id).then(function (res) {
            if (res.data) {
              tableDataRow.readState = true;
            }
          });
        }
      });
    },
    del: function del(id) {
      var _this3 = this;
      this.$confirm('确定要删除吗').then(function () {
        _this3.$api[_this3.pageConfig.delFunName](id).then(function (res) {
          if (res.data) {
            _this3.$message.success('删除成功');
            _this3.query();
          } else {
            _this3.$message.error('删除失败');
          }
        });
      });
    },
    query: function query() {
      var _this4 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this4.tableData = res.data || [];
        _this4.totalCount = res.count;
        _this4.queryLoading = false;
      });
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};