export default (function (_ref) {
  var request = _ref.request;
  return {
    advertisingAdd: function advertisingAdd(data) {
      return request({
        url: '/advertising',
        method: 'post',
        data: data
      });
    },
    advertisingModify: function advertisingModify(data) {
      return request({
        url: "/advertising/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    advertisingDel: function advertisingDel(id) {
      return request({
        url: "/advertising/".concat(id),
        method: 'delete'
      });
    },
    advertisingQuery: function advertisingQuery(data) {
      return request({
        url: '/advertising',
        method: 'get',
        params: data
      });
    },
    advertisingDetail: function advertisingDetail(id) {
      return request({
        url: "/advertising/".concat(id),
        method: 'get'
      });
    },
    advertisingOnline: function advertisingOnline(id) {
      return request({
        url: "/advertising/".concat(id, "/online/"),
        method: 'put'
      });
    },
    advertisingUnOnline: function advertisingUnOnline(id) {
      return request({
        url: "/advertising/".concat(id, "/unOnline/"),
        method: 'put'
      });
    }
  };
});