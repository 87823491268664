var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("projectTable", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tableShow === "project",
      expression: "tableShow === 'project'"
    }],
    on: {
      "child-go": _vm.goChild
    }
  }), _vm.tableShow === "relationship" ? _c("relationshipTable", {
    attrs: {
      current: _vm.current
    },
    on: {
      "child-go-project": _vm.goProject
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };