import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "page diseases_common_page"
  }, [_c("div", {
    staticClass: "container"
  }, [_c("div", {
    staticClass: "top-tabs"
  }, [_c("div", {
    staticClass: "tab-first",
    class: {
      "tab-active": _vm.searchForm.breedType == _vm.dicData.configTypes[0].code
    }
  }), _vm._l(_vm.dicData.configTypes, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "tab",
      class: {
        "tab-active": _vm.searchForm.breedType == item.code
      },
      on: {
        click: function click($event) {
          return _vm.tabClick(item.code);
        }
      }
    }, [_c("div", [_vm._v(_vm._s(item.name))])]);
  })], 2), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "left"
  }, _vm._l(this.dicData.configDiseases, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "left-item",
      class: {
        "left-active": _vm.searchForm.diseasesType == item.code
      },
      style: _vm.active == index + 1 ? "border-bottom:none" : "",
      on: {
        click: function click($event) {
          return _vm.leftClick(item.code, index);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 0), _c("div", {
    staticClass: "right"
  }, _vm._l(_vm.tableData, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "item",
      on: {
        click: function click($event) {
          return _vm.lookClick(item);
        }
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 0)])]), _c("el-dialog", {
    attrs: {
      visible: _vm.tip_show,
      title: _vm.result.name
    },
    on: {
      close: function close($event) {
        _vm.tip_show = false;
      }
    }
  }, [_c("div", {
    staticClass: "dialog-content",
    domProps: {
      innerHTML: _vm._s(_vm.result.content)
    }
  })])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };