export default (function (_ref) {
  var request = _ref.request;
  return {
    logQuery: function logQuery(data) {
      return request({
        url: '/log/joinUser',
        method: 'get',
        params: data
      });
    },
    logDetail: function logDetail(id) {
      return request({
        url: "/log/".concat(id),
        method: 'get'
      });
    }
  };
});