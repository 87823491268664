export default (function (_ref) {
  var request = _ref.request;
  return {
    fisheryPlanAdd: function fisheryPlanAdd(data) {
      return request({
        url: '/fisheryPlan',
        method: 'post',
        data: data
      });
    },
    fisheryPlanModify: function fisheryPlanModify(data) {
      return request({
        url: "/fisheryPlan/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    fisheryPlanDel: function fisheryPlanDel(id) {
      return request({
        url: "/fisheryPlan/".concat(id),
        method: 'delete'
      });
    },
    fisheryPlanQuery: function fisheryPlanQuery(data) {
      return request({
        url: '/fisheryPlan/queryJoinBreedAndPlace',
        method: 'get',
        params: data
      });
    },
    fisheryPlanDetail: function fisheryPlanDetail(id) {
      return request({
        url: "/fisheryPlan/".concat(id),
        method: 'get'
      });
    }
  };
});