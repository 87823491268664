import _createForOfIteratorHelper from "D:/work/code/Halov-China-Fishery-V2/halov-web/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _objectSpread from "D:/work/code/Halov-China-Fishery-V2/halov-web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.regexp.to-string.js";
export default {
  name: 'deviceWarn_page',
  props: {
    current: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      pageConfig: {
        editPageName: '告警',
        addFunName: 'deviceWarnAdd',
        modifyFunName: 'deviceWarnModify',
        delFunName: 'deviceWarnDel',
        queryFunName: 'deviceWarnQuery',
        detailFunName: 'deviceWarnDetail',
        queryUserFunName: 'userQuery'
      },
      searchForm: {
        page: 1,
        limit: 10,
        sortField: 'create_time'
      },
      formData: {
        deviceId: this.current.id,
        triggerCategory: 'Threshold',
        triggerType: 'Lower_Limit',
        warnFrequency: 'Once_Report',
        warnLevel: 'Seriousness'
      },
      rules: {
        triggerLower: [{
          required: true,
          message: '请输入触发下限',
          trigger: 'blur'
        }],
        deviceItemId: [{
          required: true,
          message: '请选择告警参数',
          trigger: 'blur'
        }]
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      editDialogShow: false,
      editDialogTitle: '',
      formLabelWidth: '100px',
      emptyFormData: {},
      userList: [],
      userForm: {
        page: 1,
        limit: 10
      },
      msgReceiverUser: [],
      setSelectPage: {
        pageSize: 10,
        currentPage: 1,
        pagerCount: 5,
        total: 0
      },
      loading: false,
      deviceItem_show: false
    };
  },
  components: {
    deviceItemSel: function deviceItemSel() {
      return import('./deviceItem_sel.vue');
    }
  },
  mounted: function mounted() {
    this.emptyFormData = JSON.parse(JSON.stringify(this.formData));
    this.query();
  },
  watch: {
    editDialogShow: function editDialogShow(to) {
      if (to) {
        this.setSelectPage.currentPage = 1;
        this.userQuery();
      }
    },
    msgReceiverUser: function msgReceiverUser(to) {
      console.log('msgReceiverUser', to);
    },
    'formData.triggerCategory': {
      handler: function handler(to) {
        if (to == 'Offline') {
          this.$set(this.formData, 'triggerType', 'Upper_Limit');
        }
      },
      deep: true
    },
    'formData.triggerType': {
      handler: function handler(to) {
        this.$set(this.rules, 'triggerLower', null);
        this.$refs['formDataVerify'].clearValidate();
        if (to == 'Lower_Limit') {
          this.$set(this.rules, 'triggerLower', [{
            required: true,
            message: '请输入触发下限',
            trigger: 'change'
          }]);
        } else if (to == 'Upper_Limit') {
          this.$set(this.rules, 'triggerUpper', [{
            required: true,
            message: '请输入触发上限',
            trigger: 'change'
          }]);
        } else if (to == 'Between') {
          this.$set(this.rules, 'triggerUpper', [{
            required: true,
            message: '请输入触发上限',
            trigger: 'change'
          }]);
          this.$set(this.rules, 'triggerLower', [{
            required: true,
            message: '请输入触发下限',
            trigger: 'change'
          }]);
        } else if (to == 'Equals' || to == 'Not_Equals') {
          this.$set(this.rules, 'triggerValue', [{
            required: true,
            message: '请输入触发值',
            trigger: 'change'
          }]);
        }
      },
      deep: true
    },
    'formData.warnFrequency': {
      handler: function handler(to) {
        this.$set(this.rules, 'warnInterval', null);
        this.$refs['formDataVerify'].clearValidate();
        if (to == 'Interval_Time') {
          this.$set(this.rules, 'warnInterval', [{
            required: true,
            message: '请输入预警间隔',
            trigger: 'change'
          }]);
        }
      },
      deep: true
    }
  },
  methods: {
    back: function back() {
      this.$emit('child-go-device');
    },
    deviceItemSel: function deviceItemSel(data) {
      if (data) {
        this.$set(this.formData, 'deviceItemTypeName', data.typeName);
        this.$set(this.formData, 'deviceItemId', data.id);
      }
      this.deviceItem_show = false;
    },
    initFormData: function initFormData(data) {
      var paramData = JSON.parse(JSON.stringify(data));
      this.msgReceiverUserList = [];
      this.formData = paramData;
      if (!this.formData.triggerCategory) {
        this.formData.triggerCategory = 'Threshold';
      }
      if (!this.formData.triggerType) {
        this.formData.triggerType = 'Lower_Limit';
      }
      if (!this.formData.warnFrequency) {
        this.formData.warnFrequency = 'Once_Report';
      }
      if (!this.formData.warnLevel) {
        this.formData.warnLevel = 'Seriousness';
      }
      if (this.formData.msgReceiverUser) {
        this.msgReceiverUser = this.formData.msgReceiverUser.split(',');
        for (var index in this.msgReceiverUser) {
          this.msgReceiverUserList.push({
            id: this.msgReceiverUser[index],
            nickname: this.formData.msgReceiverUserNicknames.split(',')[index]
          });
        }
      } else {
        this.msgReceiverUser = [];
      }
      if (typeof this.$refs['formDataVerify'] !== 'undefined') {
        this.$refs['formDataVerify'].clearValidate();
      }
    },
    saveFormDataProcess: function saveFormDataProcess(formData) {
      var saveFormData = JSON.parse(JSON.stringify(formData));
      return saveFormData;
    },
    add: function add() {
      this.editDialogTitle = this.pageConfig.editPageName + '新增';
      this.editDialogShow = true;
      this.formData.upperCode = this.searchForm.upperCode;
    },
    edit: function edit(data) {
      var _this = this;
      this.$api[this.pageConfig.detailFunName](data.id).then(function (res) {
        _this.initFormData(_objectSpread(_objectSpread({}, res.data), {}, {
          deviceItemTypeName: data.deviceItemTypeName
        }));
        _this.editDialogTitle = _this.pageConfig.editPageName + '维护';
        _this.editDialogShow = true;
      });
    },
    del: function del(id) {
      var _this2 = this;
      this.$confirm('确定要删除吗').then(function () {
        _this2.$api[_this2.pageConfig.delFunName](id).then(function (res) {
          if (res.data) {
            _this2.$message.success('删除成功');
            _this2.query();
          } else {
            _this2.$message.error('删除失败');
          }
        });
      });
    },
    query: function query() {
      var _this3 = this;
      this.queryLoading = true;
      this.searchForm.deviceId = this.current.id;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this3.tableData = res.data || [];
        _this3.totalCount = res.count;
        _this3.queryLoading = false;
      });
    },
    userQuery: function userQuery() {
      var _this4 = this;
      this.$api[this.pageConfig.queryUserFunName]({
        page: this.setSelectPage.currentPage,
        limit: this.setSelectPage.pageSize
      }).then(function (res) {
        if (_this4.msgReceiverUserList && _this4.msgReceiverUserList.length) {
          var _iterator = _createForOfIteratorHelper(_this4.msgReceiverUserList),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var item = _step.value;
              var flag = true;
              var _iterator2 = _createForOfIteratorHelper(res.data),
                _step2;
              try {
                for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                  var opt = _step2.value;
                  if (item.id == opt.id) {
                    flag = false;
                    break;
                  }
                }
              } catch (err) {
                _iterator2.e(err);
              } finally {
                _iterator2.f();
              }
              if (flag) {
                res.data.push(item);
              }
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
        }
        _this4.userList = res.data || [];
        _this4.setSelectPage.total = res.count;
      });
    },
    userPageChange: function userPageChange(page) {
      this.setSelectPage.currentPage = page;
      this.userQuery();
    },
    submitFormData: function submitFormData() {
      var _this5 = this;
      this.$refs['formDataVerify'].validate(function (valid) {
        if (valid) {
          _this5.formData.deviceId = _this5.current.id;
          if (_this5.formData.id) {
            _this5.$api[_this5.pageConfig.modifyFunName](_this5.saveFormDataProcess(_objectSpread(_objectSpread({}, _this5.formData), {}, {
              msgReceiverUser: _this5.msgReceiverUser.toString()
            }))).then(function (res) {
              if (res.data) {
                _this5.$message.success('编辑成功');
                _this5.initFormData(_this5.emptyFormData);
                _this5.editDialogShow = false;
                _this5.query();
              } else {
                _this5.$message.error('编辑失败');
              }
            });
          } else {
            _this5.$api[_this5.pageConfig.addFunName](_this5.saveFormDataProcess(_objectSpread(_objectSpread({}, _this5.formData), {}, {
              msgReceiverUser: _this5.msgReceiverUser.toString()
            }))).then(function (res) {
              if (res.data) {
                _this5.$message.success('新增成功');
                _this5.initFormData(_this5.emptyFormData);
                _this5.editDialogShow = false;
                _this5.query();
              } else {
                _this5.$message.error('新增失败');
              }
            });
          }
        }
      });
    },
    closeEditDialogShow: function closeEditDialogShow() {
      this.editDialogTitle = '';
      this.editDialogShow = false;
      this.initFormData(this.emptyFormData);
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : '^sort';
      this.query();
    }
  }
};