export default (function (_ref) {
  var request = _ref.request;
  return {
    lbsAdd: function lbsAdd(data) {
      return request({
        url: '/lbs',
        method: 'post',
        data: data
      });
    },
    lbsModify: function lbsModify(data) {
      return request({
        url: "/lbs/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    labsDel: function labsDel(id) {
      return request({
        url: "/lbs/".concat(id),
        method: 'delete'
      });
    },
    lbsQuery: function lbsQuery(data) {
      return request({
        url: '/lbs',
        method: 'get',
        params: data
      });
    },
    lbsJson: function lbsJson() {
      return request({
        url: '/lbs/cache',
        method: 'get'
      });
    },
    lbsDetail: function lbsDetail(id) {
      return request({
        url: "/lbs/".concat(id),
        method: 'get'
      });
    }
  };
});