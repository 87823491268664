export default (function (_ref) {
  var request = _ref.request;
  return {
    sourceUniqueBatchAdd: function sourceUniqueBatchAdd(data) {
      return request({
        url: '/sourceUniqueBatch',
        method: 'post',
        data: data
      });
    },
    sourceUniqueBatchCancel: function sourceUniqueBatchCancel(id) {
      return request({
        url: "/sourceUniqueBatch/".concat(id, "/cancel"),
        method: 'put'
      });
    },
    sourceUniqueBatchDel: function sourceUniqueBatchDel(id) {
      return request({
        url: "/sourceUniqueBatch/".concat(id),
        method: 'delete'
      });
    },
    sourceUniqueBatchQuery: function sourceUniqueBatchQuery(data) {
      return request({
        url: '/sourceUniqueBatch',
        method: 'get',
        params: data
      });
    },
    sourceUniqueBatchDetail: function sourceUniqueBatchDetail(id) {
      return request({
        url: "/sourceUniqueBatch/".concat(id),
        method: 'get'
      });
    }
  };
});