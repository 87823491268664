import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import api from '@/api';
export default {
  name: "liangping_aquaponics",
  components: {},
  data: function data() {
    return {};
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    var _this = this;
    api['liangpingToken']().then(function (res) {
      if (res.data) {
        window.location.replace(res.data);
        next();
      } else {
        _this.$message.error(res.msg);
      }
    });
  }
};