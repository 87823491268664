export default (function (_ref) {
  var request = _ref.request;
  return {
    menuAdd: function menuAdd(data) {
      return request({
        url: '/menu',
        method: 'post',
        data: data
      });
    },
    menuModify: function menuModify(data) {
      return request({
        url: "/menu/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    menuDel: function menuDel(id) {
      return request({
        url: "/menu/".concat(id),
        method: 'delete'
      });
    },
    menuDetail: function menuDetail(id) {
      return request({
        url: "/menu/".concat(id),
        method: 'get'
      });
    },
    menuCache: function menuCache() {
      return request({
        url: '/menu/cache',
        method: 'get'
      });
    },
    menuCacheClear: function menuCacheClear() {
      return request({
        url: '/menu/cache/clear',
        method: 'delete'
      });
    },
    menuCacheCurrent: function menuCacheCurrent() {
      return request({
        url: '/menu/cache/current',
        method: 'get'
      });
    }
  };
});