import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.concat.js";
import axios from 'axios';
import { get } from 'lodash';
import router from '@/router/index';
import Adapter from 'axios-mock-adapter';
import { errorCreate } from './tools';

/**
 * @description 创建请求实例
 */
function createService() {
  // 创建一个 axios 实例
  var service = axios.create();

  // 请求拦截
  service.interceptors.request.use(function (config) {
    // 如果是登录就直接返回请求
    if (config.url.indexOf('/mng/user/login/accountAndPassword') >= 0) {
      return config;
    } else {
      // 非登录请求必须携带token
      var token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = token;
      }
      return config;
    }
  }, function (error) {
    // 发送失败
    return Promise.reject(error);
  });

  // 响应拦截
  service.interceptors.response.use(function (response) {
    // dataAxios 是 axios 返回数据中的 data
    var dataAxios = response.data;
    // 这个状态码是和后端约定的
    var code = dataAxios.code;

    // 根据 code 进行判断
    if (typeof code === 'undefined') {
      // 如果没有 code 代表这不是项目后端开发的接口 比如可能是 D2Admin 请求最新版本
      return dataAxios;
    } else {
      // 有 code 代表这是一个后端接口 可以进行进一步的判断
      switch (code) {
        case 200:
          return dataAxios;
        case 400:
          // 参数检查异常
          errorCreate("[ code: 400 ] ".concat(dataAxios.msg));
          break;
        case 401:
          // 约束异常
          errorCreate("[ code: 401 ] ".concat(dataAxios.msg));
          break;
        case 402:
          // 认证异常
          clearInterval(localStorage.getItem('refreshTimer'));
          router.replace('login');
          errorCreate("[ code: 402 ] ".concat(dataAxios.msg));
          break;
        case 403:
          // 认证过期
          clearInterval(localStorage.getItem('refreshTimer'));
          router.replace('login');
          errorCreate("[ code: 403 ] ".concat(dataAxios.msg));
          break;
        case 404:
          // 资源未找到
          errorCreate("[ code: 404 ] ".concat(dataAxios.msg));
          break;
        case 405:
          // 错误的HTTP交互方法
          errorCreate("[ code: 405 ] ".concat(dataAxios.msg));
          break;
        case 409:
          // 资源冲突或资源被锁定
          errorCreate("[ code: 409 ] ".concat(dataAxios.msg));
          break;
        case 410:
          // 资源未初始化
          errorCreate("[ code: 410 ] ".concat(dataAxios.msg));
          break;
        case 411:
          // 没有数据权限
          errorCreate("[ code: 411 ] ".concat(dataAxios.msg));
          break;
        case 429:
          // 频繁请求被限制
          errorCreate("[ code: 429 ] ".concat(dataAxios.msg));
          break;
        case 500:
          // 服务端异常
          errorCreate("[ code: 500 ] ".concat(dataAxios.msg));
          break;
        case 501:
          // 服务端操作失败
          errorCreate("[ code: 501 ] ".concat(dataAxios.msg));
          break;
        case 502:
          // 服务端配置异常
          errorCreate("[ code: 502 ] ".concat(dataAxios.msg));
        case 503:
          // 对象配置异常
          errorCreate("[ code: 503 ] ".concat(dataAxios.msg));
          break;
        case 510:
          // 服务端IO异常
          errorCreate("[ code: 510 ] ".concat(dataAxios.msg));
          break;
        case 590:
          // 服务版本过低
          errorCreate("[ code: 590 ] ".concat(dataAxios.msg));
          break;
        case 591:
          // 服务已弃用
          errorCreate("[ code: 591 ] ".concat(dataAxios.msg));
          break;
        case 600:
          // 第三方异常
          errorCreate("[ code: 600 ] ".concat(dataAxios.msg));
          break;
        case 601:
          // 微信异常
          errorCreate("[ code: 601 ] ".concat(dataAxios.msg));
          break;
        case 602:
          // QQ异常
          errorCreate("[ code: 602 ] ".concat(dataAxios.msg));
          break;
        case 603:
          // 阿里异常
          errorCreate("[ code: 603 ] ".concat(dataAxios.msg));
          break;
        case 604:
          // 七牛云异常
          errorCreate("[ code: 604 ] ".concat(dataAxios.msg));
          break;
        case 605:
          // 环信异常
          errorCreate("[ code: 605 ] ".concat(dataAxios.msg));
          break;
        case 606:
          // 区块链异常
          errorCreate("[ code: 606 ] ".concat(dataAxios.msg));
          break;
        case 607:
          // 聚合异常
          errorCreate("[ code: 607 ] ".concat(dataAxios.msg));
          break;
        case 609:
          // 税务异常
          errorCreate("[ code: 608 ] ".concat(dataAxios.msg));
          break;
        default:
          // 不是正确的 code
          errorCreate("[ code: ".concat(dataAxios.code, " ] ").concat(dataAxios.msg));
          break;
      }
    }
  }, function (error) {
    var status = get(error, 'response.status');
    switch (status) {
      case 400:
        error.message = 'Http 请求错误';
        break;
      case 401:
        error.message = 'Http 未授权，请登录';
        break;
      case 403:
        error.message = 'Http 拒绝访问';
        break;
      case 404:
        error.message = "Http \u8BF7\u6C42\u5730\u5740\u51FA\u9519: ".concat(error.response.config.url);
        break;
      case 408:
        error.message = 'Http 请求超时';
        break;
      case 500:
        error.message = 'Http 服务器内部错误';
        break;
      case 501:
        error.message = 'Http 服务未实现';
        break;
      case 502:
        error.message = 'Http 网关错误';
        break;
      case 503:
        error.message = 'Http 服务不可用';
        break;
      case 504:
        error.message = 'Http 网关超时';
        break;
      case 505:
        error.message = 'Http 版本不受支持';
        break;
      default:
        break;
    }
    return Promise.reject(error);
  });
  return service;
}

/**
 * @description 创建请求方法
 * @param {Object} service axios 实例
 */
function createRequestFunction(service) {
  return function (config) {
    var token = localStorage.getItem('token');
    var configDefault = {
      headers: {
        Authorization: token,
        'Content-Type': get(config, 'headers.Content-Type', 'application/json')
      },
      timeout: 5000,
      baseURL: process.env.VUE_APP_API,
      data: {}
    };
    return service(Object.assign(configDefault, config));
  };
}

// 用于真实网络请求的实例和请求方法
export var service = createService();
export var request = createRequestFunction(service);

// 用于模拟网络请求的实例和请求方法
export var serviceForMock = createService();
export var requestForMock = createRequestFunction(serviceForMock);

// 网络请求数据模拟工具
export var mock = new Adapter(serviceForMock);