export default (function (_ref) {
  var request = _ref.request;
  return {
    projectTree: function projectTree(params) {
      return request({
        url: '/mng/project/tree',
        method: 'get',
        params: params
      });
    }
  };
});