var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "page"
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.queryLoading,
      expression: "queryLoading"
    }],
    staticStyle: {
      width: "100%",
      "margin-bottom": "20px"
    },
    attrs: {
      data: _vm.tableData,
      stripe: "",
      border: "",
      "row-key": "id",
      "element-loading-text": "正在努力加载中..."
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "序号",
      type: "index",
      width: "50"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm.buttonPerms(_vm.$route.path + ":look") ? _c("el-button", {
          attrs: {
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.look(scope.row);
            }
          }
        }, [_vm._v("查看")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "name",
      label: "名称",
      "min-width": "80"
    }
  })], 1), _c("el-dialog", {
    attrs: {
      visible: _vm.editDialogShow,
      title: "查看",
      fullscreen: _vm.fullscreen,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.editDialogShow = $event;
      },
      close: function close($event) {
        _vm.editDialogShow = false;
      }
    }
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between",
      "padding-right": "50px",
      "font-size": "18px"
    }
  }, [_c("div", [_vm._v("查看")]), _c("div", {
    staticStyle: {
      cursor: "pointer",
      color: "#409EFF"
    },
    on: {
      click: function click($event) {
        _vm.fullscreen = !_vm.fullscreen;
      }
    }
  }, [_vm._v(_vm._s(_vm.fullscreen ? "退出全屏" : "进入全屏"))])])]), _c("iframe", {
    attrs: {
      src: _vm.url,
      frameborder: "0"
    }
  })])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };