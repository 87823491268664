import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  name: 'product_mng',
  components: {
    planTable: function planTable() {
      return import('./standardization_planting_page');
    },
    planGantt: function planGantt() {
      return import('./standardization_planting_gantt');
    },
    planItem: function planItem() {
      return import('./standardization_planting_planItem');
    },
    planItemDetail: function planItemDetail() {
      return import('./standardization_planting_planItemDetail');
    }
  },
  data: function data() {
    return {
      tableShow: 'plan',
      current: {}
    };
  },
  methods: {
    goPlan: function goPlan() {
      this.tableShow = 'plan';
    },
    goChild: function goChild(data, tableName) {
      this.current = data;
      this.tableShow = tableName;
    }
  }
};