import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
export default (function (_ref) {
  var request = _ref.request;
  return {
    taskPlanAdd: function taskPlanAdd(data) {
      return request({
        url: '/task/template',
        method: 'post',
        data: data
      });
    },
    taskPlanModify: function taskPlanModify(data) {
      return request({
        url: "/task/template/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    taskPlanDel: function taskPlanDel(id) {
      return request({
        url: "/task/template/".concat(id),
        method: 'delete'
      });
    },
    taskPlanQuery: function taskPlanQuery(data) {
      return request({
        url: '/task/template',
        method: 'get',
        params: data
      });
    },
    taskPlanDetail: function taskPlanDetail(id) {
      return request({
        url: "/task/template/".concat(id),
        method: 'get'
      });
    },
    pricePlan: function pricePlan(_ref2) {
      var name = _ref2.name,
        cycle = _ref2.cycle,
        order = _ref2.order;
      return request({
        url: "/common/priceTrend/send?name=".concat(name, "&cycle=").concat(cycle, "&order=").concat(order),
        method: 'post'
      });
    }
  };
});