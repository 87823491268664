export default (function (_ref) {
  var request = _ref.request;
  return {
    aliOssSign: function aliOssSign(userId) {
      if (process.env.VUE_APP_UPLOAD_TYPE === 'server') {
        return {
          data: '上传到服务器'
        };
      }
      return request({
        url: "/oss/aliyun/sign/".concat(userId),
        method: 'post'
      });
    }
  };
});