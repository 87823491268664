export default (function (_ref) {
  var request = _ref.request;
  return {
    fisheryTaskSeedAdd: function fisheryTaskSeedAdd(data) {
      return request({
        url: '/fisheryTaskSeed',
        method: 'post',
        data: data
      });
    },
    fisheryTaskSeedModify: function fisheryTaskSeedModify(data) {
      return request({
        url: "/fisheryTaskSeed/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    fisheryTaskSeedDel: function fisheryTaskSeedDel(id) {
      return request({
        url: "/fisheryTaskSeed/".concat(id),
        method: 'delete'
      });
    },
    fisheryTaskSeedQuery: function fisheryTaskSeedQuery(data) {
      return request({
        url: '/fisheryTaskSeed/queryJoinBreedAndPlace',
        method: 'get',
        params: data
      });
    },
    fisheryTaskSeedDetail: function fisheryTaskSeedDetail(id) {
      return request({
        url: "/fisheryTaskSeed/".concat(id),
        method: 'get'
      });
    }
  };
});