import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.function.name.js";
import dayjs from 'dayjs';
export default {
  name: 'zoneInfo_form',
  components: {
    deviceSel: function deviceSel() {
      return import('../iot/device_sel');
    },
    videoSel: function videoSel() {
      return import('../iot/video_sel');
    },
    labSel: function labSel() {
      return import('../org/lab_sel_page');
    }
  },
  data: function data() {
    return {
      dayjs: dayjs,
      pageConfig: {
        editPageName: '产业园信息',
        addFunName: 'zoneInfoAdd',
        modifyFunName: 'zoneInfoModify',
        queryFunName: 'zoneInfoQuery',
        detailFunName: 'zoneInfoDetail',
        delFunName: 'zoneInfoDel'
      },
      searchForm: {
        page: 1,
        limit: 10,
        searchKey: null,
        sortField: 'create_time'
      },
      formData: {},
      rules: {
        zoneOverview: [{
          required: true,
          message: '请输入产业园概览',
          trigger: 'blur'
        }],
        factoryOverview: [{
          required: true,
          message: '请输入厂房概览',
          trigger: 'blur'
        }]
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      editDialogShow: false,
      editDialogTitle: '',
      formLabelWidth: '130px',
      emptyFormData: {},
      deviceShow: false,
      videoShow: false,
      labShow: false
    };
  },
  mounted: function mounted() {
    this.emptyFormData = JSON.parse(JSON.stringify(this.formData));
    this.query();
  },
  methods: {
    initFormData: function initFormData(data) {
      var paramData = JSON.parse(JSON.stringify(data));
      this.formData = paramData;
      if (typeof this.$refs['formDataVerify'] !== 'undefined') {
        this.$refs['formDataVerify'].clearValidate();
      }
    },
    saveFormDataProcess: function saveFormDataProcess(formData) {
      var saveFormData = JSON.parse(JSON.stringify(formData));
      return saveFormData;
    } // 选择企业
    ,
    labSel: function labSel(data) {
      if (data) {
        this.formData.showLabId = data.id;
        this.$set(this.formData, 'showLabName', data.name);
      }
      this.labShow = false;
    } // 选择视频
    ,
    videoSelect: function videoSelect(index) {
      this.videoIndex = index;
      this.videoShow = true;
    },
    videoSel: function videoSel(val) {
      if (val) {
        if (this.videoIndex == 1) {
          //产业园视频
          this.$set(this.formData, 'zoneVideoId', val.id);
          this.$set(this.formData, 'zoneVideoName', val.videoName);
        } else if (this.videoIndex == 2) {
          //厂房视频
          this.$set(this.formData, 'labParkFactoryVideoId', val.id);
          this.$set(this.formData, 'labParkFactoryVideoName', val.videoName);
        }
      }
      this.videoShow = false;
    } // 选择设备
    ,
    deviceSelect: function deviceSelect(index) {
      this.deviceIndex = index;
      this.deviceShow = true;
    },
    deviceSel: function deviceSel(val) {
      if (val) {
        if (this.deviceIndex == 1) {
          //产业园设备
          this.$set(this.formData, 'zoneDeviceId', val.id);
          this.$set(this.formData, 'zoneDeviceName', val.deviceName);
        } else if (this.deviceIndex == 2) {
          //厂房设备
          this.$set(this.formData, 'labParkFactoryDeviceId', val.id);
          this.$set(this.formData, 'labParkFactoryDeviceName', val.deviceName);
        } else if (this.deviceIndex == 3) {
          //溯源气象设备
          this.$set(this.formData, 'souceAirDeviceId', val.id);
          this.$set(this.formData, 'souceAirDeviceName', val.deviceName);
        } else if (this.deviceIndex == 4) {
          //溯源水质设备
          this.$set(this.formData, 'souceWaterDeviceId', val.id);
          this.$set(this.formData, 'souceWaterDeviceName', val.deviceName);
        } else if (this.deviceIndex == 5) {
          //监测第一设备
          this.$set(this.formData, 'monitorFirstDeviceId', val.id);
          this.$set(this.formData, 'monitorFirstDeviceName', val.deviceName);
        } else if (this.deviceIndex == 6) {
          //监测第二设备
          this.$set(this.formData, 'monitorSecondDeviceId', val.id);
          this.$set(this.formData, 'monitorSecondDeviceName', val.deviceName);
        } else if (this.deviceIndex == 7) {
          //监测第三设备
          this.$set(this.formData, 'monitorThirdDeviceId', val.id);
          this.$set(this.formData, 'monitorThirdDeviceName', val.deviceName);
        } else if (this.deviceIndex == 8) {
          //监测第四设备
          this.$set(this.formData, 'monitorFourthDeviceId', val.id);
          this.$set(this.formData, 'monitorFourthDeviceName', val.deviceName);
        } else if (this.deviceIndex == 9) {
          //监测第五设备
          this.$set(this.formData, 'monitorFifthDeviceId', val.id);
          this.$set(this.formData, 'monitorFifthDeviceName', val.deviceName);
        } else if (this.deviceIndex == 10) {
          //产业园水环境设备
          this.$set(this.formData, 'zoneDeviceSecondId', val.id);
          this.$set(this.formData, 'zoneDeviceSecondName', val.deviceName);
        } else if (this.deviceIndex == 11) {
          //厂房水环境设备
          this.$set(this.formData, 'labParkFactoryDeviceSecondId', val.id);
          this.$set(this.formData, 'labParkFactoryDeviceSecondName', val.deviceName);
        } else if (this.deviceIndex == 12) {
          //监测第一设备-溶氧
          this.$set(this.formData, 'monitorFirstDeviceSecondId', val.id);
          this.$set(this.formData, 'monitorFirstDeviceSecondName', val.deviceName);
        } else if (this.deviceIndex == 13) {
          //监测第二设备-溶氧
          this.$set(this.formData, 'monitorSecondDeviceSecondId', val.id);
          this.$set(this.formData, 'monitorSecondDeviceSecondName', val.deviceName);
        } else if (this.deviceIndex == 14) {
          //监测第三设备-溶氧
          this.$set(this.formData, 'monitorThirdDeviceSecondId', val.id);
          this.$set(this.formData, 'monitorThirdDeviceSecondName', val.deviceName);
        }
      }
      this.deviceShow = false;
    },
    add: function add() {
      this.editDialogTitle = this.pageConfig.editPageName + '新增';
      this.editDialogShow = true;
    },
    edit: function edit(data) {
      this.initFormData(data);
      this.editDialogTitle = this.pageConfig.editPageName + '维护';
      this.editDialogShow = true;
      this.$set(this.formData, 'executeDate', data.executeDate + '');
    },
    query: function query() {
      var _this = this;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this.tableData = res.data || [];
        if (_this.tableData.length) {
          _this.$api['zoneInfoDetail'](res.data[0].id).then(function (detail) {
            if (detail.data) {
              _this.initFormData(detail.data);
            }
          });
        } else {
          _this.initFormData(_this.emptyFormData);
        }
      });
    },
    submitFormData: function submitFormData() {
      var _this2 = this;
      this.$refs['formDataVerify'].validate(function (valid) {
        if (valid) {
          if (_this2.formData.id) {
            _this2.$api[_this2.pageConfig.modifyFunName](_this2.saveFormDataProcess(_this2.formData)).then(function (res) {
              if (res.data) {
                _this2.$message.success('编辑成功');
                _this2.initFormData(_this2.emptyFormData);
                _this2.editDialogShow = false;
                _this2.query();
              } else {
                _this2.$message.error('编辑失败');
              }
            });
          } else {
            _this2.$api[_this2.pageConfig.addFunName](_this2.saveFormDataProcess(_this2.formData)).then(function (res) {
              if (res.data) {
                _this2.$message.success('新增成功');
                _this2.initFormData(_this2.emptyFormData);
                _this2.editDialogShow = false;
                _this2.query();
              } else {
                _this2.$message.error('新增失败');
              }
            });
          }
        }
      });
    }
  }
};