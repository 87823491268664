export default (function (_ref) {
  var request = _ref.request;
  return {
    appVersionAdd: function appVersionAdd(data) {
      return request({
        url: '/appVersion',
        method: 'post',
        data: data
      });
    },
    appVersionModify: function appVersionModify(data) {
      return request({
        url: "/appVersion/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    appVersionQueryJoinUser: function appVersionQueryJoinUser(data) {
      return request({
        url: '/appVersion/joinUser',
        method: 'get',
        params: data
      });
    },
    appVersionDetail: function appVersionDetail(id) {
      return request({
        url: "/appVersion/".concat(id),
        method: 'get'
      });
    },
    appVersionSendProduction: function appVersionSendProduction(id) {
      return request({
        url: "/appVersion/".concat(id, "/sendProduction"),
        method: 'post'
      });
    }
  };
});