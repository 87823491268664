import "D:\\work\\code\\Halov-China-Fishery-V2\\halov-web\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "D:\\work\\code\\Halov-China-Fishery-V2\\halov-web\\node_modules\\core-js\\modules\\es.promise.js";
import "D:\\work\\code\\Halov-China-Fishery-V2\\halov-web\\node_modules\\core-js\\modules\\es.object.assign.js";
import "D:\\work\\code\\Halov-China-Fishery-V2\\halov-web\\node_modules\\core-js\\modules\\es.promise.finally.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
// Vue
import Vue from 'vue';
import i18n from './i18n';
import App from './App';
// 核心插件
import d2Admin from '@/plugin/d2admin';
// store
import store from '@/store/index';
// 参数校验
import './libs/util.int';

// 菜单和路由设置
import axios from 'axios';
import router from './router';
import { menuHeader } from '@/menu';
import { frameInRoutes } from '@/router/routes';

// V-Charts
// import VCharts from 'v-charts'

// 高德地图
// import VueAMap from 'vue-amap' // 引入高德
import vRegion from 'v-region';

// 核心插件
Vue.use(d2Admin);

// V-Charts
// Vue.use(VCharts)

// 高德地图
// Vue.use(VueAMap)
Vue.use(vRegion);

// 百度地图
import BaiduMap from 'vue-baidu-map';
Vue.use(BaiduMap, {
  ak: '0QkCAxsRC5QApnHW3HzKSWIGeR8VXgIi'
});

// 甘特图
import vGanttChart from 'v-gantt-chart';
Vue.use(vGanttChart);
new Vue({
  router: router,
  store: store,
  i18n: i18n,
  render: function render(h) {
    return h(App);
  },
  created: function created() {
    // 处理路由 得到每一级的路由设置
    this.$store.commit('d2admin/page/init', frameInRoutes);
    // 设置顶栏菜单
    this.$store.commit('d2admin/menu/headerSet', []);
    // 初始化菜单搜索功能
    this.$store.commit('d2admin/search/init', menuHeader);
  },
  mounted: function mounted() {
    // 展示系统信息
    this.$store.commit('d2admin/releases/versionShow');
    // 用户登录后从数据库加载一系列的设置
    this.$store.dispatch('d2admin/account/load');
    // 获取并记录用户 UA
    this.$store.commit('d2admin/ua/get');
    // 初始化全屏监听
    this.$store.dispatch('d2admin/fullscreen/listen');
  }
}).$mount('#app');

// 日期格式化
Vue.prototype.dateFormat2Str = function (data) {
  if (data) {
    var time = data + '';
    if (time.length === 8) {
      return time.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
    } else if (time.length === 12) {
      return time.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3 $4:$5');
    } else if (time.length === 14) {
      return time.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3 $4:$5:$6');
    } else {
      return '';
    }
  } else {
    return '';
  }
};

// 驼峰转下划线
Vue.prototype.camelToUnderline = function (data) {
  if (data) {
    var content = data + '';
    return content.replace(/([A-Z])/g, '_$1').toLowerCase();
  } else {
    return '';
  }
};

// 拼接Get参数
Vue.prototype.joinGetParam = function (data) {
  var params = '';
  for (var key in data) {
    if (data[key]) {
      if (params.length === 0) {
        params += "?".concat(key, "=").concat(data[key]);
      } else {
        params += "&".concat(key, "=").concat(data[key]);
      }
    }
  }
  return params;
};

// 文件上传
Vue.prototype.uploadFile = function (userId, currentDate, sing, file, isFile) {
  console.log('isFile', isFile);
  // 获取文件后缀
  var lastPointIndex = file.name.lastIndexOf('.');
  var suffix = file.name.substr(lastPointIndex + 1);
  var key = userId + '/' + currentDate + '/' + Date.now() + '.' + suffix; // 创建文件路径

  var formData = new FormData();
  if (process.env.VUE_APP_UPLOAD_TYPE === 'server') {
    formData.append('name', key);
    formData.append('file', file);
    return new Promise(function (resolve, reject) {
      axios({
        url: process.env.VUE_APP_API + 'mng/file/upload',
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data; boundary = ' + new Date().getTime(),
          Authorization: localStorage.getItem('token')
        }
      }).then(function (response) {
        if (response.status === 200 && response.data.code === 200) {
          resolve(process.env.VUE_APP_API + 'mng/file/download/' + response.data.data.id);
        } else {
          // 抛出错误
          reject(response);
        }
      }).catch(function (error) {
        // 抛出异常
        reject(error);
      });
    });
  } else if (process.env.VUE_APP_UPLOAD_TYPE === 'oss') {
    formData.append('key', key);
    formData.append('OSSAccessKeyId', sing.accessId);
    formData.append('policy', sing.policy);
    formData.append('signature', sing.signature);
    formData.append('success_action_status', '200');
    formData.append('file', file);
    return new Promise(function (resolve, reject) {
      axios({
        url: process.env.VUE_APP_UPLOAD_API,
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(function (response) {
        console.log('response', process.env.VUE_APP_UPLOAD_API + key);
        if (response.status === 200) {
          // 返回上传路径
          if (isFile) {
            resolve({
              url: process.env.VUE_APP_UPLOAD_API + key,
              name: file.name
            });
          } else {
            resolve(process.env.VUE_APP_UPLOAD_API + key);
          }
        } else {
          // 抛出错误
          reject(response);
        }
      }).catch(function (error) {
        // 抛出异常
        reject(error);
      });
    });
  }
};

// 数字加逗号处理(每三位加逗号)
Vue.prototype.toThousands = function () {
  var num = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  return num.toString().replace(/\d+/, function (n) {
    return n.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
  });
};

// 按钮权限
Vue.prototype.buttonPerms = function (key) {
  var operationPermission = localStorage.getItem('operationPermission') ? JSON.parse(localStorage.getItem('operationPermission')) : {};
  // console.log('operationPermission',operationPermission,key,localStorage.getItem('userId'))
  if (localStorage.getItem('userId') == '19901001') {
    return true;
  }
  return operationPermission[key] || false;
};

// 高德地图
// VueAMap.initAMapApiLoader({
//   key: process.env.VUE_APP_AMAP_KEY
//   // 插件集合
//   , plugin: [
//     'AMap.Geolocation' // 定位空间，用来获取和展示用户主机所在的经纬度位置
//     , ' AMap.Autocomplete ' // 输入提示插件
//     , ' AMap.PlaceSearch ' // POI搜索插件
//     , ' AMap.Scale ' // 右下角缩略图插件，比例尺
//     , ' AMap.OverView ' // 地图鹰眼插件
//     , ' AMap.ToolBar ' // 地图工具条
//     , ' AMap.MapType ' // 类别切换空间，实现默认图层与卫星图，实施交通层之间切换的控制
//     , ' AMap.PolyEditor ' // 编辑 折线多边形
//     , ' AMap.CircleEditor '
//     , 'AMap.Geocoder' // 地图编码
//   ]
// })

window._AMapSecurityConfig = {
  securityJsCode: process.env.VUE_APP_AMAP_SECURITY_KEY
};