import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "page"
  }, [_c("template", {
    slot: "header"
  }, [_c("el-form", {
    attrs: {
      inline: true,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "关键字"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "关键字"
    },
    model: {
      value: _vm.searchForm.searchKey,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "searchKey", $$v);
      },
      expression: "searchForm.searchKey"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.search
    }
  }, [_c("i", {
    staticClass: "fa fa-search"
  }), _vm._v(" 查询")]), _vm.buttonPerms(_vm.$route.path + ":add") ? _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.add
    }
  }, [_c("i", {
    staticClass: "fa fa-plus"
  }), _vm._v(" 添加")]) : _vm._e()], 1)], 1)], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.queryLoading,
      expression: "queryLoading"
    }],
    staticStyle: {
      width: "100%",
      "margin-bottom": "20px"
    },
    attrs: {
      data: _vm.tableData,
      stripe: "",
      border: "",
      "row-key": "id",
      "element-loading-text": "正在努力加载中..."
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "序号",
      type: "index",
      width: "50"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "275"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm.buttonPerms(_vm.$route.path + ":edit") ? _c("el-button", {
          attrs: {
            size: "mini",
            type: "warning"
          },
          on: {
            click: function click($event) {
              return _vm.edit(scope.row);
            }
          }
        }, [_vm._v("编辑")]) : _vm._e(), _vm.buttonPerms(_vm.$route.path + ":code") ? _c("el-button", {
          attrs: {
            size: "mini",
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.code(scope.row);
            }
          }
        }, [_vm._v("合格证/溯源码")]) : _vm._e(), _vm.buttonPerms(_vm.$route.path + ":del") ? _c("el-button", {
          attrs: {
            size: "mini",
            type: "danger"
          },
          on: {
            click: function click($event) {
              return _vm.del(scope.row.id);
            }
          }
        }, [_vm._v("删除")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "productName",
      label: "产品名称",
      width: "250",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "sourceBatchCode",
      label: "批次",
      width: "250",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "productAmount",
      label: "数量",
      width: "150"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "productShelfLife",
      label: "保质期（天）",
      width: "150"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "创建时间",
      sortable: "custom",
      "min-width": "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(_vm.dateFormat2Str(scope.row.createTime)))])];
      }
    }])
  })], 1), _c("el-pagination", {
    attrs: {
      background: "",
      align: "center",
      layout: "prev, pager, next, sizes, total",
      "page-sizes": [10, 20, 50, 100],
      "current-page": _vm.searchForm.page,
      "page-size": _vm.searchForm.limit,
      total: _vm.totalCount
    },
    on: {
      "current-change": _vm.pageChange,
      "size-change": _vm.sizeChange
    }
  }), _c("el-dialog", {
    attrs: {
      visible: _vm.editDialogShow,
      title: _vm.editDialogTitle,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.editDialogShow = $event;
      },
      close: function close($event) {
        return _vm.initFormData(_vm.emptyFormData);
      }
    }
  }, [_c("el-form", {
    ref: "formDataVerify",
    attrs: {
      model: _vm.formData,
      rules: _vm.rules
    }
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "捕捞批次",
      "label-width": _vm.formLabelWidth,
      prop: "taskCatchId"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      readonly: "",
      placeholder: "请选择捕捞批次"
    },
    model: {
      value: _vm.formData.catchBatchCode,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "catchBatchCode", $$v);
      },
      expression: "formData.catchBatchCode"
    }
  }), _c("el-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.catch_show = true;
      }
    }
  }, [_vm._v("选择")])], 1)])], 1)], 1), _c("el-row", [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "开始日期",
      "label-width": _vm.formLabelWidth,
      prop: "startDate"
    }
  }, [_c("el-date-picker", {
    attrs: {
      readonly: "",
      type: "date",
      placeholder: "选择日期",
      format: "yyyy-MM-dd",
      "value-format": "yyyyMMdd"
    },
    model: {
      value: _vm.formData.startDate,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "startDate", $$v);
      },
      expression: "formData.startDate"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "结束日期",
      "label-width": _vm.formLabelWidth,
      prop: "endDate"
    }
  }, [_c("el-date-picker", {
    attrs: {
      readonly: "",
      type: "date",
      placeholder: "选择日期",
      format: "yyyy-MM-dd",
      "value-format": "yyyyMMdd"
    },
    model: {
      value: _vm.formData.endDate,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "endDate", $$v);
      },
      expression: "formData.endDate"
    }
  })], 1)], 1)], 1), _c("el-row", [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "溯源批次",
      "label-width": _vm.formLabelWidth,
      prop: "sourceBatchCode"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "请输入溯源批次"
    },
    model: {
      value: _vm.formData.sourceBatchCode,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "sourceBatchCode", $$v);
      },
      expression: "formData.sourceBatchCode"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "开具日期",
      "label-width": _vm.formLabelWidth,
      prop: "issueDate"
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "date",
      placeholder: "选择日期",
      format: "yyyy-MM-dd",
      "value-format": "yyyyMMdd"
    },
    model: {
      value: _vm.formData.issueDate,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "issueDate", $$v);
      },
      expression: "formData.issueDate"
    }
  })], 1)], 1)], 1), _c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "溯源样式",
      "label-width": _vm.formLabelWidth,
      prop: "sourceStyle"
    }
  }, [_c("el-button", {
    staticStyle: {
      "margin-bottom": "10px"
    },
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        ;
        _vm.preview_show = true, _vm.certificate_title = "合格证预览";
      }
    }
  }, [_vm._v("预览")]), _c("div", {
    staticClass: "style-list"
  }, [_c("div", {
    staticClass: "style-item style-item-1",
    class: {
      "style-item-active": _vm.formData.sourceStyle == 1
    },
    on: {
      click: function click($event) {
        return _vm.styleClick(1);
      }
    }
  }, [_c("div", {
    staticClass: "item-content"
  }, [_c("div", {
    staticClass: "code"
  }, [_c("img", {
    attrs: {
      src: require("./imgs/code.png"),
      alt: ""
    }
  }), _c("div", [_vm._v("扫一扫 查溯源")])]), _c("div", {
    staticClass: "text"
  }, [_c("div", [_vm._v("产品名称：" + _vm._s(_vm.formData.productName))]), _c("div", [_vm._v("开具日期：" + _vm._s(_vm.dateFormat2Str(_vm.formData.issueDate)))]), _c("div", [_vm._v("生产单位：" + _vm._s(_vm.formData.merchantName))]), _c("div", [_vm._v("联系电话：" + _vm._s(_vm.formData.merchantContactPhone))])])]), _c("div", {
    staticClass: "item-bottom"
  }, [_c("div", [_vm._v("溯源码为主体")]), _c("div", [_vm._v("尺寸(60x30mm)")])])]), _c("div", {
    staticClass: "style-item style-item-1",
    class: {
      "style-item-active": _vm.formData.sourceStyle == 2
    },
    on: {
      click: function click($event) {
        return _vm.styleClick(2);
      }
    }
  }, [_c("div", {
    staticClass: "item-content"
  }, [_c("div", {
    staticClass: "code"
  }, [_c("img", {
    attrs: {
      src: require("./imgs/code.png"),
      alt: ""
    }
  }), _c("div", [_vm._v("扫一扫 查溯源")])]), _c("div", {
    staticClass: "text"
  }, [_c("div", [_vm._v("产品名称：" + _vm._s(_vm.formData.productName))]), _c("div", [_vm._v("数量（重量）：" + _vm._s(_vm.formData.productAmount))]), _c("div", [_vm._v("开具日期：" + _vm._s(_vm.dateFormat2Str(_vm.formData.issueDate)))]), _c("div", [_vm._v("生产单位：" + _vm._s(_vm.formData.merchantName))]), _c("div", [_vm._v("编号：" + _vm._s(_vm.formData.catchBatchCode))]), _c("div", [_vm._v("联系电话：" + _vm._s(_vm.formData.merchantContactPhone))])])]), _c("div", {
    staticClass: "item-bottom"
  }, [_c("div", [_vm._v("溯源码为主体 Ⅱ")]), _c("div", [_vm._v("尺寸(60x30mm)")])])]), _c("div", {
    staticClass: "style-item style-item-2",
    class: {
      "style-item-active": _vm.formData.sourceStyle == 3
    },
    on: {
      click: function click($event) {
        return _vm.styleClick(3);
      }
    }
  }, [_c("div", {
    staticClass: "item-content"
  }, [_c("div", {
    staticClass: "code"
  }, [_c("div", {
    staticClass: "text"
  }, [_c("div", [_vm._v("农产品合格证")]), _c("div", [_vm._v("编号：" + _vm._s(_vm.formData.catchBatchCode))])]), _c("img", {
    attrs: {
      src: require("./imgs/code.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "code"
  }, [_c("div", {
    staticClass: "text"
  }, [_c("div", [_vm._v("农产品合格证")]), _c("div", [_vm._v("编号：" + _vm._s(_vm.formData.catchBatchCode))])]), _c("img", {
    attrs: {
      src: require("./imgs/code.png"),
      alt: ""
    }
  })])]), _c("div", {
    staticClass: "item-bottom"
  }, [_c("div", [_vm._v("溯源码-双排")]), _c("div", [_vm._v("尺寸(60x30mm)")])])]), _c("div", {
    staticClass: "style-item style-item-3",
    class: {
      "style-item-active": _vm.formData.sourceStyle == 4
    },
    on: {
      click: function click($event) {
        return _vm.styleClick(4);
      }
    }
  }, [_c("div", {
    staticClass: "item-content"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("食用农产品合格证")]), _c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", {
    staticClass: "text"
  }, [_c("div", [_vm._v("产品名称：" + _vm._s(_vm.formData.productName))]), _c("div", [_vm._v("数量（重量）：" + _vm._s(_vm.formData.productAmount))]), _c("div", [_vm._v("开具日期：" + _vm._s(_vm.dateFormat2Str(_vm.formData.issueDate)))]), _c("div", [_vm._v("责任人：" + _vm._s(_vm.formData.merchantContact))]), _c("div", [_vm._v("联系电话：" + _vm._s(_vm.formData.merchantContactPhone))]), _c("div", [_vm._v("生产单位：" + _vm._s(_vm.formData.merchantName))]), _c("div", [_vm._v("单位地址：" + _vm._s(_vm.formData.merchantAddress))])]), _c("div", {
    staticClass: "code"
  }, [_c("div", [_vm._v("编号：" + _vm._s(_vm.formData.catchBatchCode))]), _c("img", {
    attrs: {
      src: require("./imgs/code.png"),
      alt: ""
    }
  }), _c("div", [_vm._v("扫一扫 查溯源")])])])]), _c("div", {
    staticClass: "item-bottom",
    staticStyle: {
      padding: "5px 8px"
    }
  }, [_c("div", [_vm._v("合格证-不含自我承诺")]), _c("div", [_vm._v("尺寸(60x40mm)")])])]), _c("div", {
    staticClass: "style-item style-item-4",
    class: {
      "style-item-active": _vm.formData.sourceStyle == 5
    },
    on: {
      click: function click($event) {
        return _vm.styleClick(5);
      }
    }
  }, [_c("div", {
    staticClass: "item-content"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("食用农产品合格证")]), _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("div", {
    staticClass: "text"
  }, [_c("div", [_vm._v("产品名称：" + _vm._s(_vm.formData.productName))]), _c("div", [_vm._v("数量（重量）：" + _vm._s(_vm.formData.productAmount))]), _c("div", [_vm._v("开具日期：" + _vm._s(_vm.dateFormat2Str(_vm.formData.issueDate)))]), _c("div", [_vm._v("责任人：" + _vm._s(_vm.formData.merchantContact))]), _c("div", [_vm._v("联系电话：" + _vm._s(_vm.formData.merchantContactPhone))]), _c("div", [_vm._v("生产单位：" + _vm._s(_vm.formData.merchantName))]), _c("div", [_vm._v("单位地址：" + _vm._s(_vm.formData.merchantAddress))])]), _c("div", {
    staticClass: "code"
  }, [_c("div", [_vm._v("编号：" + _vm._s(_vm.formData.catchBatchCode))]), _c("img", {
    attrs: {
      src: require("./imgs/code.png"),
      alt: ""
    }
  }), _c("div", [_vm._v("扫一扫 查溯源")])])]), _c("div", {
    staticClass: "promise"
  }, [_c("div", {
    staticClass: "promise-title"
  }, [_vm._v("承诺对产品质量安全以及合格证真实性负责:")]), _c("div", {
    staticStyle: {
      display: "flex",
      "flex-wrap": "wrap"
    }
  }, [_c("el-checkbox", {
    attrs: {
      label: "遵守农业安全间隔期、兽药休药期规定"
    },
    model: {
      value: _vm.formData.promiseAbideStipulate,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "promiseAbideStipulate", $$v);
      },
      expression: "formData.promiseAbideStipulate"
    }
  }), _c("el-checkbox", {
    attrs: {
      label: "销售的农用产品符合农药兽药残留食品安全国家标准"
    },
    model: {
      value: _vm.formData.promiseConformStandard,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "promiseConformStandard", $$v);
      },
      expression: "formData.promiseConformStandard"
    }
  }), _c("el-checkbox", {
    attrs: {
      label: "不使用非法添加物"
    },
    model: {
      value: _vm.formData.promiseIllegalityAdditive,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "promiseIllegalityAdditive", $$v);
      },
      expression: "formData.promiseIllegalityAdditive"
    }
  }), _c("el-checkbox", {
    attrs: {
      label: "不使用禁限农药"
    },
    model: {
      value: _vm.formData.promiseProhibitPesticide,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "promiseProhibitPesticide", $$v);
      },
      expression: "formData.promiseProhibitPesticide"
    }
  }), _c("el-checkbox", {
    attrs: {
      label: "自检合格"
    },
    model: {
      value: _vm.formData.promiseSelfInspect,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "promiseSelfInspect", $$v);
      },
      expression: "formData.promiseSelfInspect"
    }
  }), _c("el-checkbox", {
    attrs: {
      label: "农产品地理标志证书"
    },
    model: {
      value: _vm.formData.certAgriculturalProductsFlag,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "certAgriculturalProductsFlag", $$v);
      },
      expression: "formData.certAgriculturalProductsFlag"
    }
  }), _c("el-checkbox", {
    attrs: {
      label: "委托检验合格证书"
    },
    model: {
      value: _vm.formData.certEntrustPassedInspection,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "certEntrustPassedInspection", $$v);
      },
      expression: "formData.certEntrustPassedInspection"
    }
  }), _c("el-checkbox", {
    attrs: {
      label: "绿色食品证书"
    },
    model: {
      value: _vm.formData.certGreenFood,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "certGreenFood", $$v);
      },
      expression: "formData.certGreenFood"
    }
  }), _c("el-checkbox", {
    attrs: {
      label: "有机食品证书"
    },
    model: {
      value: _vm.formData.certOrganicFood,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "certOrganicFood", $$v);
      },
      expression: "formData.certOrganicFood"
    }
  })], 1)])]), _c("div", {
    staticClass: "item-bottom"
  }, [_c("div", [_vm._v("合格证-含自我承诺")]), _c("div", [_vm._v("尺寸(60x70mm)")])])])])], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "产品信息展示",
      "label-width": _vm.formLabelWidth,
      prop: "productShow"
    }
  }, [_c("el-switch", {
    attrs: {
      "active-text": "",
      "inactive-text": ""
    },
    model: {
      value: _vm.formData.productShow,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "productShow", $$v);
      },
      expression: "formData.productShow"
    }
  })], 1)], 1)], 1), _vm.formData.productShow ? _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "产品名称",
      "label-width": _vm.formLabelWidth,
      prop: "productName"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "请输入产品名称"
    },
    model: {
      value: _vm.formData.productName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "productName", $$v);
      },
      expression: "formData.productName"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "产品数量",
      "label-width": _vm.formLabelWidth,
      prop: "productAmount"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "请输入产品数量"
    },
    model: {
      value: _vm.formData.productAmount,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "productAmount", $$v);
      },
      expression: "formData.productAmount"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "产品编码",
      "label-width": _vm.formLabelWidth,
      prop: "productCode"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "请输入产品编码"
    },
    model: {
      value: _vm.formData.productCode,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "productCode", $$v);
      },
      expression: "formData.productCode"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "产品保质期(天)",
      "label-width": _vm.formLabelWidth,
      prop: "productShelfLife"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "请输入产品保质期"
    },
    model: {
      value: _vm.formData.productShelfLife,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "productShelfLife", $$v);
      },
      expression: "formData.productShelfLife"
    }
  })], 1)], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "产品图片",
      "label-width": _vm.formLabelWidth,
      prop: "productImage"
    }
  }, [!_vm.formData.productImage ? _c("el-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      accept: "image/jpeg, image/jpg, image/png",
      "list-type": "picture-card",
      "show-file-list": false,
      "before-upload": _vm.beforeImageUpload,
      "http-request": _vm.productImageUpload
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]) : _vm._e(), _vm.formData.productImage ? _c("div", {
    staticClass: "el-upload-list el-upload-list--picture-card"
  }, [_c("div", {
    staticClass: "el-upload-list__item is-success"
  }, [_vm.formData.productImage ? _c("img", {
    staticClass: "avatar",
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: _vm.formData.productImage
    }
  }) : _vm._e(), _c("label", {
    staticClass: "el-upload-list__item-status-label"
  }, [_c("i", {
    staticClass: "el-icon-upload-success el-icon-check"
  })]), _c("span", {
    staticClass: "el-upload-list__item-actions"
  }, [_c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-delete",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.removeFile("productImage");
      }
    }
  })]), _c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-zoom-in",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.previewFile("productImage");
      }
    }
  })])])])]) : _vm._e()], 1)], 1)], 1) : _vm._e(), _vm.formData.productShow ? _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "产品描述",
      "label-width": _vm.formLabelWidth,
      prop: "productDescription"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 5,
      placeholder: "请输入产品描述"
    },
    model: {
      value: _vm.formData.productDescription,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "productDescription", $$v);
      },
      expression: "formData.productDescription"
    }
  })], 1)], 1)], 1) : _vm._e(), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "展示视频信息",
      "label-width": _vm.formLabelWidth,
      prop: "videoShow"
    }
  }, [_c("el-switch", {
    attrs: {
      "active-text": "",
      "inactive-text": ""
    },
    model: {
      value: _vm.formData.videoShow,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "videoShow", $$v);
      },
      expression: "formData.videoShow"
    }
  })], 1)], 1)], 1), _vm.formData.videoShow ? _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "视频类型",
      "label-width": _vm.formLabelWidth,
      prop: "videoType"
    }
  }, [_c("el-radio", {
    staticStyle: {
      "line-height": "40px"
    },
    attrs: {
      label: "Current"
    },
    on: {
      input: _vm.videoTypeInput
    },
    model: {
      value: _vm.formData.videoType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "videoType", $$v);
      },
      expression: "formData.videoType"
    }
  }, [_vm._v("当前")]), _c("el-radio", {
    staticStyle: {
      "line-height": "40px"
    },
    attrs: {
      label: "History"
    },
    on: {
      input: _vm.videoTypeInput
    },
    model: {
      value: _vm.formData.videoType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "videoType", $$v);
      },
      expression: "formData.videoType"
    }
  }, [_vm._v("历史")])], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  })], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_vm.formData.videoType == "Current" ? _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "视频",
      "label-width": _vm.formLabelWidth,
      prop: "videoId"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      readonly: "",
      placeholder: "请选择视频"
    },
    model: {
      value: _vm.formData.videoUrl,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "videoUrl", $$v);
      },
      expression: "formData.videoUrl"
    }
  }), _c("el-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.video_show = true;
      }
    }
  }, [_vm._v("选择")])], 1)])], 1) : _vm._e(), _vm.formData.videoType == "History" ? _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "视频地址",
      "label-width": _vm.formLabelWidth,
      prop: "videoUrl"
    }
  }, [!_vm.formData.videoUrl ? _c("el-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      accept: "'video/mp4', 'video/ogg', 'video/wmv'",
      "list-type": "picture-card",
      "show-file-list": false,
      "before-upload": _vm.beforeVideoUpload,
      "http-request": _vm.videoUrlUpload
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]) : _vm._e(), _vm.formData.videoUrl ? _c("div", {
    staticClass: "el-upload-list el-upload-list--picture-card"
  }, [_c("div", {
    staticClass: "el-upload-list__item is-success"
  }, [_vm.formData.videoUrl ? _c("video", {
    staticClass: "avatar",
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: _vm.formData.videoUrl
    }
  }) : _vm._e(), _c("label", {
    staticClass: "el-upload-list__item-status-label"
  }, [_c("i", {
    staticClass: "el-icon-upload-success el-icon-check"
  })]), _c("span", {
    staticClass: "el-upload-list__item-actions"
  }, [_c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-delete",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.removeFile("videoUrl");
      }
    }
  })]), _c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-zoom-in",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.previewFile("videoUrl");
      }
    }
  })])])])]) : _vm._e()], 1)], 1) : _vm._e()], 1)], 1) : _vm._e(), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "展示商家信息",
      "label-width": _vm.formLabelWidth,
      prop: "merchantShow"
    }
  }, [_c("el-switch", {
    attrs: {
      "active-text": "",
      "inactive-text": ""
    },
    model: {
      value: _vm.formData.merchantShow,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "merchantShow", $$v);
      },
      expression: "formData.merchantShow"
    }
  })], 1)], 1)], 1), _vm.formData.merchantShow ? _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "商家名称",
      "label-width": _vm.formLabelWidth,
      prop: "merchantName"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "请输入商家名称"
    },
    model: {
      value: _vm.formData.merchantName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "merchantName", $$v);
      },
      expression: "formData.merchantName"
    }
  })], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "联系人",
      "label-width": _vm.formLabelWidth,
      prop: "merchantContact"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "请输入商家地址"
    },
    model: {
      value: _vm.formData.merchantContact,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "merchantContact", $$v);
      },
      expression: "formData.merchantContact"
    }
  })], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "联系方式",
      "label-width": _vm.formLabelWidth,
      prop: "merchantContactPhone"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "请输入商家联系方式"
    },
    model: {
      value: _vm.formData.merchantContactPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "merchantContactPhone", $$v);
      },
      expression: "formData.merchantContactPhone"
    }
  })], 1)], 1)], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "详细地址",
      "label-width": _vm.formLabelWidth,
      prop: "merchantAddress"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 7
    },
    model: {
      value: _vm.formData.merchantAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "merchantAddress", $$v);
      },
      expression: "formData.merchantAddress"
    }
  })], 1)], 1)], 1)], 1)], 1) : _vm._e(), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "展示报告信息",
      "label-width": _vm.formLabelWidth,
      prop: "reportShow"
    }
  }, [_c("el-switch", {
    attrs: {
      "active-text": "",
      "inactive-text": ""
    },
    model: {
      value: _vm.formData.reportShow,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "reportShow", $$v);
      },
      expression: "formData.reportShow"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_vm.formData.reportShow ? _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    staticClass: "reportFiles-form-item",
    attrs: {
      label: "报告文件",
      "label-width": _vm.formLabelWidth,
      prop: "reportFiles"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex"
    }
  }, _vm._l(_vm.formData.reportFiles, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "el-upload-list el-upload-list--picture-card"
    }, [_c("div", {
      staticClass: "el-upload-list__item is-success"
    }, [item ? _c("img", {
      staticClass: "avatar",
      staticStyle: {
        width: "100%",
        height: "100%"
      },
      attrs: {
        src: item
      }
    }) : _vm._e(), _c("label", {
      staticClass: "el-upload-list__item-status-label"
    }, [_c("i", {
      staticClass: "el-icon-upload-success el-icon-check"
    })]), _c("span", {
      staticClass: "el-upload-list__item-actions"
    }, [_c("span", {
      staticClass: "el-upload-list__item-delete"
    }, [_c("i", {
      staticClass: "el-icon-delete",
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.removeFile("reportFiles", index);
        }
      }
    })]), _c("span", {
      staticClass: "el-upload-list__item-delete"
    }, [_c("i", {
      staticClass: "el-icon-zoom-in",
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.previewFile("reportFiles", index);
        }
      }
    })])])])]);
  }), 0), !_vm.formData.reportFiles || _vm.formData.reportFiles.length < 6 ? _c("el-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      accept: "image/jpeg, image/jpg, image/png",
      "list-type": "picture-card",
      "show-file-list": false,
      "before-upload": _vm.beforeImageUpload,
      "http-request": _vm.reportFilesUpload
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]) : _vm._e()], 1)], 1) : _vm._e()], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "展示环境信息",
      "label-width": _vm.formLabelWidth,
      prop: "environmentShow"
    }
  }, [_c("el-switch", {
    attrs: {
      "active-text": "",
      "inactive-text": ""
    },
    model: {
      value: _vm.formData.environmentShow,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "environmentShow", $$v);
      },
      expression: "formData.environmentShow"
    }
  })], 1)], 1)], 1), _vm.formData.environmentShow ? _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "环境信息类型",
      "label-width": _vm.formLabelWidth,
      prop: "environmentType"
    }
  }, [_c("el-radio", {
    staticStyle: {
      "line-height": "40px"
    },
    attrs: {
      label: "Current"
    },
    on: {
      input: _vm.environmentTypeInput
    },
    model: {
      value: _vm.formData.environmentType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "environmentType", $$v);
      },
      expression: "formData.environmentType"
    }
  }, [_vm._v("当前")]), _c("el-radio", {
    staticStyle: {
      "line-height": "40px"
    },
    attrs: {
      label: "History"
    },
    on: {
      input: _vm.environmentTypeInput
    },
    model: {
      value: _vm.formData.environmentType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "environmentType", $$v);
      },
      expression: "formData.environmentType"
    }
  }, [_vm._v("历史")])], 1)], 1), _vm.formData.environmentType == "Current" ? _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "环境设备",
      "label-width": _vm.formLabelWidth,
      prop: "environmentDeviceId"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      readonly: "",
      placeholder: "请选择设备"
    },
    model: {
      value: _vm.formData.environmentDeviceHistory,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "environmentDeviceHistory", $$v);
      },
      expression: "formData.environmentDeviceHistory"
    }
  }), _c("el-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.device_show = true;
      }
    }
  }, [_vm._v("选择")])], 1)])], 1) : _vm._e(), _vm.formData.environmentType == "History" ? _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "环境设备历史",
      "label-width": _vm.formLabelWidth,
      prop: "environmentDeviceHistory"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 5,
      autocomplete: "off",
      readonly: "",
      placeholder: "请填写环境设备历史"
    },
    model: {
      value: _vm.formData.environmentDeviceHistory.text,
      callback: function callback($$v) {
        _vm.$set(_vm.formData.environmentDeviceHistory, "text", $$v);
      },
      expression: "formData.environmentDeviceHistory.text"
    }
  }), _c("el-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.deviceHistory_show = true;
      }
    }
  }, [_vm._v("添加")])], 1)])], 1) : _vm._e()], 1) : _vm._e(), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "展示任务信息",
      "label-width": _vm.formLabelWidth,
      prop: "taskShow"
    }
  }, [_c("el-switch", {
    attrs: {
      "active-text": "",
      "inactive-text": ""
    },
    model: {
      value: _vm.formData.taskShow,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "taskShow", $$v);
      },
      expression: "formData.taskShow"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "农产品地理标志证书",
      "label-width": _vm.formLabelWidth,
      prop: "certAgriculturalProductsFlag"
    }
  }, [!_vm.formData.certAgriculturalProductsFlag ? _c("el-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      accept: "image/jpeg, image/jpg, image/png",
      "list-type": "picture-card",
      "show-file-list": false,
      "before-upload": _vm.beforeImageUpload,
      "http-request": _vm.certAgriculturalProductsFlagUpload
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]) : _vm._e(), _vm.formData.certAgriculturalProductsFlag ? _c("div", {
    staticClass: "el-upload-list el-upload-list--picture-card"
  }, [_c("div", {
    staticClass: "el-upload-list__item is-success"
  }, [_vm.formData.certAgriculturalProductsFlag ? _c("img", {
    staticClass: "avatar",
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: _vm.formData.certAgriculturalProductsFlag
    }
  }) : _vm._e(), _c("label", {
    staticClass: "el-upload-list__item-status-label"
  }, [_c("i", {
    staticClass: "el-icon-upload-success el-icon-check"
  })]), _c("span", {
    staticClass: "el-upload-list__item-actions"
  }, [_c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-delete",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.removeFile("certAgriculturalProductsFlag");
      }
    }
  })]), _c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-zoom-in",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.previewFile("certAgriculturalProductsFlag");
      }
    }
  })])])])]) : _vm._e()], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "委托检验合格证书",
      "label-width": _vm.formLabelWidth,
      prop: "certEntrustPassedInspection"
    }
  }, [!_vm.formData.certEntrustPassedInspection ? _c("el-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      accept: "image/jpeg, image/jpg, image/png",
      "list-type": "picture-card",
      "show-file-list": false,
      "before-upload": _vm.beforeImageUpload,
      "http-request": _vm.certEntrustPassedInspectionUpload
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]) : _vm._e(), _vm.formData.certEntrustPassedInspection ? _c("div", {
    staticClass: "el-upload-list el-upload-list--picture-card"
  }, [_c("div", {
    staticClass: "el-upload-list__item is-success"
  }, [_vm.formData.certEntrustPassedInspection ? _c("img", {
    staticClass: "avatar",
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: _vm.formData.certEntrustPassedInspection
    }
  }) : _vm._e(), _c("label", {
    staticClass: "el-upload-list__item-status-label"
  }, [_c("i", {
    staticClass: "el-icon-upload-success el-icon-check"
  })]), _c("span", {
    staticClass: "el-upload-list__item-actions"
  }, [_c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-delete",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.removeFile("certEntrustPassedInspection");
      }
    }
  })]), _c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-zoom-in",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.previewFile("certEntrustPassedInspection");
      }
    }
  })])])])]) : _vm._e()], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "绿色食品证书",
      "label-width": _vm.formLabelWidth,
      prop: "certGreenFood"
    }
  }, [!_vm.formData.certGreenFood ? _c("el-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      accept: "image/jpeg, image/jpg, image/png",
      "list-type": "picture-card",
      "show-file-list": false,
      "before-upload": _vm.beforeImageUpload,
      "http-request": _vm.certGreenFoodUpload
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]) : _vm._e(), _vm.formData.certGreenFood ? _c("div", {
    staticClass: "el-upload-list el-upload-list--picture-card"
  }, [_c("div", {
    staticClass: "el-upload-list__item is-success"
  }, [_vm.formData.certGreenFood ? _c("img", {
    staticClass: "avatar",
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: _vm.formData.certGreenFood
    }
  }) : _vm._e(), _c("label", {
    staticClass: "el-upload-list__item-status-label"
  }, [_c("i", {
    staticClass: "el-icon-upload-success el-icon-check"
  })]), _c("span", {
    staticClass: "el-upload-list__item-actions"
  }, [_c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-delete",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.removeFile("certGreenFood");
      }
    }
  })]), _c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-zoom-in",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.previewFile("certGreenFood");
      }
    }
  })])])])]) : _vm._e()], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "有机食品证书",
      "label-width": _vm.formLabelWidth,
      prop: "certOrganicFood"
    }
  }, [!_vm.formData.certOrganicFood ? _c("el-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      accept: "image/jpeg, image/jpg, image/png",
      "list-type": "picture-card",
      "show-file-list": false,
      "before-upload": _vm.beforeImageUpload,
      "http-request": _vm.certOrganicFoodUpload
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]) : _vm._e(), _vm.formData.certOrganicFood ? _c("div", {
    staticClass: "el-upload-list el-upload-list--picture-card"
  }, [_c("div", {
    staticClass: "el-upload-list__item is-success"
  }, [_vm.formData.certOrganicFood ? _c("img", {
    staticClass: "avatar",
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: _vm.formData.certOrganicFood
    }
  }) : _vm._e(), _c("label", {
    staticClass: "el-upload-list__item-status-label"
  }, [_c("i", {
    staticClass: "el-icon-upload-success el-icon-check"
  })]), _c("span", {
    staticClass: "el-upload-list__item-actions"
  }, [_c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-delete",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.removeFile("certOrganicFood");
      }
    }
  })]), _c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-zoom-in",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.previewFile("certOrganicFood");
      }
    }
  })])])])]) : _vm._e()], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "顶部图片",
      "label-width": _vm.formLabelWidth,
      prop: "topImage"
    }
  }, [!_vm.formData.topImage ? _c("el-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      accept: "image/jpeg, image/jpg, image/png",
      "list-type": "picture-card",
      "show-file-list": false,
      "before-upload": _vm.beforeImageUpload,
      "http-request": _vm.topImageUpload
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]) : _vm._e(), _vm.formData.topImage ? _c("div", {
    staticClass: "el-upload-list el-upload-list--picture-card"
  }, [_c("div", {
    staticClass: "el-upload-list__item is-success"
  }, [_vm.formData.topImage ? _c("img", {
    staticClass: "avatar",
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: _vm.formData.topImage
    }
  }) : _vm._e(), _c("label", {
    staticClass: "el-upload-list__item-status-label"
  }, [_c("i", {
    staticClass: "el-icon-upload-success el-icon-check"
  })]), _c("span", {
    staticClass: "el-upload-list__item-actions"
  }, [_c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-delete",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.removeFile("topImage");
      }
    }
  })]), _c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-zoom-in",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.previewFile("topImage");
      }
    }
  })])])])]) : _vm._e()], 1)], 1), _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "承诺",
      "label-width": _vm.formLabelWidth,
      prop: "promiseAbideStipulate"
    }
  }, [_c("el-checkbox", {
    attrs: {
      label: "遵守农业安全间隔期、兽药休药期规定"
    },
    model: {
      value: _vm.formData.promiseAbideStipulate,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "promiseAbideStipulate", $$v);
      },
      expression: "formData.promiseAbideStipulate"
    }
  }), _c("el-checkbox", {
    attrs: {
      label: "销售的农用产品符合农药兽药残留食品安全国家标准"
    },
    model: {
      value: _vm.formData.promiseConformStandard,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "promiseConformStandard", $$v);
      },
      expression: "formData.promiseConformStandard"
    }
  }), _c("el-checkbox", {
    attrs: {
      label: "不使用非法添加物"
    },
    model: {
      value: _vm.formData.promiseIllegalityAdditive,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "promiseIllegalityAdditive", $$v);
      },
      expression: "formData.promiseIllegalityAdditive"
    }
  }), _c("el-checkbox", {
    attrs: {
      label: "不使用禁限农药"
    },
    model: {
      value: _vm.formData.promiseProhibitPesticide,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "promiseProhibitPesticide", $$v);
      },
      expression: "formData.promiseProhibitPesticide"
    }
  }), _c("el-checkbox", {
    attrs: {
      label: "自检合格"
    },
    model: {
      value: _vm.formData.promiseSelfInspect,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "promiseSelfInspect", $$v);
      },
      expression: "formData.promiseSelfInspect"
    }
  })], 1)], 1)], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeEditDialogShow
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitFormData
    }
  }, [_vm._v("确 定")])], 1)], 1), _vm.video_show ? _c("videoSel", {
    on: {
      "child-video-sel": _vm.videoSel
    }
  }) : _vm._e(), _vm.device_show ? _c("deviceSel", {
    on: {
      "child-device-sel": _vm.deviceSel
    }
  }) : _vm._e(), _c("el-dialog", {
    attrs: {
      visible: _vm.deviceHistory_show,
      title: "环境设备历史"
    },
    on: {
      close: function close($event) {
        _vm.deviceHistory_show = false;
      }
    }
  }, [_c("el-form", [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, _vm._l(_vm.dicData.configTypes, function (item, index) {
    return _c("el-col", {
      key: index,
      attrs: {
        span: 12
      }
    }, [_c("el-form-item", {
      attrs: {
        label: item.name,
        "label-width": "100px"
      }
    }, [_c("el-col", {
      attrs: {
        span: 12
      }
    }, [_c("el-input", {
      attrs: {
        autocomplete: "off",
        placeholder: "最后值"
      },
      model: {
        value: _vm.deviceItem[index].lastValue,
        callback: function callback($$v) {
          _vm.$set(_vm.deviceItem[index], "lastValue", $$v);
        },
        expression: "deviceItem[index].lastValue"
      }
    })], 1), _c("el-col", {
      attrs: {
        span: 12
      }
    }, [_c("el-input", {
      attrs: {
        autocomplete: "off",
        placeholder: "单位"
      },
      model: {
        value: _vm.deviceItem[index].unit,
        callback: function callback($$v) {
          _vm.$set(_vm.deviceItem[index], "unit", $$v);
        },
        expression: "deviceItem[index].unit"
      }
    })], 1)], 1)], 1);
  }), 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.deviceHistory_show = false;
      }
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitDeviceHistory
    }
  }, [_vm._v("确 定")])], 1)], 1), _c("el-dialog", {
    staticClass: "certificate-dialog",
    attrs: {
      visible: _vm.preview_show,
      title: _vm.certificate_title
    },
    on: {
      close: function close($event) {
        ;
        _vm.preview_show = false, _vm.preview_code = "";
      }
    }
  }, [_vm.formData.sourceStyle == 1 ? _c("div", {
    staticClass: "style-item style-item-1",
    staticStyle: {
      width: "600px",
      height: "300px"
    },
    attrs: {
      id: "export-image"
    }
  }, [_c("div", {
    staticClass: "item-content"
  }, [_c("div", {
    staticClass: "code"
  }, [_vm.preview_code ? _c("img", {
    attrs: {
      src: _vm.preview_code,
      alt: ""
    }
  }) : _c("img", {
    attrs: {
      src: require("@/assets/code.png"),
      alt: ""
    }
  }), _c("div", [_vm._v("扫一扫 查溯源")])]), _c("div", {
    staticClass: "text"
  }, [_c("div", [_vm._v("产品名称：" + _vm._s(_vm.formData.productName))]), _c("div", [_vm._v("开具日期：" + _vm._s(_vm.dateFormat2Str(_vm.formData.issueDate)))]), _c("div", [_vm._v("生产单位：" + _vm._s(_vm.formData.merchantName))]), _c("div", [_vm._v("联系电话：" + _vm._s(_vm.formData.merchantContactPhone))])])])]) : _vm._e(), _vm.formData.sourceStyle == 2 ? _c("div", {
    staticClass: "style-item style-item-1",
    staticStyle: {
      width: "600px",
      height: "300px"
    },
    attrs: {
      id: "export-image"
    }
  }, [_c("div", {
    staticClass: "item-content"
  }, [_c("div", {
    staticClass: "code"
  }, [_vm.preview_code ? _c("img", {
    attrs: {
      src: _vm.preview_code,
      alt: ""
    }
  }) : _c("img", {
    attrs: {
      src: require("@/assets/code.png"),
      alt: ""
    }
  }), _c("div", [_vm._v("扫一扫 查溯源")])]), _c("div", {
    staticClass: "text"
  }, [_c("div", [_vm._v("产品名称：" + _vm._s(_vm.formData.productName))]), _c("div", [_vm._v("数量（重量）：" + _vm._s(_vm.formData.productAmount))]), _c("div", [_vm._v("开具日期：" + _vm._s(_vm.dateFormat2Str(_vm.formData.issueDate)))]), _c("div", [_vm._v("生产单位：" + _vm._s(_vm.formData.merchantName))]), _c("div", [_vm._v("编号：" + _vm._s(_vm.formData.catchBatchCode))]), _c("div", [_vm._v("联系电话：" + _vm._s(_vm.formData.merchantContactPhone))])])])]) : _vm._e(), _vm.formData.sourceStyle == 3 ? _c("div", {
    staticClass: "style-item style-item-2",
    staticStyle: {
      width: "600px",
      height: "300px"
    },
    attrs: {
      id: "export-image"
    }
  }, [_c("div", {
    staticClass: "item-content"
  }, [_c("div", {
    staticClass: "code"
  }, [_c("div", {
    staticClass: "text"
  }, [_c("div", [_vm._v("农产品合格证")]), _c("div", [_vm._v("编号：" + _vm._s(_vm.formData.catchBatchCode))])]), _vm.preview_code ? _c("img", {
    attrs: {
      src: _vm.preview_code,
      alt: ""
    }
  }) : _c("img", {
    attrs: {
      src: require("@/assets/code.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "code"
  }, [_c("div", {
    staticClass: "text"
  }, [_c("div", [_vm._v("农产品合格证")]), _c("div", [_vm._v("编号：" + _vm._s(_vm.formData.catchBatchCode))])]), _vm.preview_code ? _c("img", {
    attrs: {
      src: _vm.preview_code,
      alt: ""
    }
  }) : _c("img", {
    attrs: {
      src: require("@/assets/code.png"),
      alt: ""
    }
  })])])]) : _vm._e(), _vm.formData.sourceStyle == 4 ? _c("div", {
    staticClass: "style-item style-item-3",
    staticStyle: {
      width: "600px",
      height: "400px"
    },
    attrs: {
      id: "export-image"
    }
  }, [_c("div", {
    staticClass: "item-content"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("食用农产品合格证")]), _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("div", {
    staticClass: "text"
  }, [_c("div", [_vm._v("产品名称：" + _vm._s(_vm.formData.productName))]), _c("div", [_vm._v("数量（重量）：" + _vm._s(_vm.formData.productAmount))]), _c("div", [_vm._v("开具日期：" + _vm._s(_vm.dateFormat2Str(_vm.formData.issueDate)))]), _c("div", [_vm._v("责任人：" + _vm._s(_vm.formData.merchantContact))]), _c("div", [_vm._v("联系电话：" + _vm._s(_vm.formData.merchantContactPhone))]), _c("div", [_vm._v("生产单位：" + _vm._s(_vm.formData.merchantName))]), _c("div", [_vm._v("单位地址：" + _vm._s(_vm.formData.merchantAddress))])]), _c("div", {
    staticClass: "code"
  }, [_c("div", [_vm._v("编号：" + _vm._s(_vm.formData.catchBatchCode))]), _vm.preview_code ? _c("img", {
    attrs: {
      src: _vm.preview_code,
      alt: ""
    }
  }) : _c("img", {
    attrs: {
      src: require("@/assets/code.png"),
      alt: ""
    }
  }), _c("div", [_vm._v("扫一扫 查溯源")])])])])]) : _vm._e(), _vm.formData.sourceStyle == 5 ? _c("div", {
    staticClass: "style-item style-item-4",
    staticStyle: {
      width: "600px",
      height: "700px"
    },
    attrs: {
      id: "export-image"
    }
  }, [_c("div", {
    staticClass: "item-content"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("食用农产品合格证")]), _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("div", {
    staticClass: "text"
  }, [_c("div", [_vm._v("产品名称：" + _vm._s(_vm.formData.productName))]), _c("div", [_vm._v("数量（重量）：" + _vm._s(_vm.formData.productAmount))]), _c("div", [_vm._v("开具日期：" + _vm._s(_vm.dateFormat2Str(_vm.formData.issueDate)))]), _c("div", [_vm._v("责任人：" + _vm._s(_vm.formData.merchantContact))]), _c("div", [_vm._v("联系电话：" + _vm._s(_vm.formData.merchantContactPhone))]), _c("div", [_vm._v("生产单位：" + _vm._s(_vm.formData.merchantName))]), _c("div", [_vm._v("单位地址：" + _vm._s(_vm.formData.merchantAddress))])]), _c("div", {
    staticClass: "code"
  }, [_c("div", [_vm._v("编号：" + _vm._s(_vm.formData.catchBatchCode))]), _vm.preview_code ? _c("img", {
    attrs: {
      src: _vm.preview_code,
      alt: ""
    }
  }) : _c("img", {
    attrs: {
      src: require("@/assets/code.png"),
      alt: ""
    }
  }), _c("div", [_vm._v("扫一扫 查溯源")])])]), _c("div", {
    staticClass: "promise"
  }, [_c("div", {
    staticClass: "promise-title"
  }, [_vm._v("承诺对产品质量安全以及合格证真实性负责:")]), _c("div", {
    staticStyle: {
      display: "flex",
      "flex-wrap": "wrap"
    }
  }, [_c("el-checkbox", {
    attrs: {
      readonly: "",
      label: "遵守农业安全间隔期、兽药休药期规定"
    },
    model: {
      value: _vm.formData.promiseAbideStipulate,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "promiseAbideStipulate", $$v);
      },
      expression: "formData.promiseAbideStipulate"
    }
  }), _c("el-checkbox", {
    attrs: {
      label: "销售的农用产品符合农药兽药残留食品安全国家标准"
    },
    model: {
      value: _vm.formData.promiseConformStandard,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "promiseConformStandard", $$v);
      },
      expression: "formData.promiseConformStandard"
    }
  }), _c("el-checkbox", {
    attrs: {
      label: "不使用非法添加物"
    },
    model: {
      value: _vm.formData.promiseIllegalityAdditive,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "promiseIllegalityAdditive", $$v);
      },
      expression: "formData.promiseIllegalityAdditive"
    }
  }), _c("el-checkbox", {
    attrs: {
      label: "不使用禁限农药"
    },
    model: {
      value: _vm.formData.promiseProhibitPesticide,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "promiseProhibitPesticide", $$v);
      },
      expression: "formData.promiseProhibitPesticide"
    }
  }), _c("el-checkbox", {
    attrs: {
      label: "自检合格"
    },
    model: {
      value: _vm.formData.promiseSelfInspect,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "promiseSelfInspect", $$v);
      },
      expression: "formData.promiseSelfInspect"
    }
  }), _c("el-checkbox", {
    attrs: {
      label: "农产品地理标志证书"
    },
    model: {
      value: _vm.formData.certAgriculturalProductsFlag,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "certAgriculturalProductsFlag", $$v);
      },
      expression: "formData.certAgriculturalProductsFlag"
    }
  }), _c("el-checkbox", {
    attrs: {
      label: "委托检验合格证书"
    },
    model: {
      value: _vm.formData.certEntrustPassedInspection,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "certEntrustPassedInspection", $$v);
      },
      expression: "formData.certEntrustPassedInspection"
    }
  }), _c("el-checkbox", {
    attrs: {
      label: "绿色食品证书"
    },
    model: {
      value: _vm.formData.certGreenFood,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "certGreenFood", $$v);
      },
      expression: "formData.certGreenFood"
    }
  }), _c("el-checkbox", {
    attrs: {
      label: "有机食品证书"
    },
    model: {
      value: _vm.formData.certOrganicFood,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "certOrganicFood", $$v);
      },
      expression: "formData.certOrganicFood"
    }
  })], 1)])])]) : _vm._e(), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.exportClick
    }
  }, [_vm._v("导出")])], 1)])], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };