import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "d2-layout-header-aside-group screen-container",
    class: {
      grayMode: _vm.grayActive,
      opacity: _vm.opacity
    },
    style: _vm.styleLayoutMainGroup,
    attrs: {
      id: "screen-container"
    }
  }, [_c("div", {
    staticClass: "d2-layout-header-aside-mask"
  }), _c("div", {
    staticClass: "d2-layout-header-aside-content",
    attrs: {
      flex: "dir:top"
    }
  }, [_c("div", {
    staticClass: "d2-theme-header",
    style: {
      opacity: 1
    },
    attrs: {
      "flex-box": "0",
      flex: ""
    }
  }, [_c("router-link", {
    class: {
      "logo-group": true,
      "logo-transition": _vm.asideTransition
    },
    style: {
      width: "350px"
    },
    attrs: {
      to: "/index",
      "flex-box": "0"
    }
  }, [_c("img", {
    staticClass: "icon-img-logo",
    attrs: {
      src: _vm.sysIndexLeftFoldLogo || "".concat(_vm.$baseUrl, "image/theme/").concat(_vm.themeActiveSetting.name, "/logo/icon-only.png")
    }
  }), _c("img", {
    staticClass: "text-img-logo",
    attrs: {
      src: _vm.sysIndexLeftLogo || "".concat(_vm.$baseUrl, "image/theme/").concat(_vm.themeActiveSetting.name, "/logo/all.png")
    }
  })]), _c("div", {
    staticClass: "toggle-aside-btn",
    attrs: {
      "flex-box": "0"
    },
    on: {
      click: _vm.handleToggleAside
    }
  }, [_c("d2-icon", {
    attrs: {
      name: "bars"
    }
  })], 1), _c("d2-menu-header", {
    attrs: {
      "flex-box": "1"
    },
    on: {
      handleOpen: _vm.handleOpen
    }
  }), _c("div", {
    staticClass: "d2-header-right",
    attrs: {
      "flex-box": "0"
    }
  }, [_vm.halo6AdminUrl && _vm.currentUser === "Wechat:guan409932398" ? _c("div", {
    staticClass: "url",
    on: {
      click: _vm.outlinkClick
    }
  }, [_c("i", {
    staticClass: "iconproject icon-project-fuwuqijigui",
    staticStyle: {
      "font-size": "22px"
    }
  })]) : _vm._e(), _vm.ShowRemind ? _c("div", {
    staticClass: "notice",
    on: {
      click: function click($event) {
        _vm.show = !_vm.show;
      }
    }
  }, [_c("d2-icon", {
    attrs: {
      name: "bell-o"
    }
  }), _vm.noticeCount ? _c("div", {
    staticClass: "num"
  }, [_vm._v(_vm._s(_vm.noticeCount))]) : _vm._e(), _c("audio", {
    ref: "initAudio",
    attrs: {
      controls: "",
      height: "10",
      width: "10"
    }
  }, [_c("source", {
    attrs: {
      src: require("../../assets/audio/notice.mp3"),
      type: "audio/mpeg"
    }
  })])], 1) : _vm._e(), _c("d2-header-fullscreen"), _c("d2-header-theme"), _c("d2-header-size"), _c("d2-header-color"), _c("d2-header-user")], 1)], 1), _vm.category == "Admin" ? _c("div", {
    staticClass: "d2-theme-container",
    attrs: {
      "flex-box": "1",
      flex: ""
    }
  }, [_c("div", {
    ref: "aside",
    class: {
      "d2-theme-container-aside": true,
      "d2-theme-container-transition": _vm.asideTransition
    },
    style: {
      width: _vm.asideCollapse ? _vm.asideWidthCollapse : _vm.asideWidth,
      opacity: 1
    },
    attrs: {
      "flex-box": "0"
    }
  }, [_c("d2-menu-side")], 1), _c("div", {
    staticClass: "d2-theme-container-main",
    attrs: {
      "flex-box": "1",
      flex: ""
    }
  }, [_c("transition", {
    attrs: {
      name: "fade-scale"
    }
  }, [_c("div", {
    staticClass: "d2-theme-container-main-layer",
    attrs: {
      flex: "dir:top"
    }
  }, [_c("div", {
    staticClass: "d2-theme-container-main-body",
    attrs: {
      "flex-box": "1"
    }
  }, [_c("div", {
    staticClass: "left"
  }, [_c("div", {
    staticClass: "chart-bar",
    attrs: {
      id: "chart-bar"
    }
  }), _c("div", {
    staticClass: "chart-pie",
    attrs: {
      id: "chart-pie"
    }
  }), _c("div", {
    staticClass: "ranking"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("省域产值统计（单位：万元）")]), _c("div", {
    staticStyle: {
      height: "calc(100% - 30px)",
      "overflow-y": "auto"
    }
  }, _vm._l(_vm.gdp_list, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "ranking-item"
    }, [_c("div", {
      staticClass: "ranking-item-left"
    }, [_c("div", {
      staticClass: "ranking-num"
    }, [_vm._v("NO." + _vm._s(index + 1))]), _c("div", {
      staticStyle: {
        width: "calc(100% - 72px)"
      }
    }, [_c("div", {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.name))]), _c("div", {
      staticClass: "progress",
      style: "width:" + item.gdp / _vm.gdp_max * 100 + "%"
    })])]), _c("div", {
      staticClass: "ranking-item-right"
    }, [_vm._v(_vm._s(_vm.toThousands(item.gdp)))])]);
  }), 0)])]), _c("div", {
    staticClass: "center"
  }, [_c("div", {
    attrs: {
      id: "map"
    }
  }), _c("div", {
    staticClass: "main-top"
  }, [_c("div", {
    staticClass: "screen-item"
  }, [_c("div", {
    staticClass: "num"
  }, [_vm._v(_vm._s(_vm.areaNum))]), _c("div", {
    staticClass: "text"
  }, [_vm._v("养殖总面积")])]), _c("div", {
    staticClass: "screen-item"
  }, [_c("div", {
    staticClass: "num"
  }, [_vm._v(_vm._s(_vm.deviceNum))]), _c("div", {
    staticClass: "text"
  }, [_vm._v("物联网装备数")])])]), _c("div", {
    staticClass: "main-bottom"
  }, [_c("div", {
    staticClass: "screen-item"
  }, [_c("div", {
    staticClass: "num"
  }, [_vm._v("47521")]), _c("div", {
    staticClass: "text"
  }, [_vm._v("采集器")])]), _c("div", {
    staticClass: "screen-item"
  }, [_c("div", {
    staticClass: "num"
  }, [_vm._v("169368")]), _c("div", {
    staticClass: "text"
  }, [_vm._v("传感器")])]), _c("div", {
    staticClass: "screen-item"
  }, [_c("div", {
    staticClass: "num"
  }, [_vm._v("253")]), _c("div", {
    staticClass: "text"
  }, [_vm._v("水下机器人")])]), _c("div", {
    staticClass: "screen-item"
  }, [_c("div", {
    staticClass: "num"
  }, [_vm._v("10398")]), _c("div", {
    staticClass: "text"
  }, [_vm._v("智能投饵机")])]), _c("div", {
    staticClass: "screen-item"
  }, [_c("div", {
    staticClass: "num"
  }, [_vm._v("3956")]), _c("div", {
    staticClass: "text"
  }, [_vm._v("AI摄像头")])]), _c("div", {
    staticClass: "screen-item"
  }, [_c("div", {
    staticClass: "num"
  }, [_vm._v("178")]), _c("div", {
    staticClass: "text"
  }, [_vm._v("无人机")])]), _c("div", {
    staticClass: "screen-item"
  }, [_c("div", {
    staticClass: "num"
  }, [_vm._v("102")]), _c("div", {
    staticClass: "text"
  }, [_vm._v("无人车")])]), _c("div", {
    staticClass: "screen-item"
  }, [_c("div", {
    staticClass: "num"
  }, [_vm._v("99")]), _c("div", {
    staticClass: "text"
  }, [_vm._v("无人船")])])])]), _c("div", {
    staticClass: "right"
  }, [_vm.show ? _c("div", {
    staticClass: "map-back",
    on: {
      click: _vm.mapInit
    }
  }, [_vm._v("返回上一级")]) : _vm._e(), _c("div", {
    staticClass: "table"
  }, [_c("div", {
    staticClass: "title",
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("div", [_vm._v("数字渔业示范项目")])]), _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.lab_tableData,
      "max-height": _vm.table_height,
      "row-key": "id",
      "tooltip-effect": "light",
      "element-loading-text": "正在努力加载中..."
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "name",
      label: "企业名称",
      "min-width": "100",
      "show-overflow-tooltip": "",
      align: "center",
      "header-align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", {
          staticClass: "one-overflow",
          staticStyle: {
            color: "#20FDFA"
          }
        }, [_vm._v(_vm._s(scope.row.name))])];
      }
    }], null, false, 4034481977)
  }), _c("el-table-column", {
    attrs: {
      prop: "cultureMode",
      label: "养殖模式",
      width: "100",
      "show-overflow-tooltip": "",
      align: "center",
      "header-align": "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "cultureArea",
      label: "占地面积",
      width: "100",
      "show-overflow-tooltip": "",
      align: "center",
      "header-align": "center"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "cultureWaterBody",
      label: "养殖水体",
      width: "100",
      "show-overflow-tooltip": "",
      align: "center",
      "header-align": "center"
    }
  })], 1)], 1), _c("div", {
    staticClass: "chart-line",
    attrs: {
      id: "chart-line"
    }
  }), _c("div", {
    staticClass: "table"
  }, [_c("div", {
    staticClass: "navs"
  }, [_c("div", {
    staticClass: "nav",
    class: {
      "nav-active": _vm.bar_active == 1
    },
    staticStyle: {
      "margin-right": "30px"
    },
    on: {
      click: function click($event) {
        _vm.bar_active = 1;
      }
    }
  }, [_vm._v("海水养殖模式产量统计")]), _c("div", {
    staticClass: "nav",
    class: {
      "nav-active": _vm.bar_active == 2
    },
    on: {
      click: function click($event) {
        _vm.bar_active = 2;
      }
    }
  }, [_vm._v("淡水养殖模式产量统计")])]), _c("pie3D", {
    staticStyle: {
      height: "calc(100% - 44px)"
    },
    attrs: {
      bar_active: _vm.bar_active
    }
  })], 1)])])])])], 1)]) : _c("div", {
    staticClass: "d2-theme-container",
    attrs: {
      "flex-box": "1",
      flex: ""
    }
  }, [_c("div", {
    ref: "aside",
    class: {
      "d2-theme-container-aside": true,
      "d2-theme-container-transition": _vm.asideTransition
    },
    style: {
      width: _vm.asideCollapse ? _vm.asideWidthCollapse : _vm.asideWidth,
      opacity: 1
    },
    attrs: {
      "flex-box": "0"
    }
  }, [_c("d2-menu-side")], 1), _c("div", {
    staticClass: "d2-theme-container-main",
    attrs: {
      "flex-box": "1",
      flex: ""
    }
  }, [_c("transition", {
    attrs: {
      name: "fade-scale"
    }
  }, [_c("div", {
    staticClass: "d2-theme-container-main-layer",
    attrs: {
      flex: "dir:top"
    }
  }, [_c("div", {
    staticClass: "d2-theme-container-main-header",
    attrs: {
      "flex-box": "0"
    }
  }, [_c("d2-tabs")], 1), _c("div", {
    staticClass: "d2-theme-container-main-body",
    attrs: {
      "flex-box": "1"
    }
  }, [_c("transition", {
    attrs: {
      name: _vm.transitionActive ? "fade-transverse" : ""
    }
  }, [_c("Index")], 1)], 1)])])], 1)])]), _vm.show ? _c("notice", {
    on: {
      close: function close($event) {
        _vm.show = false;
      }
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };