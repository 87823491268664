import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.function.name.js";
export default {
  name: 'cms_article_simple_sel',
  props: {
    category: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      pageConfig: {
        queryFunName: 'cmsArticleSimpleQuery',
        dicTreeFunName: 'mngDictionaryLowerTree'
      },
      dicData: {
        categoryUpperCode: 'cms_article_category',
        configCategorys: [],
        configCategoryOptions: {},
        typeUpperCode: 'cms_article_type',
        configTypes: [],
        configTypeOptions: {}
      },
      searchForm: {
        page: 1,
        limit: 10,
        searchKey: null,
        type: this.type,
        category: this.category,
        sortField: 'create_time'
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0
    };
  },
  mounted: function mounted() {
    var _this = this;
    Promise.all([new Promise(function (resolve, reject) {
      _this.$api[_this.pageConfig.dicTreeFunName](_this.dicData.categoryUpperCode).then(function (res) {
        if (res.data) {
          _this.dicData.configCategorys = res.data;
          for (var i = 0; i < res.data.length; i++) {
            _this.dicData.configCategoryOptions[res.data[i].code] = res.data[i].name;
          }
        }
        resolve(res);
      });
    }), new Promise(function (resolve, reject) {
      _this.$api[_this.pageConfig.dicTreeFunName](_this.dicData.typeUpperCode).then(function (res) {
        if (res.data) {
          _this.dicData.configTypes = res.data;
          for (var i = 0; i < res.data.length; i++) {
            _this.dicData.configTypeOptions[res.data[i].code] = res.data[i].name;
          }
        }
        resolve(res);
      });
    })]).then(function (result) {
      _this.query();
    });
  },
  methods: {
    sel: function sel(article) {
      this.$emit('child-article-sel', article);
    },
    query: function query() {
      var _this2 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this2.tableData = res.data || [];
        _this2.totalCount = res.count;
        _this2.queryLoading = false;
      });
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};