export default (function (_ref) {
  var request = _ref.request;
  return {
    productAskcmdAdd: function productAskcmdAdd(data) {
      return request({
        url: '/productAskcmd',
        method: 'post',
        data: data
      });
    },
    productAskcmdModify: function productAskcmdModify(data) {
      return request({
        url: "/productAskcmd/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    productAskcmdDel: function productAskcmdDel(id) {
      return request({
        url: "/productAskcmd/".concat(id),
        method: 'delete'
      });
    },
    productAskcmdQuery: function productAskcmdQuery(data) {
      return request({
        url: '/productAskcmd',
        method: 'get',
        params: data
      });
    },
    productAskcmdDetail: function productAskcmdDetail(id) {
      return request({
        url: "/productAskcmd/".concat(id),
        method: 'get'
      });
    }
  };
});