export default (function (_ref) {
  var request = _ref.request;
  return {
    fisheryTaskDiseaseAdd: function fisheryTaskDiseaseAdd(data) {
      return request({
        url: '/fisheryTaskDisease',
        method: 'post',
        data: data
      });
    },
    fisheryTaskDiseaseModify: function fisheryTaskDiseaseModify(data) {
      return request({
        url: "/fisheryTaskDisease/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    fisheryTaskDiseaseDel: function fisheryTaskDiseaseDel(id) {
      return request({
        url: "/fisheryTaskDisease/".concat(id),
        method: 'delete'
      });
    },
    fisheryTaskDiseaseQueryJoinPlace: function fisheryTaskDiseaseQueryJoinPlace(data) {
      return request({
        url: '/fisheryTaskDisease/queryJoinPlace',
        method: 'get',
        params: data
      });
    },
    fisheryTaskDiseaseDetail: function fisheryTaskDiseaseDetail(id) {
      return request({
        url: "/fisheryTaskDisease/".concat(id),
        method: 'get'
      });
    }
  };
});