export default (function (_ref) {
  var request = _ref.request;
  return {
    productAdd: function productAdd(data) {
      return request({
        url: '/product',
        method: 'post',
        data: data
      });
    },
    productModify: function productModify(data) {
      return request({
        url: "/product/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    productDel: function productDel(id) {
      return request({
        url: "/product/".concat(id),
        method: 'delete'
      });
    },
    productQuery: function productQuery(data) {
      return request({
        url: '/product',
        method: 'get',
        params: data
      });
    },
    productDetail: function productDetail(id) {
      return request({
        url: "/product/".concat(id),
        method: 'get'
      });
    }
  };
});