export default (function (_ref) {
  var request = _ref.request;
  return {
    roleMenus: function roleMenus(roleId) {
      return request({
        url: "/rolesMenus/".concat(roleId, "/menus"),
        method: 'get'
      });
    }
  };
});