import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.slice.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "page price_quotation_page"
  }, [_c("div", {
    staticClass: "container"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      width: "100%",
      "justify-content": "space-between"
    }
  }, [_c("div", {
    staticClass: "index"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("农产品批发价格200指数")]), _c("div", {
    staticClass: "index-content"
  }, [_c("div", {
    staticClass: "step-2"
  }, [_c("div", {
    staticClass: "new-tag"
  }, [_c("div", {
    staticClass: "dot"
  }), _c("div", [_vm._v("农产品批发价格")])]), _c("div", {
    staticStyle: {}
  }, [_c("div", [_vm._v("200指数")]), _vm.todayData.agriculture ? _c("div", {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.todayData.agriculture - _vm.prevData.agriculture < 0 ? (_vm.todayData.agriculture - _vm.prevData.agriculture).toFixed(2) : "+" + (_vm.todayData.agriculture - _vm.prevData.agriculture).toFixed(2)))]) : _vm._e()]), _c("div", {
    staticClass: "tag"
  }, [_vm._v(_vm._s(_vm.todayData.agriculture || ""))])]), _c("div", {
    staticClass: "step-1"
  }), _c("div", {
    staticClass: "step-3"
  }, [_c("div", {
    staticClass: "bifurcate"
  }), _c("div", [_c("div", {
    staticClass: "tag-container"
  }, [_c("div", {
    staticClass: "new-tag"
  }, [_c("div", {
    staticClass: "dot"
  }), _c("div", [_vm._v("“菜篮子”产品批发")])]), _c("div", [_c("div", [_vm._v("价格指数")]), _vm.todayData.vegetableBasket ? _c("div", {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.todayData.vegetableBasket - _vm.prevData.vegetableBasket < 0 ? (_vm.todayData.vegetableBasket - _vm.prevData.vegetableBasket).toFixed(2) : "+" + (_vm.todayData.vegetableBasket - _vm.prevData.vegetableBasket).toFixed(2)))]) : _vm._e()]), _c("div", {
    staticClass: "tag"
  }, [_vm._v(_vm._s(_vm.todayData.vegetableBasket || ""))])]), _c("div", {
    staticClass: "tag-container",
    staticStyle: {
      "margin-top": "76px"
    }
  }, [_c("div", {
    staticClass: "new-tag"
  }, [_c("div", {
    staticClass: "dot"
  }), _c("div", [_vm._v("粮油产品批发")])]), _c("div", [_c("div", [_vm._v("价格指数")]), _vm.todayData.grainAndOil ? _c("div", {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.todayData.grainAndOil - _vm.prevData.grainAndOil < 0 ? (_vm.todayData.grainAndOil - _vm.prevData.grainAndOil).toFixed(2) : "+" + (_vm.todayData.grainAndOil - _vm.prevData.grainAndOil).toFixed(2)))]) : _vm._e()]), _c("div", {
    staticClass: "tag"
  }, [_vm._v(_vm._s(_vm.todayData.grainAndOil || ""))])])]), _c("div", [_c("div", {
    staticClass: "type-container top-type-container"
  }, [_c("div", {
    staticClass: "type"
  }, [_c("div", {
    staticClass: "line-dot"
  }, [_c("div", {
    staticClass: "dot"
  })]), _c("div", {
    staticClass: "new-tag"
  }, [_c("div", {
    staticClass: "dot"
  }), _c("div", [_vm._v("石斑鱼")])]), _c("div", {
    staticClass: "value"
  }, [_vm._v("今日价格"), _c("span", [_vm._v(_vm._s(_vm.nowPrice.shibanyu || "-"))]), _vm._v("元")])]), _c("div", {
    staticClass: "type",
    staticStyle: {
      "margin-left": "30px"
    }
  }, [_c("div", {
    staticClass: "line-dot"
  }, [_c("div", {
    staticClass: "dot"
  })]), _c("div", {
    staticClass: "new-tag"
  }, [_c("div", {
    staticClass: "dot"
  }), _c("div", [_vm._v("梭子蟹")])]), _c("div", {
    staticClass: "value"
  }, [_vm._v("今日价格"), _c("span", [_vm._v(_vm._s(_vm.nowPrice.suozixie || "-"))]), _vm._v("元")])])]), _c("div", {
    staticClass: "line"
  }, [_c("div", {
    staticClass: "dot"
  })]), _c("div", {
    staticClass: "type-container bottom-type-container"
  }, [_c("div", {
    staticClass: "type"
  }, [_c("div", {
    staticClass: "line-dot"
  }, [_c("div", {
    staticClass: "dot"
  })]), _c("div", {
    staticClass: "new-tag"
  }, [_c("div", {
    staticClass: "dot"
  }), _c("div", [_vm._v("对虾")])]), _c("div", {
    staticClass: "value"
  }, [_vm._v("今日价格"), _c("span", [_vm._v(_vm._s(_vm.nowPrice.duixia || "-"))]), _vm._v("元")])]), _c("div", {
    staticClass: "type",
    staticStyle: {
      "margin-left": "30px"
    }
  }, [_c("div", {
    staticClass: "line-dot"
  }, [_c("div", {
    staticClass: "dot"
  })]), _c("div", {
    staticClass: "new-tag"
  }, [_c("div", {
    staticClass: "dot"
  }), _c("div", [_vm._v("海参")])]), _c("div", {
    staticClass: "value"
  }, [_vm._v("今日价格"), _c("span", [_vm._v(_vm._s(_vm.nowPrice.haishen || "-"))]), _vm._v("元")])])])])])])]), _c("div", {
    staticClass: "chart"
  }, [_c("div", {
    staticClass: "title",
    staticStyle: {
      "margin-bottom": "16px"
    }
  }, [_c("div", [_vm._v("曲线图 "), _c("span", [_vm._v("单位：元/公斤")])]), _c("div", {
    staticClass: "lenged"
  }, [_c("div", [_c("div", {
    staticClass: "dot",
    staticStyle: {
      background: "rgba(39, 136, 225, 1)"
    }
  }), _c("div", [_vm._v("海参")])]), _c("div", [_c("div", {
    staticClass: "dot",
    staticStyle: {
      background: "rgba(221, 69, 136, 1)"
    }
  }), _c("div", [_vm._v("石斑鱼")])]), _c("div", [_c("div", {
    staticClass: "dot",
    staticStyle: {
      background: "rgba(102, 220, 149, 1)"
    }
  }), _c("div", [_vm._v("对虾")])]), _c("div", [_c("div", {
    staticClass: "dot",
    staticStyle: {
      background: "rgba(220, 177, 102, 1)"
    }
  }), _c("div", [_vm._v("梭子蟹")])])])]), _c("div", {
    staticClass: "search"
  }, [_c("div", [_vm._v("周期：")]), _c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请选择",
      clearable: ""
    },
    model: {
      value: _vm.cycle,
      callback: function callback($$v) {
        _vm.cycle = $$v;
      },
      expression: "cycle"
    }
  }, _vm._l(_vm.options, function (item) {
    return _c("el-option", {
      key: item,
      attrs: {
        label: item,
        value: item
      }
    });
  }), 1), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.chartsInit
    }
  }, [_c("i", {
    staticClass: "fa fa-search"
  }), _vm._v(" 查询")])], 1), _c("div", {
    staticClass: "chart-content",
    attrs: {
      id: "chart-line"
    }
  })])]), _c("div", {
    staticStyle: {
      display: "flex",
      width: "100%",
      "justify-content": "space-between",
      height: "calc(100% - 390px)",
      "min-height": "550px"
    }
  }, [_c("div", {
    staticClass: "show"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("园区优质水产品")]), _c("div", {
    staticClass: "show-list"
  }, _vm._l(_vm.tableData, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "item-container"
    }, [_c("div", {
      staticClass: "item"
    }, [_c("img", {
      attrs: {
        src: item.listImage,
        alt: ""
      }
    }), _c("div", [_vm._v(_vm._s(item.name))])])]);
  }), 0)]), _c("div", {
    staticClass: "dynamics"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("行业动态")]), _c("div", {
    staticClass: "dynamics-list"
  }, [_vm._l(_vm.dynamicsData, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "item",
      class: {
        active: index == 0
      },
      on: {
        click: function click($event) {
          return _vm.dynamicsDetail(item);
        }
      }
    }, [_c("div", {
      staticClass: "text one-overflow"
    }, [_vm._v(_vm._s(item.title))]), _c("div", {
      staticClass: "date"
    }, [_vm._v(_vm._s((item.createTime + "").slice(4, 6)) + "-" + _vm._s((item.createTime + "").slice(6, 8)))])]);
  }), _c("el-pagination", {
    attrs: {
      "hide-on-single-page": true,
      background: "",
      align: "center",
      layout: "prev, pager, next, total",
      "page-sizes": [10, 20, 50, 100],
      "current-page": _vm.searchForm.page,
      "page-size": _vm.searchForm.limit,
      total: _vm.totalCount
    },
    on: {
      "current-change": _vm.pageChange,
      "size-change": _vm.sizeChange
    }
  })], 2)])])]), _c("el-dialog", {
    attrs: {
      visible: _vm.dynamics_show,
      title: _vm.dynamics.title
    },
    on: {
      close: function close($event) {
        _vm.dynamics_show = false;
      }
    }
  }, [_c("div", {
    staticClass: "dialog-content"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "margin-bottom": "20px"
    }
  }, [_vm.dynamics.author ? _c("div", {
    staticStyle: {
      "margin-right": "30px"
    }
  }, [_vm._v("作者：" + _vm._s(_vm.dynamics.author))]) : _vm._e(), _vm.dynamics.viewsCount ? _c("div", [_vm._v("浏览量：" + _vm._s(_vm.dynamics.viewsCount))]) : _vm._e()]), _vm.dynamics.coverImage ? _c("img", {
    staticStyle: {
      "max-width": "100%",
      height: "auto"
    },
    attrs: {
      src: _vm.dynamics.coverImage,
      alt: ""
    }
  }) : _vm._e(), _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.dynamics.content)
    }
  })])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };