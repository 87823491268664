export default (function (_ref) {
  var request = _ref.request;
  return {
    fisheryTaskTourAdd: function fisheryTaskTourAdd(data) {
      return request({
        url: '/fisheryTaskTour',
        method: 'post',
        data: data
      });
    },
    fisheryTaskTourModify: function fisheryTaskTourModify(data) {
      return request({
        url: "/fisheryTaskTour/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    fisheryTaskTourDel: function fisheryTaskTourDel(id) {
      return request({
        url: "/fisheryTaskTour/".concat(id),
        method: 'delete'
      });
    },
    fisheryTaskTourQuery: function fisheryTaskTourQuery(data) {
      return request({
        url: '/fisheryTaskTour/queryJoinBreedAndPlace',
        method: 'get',
        params: data
      });
    },
    fisheryTaskTourDetail: function fisheryTaskTourDetail(id) {
      return request({
        url: "/fisheryTaskTour/".concat(id),
        method: 'get'
      });
    }
  };
});