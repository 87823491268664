import _createForOfIteratorHelper from "D:/work/code/Halov-China-Fishery-V2/halov-web/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _objectSpread from "D:/work/code/Halov-China-Fishery-V2/halov-web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { mapState } from 'vuex';
import D2PageCover from './components/d2-page-cover';
import util from '@/libs/util';
export default {
  components: {
    D2PageCover: D2PageCover
  },
  computed: _objectSpread({}, mapState('d2admin/user', ['info'])),
  data: function data() {
    return {
      sys_index_logo: ''
    };
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    var menu = localStorage.getItem('menu') ? JSON.parse(localStorage.getItem('menu')) : [];
    console.log('index menu', menu);
    var userPerms = localStorage.getItem('menu') ? JSON.parse(localStorage.getItem('menu')) : [];
    // console.log('index menu',menu)
    // for(let item of menu){
    //   if(item.href=='/china_screen'){
    //     next('/china_screen')
    //     return
    //   }
    //   for(let opt of item.lowerMenus){
    //     if(opt.href=='/china_screen'){
    //       next('/china_screen')
    //       return
    //     }
    //   }
    // }
    // next()

    if (userPerms.indexOf('/china_screen') > -1) {
      next('/china_screen');
      return;
    } else {
      var _iterator = _createForOfIteratorHelper(menu),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          if (item.href) {
            next(item.href);
            return;
          }
          var _iterator2 = _createForOfIteratorHelper(item.lowerMenus),
            _step2;
          try {
            for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
              var opt = _step2.value;
              if (opt.href) {
                next(opt.href);
                return;
              }
            }
          } catch (err) {
            _iterator2.e(err);
          } finally {
            _iterator2.f();
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    }
    next();
  },
  mounted: function mounted() {
    // 设置侧边栏菜单
    // let menu = JSON.parse(localStorage.getItem('menu'))
    // // console.log('menu',menu)
    // menu = util.changeMenu(menu)
    // this.$store.commit('d2admin/menu/asideSet', [])
    // this.$store.commit('d2admin/menu/headerSet', menu)
    this.getLogo();
  },
  methods: {
    // TODO: 首页Logo
    getLogo: function getLogo() {
      var _this = this;
      this.$api['aboutsCache']('sys_index_logo').then(function (res) {
        if (res.data) {
          _this.sys_index_logo = res.data.sys_index_logo || '';
        }
      });
    }
  }
};