export default (function (_ref) {
  var request = _ref.request;
  return {
    fisheryTaskSplitAdd: function fisheryTaskSplitAdd(data) {
      return request({
        url: '/fisheryTaskSplit',
        method: 'post',
        data: data
      });
    },
    fisheryTaskSplitModify: function fisheryTaskSplitModify(data) {
      return request({
        url: "/fisheryTaskSplit/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    fisheryTaskSplitDel: function fisheryTaskSplitDel(id) {
      return request({
        url: "/fisheryTaskSplit/".concat(id),
        method: 'delete'
      });
    },
    fisheryTaskSplitQuery: function fisheryTaskSplitQuery(data) {
      return request({
        url: '/fisheryTaskSplit/queryJoinBreedAndPlace',
        method: 'get',
        params: data
      });
    },
    fisheryTaskSplitDetail: function fisheryTaskSplitDetail(id) {
      return request({
        url: "/fisheryTaskSplit/".concat(id),
        method: 'get'
      });
    }
  };
});