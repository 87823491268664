import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "page"
  }, [_c("template", {
    slot: "header"
  }, [_c("el-form", {
    attrs: {
      inline: true,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "分类"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请选择",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.breedType,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "breedType", $$v);
      },
      expression: "searchForm.breedType"
    }
  }, _vm._l(_vm.dicData.configTypes, function (item) {
    return _c("el-option", {
      key: item.code,
      attrs: {
        label: item.name,
        value: item.code
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "关键字"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "关键字"
    },
    model: {
      value: _vm.searchForm.searchKey,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "searchKey", $$v);
      },
      expression: "searchForm.searchKey"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.search
    }
  }, [_c("i", {
    staticClass: "fa fa-search"
  }), _vm._v(" 查询")]), _vm.buttonPerms(_vm.$route.path + ":add") ? _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.add
    }
  }, [_c("i", {
    staticClass: "fa fa-plus"
  }), _vm._v(" 添加")]) : _vm._e()], 1)], 1)], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.queryLoading,
      expression: "queryLoading"
    }],
    staticStyle: {
      width: "100%",
      "margin-bottom": "20px"
    },
    attrs: {
      data: _vm.tableData,
      stripe: "",
      border: "",
      "row-key": "id",
      "element-loading-text": "正在努力加载中..."
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "序号",
      type: "index",
      width: "50"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "175"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm.buttonPerms(_vm.$route.path + ":edit") ? _c("el-button", {
          attrs: {
            size: "mini",
            type: "warning"
          },
          on: {
            click: function click($event) {
              return _vm.edit(scope.row.id);
            }
          }
        }, [_vm._v("编辑")]) : _vm._e(), _vm.buttonPerms(_vm.$route.path + ":del") || _vm.buttonPerms(_vm.$route.path + ":productManage") || _vm.buttonPerms(_vm.$route.path + ":breedDiseases") || _vm.buttonPerms(_vm.$route.path + ":feedMethod") || _vm.buttonPerms(_vm.$route.path + ":breedMode") || _vm.buttonPerms(_vm.$route.path + ":breedSegment") || _vm.buttonPerms(_vm.$route.path + ":waterManage") || _vm.buttonPerms(_vm.$route.path + ":breedFormula") ? _c("el-dropdown", {
          staticStyle: {
            "margin-left": "10px"
          },
          attrs: {
            size: "mini",
            "split-button": "",
            type: "primary"
          },
          on: {
            command: _vm.moreButton
          }
        }, [_vm._v(" 更多 "), _c("el-dropdown-menu", {
          attrs: {
            slot: "dropdown"
          },
          slot: "dropdown"
        }, [_vm.buttonPerms(_vm.$route.path + ":breedMode") ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-scissors",
            command: _vm.beforeCommand(scope.$index, scope.row, "breedMode")
          }
        }, [_vm._v("养殖模式")]) : _vm._e(), _vm.buttonPerms(_vm.$route.path + ":breedSegment") ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-tickets",
            command: _vm.beforeCommand(scope.$index, scope.row, "breedSegment")
          }
        }, [_vm._v("养殖环节")]) : _vm._e(), _vm.buttonPerms(_vm.$route.path + ":waterManage") ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-tickets",
            command: _vm.beforeCommand(scope.$index, scope.row, "waterManage")
          }
        }, [_vm._v("水质管理")]) : _vm._e(), _vm.buttonPerms(_vm.$route.path + ":breedFormula") ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-tickets",
            command: _vm.beforeCommand(scope.$index, scope.row, "breedFormula")
          }
        }, [_vm._v("配方饲料")]) : _vm._e(), _vm.buttonPerms(_vm.$route.path + ":feedMethod") ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-tickets",
            command: _vm.beforeCommand(scope.$index, scope.row, "feedMethod")
          }
        }, [_vm._v("投喂方法")]) : _vm._e(), _vm.buttonPerms(_vm.$route.path + ":breedDiseases") ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-tickets",
            command: _vm.beforeCommand(scope.$index, scope.row, "breedDiseases")
          }
        }, [_vm._v("疾病防治")]) : _vm._e(), _vm.buttonPerms(_vm.$route.path + ":productManage") ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-tickets",
            command: _vm.beforeCommand(scope.$index, scope.row, "productManage")
          }
        }, [_vm._v("生产管理")]) : _vm._e(), _vm.buttonPerms(_vm.$route.path + ":del") ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-delete",
            command: _vm.beforeCommand(scope.$index, scope.row, "del")
          }
        }, [_vm._v("删除")]) : _vm._e()], 1)], 1) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "breedType",
      label: "分类",
      width: "150",
      "show-overflow-tooltip": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(_vm.dicData.configTypeOptions[scope.row.breedType]))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "name",
      label: "名称",
      width: "250",
      "show-overflow-tooltip": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.row.name) + " "), scope.row.name !== scope.row.alias && scope.row.alias ? _c("span", [_vm._v("( " + _vm._s(scope.row.alias) + " )")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "code",
      label: "编码",
      width: "100",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "waterType",
      label: "水质",
      sortable: "custom"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.waterType == "SeaWater" ? _c("div", [_vm._v("海水")]) : _vm._e(), scope.row.waterType == "FreshWater" ? _c("div", [_vm._v("淡水")]) : _vm._e()];
      }
    }])
  })], 1), _c("el-pagination", {
    attrs: {
      background: "",
      align: "center",
      layout: "prev, pager, next, sizes, total",
      "page-sizes": [10, 20, 50, 100],
      "current-page": _vm.searchForm.page,
      "page-size": _vm.searchForm.limit,
      total: _vm.totalCount
    },
    on: {
      "current-change": _vm.pageChange,
      "size-change": _vm.sizeChange
    }
  }), _c("el-dialog", {
    attrs: {
      visible: _vm.editDialogShow,
      title: _vm.editDialogTitle,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.editDialogShow = $event;
      },
      close: function close($event) {
        return _vm.initFormData(_vm.emptyFormData);
      }
    }
  }, [_c("el-form", {
    ref: "formDataVerify",
    attrs: {
      model: _vm.formData,
      rules: _vm.rules
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "名称",
      "label-width": _vm.formLabelWidth,
      prop: "name"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "请输入名称"
    },
    model: {
      value: _vm.formData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "name", $$v);
      },
      expression: "formData.name"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "别名",
      "label-width": _vm.formLabelWidth,
      prop: "alias"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "请输入别名"
    },
    model: {
      value: _vm.formData.alias,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "alias", $$v);
      },
      expression: "formData.alias"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "分类",
      "label-width": _vm.formLabelWidth,
      prop: "breedType"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "请选择",
      clearable: ""
    },
    model: {
      value: _vm.formData.breedType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "breedType", $$v);
      },
      expression: "formData.breedType"
    }
  }, _vm._l(_vm.dicData.configTypes, function (item) {
    return _c("el-option", {
      key: item.code,
      attrs: {
        label: item.name,
        value: item.code
      }
    });
  }), 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "水质",
      "label-width": _vm.formLabelWidth,
      prop: "waterType"
    }
  }, [_c("el-radio", {
    staticStyle: {
      "line-height": "40px"
    },
    attrs: {
      label: "SeaWater"
    },
    model: {
      value: _vm.formData.waterType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "waterType", $$v);
      },
      expression: "formData.waterType"
    }
  }, [_vm._v("海水")]), _c("el-radio", {
    staticStyle: {
      "line-height": "40px"
    },
    attrs: {
      label: "FreshWater"
    },
    model: {
      value: _vm.formData.waterType,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "waterType", $$v);
      },
      expression: "formData.waterType"
    }
  }, [_vm._v("淡水")])], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "编码",
      "label-width": _vm.formLabelWidth,
      prop: "code"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "请输入编码"
    },
    model: {
      value: _vm.formData.code,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "code", $$v);
      },
      expression: "formData.code"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "排序号",
      "label-width": _vm.formLabelWidth,
      prop: "sort"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "请输入排序号"
    },
    model: {
      value: _vm.formData.sort,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "sort", $$v);
      },
      expression: "formData.sort"
    }
  })], 1)], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "品种图片",
      "label-width": _vm.formLabelWidth,
      prop: "image"
    }
  }, [!_vm.formData.image ? _c("el-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      accept: "image/jpeg, image/jpg, image/png",
      "list-type": "picture-card",
      "show-file-list": false,
      "before-upload": _vm.beforeFileUpload,
      "http-request": _vm.Upload
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]) : _vm._e(), _vm.formData.image ? _c("div", {
    staticClass: "el-upload-list el-upload-list--picture-card"
  }, [_c("div", {
    staticClass: "el-upload-list__item is-success"
  }, [_vm.formData.image ? _c("img", {
    staticClass: "avatar",
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: _vm.formData.image
    }
  }) : _vm._e(), _c("label", {
    staticClass: "el-upload-list__item-status-label"
  }, [_c("i", {
    staticClass: "el-icon-upload-success el-icon-check"
  })]), _c("span", {
    staticClass: "el-upload-list__item-actions"
  }, [_c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-delete",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.removeFile("image");
      }
    }
  })]), _c("span", {
    staticClass: "el-upload-list__item-delete"
  }, [_c("i", {
    staticClass: "el-icon-zoom-in",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.previewFile("image");
      }
    }
  })])])])]) : _vm._e()], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "描述",
      "label-width": _vm.formLabelWidth,
      prop: "description"
    }
  }, [_c("d2-ueditor", {
    staticStyle: {
      width: "100%"
    },
    model: {
      value: _vm.formData.description,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "description", $$v);
      },
      expression: "formData.description"
    }
  })], 1)], 1)], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeEditDialogShow
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitFormData
    }
  }, [_vm._v("确 定")])], 1)], 1)], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };