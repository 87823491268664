import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  name: 'knowledge_page_mng',
  components: {
    knowledgeTable: function knowledgeTable() {
      return import('./knowledge_page');
    },
    knowledgeDetails: function knowledgeDetails() {
      return import('./knowledge_details');
    }
  },
  data: function data() {
    return {
      tableShow: 'knowledge',
      current: {}
    };
  },
  methods: {
    goKnowledge: function goKnowledge() {
      this.tableShow = 'knowledge';
    },
    goChild: function goChild(data, tableName) {
      this.current = data;
      this.tableShow = tableName;
    }
  }
};