import _createForOfIteratorHelper from "D:/work/code/Halov-China-Fishery-V2/halov-web/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
export default {
  name: 'breed_mode_page',
  props: {
    current: {
      type: Object,
      default: {}
    }
  },
  components: {
    diseaseSel: function diseaseSel() {
      return import('./disease_sel');
    },
    symptomSet: function symptomSet() {
      return import('./disease_symptom_set');
    }
  },
  data: function data() {
    return {
      pageConfig: {
        editPageName: '疾病防治',
        addFunName: 'breedJoinDiseasesAdd',
        modifyFunName: 'breedJoinDiseasesModify',
        delFunName: 'breedJoinDiseasesDel',
        queryFunName: 'breedJoinDiseasesQueryJoinDiseasesAndDiseasesCategory',
        detailFunName: 'breedJoinDiseasesDetail',
        dicTreeFunName: 'dictionaryCache',
        delSymptomFunName: 'breedJoinDiseasesSymptomDel'
      },
      searchForm: {
        page: 1,
        limit: 10,
        breedId: this.current.id,
        sortField: 'create_time'
      },
      formData: {
        breedId: this.current.id
      },
      rules: {
        segment: [{
          required: true,
          message: '请输入环节',
          trigger: 'blur'
        }],
        period: [{
          required: true,
          message: '请输入阶段',
          trigger: 'blur'
        }]
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      editDialogShow: false,
      editDialogTitle: '',
      formLabelWidth: '80px',
      emptyFormData: {},
      dicData: {
        breedUpperCode: 'Fishery_Breed_Type',
        configBreeds: [],
        configBreedOptions: {},
        symptomUpperCode: 'Fishery_Symptom_Type',
        configSymptoms: [],
        configSymptomOptions: {}
      },
      disease_show: false,
      symptom_show: false,
      breedJoinDiseasesId: '' // 品种疾病id
      ,
      weights: 0
    };
  },
  mounted: function mounted() {
    var _this = this;
    Promise.all([new Promise(function (resolve, reject) {
      _this.$api[_this.pageConfig.dicTreeFunName](_this.dicData.breedUpperCode).then(function (res) {
        if (res.data) {
          _this.dicData.configBreeds = res.data;
          var _iterator = _createForOfIteratorHelper(res.data),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var item = _step.value;
              _this.dicData.configBreedOptions[item.code] = item.name;
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
        }
        resolve(res);
      });
    }), new Promise(function (resolve, reject) {
      _this.$api[_this.pageConfig.dicTreeFunName](_this.dicData.symptomUpperCode).then(function (res) {
        if (res.data) {
          _this.dicData.configSymptoms = res.data;
          var _iterator2 = _createForOfIteratorHelper(res.data),
            _step2;
          try {
            for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
              var item = _step2.value;
              _this.dicData.configSymptomOptions[item.code] = item.name;
            }
          } catch (err) {
            _iterator2.e(err);
          } finally {
            _iterator2.f();
          }
        }
        resolve(res);
      });
    })]).then(function (result) {
      _this.query();
    });
  },
  methods: {
    back: function back() {
      this.$emit('child-go-breed');
    },
    initFormData: function initFormData(data) {
      var paramData = JSON.parse(JSON.stringify(data));
      this.formData = paramData;
      this.formData.breedId = this.current.id;
      if (typeof this.$refs['formDataVerify'] !== 'undefined') {
        this.$refs['formDataVerify'].clearValidate();
      }
    },
    saveFormDataProcess: function saveFormDataProcess(formData) {
      var saveFormData = JSON.parse(JSON.stringify(formData));
      return saveFormData;
    },
    add: function add(data) {
      var _this2 = this;
      if (data) {
        this.$api[this.pageConfig.addFunName](this.saveFormDataProcess({
          breedId: this.current.id,
          diseasesId: data.id
        })).then(function (res) {
          if (res.data) {
            _this2.$message.success('新增成功');
            _this2.query();
          } else {
            _this2.$message.error('新增失败');
          }
        });
      }
      this.disease_show = false;
    },
    edit: function edit(data) {
      this.breedJoinDiseasesId = data.id;
      this.weights = 0;
      var _iterator3 = _createForOfIteratorHelper(data.diseasesSymptoms),
        _step3;
      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var item = _step3.value;
          this.weights += item.weights || 0;
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }
      this.symptom_show = true;
    },
    symptomSet: function symptomSet(data) {
      if (data) {
        this.query();
      }
      this.symptom_show = false;
    },
    del: function del(id) {
      var _this3 = this;
      this.$confirm('确定要删除吗').then(function () {
        _this3.$api[_this3.pageConfig.delFunName](id).then(function (res) {
          if (res.data) {
            _this3.$message.success('删除成功');
            _this3.query();
          } else {
            _this3.$message.error('删除失败');
          }
        });
      });
    },
    delSymptom: function delSymptom(id) {
      var _this4 = this;
      this.$confirm('确定要删除吗').then(function () {
        _this4.$api[_this4.pageConfig.delSymptomFunName](id).then(function (res) {
          if (res.data) {
            _this4.$message.success('删除成功');
            _this4.query();
          } else {
            _this4.$message.error('删除失败');
          }
        });
      });
    },
    query: function query() {
      var _this5 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this5.tableData = res.data || [];
        _this5.totalCount = res.count;
        _this5.queryLoading = false;
      });
    },
    submitFormData: function submitFormData() {
      var _this6 = this;
      if (this.formData.id) {
        this.$api[this.pageConfig.modifyFunName](this.saveFormDataProcess(this.formData)).then(function (res) {
          if (res.data) {
            _this6.$message.success('编辑成功');
            _this6.initFormData(_this6.emptyFormData);
            _this6.editDialogShow = false;
            _this6.query();
          } else {
            _this6.$message.error('编辑失败');
          }
        });
      } else {
        this.$api[this.pageConfig.addFunName](this.saveFormDataProcess(this.formData)).then(function (res) {
          if (res.data) {
            _this6.$message.success('新增成功');
            _this6.initFormData(_this6.emptyFormData);
            _this6.editDialogShow = false;
            _this6.query();
          } else {
            _this6.$message.error('新增失败');
          }
        });
      }
    },
    closeEditDialogShow: function closeEditDialogShow() {
      this.editDialogTitle = '';
      this.editDialogShow = false;
      this.initFormData(this.emptyFormData);
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : '^sort';
      this.query();
    }
  }
};