export default (function (_ref) {
  var request = _ref.request;
  return {
    articleAdd: function articleAdd(data) {
      return request({
        url: '/article',
        method: 'post',
        data: data
      });
    },
    articleModify: function articleModify(data) {
      return request({
        url: "/article/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    articleDel: function articleDel(id) {
      return request({
        url: "/article/".concat(id),
        method: 'delete'
      });
    },
    articleQuery: function articleQuery(data) {
      return request({
        url: "/article",
        method: 'get',
        params: data
      });
    },
    articleDetail: function articleDetail(id) {
      return request({
        url: "/article/".concat(id),
        method: 'get'
      });
    }
  };
});