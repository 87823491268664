export default (function (_ref) {
  var request = _ref.request;
  return {
    taskPlanItemDetailsAdd: function taskPlanItemDetailsAdd(data) {
      return request({
        url: '/task/templateItemDetail',
        method: 'post',
        data: data
      });
    },
    taskPlanItemDetailsModify: function taskPlanItemDetailsModify(data) {
      return request({
        url: "/task/templateItemDetail/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    taskPlanItemDetailsDel: function taskPlanItemDetailsDel(id) {
      return request({
        url: "/task/templateItemDetail/".concat(id),
        method: 'delete'
      });
    },
    taskPlanItemDetailsQuery: function taskPlanItemDetailsQuery(data) {
      return request({
        url: '/task/templateItemDetail',
        method: 'get',
        params: data
      });
    },
    taskPlanItemDetailsDetail: function taskPlanItemDetailsDetail(id) {
      return request({
        url: "/task/templateItemDetail/".concat(id),
        method: 'get'
      });
    }
  };
});