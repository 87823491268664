// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./iconfont.woff2?t=1684830296074");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./iconfont.woff?t=1684830296074");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./iconfont.ttf?t=1684830296074");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "@font-face {\r\n  font-family: \"iconfont-user\"; /* Project id 4082285 */\r\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2'),\r\n       url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff'),\r\n       url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('truetype');\n}\n.iconfont-user {\r\n  font-family: \"iconfont-user\" !important;\r\n  font-size: 16px;\r\n  font-style: normal;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\n}\n.icon-user-kefu:before {\r\n  content: \"\\e615\";\n}\n.icon-user-zhanghu:before {\r\n  content: \"\\e602\";\n}\n.icon-user-mingpian:before {\r\n  content: \"\\e671\";\n}\n.icon-user-nvshangjia:before {\r\n  content: \"\\e600\";\n}\n.icon-user-haoyou:before {\r\n  content: \"\\e601\";\n}\n.icon-user-nongmin:before {\r\n  content: \"\\e608\";\n}\n.icon-user-yonghu:before {\r\n  content: \"\\e60c\";\n}\n.icon-user-qiyeguanli_yuangongguanli:before {\r\n  content: \"\\e62c\";\n}\n.icon-user-zhaozhuanjia:before {\r\n  content: \"\\e68a\";\n}\n.icon-user-organiz-full:before {\r\n  content: \"\\e8eb\";\n}\n.icon-user-jianguanrenyuan:before {\r\n  content: \"\\e750\";\n}\n.icon-user-zhuanjia:before {\r\n  content: \"\\e624\";\n}\r\n\r\n", ""]);
// Exports
module.exports = exports;
