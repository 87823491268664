export default (function (_ref) {
  var request = _ref.request;
  return {
    mngProjectAdd: function mngProjectAdd(data) {
      return request({
        url: '/mng/project',
        method: 'post',
        data: data
      });
    },
    mngProjectModify: function mngProjectModify(data) {
      return request({
        url: "/mng/project/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    mngProjectDel: function mngProjectDel(id) {
      return request({
        url: "/mng/project/".concat(id),
        method: 'delete'
      });
    },
    mngProjectQuery: function mngProjectQuery(data) {
      return request({
        url: '/mng/project',
        method: 'get',
        params: data
      });
    },
    mngProjectDetail: function mngProjectDetail(id) {
      return request({
        url: "/mng/project/".concat(id),
        method: 'get'
      });
    }
  };
});