import _toConsumableArray from "D:/work/code/Halov-China-Fishery-V2/halov-web/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "D:/work/code/Halov-China-Fishery-V2/halov-web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _createForOfIteratorHelper from "D:/work/code/Halov-China-Fishery-V2/halov-web/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.values.js";
import echarts from 'echarts';
import dayjs from 'dayjs';
export default {
  name: 'device_history_analysis',
  props: {
    current: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      dayjs: dayjs,
      pageConfig: {
        editPageName: '历史分析',
        queryFunName: 'deviceItemLogQuery',
        itemQueryFunName: 'deviceItemQuery',
        dicTreeFunName: 'dictionaryCache'
      },
      searchForm: {
        page: 1,
        limit: -1,
        sortField: 'create_time',
        deviceId: this.current.id,
        starDate: null,
        endDate: null
      },
      dicData: {
        typeUpperCode: 'iot_sensor_item',
        configTypes: [],
        configTypeOptions: {},
        configTypeIdOptions: {}
      },
      item_list: [],
      checked: []
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$api[this.pageConfig.dicTreeFunName](this.dicData.typeUpperCode).then(function (res) {
      if (res.data) {
        _this.dicData.configTypes = res.data;
        var _iterator = _createForOfIteratorHelper(res.data),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var item = _step.value;
            _this.dicData.configTypeOptions[item.code] = item.name;
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        _this.itemQuery();
      }
    });
    var nowTime = new Date();
    this.$set(this.searchForm, 'starDate', dayjs(nowTime - 1000 * 60 * 60 * 24).format('YYYYMMDD'));
    this.$set(this.searchForm, 'endDate', dayjs(nowTime).format('YYYYMMDD'));
  },
  methods: {
    back: function back() {
      this.$emit('child-go-device');
    },
    itemQuery: function itemQuery() {
      var _this2 = this;
      this.$api[this.pageConfig.itemQueryFunName]({
        page: 1,
        limit: 50,
        deviceId: this.current.id
      }).then(function (res) {
        var _iterator2 = _createForOfIteratorHelper(res.data),
          _step2;
        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var item = _step2.value;
            _this2.dicData.configTypeIdOptions[item.id] = _this2.dicData.configTypeOptions[item.type];
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
        _this2.item_list = res.data || [];
        console.log('this.dicData.configTypeIdOptions', _this2.dicData);
      });
    },
    query: function query() {
      var _this3 = this;
      if (this.echarts) {
        this.echarts.clear();
      }
      this.echarts = echarts.init(document.getElementById('chart-container'));
      this.$api[this.pageConfig.queryFunName](_objectSpread(_objectSpread({}, this.searchForm), {}, {
        deviceItemId: this.checked.toString()
      })).then(function (res) {
        var obj = {};
        var legend = [];
        var xAxis = [];
        var series_obj = {};
        var createTime = '';
        var _iterator3 = _createForOfIteratorHelper(res.data),
          _step3;
        try {
          for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
            var _item2 = _step3.value;
            createTime = _this3.dateFormat2Str(_item2.createTime);
            if (!obj[_item2.deviceItemId]) {
              legend.push(_this3.dicData.configTypeIdOptions[_item2.deviceItemId]);
              obj[_item2.deviceItemId] = {
                label: _this3.dicData.configTypeIdOptions[_item2.deviceItemId],
                data: [_objectSpread(_objectSpread({}, _item2), {}, {
                  createTime: createTime
                })]
              };
            } else {
              obj[_item2.deviceItemId].data.push(_objectSpread(_objectSpread({}, _item2), {}, {
                createTime: createTime
              }));
            }
            xAxis.push(createTime);
          }
        } catch (err) {
          _iterator3.e(err);
        } finally {
          _iterator3.f();
        }
        xAxis = _toConsumableArray(new Set(xAxis));
        // console.log('xAxis',xAxis)
        for (var key in obj) {
          obj[key].options = {};
          var _iterator4 = _createForOfIteratorHelper(obj[key].data),
            _step4;
          try {
            for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
              var item = _step4.value;
              obj[key].options[item.createTime] = item;
            }
          } catch (err) {
            _iterator4.e(err);
          } finally {
            _iterator4.f();
          }
        }
        // console.log('obj',obj)

        for (var _key in obj) {
          series_obj[_key] = {
            name: obj[_key].label,
            type: 'line',
            data: []
          };
          var _iterator5 = _createForOfIteratorHelper(xAxis),
            _step5;
          try {
            for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
              var _item = _step5.value;
              series_obj[_key].data.push(obj[_key].options[_item] ? obj[_key].options[_item].deviceItemValue : '0');
            }
          } catch (err) {
            _iterator5.e(err);
          } finally {
            _iterator5.f();
          }
        }

        // for(let key in obj){
        //     series.push({
        //         name:obj[key].label,
        //         type:'line',
        //         data:obj[key].data,
        //     })
        // }

        // console.log('series_obj',series_obj)
        console.log('legend1', legend);
        var option = {
          color: ['#2788E1', '#DD4588', '#66DC95', '#502CF1', '#E0831A', '#8fd3e8', '#d95850'],
          legend: {
            textStyle: {
              color: '#fff'
            },
            data: legend
          },
          grid: {
            bottom: 80
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            axisLabel: {
              show: true,
              rotate: 30,
              textStyle: {
                color: '#fff'
              }
            },
            axisLine: {
              lineStyle: {
                color: '#094060'
              }
            },
            data: xAxis
          },
          yAxis: {
            axisLabel: {
              show: true,
              textStyle: {
                color: '#fff'
              }
            },
            axisLine: {
              lineStyle: {
                color: '#094060'
              }
            },
            type: 'value'
          },
          series: Object.values(series_obj)
        };
        _this3.echarts.setOption(option);
      });
    },
    search: function search() {
      if (!this.checked.length) {
        this.$message.info('请先选择设备参数');
        return;
      }
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : '^sort';
      this.query();
    }
  }
};