import 'video.js/dist/video-js.css';
import videojs from 'video.js';
export default {
  data: function data() {
    return {
      pageConfig: {
        editPageName: '摄像头播放',
        queryFunName: 'videoQuery',
        detailFunName: 'videoDetail'
      }
    };
  },
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  mounted: function mounted() {
    this.query();
  },
  destroyed: function destroyed() {
    this.player.dispose();
  },
  methods: {
    options: function options(src) {
      return {
        autoplay: true // true,浏览器准备好时开始播放。
        ,
        muted: true // 默认情况下将会消除音频。
        ,
        loop: true // 导致视频一结束就重新开始。
        ,
        controls: false // 取消视频中的进度条
        ,
        preload: 'auto' // auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        ,
        language: 'zh-CN' // 汉化
        ,
        fluid: true // 当true时，将按比例缩放以适应其容器。
        ,
        sources: [{
          type: 'application/x-mpegURL',
          src: src // 视频播放地址
        }],

        notSupportedMessage: '此视频暂无法播放，请稍后再试' // 无法播放媒体源时显示的默认信息。
        ,
        textTrackDisplay: false
      };
    },
    query: function query() {
      var _this = this;
      this.$api[this.pageConfig.detailFunName](this.id).then(function (res) {
        _this.player = videojs('valveVideogj', _this.options(res.data.playUrl), function () {
          _this.player.play();
        });
      });
    },
    close: function close() {
      this.$emit('close');
    }
  }
};