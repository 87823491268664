export default (function (_ref) {
  var request = _ref.request;
  return {
    switchAdd: function switchAdd(data) {
      return request({
        url: '/switchs',
        method: 'post',
        data: data
      });
    },
    switchModify: function switchModify(data) {
      return request({
        url: "/switchs/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    switchDel: function switchDel(id) {
      return request({
        url: "/switchs/".concat(id),
        method: 'delete'
      });
    },
    switchQuery: function switchQuery(data) {
      return request({
        url: '/switchs',
        method: 'get',
        params: data
      });
    },
    switchDetail: function switchDetail(id) {
      return request({
        url: "/switchs/".concat(id),
        method: 'get'
      });
    },
    switchCache: function switchCache(code) {
      return request({
        url: "/switchs/cache/".concat(code),
        method: 'get'
      });
    },
    switchsCache: function switchsCache(codes) {
      return request({
        url: "/switchs/cache?codes=".concat(codes),
        method: 'get'
      });
    },
    switchClearCache: function switchClearCache(code) {
      return request({
        url: "/switchs/cache/clear?code=".concat(code || ''),
        method: 'delete'
      });
    }
  };
});