import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  name: 'breed_page_mng',
  components: {
    breedTable: function breedTable() {
      return import('./breed_page');
    },
    breedModeTable: function breedModeTable() {
      return import('./breed_mode_page');
    },
    breedSegmentTable: function breedSegmentTable() {
      return import('./breed_segment_page');
    },
    waterManageTable: function waterManageTable() {
      return import('./breed_waterManage_page');
    },
    breedFormulaTable: function breedFormulaTable() {
      return import('./breed_formula_page');
    },
    feedMethodTable: function feedMethodTable() {
      return import('./breed_feedMethod_page');
    },
    breedDiseasesTable: function breedDiseasesTable() {
      return import('./breed_diseases_page');
    },
    productManageTable: function productManageTable() {
      return import('./breed_productManage_page');
    }
  },
  data: function data() {
    return {
      tableShow: 'breed',
      current: {}
    };
  },
  methods: {
    goBreed: function goBreed() {
      this.tableShow = 'breed';
    },
    goChild: function goChild(data, tableName) {
      this.current = data;
      this.tableShow = tableName;
    }
  }
};