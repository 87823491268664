import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.json.stringify.js";
import dayjs from 'dayjs';
export default {
  name: 'only_goods_page',
  components: {
    sourceBatchSel: function sourceBatchSel() {
      return import('./source_batch_sel.vue');
    }
  },
  data: function data() {
    return {
      dayjs: dayjs,
      pageConfig: {
        editPageName: '一物一码',
        addFunName: 'sourceUniqueBatchAdd',
        cancelFunName: 'sourceUniqueBatchCancel',
        queryFunName: 'sourceUniqueBatchQuery',
        detailFunName: 'sourceUniqueBatchDetail',
        delFunName: 'sourceUniqueBatchDel',
        downFunName: 'sourceBatchDownQrCods'
      },
      searchForm: {
        page: 1,
        limit: 10,
        searchKey: null,
        sortField: 'create_time'
      },
      formData: {
        limitScan: 'No_Limit'
      },
      rules: {
        generateAmount: [{
          required: true,
          message: '请输入生成数量',
          trigger: 'blur'
        }],
        scanAmount: [{
          required: true,
          message: '请输入扫描次数',
          trigger: 'blur'
        }],
        sourceBatchId: [{
          required: true,
          message: '请选择溯源批次',
          trigger: 'blur'
        }],
        validTime: [{
          required: true,
          message: '请选择有效期截止',
          trigger: 'blur'
        }]
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      editDialogShow: false,
      detailDialogShow: false,
      detailData: {},
      editDialogTitle: '',
      formLabelWidth: '100px',
      emptyFormData: {},
      batch_show: false
    };
  },
  mounted: function mounted() {
    this.emptyFormData = JSON.parse(JSON.stringify(this.formData));
    this.query();
  },
  methods: {
    initFormData: function initFormData(data) {
      var paramData = JSON.parse(JSON.stringify(data));
      this.formData = paramData;
      if (!this.formData.limitScan) {
        this.$set(this.formData, 'limitScan', 'No_Limit');
      }
      if (typeof this.$refs['formDataVerify'] !== 'undefined') {
        this.$refs['formDataVerify'].clearValidate();
      }
    },
    saveFormDataProcess: function saveFormDataProcess(formData) {
      var saveFormData = JSON.parse(JSON.stringify(formData));
      return saveFormData;
    },
    beforeCommand: function beforeCommand(index, row, command) {
      return {
        index: index,
        row: row,
        command: command
      };
    },
    moreButton: function moreButton(command) {
      switch (command.command) {
        case 'cancel':
          // 作废
          this.cancel(command.row.id);
          break;
        case 'del':
          // 删除
          this.del(command.row.id);
          break;
        case 'detail':
          // 详情
          this.detail(command.row);
          break;
      }
    },
    add: function add() {
      this.editDialogTitle = this.pageConfig.editPageName + '新增';
      this.editDialogShow = true;
    },
    detail: function detail(data) {
      this.detailData = data;
      this.$set(this.detailData, 'createTime', data.createTime + '');
      this.$set(this.detailData, 'validTime', data.validTime + '');
      this.detailDialogShow = true;
      // this.$api[this.pageConfig.detailFunName](id).then((res) => {
      //     this.detailData = res.data
      //     this.$set(this.detailData, 'createTime', res.data.createTime+'')
      //     this.$set(this.detailData, 'validTime', res.data.validTime+'')
      //     this.detailDialogShow = true
      // })
    },
    cancel: function cancel(id) {
      var _this = this;
      this.$confirm('确定要作废吗').then(function () {
        _this.$api[_this.pageConfig.cancelFunName](id).then(function (res) {
          if (res.data) {
            _this.$message.success('作废成功');
            _this.query();
          } else {
            _this.$message.error('作废失败');
          }
        });
      });
    },
    download: function download(id) {
      this.$api[this.pageConfig.downFunName](id).then(function (res) {
        window.open("/api/sourceUniqueBatch/".concat(id, "/downQrCods"));
      });
    },
    detailList: function detailList(course) {
      this.$emit('child-go', course, 'details');
    },
    sourceBatchSel: function sourceBatchSel(val) {
      if (val) {
        this.$set(this.formData, 'sourceBatchId', val.id);
        this.$set(this.formData, 'productName', val.productName);
      }
      this.batch_show = false;
    },
    del: function del(id) {
      var _this2 = this;
      this.$confirm('确定要删除吗').then(function () {
        _this2.$api[_this2.pageConfig.delFunName](id).then(function (res) {
          if (res.data) {
            _this2.$message.success('删除成功');
            _this2.query();
          } else {
            _this2.$message.error('删除失败');
          }
        });
      });
    },
    query: function query() {
      var _this3 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this3.tableData = res.data || [];
        _this3.totalCount = res.count;
        _this3.queryLoading = false;
      });
    },
    submitFormData: function submitFormData() {
      var _this4 = this;
      this.$refs['formDataVerify'].validate(function (valid) {
        if (valid) {
          _this4.$api[_this4.pageConfig.addFunName](_this4.saveFormDataProcess(_this4.formData)).then(function (res) {
            if (res.data) {
              _this4.$message.success('新增成功');
              _this4.initFormData(_this4.emptyFormData);
              _this4.editDialogShow = false;
              _this4.query();
            } else {
              _this4.$message.error('新增失败');
            }
          });
        }
      });
    },
    closeEditDialogShow: function closeEditDialogShow() {
      this.editDialogTitle = '';
      this.editDialogShow = false;
      this.initFormData(this.emptyFormData);
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};