export default (function (_ref) {
  var request = _ref.request;
  return {
    fisheryTaskMedicateAdd: function fisheryTaskMedicateAdd(data) {
      return request({
        url: '/fisheryTaskMedicate',
        method: 'post',
        data: data
      });
    },
    fisheryTaskMedicateModify: function fisheryTaskMedicateModify(data) {
      return request({
        url: "/fisheryTaskMedicate/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    fisheryTaskMedicateDel: function fisheryTaskMedicateDel(id) {
      return request({
        url: "/fisheryTaskMedicate/".concat(id),
        method: 'delete'
      });
    },
    fisheryTaskMedicateQuery: function fisheryTaskMedicateQuery(data) {
      return request({
        url: '/fisheryTaskMedicate/queryJoinBreedAndPlace',
        method: 'get',
        params: data
      });
    },
    fisheryTaskMedicateDetail: function fisheryTaskMedicateDetail(id) {
      return request({
        url: "/fisheryTaskMedicate/".concat(id),
        method: 'get'
      });
    }
  };
});