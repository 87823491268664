var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "page"
  }, [_c("template", {
    slot: "header"
  }, [_c("el-page-header", {
    attrs: {
      content: "《 " + _vm.current.deviceName + " 》 设备告警历史列表"
    },
    on: {
      back: _vm.back
    }
  })], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.queryLoading,
      expression: "queryLoading"
    }],
    staticStyle: {
      width: "100%",
      "margin-bottom": "20px"
    },
    attrs: {
      data: _vm.tableData,
      stripe: "",
      border: "",
      "row-key": "id",
      "element-loading-text": "正在努力加载中..."
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "序号",
      type: "index",
      width: "50"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "deviceId",
      label: "设备",
      "min-width": "80"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "deviceItemId",
      label: "检测项目",
      "min-width": "80"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "handleState",
      label: "处置状态",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.handleState == "Wait" ? _c("div", {
          staticClass: "text-interrupt"
        }, [_vm._v("待处理")]) : _vm._e(), scope.row.handleState == "End" ? _c("div", {
          staticClass: "text-suggest"
        }, [_vm._v("已处理")]) : _vm._e(), scope.row.handleState == "Ignore" ? _c("div", {
          staticClass: "text-pause"
        }, [_vm._v("忽略")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "handleTime",
      label: "处置时间",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.handleTime ? _vm.dayjs(scope.row.handleTime * 1000).format("YYYY-MM-DD HH:mm:ss") : ""))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "handleUser",
      label: "处置人",
      width: "80"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "selfRecoveryState",
      label: "自动恢复状态",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.selfRecoveryState == "No_Recovery" ? _c("div", {
          staticClass: "text-interrupt"
        }, [_vm._v("未恢复")]) : _vm._e(), scope.row.selfRecoveryState == "Recovered" ? _c("div", {
          staticClass: "text-suggest"
        }, [_vm._v("已恢复")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "selfRecoveryTime",
      label: "自动恢复时间",
      width: "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(_vm.dateFormat2Str(scope.row.selfRecoveryTime)))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "warnCategory",
      label: "告警类别",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.warnCategory == "Threshold" ? _c("div", [_vm._v("阈值")]) : _vm._e(), scope.row.warnCategory == "Offline" ? _c("div", [_vm._v("离线")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "warnType",
      label: "告警类型",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.warnType == "Lower_Limit" ? _c("div", [_vm._v(" 下限预警")]) : _vm._e(), scope.row.warnType == "Upper_Limit" ? _c("div", [_vm._v("上限预警")]) : _vm._e(), scope.row.warnType == "Between" ? _c("div", [_vm._v("区间预警")]) : _vm._e(), scope.row.warnType == "Equals" ? _c("div", [_vm._v("等值预警")]) : _vm._e(), scope.row.warnType == "Not_Equals" ? _c("div", [_vm._v("不等值预警")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "warnContent",
      label: "告警内容",
      "min-width": "80",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "warnPresetValue",
      label: "告警预设值",
      width: "100"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "warnLower",
      label: "告警下限",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.warnType == "Lower_Limit" || scope.row.warnType == "Between" ? scope.row.warnLower || "-" : "-"))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "warnUpper",
      label: "告警上限",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.warnType == "Upper_Limit" || scope.row.warnType == "Between" ? scope.row.warnUpper || "-" : "-"))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "warnValue",
      label: "告警值",
      width: "70"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(scope.row.warnType == "Equals" || scope.row.warnType == "Not_Equals" ? scope.row.warnValue || "-" : "-"))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "warnLevel",
      label: "告警级别",
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.warnLevel == "Seriousness" ? _c("div", [_vm._v(" 严重")]) : _vm._e(), scope.row.warnLevel == "Exigency" ? _c("div", [_vm._v("紧急")]) : _vm._e(), scope.row.warnLevel == "Normal" ? _c("div", [_vm._v("一般")]) : _vm._e()];
      }
    }])
  })], 1), _c("el-pagination", {
    attrs: {
      background: "",
      align: "center",
      layout: "prev, pager, next, sizes, total",
      "page-sizes": [10, 20, 50, 100],
      "current-page": _vm.searchForm.page,
      "page-size": _vm.searchForm.limit,
      total: _vm.totalCount
    },
    on: {
      "current-change": _vm.pageChange,
      "size-change": _vm.sizeChange
    }
  })], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };