export default (function (_ref) {
  var request = _ref.request;
  return {
    fisheryTaskFeedAdd: function fisheryTaskFeedAdd(data) {
      return request({
        url: '/fisheryTaskFeed',
        method: 'post',
        data: data
      });
    },
    fisheryTaskFeedModify: function fisheryTaskFeedModify(data) {
      return request({
        url: "/fisheryTaskFeed/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    fisheryTaskFeedDel: function fisheryTaskFeedDel(id) {
      return request({
        url: "/fisheryTaskFeed/".concat(id),
        method: 'delete'
      });
    },
    fisheryTaskFeedQuery: function fisheryTaskFeedQuery(data) {
      return request({
        url: '/fisheryTaskFeed/queryJoinBreedAndPlace',
        method: 'get',
        params: data
      });
    },
    fisheryTaskFeedDetail: function fisheryTaskFeedDetail(id) {
      return request({
        url: "/fisheryTaskFeed/".concat(id),
        method: 'get'
      });
    }
  };
});