export default (function (_ref) {
  var request = _ref.request;
  return {
    breedFormulaAdd: function breedFormulaAdd(data) {
      return request({
        url: '/breedFormula',
        method: 'post',
        data: data
      });
    },
    breedFormulaModify: function breedFormulaModify(data) {
      return request({
        url: "/breedFormula/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    breedFormulaDel: function breedFormulaDel(id) {
      return request({
        url: "/breedFormula/".concat(id),
        method: 'delete'
      });
    },
    breedFormulaQuery: function breedFormulaQuery(data) {
      return request({
        url: '/breedFormula',
        method: 'get',
        params: data
      });
    },
    breedFormulaDetail: function breedFormulaDetail(id) {
      return request({
        url: "/breedFormula/".concat(id),
        method: 'get'
      });
    }
  };
});