import _createForOfIteratorHelper from "D:/work/code/Halov-China-Fishery-V2/halov-web/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.function.name.js";
export default {
  name: 'breed_page_sel',
  data: function data() {
    return {
      pageConfig: {
        queryFunName: 'breedQuery',
        dicTreeFunName: 'dictionaryCache'
      },
      searchForm: {
        page: 1,
        limit: 10,
        searchKey: null,
        sortField: '^sort'
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      dicData: {
        typeUpperCode: 'Fishery_Breed_Type',
        configTypes: [],
        configTypeOptions: {}
      }
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$api[this.pageConfig.dicTreeFunName](this.dicData.typeUpperCode).then(function (res) {
      if (res.data) {
        _this.dicData.configTypes = res.data;
        var _iterator = _createForOfIteratorHelper(res.data),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var item = _step.value;
            _this.dicData.configTypeOptions[item.code] = item.name;
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        _this.query();
      }
    });
  },
  methods: {
    sel: function sel(data) {
      this.$emit('child-breed-sel', data);
    },
    query: function query() {
      var _this2 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this2.tableData = res.data || [];
        _this2.totalCount = res.count;
        _this2.queryLoading = false;
      });
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};