import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import dayjs from 'dayjs';
import axios from 'axios';
import md5 from 'js-md5';
export default {
  name: 'without_control_page',
  data: function data() {
    return {
      dayjs: dayjs,
      pageConfig: {
        editPageName: '外部设备控制'
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      url: '',
      editDialogShow: false,
      fullscreen: false
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$api['configsCache']('YX_Crab_Breed_SID,YX_Crab_Breed_UID').then(function (res) {
      if (res.data) {
        _this.YX_Crab_Breed_URL = '/sukonApi';
        _this.YX_Crab_Breed_SID = res.data.YX_Crab_Breed_SID;
        _this.YX_Crab_Breed_UID = res.data.YX_Crab_Breed_UID;
        _this.query();
      } else {
        _this.$message.error(res.msg);
      }
    });
  },
  methods: {
    look: function look(_ref) {
      var _this2 = this;
      var id = _ref.id,
        type = _ref.type;
      var formData = new FormData();
      var for_client = 'p'; //使用场景【p: 电脑端, m: 手机端, 空: 获取所有组态】
      formData.append('token', this.token);
      formData.append('projectId', id);
      formData.append('for_client', for_client);
      axios({
        url: this.YX_Crab_Breed_URL + '/api/v1/mndraw/mndrawParams',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        data: formData
      }).then(function (res) {
        if (res.data.code == 70000) {
          if (res.data.data) {
            _this2.url = "https://sukon-cloud.com/mndraw/client?id=" + res.data.data.id + "&type=" + res.data.data.type + "&resourceId=" + res.data.data.resourceId + "&token=" + _this2.token + "&uid=" + _this2.YX_Crab_Breed_UID;
            _this2.editDialogShow = true;
          }
        }
      });
    },
    query: function query() {
      var _this3 = this;
      this.queryLoading = true;
      var formData = new FormData();
      var uid = this.YX_Crab_Breed_UID;
      var sid = this.YX_Crab_Breed_SID;
      var random = '' + parseInt(Math.random() * 9) + parseInt(Math.random() * 9) + parseInt(Math.random() * 9) + parseInt(Math.random() * 9) + parseInt(Math.random() * 9) + parseInt(Math.random() * 9);
      var timestamp = Date.now();
      var signature = md5(uid + sid + random + timestamp).toString().toUpperCase();
      formData.append('uid', uid);
      formData.append('sid', sid);
      formData.append('random', random);
      formData.append('timestamp', timestamp);
      formData.append('signature', signature);
      axios({
        url: this.YX_Crab_Breed_URL + '/api/v1/token/initToken',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        data: formData
      }).then(function (response) {
        if (response.data.code == 10000) {
          _this3.token = response.data.data.token;
          var _formData = new FormData();
          _formData.append('token', _this3.token);
          axios({
            url: _this3.YX_Crab_Breed_URL + '/api/v1/base/projects',
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8'
            },
            data: _formData
          }).then(function (res) {
            if (res.data.code == 20000) {
              _this3.tableData = res.data.data || [];
              _this3.totalCount = res.data.data.length;
              _this3.queryLoading = false;
            }
          });
        }
      }).catch(function (error) {
        console.log('error', error);
      });
    }
  }
};