var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "d2-page-cover"
  }, [_c("div", {
    staticClass: "d2-page-cover__logo"
  }, [_vm._t("default")], 2), _c("p", {
    staticClass: "d2-page-cover__title"
  }, [_vm._v(_vm._s(_vm.sys_name || ""))]), _vm._t("footer"), _vm._m(0)], 2);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("a", {
    attrs: {
      target: "blank",
      href: "http://www.rich-link.cn"
    }
  }, [_c("img", {
    staticStyle: {
      position: "absolute",
      top: "0",
      right: "0",
      border: "0",
      width: "150px"
    },
    attrs: {
      src: require("./image/darkblue@2x.png"),
      alt: "Fork me on GitHub"
    }
  })]);
}];
render._withStripped = true;
export { render, staticRenderFns };