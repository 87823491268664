import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.function.name.js";
import dayjs from 'dayjs';
export default {
  name: 'project_relationship_page',
  props: {
    current: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      dayjs: dayjs,
      pageConfig: {
        editPageName: '项目关系',
        addFunName: 'projectLabAdd',
        modifyFunName: 'projectLabModify',
        queryFunName: 'projectLabQueryForStructures',
        detailFunName: 'projectLabDetail',
        delFunName: 'projectLabDel'
      },
      searchForm: {
        page: 1,
        limit: 10,
        searchKey: null,
        sortField: 'create_time'
      },
      formData: {
        projectId: this.current.id
      },
      rules: {
        labId: [{
          required: true,
          message: '请选择企业',
          trigger: 'blur'
        }],
        structuresId: [{
          required: true,
          message: '请选择建筑物',
          trigger: 'blur'
        }],
        projectId: [{
          required: true,
          message: '请选择项目',
          trigger: 'blur'
        }]
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      editDialogShow: false,
      editDialogTitle: '',
      formLabelWidth: '80px',
      emptyFormData: {},
      enterprise_show: false,
      structures_show: false,
      project_show: false
    };
  },
  components: {
    labSel: function labSel() {
      return import('../org/lab_sel_page.vue');
    },
    structuresSel: function structuresSel() {
      return import('../org/structures_sel.vue');
    },
    projectSel: function projectSel() {
      return import('./project_sel.vue');
    }
  },
  mounted: function mounted() {
    this.emptyFormData = JSON.parse(JSON.stringify(this.formData));
    this.query();
  },
  methods: {
    initFormData: function initFormData(data) {
      var paramData = JSON.parse(JSON.stringify(data));
      if (!paramData.projectId) {
        paramData.projectId = this.current.id;
      }
      this.formData = paramData;
      if (typeof this.$refs['formDataVerify'] !== 'undefined') {
        this.$refs['formDataVerify'].clearValidate();
      }
    },
    saveFormDataProcess: function saveFormDataProcess(formData) {
      var saveFormData = JSON.parse(JSON.stringify(formData));
      return saveFormData;
    },
    back: function back() {
      this.$emit('child-go-project');
    },
    add: function add() {
      this.$set(this.formData, 'executeUser', localStorage.getItem('userId'));
      this.$set(this.formData, 'executeUserNickname', localStorage.getItem('userNickname'));
      this.$set(this.formData, 'executeDate', dayjs().format('YYYYMMDD'));
      this.editDialogTitle = this.pageConfig.editPageName + '新增';
      this.editDialogShow = true;
    },
    edit: function edit(data) {
      // this.$api[this.pageConfig.detailFunName](data.id).then(async(res) => {
      //   this.initFormData({...res.data,labName:data.labName,structuresName:data.name})
      //   this.editDialogTitle = this.pageConfig.editPageName + '维护'
      //   this.editDialogShow = true
      //   this.$set(this.formData, 'executeDate', res.data.executeDate + '')
      // })
      this.initFormData({
        id: data.id,
        labName: data.labName,
        structuresName: data.name,
        labId: data.labId,
        structuresId: data.id,
        projectId: this.current.id
      });
      this.editDialogTitle = this.pageConfig.editPageName + '维护';
      this.editDialogShow = true;
    } // 选择企业
    ,
    labSel: function labSel(data) {
      if (data) {
        this.$set(this.formData, 'labId', data.id);
        this.$set(this.formData, 'labName', data.name);
        this.$set(this.formData, 'structuresId', '');
        this.$set(this.formData, 'structuresName', '');
      }
      this.enterprise_show = false;
    } // 选择建筑物
    ,
    structuresSel: function structuresSel(data) {
      if (data) {
        this.$set(this.formData, 'structuresId', data.id);
        this.$set(this.formData, 'structuresName', data.name);
      }
      this.structures_show = false;
    } // 选择项目
    ,
    projectSel: function projectSel(data) {
      if (data) {
        this.$set(this.formData, 'projectId', data.id);
        this.$set(this.formData, 'projectName', data.name);
      }
      this.project_show = false;
    },
    del: function del(id) {
      var _this = this;
      this.$confirm('确定要删除吗').then(function () {
        _this.$api[_this.pageConfig.delFunName](id).then(function (res) {
          if (res.data) {
            _this.$message.success('删除成功');
            _this.query();
          } else {
            _this.$message.error('删除失败');
          }
        });
      });
    },
    query: function query() {
      var _this2 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName]({
        data: this.searchForm,
        projectId: this.current.id,
        join: true
      }).then(function (res) {
        _this2.tableData = res.data || [];
        _this2.totalCount = res.count;
        _this2.queryLoading = false;
      });
    },
    submitFormData: function submitFormData() {
      var _this3 = this;
      this.$refs['formDataVerify'].validate(function (valid) {
        if (valid) {
          if (_this3.formData.id) {
            console.log('编辑成功', _this3.formData, _this3.pageConfig.modifyFunName);
            _this3.$api[_this3.pageConfig.modifyFunName](_this3.saveFormDataProcess(_this3.formData)).then(function (res) {
              if (res.data) {
                _this3.$message.success('编辑成功');
                _this3.initFormData(_this3.emptyFormData);
                _this3.editDialogShow = false;
                _this3.query();
              } else {
                _this3.$message.error('编辑失败');
              }
            });
          } else {
            _this3.$api[_this3.pageConfig.addFunName](_this3.saveFormDataProcess(_this3.formData)).then(function (res) {
              if (res.data) {
                _this3.$message.success('新增成功');
                _this3.initFormData(_this3.emptyFormData);
                _this3.editDialogShow = false;
                _this3.query();
              } else {
                _this3.$message.error('新增失败');
              }
            });
          }
        }
      });
    },
    closeEditDialogShow: function closeEditDialogShow() {
      this.editDialogTitle = '';
      this.editDialogShow = false;
      this.initFormData(this.emptyFormData);
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};