export default (function (_ref) {
  var request = _ref.request;
  return {
    productItemAdd: function productItemAdd(data) {
      return request({
        url: '/productItem',
        method: 'post',
        data: data
      });
    },
    productItemModify: function productItemModify(data) {
      return request({
        url: "/productItem/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    productItemDel: function productItemDel(id) {
      return request({
        url: "/productItem/".concat(id),
        method: 'delete'
      });
    },
    productItemQuery: function productItemQuery(data) {
      return request({
        url: '/productItem',
        method: 'get',
        params: data
      });
    },
    productItemDetail: function productItemDetail(id) {
      return request({
        url: "/productItem/".concat(id),
        method: 'get'
      });
    }
  };
});