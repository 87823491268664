export default (function (_ref) {
  var request = _ref.request;
  return {
    workOrderReplayAdd: function workOrderReplayAdd(data) {
      return request({
        url: '/workorderReply',
        method: 'post',
        data: data
      });
    },
    workOrderReplayQuery: function workOrderReplayQuery(data) {
      return request({
        url: '/workorderReply/joinUser',
        method: 'get',
        params: data
      });
    }
  };
});