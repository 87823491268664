export default (function (_ref) {
  var request = _ref.request;
  return {
    taskPlanItemAdd: function taskPlanItemAdd(data) {
      return request({
        url: '/task/templateItem',
        method: 'post',
        data: data
      });
    },
    taskPlanItemModify: function taskPlanItemModify(data) {
      return request({
        url: "/task/templateItem/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    taskPlanItemDel: function taskPlanItemDel(id) {
      return request({
        url: "/task/templateItem/".concat(id),
        method: 'delete'
      });
    },
    taskPlanItemQuery: function taskPlanItemQuery(data) {
      return request({
        url: '/task/templateItem',
        method: 'get',
        params: data
      });
    },
    taskPlanItemDetail: function taskPlanItemDetail(id) {
      return request({
        url: "/task/templateItem/".concat(id),
        method: 'get'
      });
    }
  };
});