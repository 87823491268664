var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-login"
  }, [_c("video", {
    staticClass: "page-login-bg",
    attrs: {
      id: "valveVideogj",
      autoplay: "",
      muted: "",
      loop: "",
      src: "https://ossgy.rich-link.cn/system/deep_sea.mp4"
    },
    domProps: {
      muted: true
    }
  }), _vm._m(0), _c("div", {
    staticClass: "form-container"
  }, [_c("img", {
    staticClass: "form-bg-img",
    attrs: {
      src: "https://ossgy.rich-link.cn/1705212595702325248/20230926/1695715359809.png",
      alt: ""
    }
  }), _c("img", {
    staticClass: "form-top-img",
    attrs: {
      src: require("./image/form-top-img.png"),
      alt: ""
    }
  }), _c("img", {
    staticClass: "logo",
    attrs: {
      src: require("./image/logo.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "form"
  }, [_c("div", {
    staticClass: "form-title"
  }, [_vm._v(_vm._s(_vm.type == 1 ? "手机号验证码登录" : _vm.type == 2 ? "扫码登录" : _vm.type == 3 ? "扫码绑定微信" : ""))]), _vm.type == 1 ? _c("el-form", {
    ref: "loginForm",
    attrs: {
      "label-position": "top",
      rules: _vm.rules,
      model: _vm.formLogin,
      size: "default"
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "phone"
    }
  }, [_c("div", {
    staticClass: "input"
  }, [_c("img", {
    attrs: {
      src: require("./image/inpit-img-1.png"),
      alt: ""
    }
  }), _c("el-input", {
    attrs: {
      maxlength: "11",
      placeholder: "手机号码"
    },
    model: {
      value: _vm.formLogin.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.formLogin, "phone", $$v);
      },
      expression: "formLogin.phone"
    }
  })], 1)]), _c("el-form-item", {
    attrs: {
      prop: "captcha"
    }
  }, [_c("div", {
    staticClass: "captcha-input",
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", {
    staticClass: "input"
  }, [_c("img", {
    attrs: {
      src: require("./image/inpit-img-2.png"),
      alt: ""
    }
  }), _c("el-input", {
    attrs: {
      maxlength: "6",
      placeholder: "验证码"
    },
    model: {
      value: _vm.formLogin.captcha,
      callback: function callback($$v) {
        _vm.$set(_vm.formLogin, "captcha", $$v);
      },
      expression: "formLogin.captcha"
    }
  })], 1), _vm.countdown == 60 ? _c("div", {
    staticClass: "btn get-code",
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: _vm.getCode
    }
  }, [_vm._v("获取验证码")]) : _c("div", {
    staticClass: "btn get-code again"
  }, [_vm._v("(" + _vm._s(_vm.countdown) + ")s后重新获取")])])]), _c("el-button", {
    staticClass: "button-login",
    attrs: {
      size: "default",
      type: "primary"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("登录")])], 1) : _vm._e(), _vm.type == 1 ? _c("img", {
    staticClass: "code",
    attrs: {
      src: require("./image/codelogin.png"),
      alt: ""
    },
    on: {
      click: function click($event) {
        _vm.type = 2;
      }
    }
  }) : _vm._e(), _vm.type == 2 ? _c("div", {
    attrs: {
      id: "code-container"
    }
  }) : _vm._e(), _vm.type == 2 ? _c("img", {
    staticClass: "code",
    attrs: {
      src: require("./image/userlogin.png"),
      alt: ""
    },
    on: {
      click: function click($event) {
        _vm.type = 1;
      }
    }
  }) : _vm._e(), _vm.type == 3 ? _c("div", {
    attrs: {
      id: "code-container"
    }
  }) : _vm._e()], 1)]), _c("img", {
    staticClass: "bottom-bg",
    attrs: {
      src: "https://ossgy.rich-link.cn/1705212595702325248/20230926/1695715294635.png",
      alt: ""
    }
  }), _vm._m(1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "header"
  }, [_c("img", {
    attrs: {
      src: require("./image/header-bg.png"),
      alt: ""
    }
  }), _c("div", [_vm._v("国家数字渔业创新中心")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "bottom-list"
  }, [_c("div", {
    staticClass: "item"
  }, [_c("div", {
    staticClass: "item-top"
  }, [_c("img", {
    staticClass: "item-top-bg",
    attrs: {
      src: require("./image/item-bg-1.png"),
      alt: ""
    }
  }), _c("img", {
    staticClass: "item-top-bg item-top-bg-hover",
    attrs: {
      src: require("./image/item-bg-2.png"),
      alt: ""
    }
  }), _c("img", {
    staticClass: "item-top-img",
    attrs: {
      src: require("./image/item1.png"),
      alt: ""
    }
  })]), _c("img", {
    staticClass: "item-bottom-img",
    attrs: {
      src: require("./image/item-bg-3.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "text"
  }, [_vm._v("工船养殖")]), _c("div", {
    staticClass: "options"
  }, [_c("div", {
    staticClass: "option-title"
  }, [_vm._v("工船养殖")]), _c("div", {
    staticClass: "options-imgs"
  }, [_c("img", {
    attrs: {
      src: require("./image/item1.png"),
      alt: ""
    }
  }), _c("img", {
    attrs: {
      src: require("./image/item1.png"),
      alt: ""
    }
  }), _c("img", {
    attrs: {
      src: require("./image/item1.png"),
      alt: ""
    }
  })])])]), _c("div", {
    staticClass: "item"
  }, [_c("div", {
    staticClass: "item-top"
  }, [_c("img", {
    staticClass: "item-top-bg",
    attrs: {
      src: require("./image/item-bg-1.png"),
      alt: ""
    }
  }), _c("img", {
    staticClass: "item-top-bg item-top-bg-hover",
    attrs: {
      src: require("./image/item-bg-2.png"),
      alt: ""
    }
  }), _c("img", {
    staticClass: "item-top-img",
    attrs: {
      src: require("./image/item2.png"),
      alt: ""
    }
  })]), _c("img", {
    staticClass: "item-bottom-img",
    attrs: {
      src: require("./image/item-bg-3.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "text"
  }, [_vm._v("大水面养殖")]), _c("div", {
    staticClass: "options"
  }, [_c("div", {
    staticClass: "option-title"
  }, [_vm._v("大水面养殖")]), _c("div", {
    staticClass: "options-imgs"
  }, [_c("img", {
    attrs: {
      src: require("./image/item1.png"),
      alt: ""
    }
  }), _c("img", {
    attrs: {
      src: require("./image/item1.png"),
      alt: ""
    }
  }), _c("img", {
    attrs: {
      src: require("./image/item1.png"),
      alt: ""
    }
  })])])]), _c("div", {
    staticClass: "item"
  }, [_c("div", {
    staticClass: "item-top"
  }, [_c("img", {
    staticClass: "item-top-bg",
    attrs: {
      src: require("./image/item-bg-1.png"),
      alt: ""
    }
  }), _c("img", {
    staticClass: "item-top-bg item-top-bg-hover",
    attrs: {
      src: require("./image/item-bg-2.png"),
      alt: ""
    }
  }), _c("img", {
    staticClass: "item-top-img",
    attrs: {
      src: require("./image/item3.png"),
      alt: ""
    }
  })]), _c("img", {
    staticClass: "item-bottom-img",
    attrs: {
      src: require("./image/item-bg-3.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "text"
  }, [_vm._v("大围网养殖")]), _c("div", {
    staticClass: "options"
  }, [_c("div", {
    staticClass: "option-title"
  }, [_vm._v("大围网养殖")]), _c("div", {
    staticClass: "options-imgs"
  }, [_c("img", {
    attrs: {
      src: require("./image/item1.png"),
      alt: ""
    }
  }), _c("img", {
    attrs: {
      src: require("./image/item1.png"),
      alt: ""
    }
  }), _c("img", {
    attrs: {
      src: require("./image/item1.png"),
      alt: ""
    }
  })])])]), _c("div", {
    staticClass: "item"
  }, [_c("div", {
    staticClass: "item-top"
  }, [_c("img", {
    staticClass: "item-top-bg",
    attrs: {
      src: require("./image/item-bg-1.png"),
      alt: ""
    }
  }), _c("img", {
    staticClass: "item-top-bg item-top-bg-hover",
    attrs: {
      src: require("./image/item-bg-2.png"),
      alt: ""
    }
  }), _c("img", {
    staticClass: "item-top-img",
    attrs: {
      src: require("./image/item4.png"),
      alt: ""
    }
  })]), _c("img", {
    staticClass: "item-bottom-img",
    attrs: {
      src: require("./image/item-bg-3.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "text"
  }, [_vm._v("鱼菜共生")]), _c("div", {
    staticClass: "options"
  }, [_c("div", {
    staticClass: "option-title"
  }, [_vm._v("鱼菜共生")]), _c("div", {
    staticClass: "options-imgs"
  }, [_c("img", {
    attrs: {
      src: require("./image/item1.png"),
      alt: ""
    }
  }), _c("img", {
    attrs: {
      src: require("./image/item1.png"),
      alt: ""
    }
  }), _c("img", {
    attrs: {
      src: require("./image/item1.png"),
      alt: ""
    }
  })])])]), _c("div", {
    staticClass: "item"
  }, [_c("div", {
    staticClass: "item-top"
  }, [_c("img", {
    staticClass: "item-top-bg",
    attrs: {
      src: require("./image/item-bg-1.png"),
      alt: ""
    }
  }), _c("img", {
    staticClass: "item-top-bg item-top-bg-hover",
    attrs: {
      src: require("./image/item-bg-2.png"),
      alt: ""
    }
  }), _c("img", {
    staticClass: "item-top-img",
    attrs: {
      src: require("./image/item5.png"),
      alt: ""
    }
  })]), _c("img", {
    staticClass: "item-bottom-img",
    attrs: {
      src: require("./image/item-bg-3.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "text"
  }, [_vm._v("池塘养殖")]), _c("div", {
    staticClass: "options"
  }, [_c("div", {
    staticClass: "option-title"
  }, [_vm._v("池塘养殖")]), _c("div", {
    staticClass: "options-imgs"
  }, [_c("img", {
    attrs: {
      src: require("./image/item1.png"),
      alt: ""
    }
  }), _c("img", {
    attrs: {
      src: require("./image/item1.png"),
      alt: ""
    }
  }), _c("img", {
    attrs: {
      src: require("./image/item1.png"),
      alt: ""
    }
  })])])]), _c("div", {
    staticClass: "item"
  }, [_c("div", {
    staticClass: "item-top"
  }, [_c("img", {
    staticClass: "item-top-bg",
    attrs: {
      src: require("./image/item-bg-1.png"),
      alt: ""
    }
  }), _c("img", {
    staticClass: "item-top-bg item-top-bg-hover",
    attrs: {
      src: require("./image/item-bg-2.png"),
      alt: ""
    }
  }), _c("img", {
    staticClass: "item-top-img",
    attrs: {
      src: require("./image/item6.png"),
      alt: ""
    }
  })]), _c("img", {
    staticClass: "item-bottom-img",
    attrs: {
      src: require("./image/item-bg-3.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "text"
  }, [_vm._v("工厂化养殖")]), _c("div", {
    staticClass: "options"
  }, [_c("div", {
    staticClass: "option-title"
  }, [_vm._v("工厂化养殖")]), _c("div", {
    staticClass: "options-imgs"
  }, [_c("img", {
    attrs: {
      src: require("./image/item1.png"),
      alt: ""
    }
  }), _c("img", {
    attrs: {
      src: require("./image/item1.png"),
      alt: ""
    }
  }), _c("img", {
    attrs: {
      src: require("./image/item1.png"),
      alt: ""
    }
  })])])])]);
}];
render._withStripped = true;
export { render, staticRenderFns };