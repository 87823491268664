export default (function (_ref) {
  var request = _ref.request;
  return {
    breedJoinDiseasesAdd: function breedJoinDiseasesAdd(data) {
      return request({
        url: '/breedJoinDiseases',
        method: 'post',
        data: data
      });
    },
    breedJoinDiseasesModify: function breedJoinDiseasesModify(data) {
      return request({
        url: "/breedJoinDiseases/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    breedJoinDiseasesDel: function breedJoinDiseasesDel(id) {
      return request({
        url: "/breedJoinDiseases/".concat(id),
        method: 'delete'
      });
    },
    breedJoinDiseasesQueryJoinDiseasesAndDiseasesCategory: function breedJoinDiseasesQueryJoinDiseasesAndDiseasesCategory(data) {
      return request({
        url: '/breedJoinDiseases/queryJoinDiseasesAndDiseasesCategory',
        method: 'get',
        params: data
      });
    },
    breedJoinDiseasesDetail: function breedJoinDiseasesDetail(id) {
      return request({
        url: "/breedJoinDiseases/".concat(id),
        method: 'get'
      });
    }
  };
});