import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "page"
  }, [_c("template", {
    slot: "header"
  }, [_c("el-form", {
    attrs: {
      inline: true,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "关键字"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "关键字"
    },
    model: {
      value: _vm.searchForm.searchKey,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "searchKey", $$v);
      },
      expression: "searchForm.searchKey"
    }
  })], 1), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.search
    }
  }, [_c("i", {
    staticClass: "fa fa-search"
  }), _vm._v(" 查询")]), _vm.buttonPerms(_vm.$route.path + ":add") ? _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.add
    }
  }, [_c("i", {
    staticClass: "fa fa-plus"
  }), _vm._v(" 添加")]) : _vm._e()], 1)], 1)], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.queryLoading,
      expression: "queryLoading"
    }],
    staticStyle: {
      width: "100%",
      "margin-bottom": "20px"
    },
    attrs: {
      data: _vm.tableData,
      stripe: "",
      border: "",
      "row-key": "id",
      "element-loading-text": "正在努力加载中..."
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "序号",
      type: "index",
      width: "50"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm.buttonPerms(_vm.$route.path + ":preview") ? _c("el-button", {
          attrs: {
            size: "mini",
            type: "warning"
          },
          on: {
            click: function click($event) {
              return _vm.preview(scope.row.id);
            }
          }
        }, [_vm._v("预览")]) : _vm._e(), _vm.buttonPerms(_vm.$route.path + ":edit") || !scope.row.labId && _vm.buttonPerms(_vm.$route.path + ":bind") || !!scope.row.labId && _vm.buttonPerms(_vm.$route.path + ":unbind") || _vm.buttonPerms(_vm.$route.path + ":del") ? _c("el-dropdown", {
          staticStyle: {
            "margin-left": "10px"
          },
          attrs: {
            size: "mini",
            "split-button": "",
            type: "primary"
          },
          on: {
            command: _vm.moreButton
          }
        }, [_vm._v(" 更多 "), _c("el-dropdown-menu", {
          attrs: {
            slot: "dropdown"
          },
          slot: "dropdown"
        }, [_vm.buttonPerms(_vm.$route.path + ":edit") ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-edit",
            command: _vm.beforeCommand(scope.$index, scope.row, "edit")
          }
        }, [_vm._v("编辑")]) : _vm._e(), !scope.row.labId && _vm.buttonPerms(_vm.$route.path + ":bind") ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-connection",
            command: _vm.beforeCommand(scope.$index, scope.row, "bind")
          }
        }, [_vm._v("位置绑定")]) : _vm._e(), !!scope.row.labId && _vm.buttonPerms(_vm.$route.path + ":unbind") ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-scissors",
            command: _vm.beforeCommand(scope.$index, scope.row, "unbind")
          }
        }, [_vm._v("位置解绑")]) : _vm._e(), _vm.buttonPerms(_vm.$route.path + ":del") ? _c("el-dropdown-item", {
          attrs: {
            icon: "el-icon-delete",
            command: _vm.beforeCommand(scope.$index, scope.row, "del")
          }
        }, [_vm._v("删除视频")]) : _vm._e()], 1)], 1) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "videoName",
      label: "视频名称",
      width: "250",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "videoSn",
      label: "序列号",
      width: "180",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "ezvizSerial",
      label: "设备号",
      width: "180",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "ezvizChannel",
      label: "通道号",
      width: "80",
      "show-overflow-tooltip": ""
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "videoCategory",
      label: "类型",
      sortable: "custom",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.videoCategory == "Ezviz" ? _c("div", [_vm._v("萤石云")]) : _vm._e(), scope.row.videoCategory == "Uniview" ? _c("div", [_vm._v("宇视云")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "ezvizQuality",
      label: "清晰度",
      sortable: "custom",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.ezvizQuality == 1 ? _c("div", [_vm._v(" 高清（主码流）")]) : _vm._e(), scope.row.ezvizQuality == 2 ? _c("div", [_vm._v("流畅（子码流）")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "ezvizProtocol",
      label: "视频协议",
      sortable: "custom",
      width: "110"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.ezvizProtocol == 1 ? _c("div", [_vm._v("ezopen")]) : _vm._e(), scope.row.ezvizProtocol == 2 ? _c("div", [_vm._v("hls")]) : _vm._e(), scope.row.ezvizProtocol == 3 ? _c("div", [_vm._v("rtmp")]) : _vm._e(), scope.row.ezvizProtocol == 4 ? _c("div", [_vm._v("flv")]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "labName",
      label: "所属企业",
      sortable: "custom",
      width: "110"
    }
  }), _c("el-table-column", {
    attrs: {
      prop: "structuresName",
      label: "所属建筑物",
      sortable: "custom",
      width: "120"
    }
  })], 1), _c("el-pagination", {
    attrs: {
      background: "",
      align: "center",
      layout: "prev, pager, next, sizes, total",
      "page-sizes": [10, 20, 50, 100],
      "current-page": _vm.searchForm.page,
      "page-size": _vm.searchForm.limit,
      total: _vm.totalCount
    },
    on: {
      "current-change": _vm.pageChange,
      "size-change": _vm.sizeChange
    }
  }), _c("el-dialog", {
    attrs: {
      visible: _vm.editDialogShow,
      title: _vm.editDialogTitle,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.editDialogShow = $event;
      },
      close: function close($event) {
        return _vm.initFormData(_vm.emptyFormData);
      }
    }
  }, [_c("el-form", {
    ref: "formDataVerify",
    attrs: {
      model: _vm.formData,
      rules: _vm.rules
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "名称",
      "label-width": _vm.formLabelWidth,
      prop: "videoName"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "请输入视频名称"
    },
    model: {
      value: _vm.formData.videoName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "videoName", $$v);
      },
      expression: "formData.videoName"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "\u8BBE\u5907\u53F7(".concat(_vm.formData.videoCategory == "Uniview" ? "宇" : "萤", ")"),
      "label-width": _vm.formLabelWidth,
      prop: "ezvizSerial"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "请输入设备序列号"
    },
    model: {
      value: _vm.formData.ezvizSerial,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "ezvizSerial", $$v);
      },
      expression: "formData.ezvizSerial"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "序列号",
      "label-width": _vm.formLabelWidth,
      prop: "videoSn"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "请输入视频编号"
    },
    model: {
      value: _vm.formData.videoSn,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "videoSn", $$v);
      },
      expression: "formData.videoSn"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "\u901A\u9053\u53F7(".concat(_vm.formData.videoCategory == "Uniview" ? "宇" : "萤", ")"),
      "label-width": _vm.formLabelWidth,
      prop: "ezvizChannel"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "请输入设备通道号"
    },
    model: {
      value: _vm.formData.ezvizChannel,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "ezvizChannel", $$v);
      },
      expression: "formData.ezvizChannel"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "类型",
      "label-width": _vm.formLabelWidth,
      prop: "videoCategory"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "small"
    },
    model: {
      value: _vm.formData.videoCategory,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "videoCategory", $$v);
      },
      expression: "formData.videoCategory"
    }
  }, [_c("el-radio", {
    staticStyle: {
      "line-height": "40px"
    },
    attrs: {
      label: "Ezviz"
    }
  }, [_vm._v("萤石云")]), _c("el-radio", {
    staticStyle: {
      "line-height": "40px"
    },
    attrs: {
      label: "Uniview"
    }
  }, [_vm._v("宇视云")])], 1)], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "视频协议",
      "label-width": _vm.formLabelWidth,
      prop: "ezvizProtocol"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "small"
    },
    model: {
      value: _vm.formData.ezvizProtocol,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "ezvizProtocol", $$v);
      },
      expression: "formData.ezvizProtocol"
    }
  }, [_c("el-radio", {
    staticStyle: {
      "line-height": "40px"
    },
    attrs: {
      label: 1
    }
  }, [_vm._v("ezopen")]), _c("el-radio", {
    staticStyle: {
      "line-height": "40px"
    },
    attrs: {
      label: 2
    }
  }, [_vm._v("hls")]), _c("el-radio", {
    staticStyle: {
      "line-height": "40px"
    },
    attrs: {
      label: 3
    }
  }, [_vm._v("rtmp")]), _c("el-radio", {
    staticStyle: {
      "line-height": "40px"
    },
    attrs: {
      label: 4
    }
  }, [_vm._v("flv")])], 1)], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "清晰度",
      "label-width": _vm.formLabelWidth,
      prop: "ezvizQuality"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "small"
    },
    model: {
      value: _vm.formData.ezvizQuality,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "ezvizQuality", $$v);
      },
      expression: "formData.ezvizQuality"
    }
  }, [_c("el-radio", {
    staticStyle: {
      "line-height": "40px"
    },
    attrs: {
      label: 2
    }
  }, [_vm._v("流畅（子码流）")]), _c("el-radio", {
    staticStyle: {
      "line-height": "40px"
    },
    attrs: {
      label: 1
    }
  }, [_vm._v("高清（主码流）")])], 1)], 1)], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "备注",
      "label-width": _vm.formLabelWidth,
      prop: "remark"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 5,
      placeholder: "请输入备注"
    },
    model: {
      value: _vm.formData.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "remark", $$v);
      },
      expression: "formData.remark"
    }
  })], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeEditDialogShow
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitFormData
    }
  }, [_vm._v("确 定")])], 1)], 1), _vm.bindShow ? _c("bindSel", {
    attrs: {
      formData: _vm.formData,
      funName: "videoBind"
    },
    on: {
      "child-bind-sel": _vm.bindSel
    }
  }) : _vm._e(), _vm.preview_show ? _c("camera", {
    attrs: {
      id: _vm.preview_id
    },
    on: {
      close: function close($event) {
        _vm.preview_show = false;
      }
    }
  }) : _vm._e()], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };