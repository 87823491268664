import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  name: 'device_mng',
  components: {
    deviceTable: function deviceTable() {
      return import('./device_page');
    },
    deviceItemTable: function deviceItemTable() {
      return import('./deviceItem_page');
    },
    historyTable: function historyTable() {
      return import('./history_logs_page');
    },
    warnTable: function warnTable() {
      return import('./device_warn_page');
    },
    warnHistoryTable: function warnHistoryTable() {
      return import('./device_warn_history');
    },
    historyAnalysisTable: function historyAnalysisTable() {
      return import('./device_history_analysis');
    },
    askCmdTable: function askCmdTable() {
      return import('./device_askCmd_page');
    }
  },
  data: function data() {
    return {
      tableShow: 'device',
      current: {},
      prev: {}
    };
  },
  methods: {
    goDevice: function goDevice() {
      this.tableShow = 'device';
    },
    goChild: function goChild(data, tableName, prev) {
      this.current = data;
      this.tableShow = tableName;
      if (prev) {
        this.prev = prev;
      }
    }
  }
};