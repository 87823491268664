import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "page"
  }, [_c("template", {
    slot: "header"
  }, [_c("el-form", {
    attrs: {
      inline: true,
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "关键字"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "关键字"
    },
    model: {
      value: _vm.searchForm.searchKey,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "searchKey", $$v);
      },
      expression: "searchForm.searchKey"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "阅读状态"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.readState,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "readState", $$v);
      },
      expression: "searchForm.readState"
    }
  }, [_c("el-option", {
    attrs: {
      label: "未读",
      value: false
    }
  }), _c("el-option", {
    attrs: {
      label: "已读",
      value: true
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "回复状态"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择",
      clearable: ""
    },
    model: {
      value: _vm.searchForm.replyState,
      callback: function callback($$v) {
        _vm.$set(_vm.searchForm, "replyState", $$v);
      },
      expression: "searchForm.replyState"
    }
  }, [_c("el-option", {
    attrs: {
      label: "未回复",
      value: false
    }
  }), _c("el-option", {
    attrs: {
      label: "已回复",
      value: true
    }
  })], 1)], 1), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.search
    }
  }, [_c("i", {
    staticClass: "fa fa-search"
  }), _vm._v(" 查询")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.add
    }
  }, [_c("i", {
    staticClass: "fa fa-plus"
  }), _vm._v(" 添加")])], 1)], 1)], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.queryLoading,
      expression: "queryLoading"
    }],
    staticStyle: {
      width: "100%",
      "margin-bottom": "20px"
    },
    attrs: {
      data: _vm.tableData,
      stripe: "",
      border: "",
      "row-key": "id",
      "element-loading-text": "正在努力加载中..."
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "序号",
      type: "index",
      width: "90"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "操作",
      width: "115"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            size: "mini",
            type: "warning"
          },
          on: {
            click: function click($event) {
              return _vm.detailAndReplay(scope.row);
            }
          }
        }, [_vm._v("详情与回复")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "readState",
      label: "阅读",
      "show-overflow-tooltip": "",
      width: "75",
      sortable: "custom"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.readState ? _c("div", {
          staticClass: "text-suggest"
        }, [_vm._v("已读")]) : _c("div", {
          staticClass: "text-bug"
        }, [_vm._v("未读")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "replyState",
      label: "回复",
      "show-overflow-tooltip": "",
      width: "75",
      sortable: "custom"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.replyState ? _c("div", {
          staticClass: "text-suggest"
        }, [_vm._v("已回复")]) : _c("div", {
          staticClass: "text-bug"
        }, [_vm._v("未回复")])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "content",
      label: "标题",
      "show-overflow-tooltip": "",
      "min-width": "250"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.row.title || "") + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "createUserNickname",
      label: "创建用户",
      "show-overflow-tooltip": "",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.row.createUserNickname || "") + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "userPhone",
      label: "联系人",
      "show-overflow-tooltip": "",
      width: "110"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.row.userNickname || "") + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "userPhone",
      label: "联系电话",
      "show-overflow-tooltip": "",
      width: "110"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.row.userPhone || "") + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "userPhone",
      label: "联系邮箱",
      "show-overflow-tooltip": "",
      width: "110"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v(" " + _vm._s(scope.row.userEmail || "") + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "createTime",
      label: "创建时间",
      sortable: "custom",
      "min-width": "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_vm._v(_vm._s(_vm.dateFormat2Str(scope.row.createTime)))])];
      }
    }])
  })], 1), _c("el-pagination", {
    attrs: {
      background: "",
      align: "center",
      layout: "prev, pager, next, sizes, total",
      "page-sizes": [10, 20, 50, 100],
      "current-page": _vm.searchForm.page,
      "page-size": _vm.searchForm.limit,
      total: _vm.totalCount
    },
    on: {
      "current-change": _vm.pageChange,
      "size-change": _vm.sizeChange
    }
  }), _c("el-dialog", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.queryReplayLoading,
      expression: "queryReplayLoading"
    }],
    attrs: {
      visible: _vm.replayDialogShow,
      title: _vm.replayDialogTitle,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.replayDialogShow = $event;
      },
      close: function close($event) {
        return _vm.initReplayFormData(_vm.emptyReplayFormData);
      }
    }
  }, [_c("el-row", {
    staticClass: "work-detail",
    staticStyle: {
      "margin-bottom": "36px",
      background: "rgb(221, 226, 231)",
      padding: "24px 12px 12px 12px",
      "border-radius": "8px"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 3,
      offset: 1
    }
  }, [_vm._v("标题:")]), _c("el-col", {
    attrs: {
      span: 20
    }
  }, [_vm._v(_vm._s(_vm.workOrderDetail.title || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 3,
      offset: 1
    }
  }, [_vm._v("联系人:")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(_vm._s(_vm.workOrderDetail.createUserNickname || ""))]), _c("el-col", {
    attrs: {
      span: 3,
      offset: 1
    }
  }, [_vm._v("联系方式:")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(_vm._s(_vm.workOrderDetail.userPhone || ""))])], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 3,
      offset: 1
    }
  }, [_vm._v("提交时间:")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v(_vm._s(_vm.dateFormat2Str(_vm.workOrderDetail.createTime)))]), _c("el-col", {
    attrs: {
      span: 3,
      offset: 1
    }
  }, [_vm._v("回复状态:")]), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm.workOrderDetail.replyState ? _c("div", {
    staticClass: "text-suggest"
  }, [_vm._v("已回复")]) : _c("div", {
    staticClass: "text-bug"
  }, [_vm._v("未回复")])])], 1)], 1), _c("el-row", {
    staticStyle: {
      border: "1px solid silver"
    }
  }, [_c("el-col", {
    staticStyle: {
      "margin-bottom": "12px",
      background: "rgb(249,249,249)",
      padding: "12px",
      "border-left": "4px solid orange"
    },
    attrs: {
      span: 24
    }
  }, [_vm._v(" 沟通记录 ")]), _vm._l(_vm.replayTableData, function (item) {
    return _c("el-col", {
      key: item.id,
      attrs: {
        span: 12,
        offset: _vm.isMe === item.userId ? 12 : 1
      }
    }, [_vm.isMe !== item.userId ? _c("el-row", {
      staticStyle: {
        margin: "12px 0px",
        "align-items": "center"
      },
      attrs: {
        gutter: 5,
        type: "flex"
      }
    }, [_c("el-col", {
      attrs: {
        span: 4
      }
    }, [_c("el-image", {
      staticStyle: {
        width: "30px",
        height: "30px",
        "border-radius": "50%"
      },
      attrs: {
        src: item.replyUserHeadImage,
        fit: "fill"
      }
    })], 1), _c("el-col", {
      attrs: {
        span: 8
      }
    }, [_vm._v(_vm._s(item.replyUserNickname || ""))]), _c("el-col", [_vm._v(_vm._s(_vm.dateFormat2Str(item.createTime)))])], 1) : _c("el-row", {
      staticStyle: {
        margin: "12px 0px",
        "align-items": "center"
      },
      attrs: {
        gutter: 5,
        type: "flex"
      }
    }, [_c("el-col", {
      staticStyle: {
        "text-align": "right"
      }
    }, [_vm._v(_vm._s(_vm.dateFormat2Str(item.createTime)))]), _c("el-col", {
      attrs: {
        span: 8
      }
    }, [_vm._v(_vm._s(item.replyUserNickname || ""))]), _c("el-col", {
      attrs: {
        span: 4
      }
    }, [_c("el-image", {
      staticStyle: {
        width: "30px",
        height: "30px",
        "border-radius": "50%"
      },
      attrs: {
        src: item.replyUserHeadImage,
        fit: "fill"
      }
    })], 1)], 1), _c("el-row", {
      staticStyle: {
        position: "relative"
      },
      attrs: {
        gutter: 5
      }
    }, [_vm.isMe !== item.userId ? _c("div", {
      staticClass: "triangle",
      staticStyle: {
        position: "absolute"
      }
    }) : _c("div", {
      staticClass: "triangle-right",
      staticStyle: {
        position: "absolute"
      }
    }), _c("el-col", {
      staticStyle: {
        background: "#dde2e7",
        "margin-bottom": "24px",
        padding: "12px",
        "border-radius": "8px",
        position: "relative"
      },
      attrs: {
        span: 12,
        offset: _vm.isMe === item.userId ? 8 : 3
      },
      domProps: {
        innerHTML: _vm._s(item.content)
      }
    }), item.imgs ? _c("el-col", {
      staticStyle: {
        background: "#dde2e7",
        "margin-bottom": "24px",
        padding: "12px",
        "border-radius": "8px"
      },
      attrs: {
        span: 12,
        offset: _vm.isMe === item.userId ? 8 : 3
      }
    }, _vm._l(item.imgs, function (itemImg, index) {
      return _c("el-image", {
        key: index,
        staticStyle: {
          width: "76px",
          height: "76px",
          "margin-right": "4px",
          cursor: "pointer"
        },
        attrs: {
          src: itemImg.url,
          fit: "fill"
        },
        on: {
          click: function click($event) {
            return _vm.previewFile(itemImg.url);
          }
        }
      });
    }), 1) : _vm._e()], 1)], 1);
  })], 2), _c("el-pagination", {
    staticStyle: {
      "margin-top": "30px"
    },
    attrs: {
      background: "",
      align: "center",
      layout: "prev, pager, next",
      "current-page": _vm.searchReplayForm.page,
      "page-size": _vm.searchReplayForm.limit,
      total: _vm.replayTotalCount
    },
    on: {
      "current-change": _vm.replayPageChange,
      "size-change": _vm.replaySizeChange
    }
  }), _c("el-form", {
    ref: "formDataReplayVerify",
    attrs: {
      model: _vm.replayFormData,
      rules: _vm.replayRules
    }
  }, [_c("el-row", {
    staticStyle: {
      "margin-top": "36px"
    }
  }, [_c("el-col", [_c("el-form-item", {
    attrs: {
      label: "回复：",
      "label-width": _vm.replayFormLabelWidth,
      prop: "content"
    }
  }, [_c("d2-ueditor", {
    staticStyle: {
      width: "98%"
    },
    model: {
      value: _vm.replayFormData.content,
      callback: function callback($$v) {
        _vm.$set(_vm.replayFormData, "content", $$v);
      },
      expression: "replayFormData.content"
    }
  })], 1)], 1)], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeReplayDialogShow
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitReplayFormData
    }
  }, [_vm._v("确 定")])], 1)], 1), _c("el-dialog", {
    attrs: {
      visible: _vm.editDialogShow,
      title: _vm.editDialogTitle,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.editDialogShow = $event;
      },
      close: function close($event) {
        return _vm.initFormData(_vm.emptyFormData);
      }
    }
  }, [_c("el-form", {
    ref: "formDataVerify",
    attrs: {
      model: _vm.formData,
      rules: _vm.rules
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "标题",
      "label-width": _vm.formLabelWidth,
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "请输入标题"
    },
    model: {
      value: _vm.formData.title,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "title", $$v);
      },
      expression: "formData.title"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "联系人",
      "label-width": _vm.formLabelWidth,
      prop: "userNickname"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "请输入联系人"
    },
    model: {
      value: _vm.formData.userNickname,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "userNickname", $$v);
      },
      expression: "formData.userNickname"
    }
  })], 1)], 1)], 1), _c("el-row", {
    attrs: {
      gutter: 5
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "联系人手机号",
      "label-width": _vm.formLabelWidth,
      prop: "userPhone"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "请输入联系人手机号"
    },
    model: {
      value: _vm.formData.userPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "userPhone", $$v);
      },
      expression: "formData.userPhone"
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "联系人邮箱",
      "label-width": _vm.formLabelWidth,
      prop: "userEmail"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: "请输入联系人邮箱"
    },
    model: {
      value: _vm.formData.userEmail,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "userEmail", $$v);
      },
      expression: "formData.userEmail"
    }
  })], 1)], 1)], 1), _c("el-form-item", {
    attrs: {
      label: "图片",
      "label-width": _vm.formLabelWidth,
      prop: "imgs"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_vm._l(_vm.formData.imgs, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "el-upload-list el-upload-list--picture-card"
    }, [_c("div", {
      staticClass: "el-upload-list__item is-success"
    }, [item.url ? _c("img", {
      staticClass: "avatar",
      staticStyle: {
        width: "100%",
        height: "100%"
      },
      attrs: {
        src: item.url
      }
    }) : _vm._e(), _c("label", {
      staticClass: "el-upload-list__item-status-label"
    }, [_c("i", {
      staticClass: "el-icon-upload-success el-icon-check"
    })]), _c("span", {
      staticClass: "el-upload-list__item-actions"
    }, [_c("span", {
      staticClass: "el-upload-list__item-delete"
    }, [_c("i", {
      staticClass: "el-icon-delete",
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.removeFile(index);
        }
      }
    })]), _c("span", {
      staticClass: "el-upload-list__item-delete"
    }, [_c("i", {
      staticClass: "el-icon-zoom-in",
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.previewFile(index);
        }
      }
    })])])])]);
  }), !_vm.formData.imgs || _vm.formData.imgs.length < 6 ? _c("el-upload", {
    staticClass: "avatar-uploader",
    attrs: {
      action: "",
      accept: "image/jpeg, image/jpg, image/png",
      "list-type": "picture-card",
      "show-file-list": false,
      "before-upload": _vm.beforeFileUpload,
      "http-request": _vm.Upload
    }
  }, [_c("i", {
    staticClass: "el-icon-plus"
  })]) : _vm._e()], 2)]), _c("el-form-item", {
    attrs: {
      label: "内容",
      "label-width": _vm.formLabelWidth,
      prop: "content"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 5,
      placeholder: "请输入内容"
    },
    model: {
      value: _vm.formData.content,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "content", $$v);
      },
      expression: "formData.content"
    }
  })], 1)], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeEditDialogShow
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitFormData
    }
  }, [_vm._v("确 定")])], 1)], 1)], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };