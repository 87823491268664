var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("knowledgeTable", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tableShow === "knowledge",
      expression: "tableShow === 'knowledge'"
    }],
    on: {
      "child-go": _vm.goChild
    }
  }), _vm.tableShow === "details" ? _c("knowledgeDetails", {
    attrs: {
      current: _vm.current
    },
    on: {
      "child-go-knowledge": _vm.goKnowledge
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };