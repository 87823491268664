import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "page water_monitoring_page"
  }, [_c("div", {
    staticClass: "container"
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "margin-bottom": "20px"
    }
  }, [_vm.zoneInfo.monitorFirstDeviceId ? _c("div", {
    staticClass: "pond-container"
  }, [_c("div", {
    staticClass: "name"
  }, [_vm._v("1#" + _vm._s(_vm.zoneInfo.monitorFirstDeviceName))]), _c("div", {
    staticClass: "pond-content"
  }, _vm._l(_vm.deviceItem_tableData_1, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "item"
    }, [_c("div", {
      staticClass: "img"
    }, [_c("img", {
      attrs: {
        src: _vm.dicData.configTypeOptions[item.type].image,
        alt: ""
      }
    })]), _c("div", {
      staticClass: "text"
    }, [_vm._v(_vm._s(_vm.dicData.configTypeOptions[item.type].name))]), _c("div", {
      staticClass: "value"
    }, [_vm._v(_vm._s(item.lastValue || "-") + " "), item.unit ? _c("span", [_vm._v(_vm._s(item.unit))]) : _vm._e()])]);
  }), 0)]) : _vm._e(), _vm.zoneInfo.monitorSecondDeviceId ? _c("div", {
    staticClass: "pond-container"
  }, [_c("div", {
    staticClass: "name"
  }, [_vm._v("2#" + _vm._s(_vm.zoneInfo.monitorSecondDeviceName))]), _c("div", {
    staticClass: "pond-content"
  }, _vm._l(_vm.deviceItem_tableData_2, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "item"
    }, [_c("div", {
      staticClass: "img"
    }, [_c("img", {
      attrs: {
        src: _vm.dicData.configTypeOptions[item.type].image,
        alt: ""
      }
    })]), _c("div", {
      staticClass: "text"
    }, [_vm._v(_vm._s(_vm.dicData.configTypeOptions[item.type].name))]), _c("div", {
      staticClass: "value"
    }, [_vm._v(_vm._s(item.lastValue || "-") + " "), item.unit ? _c("span", [_vm._v(_vm._s(item.unit))]) : _vm._e()])]);
  }), 0)]) : _vm._e(), _vm.zoneInfo.monitorThirdDeviceId ? _c("div", {
    staticClass: "pond-container"
  }, [_c("div", {
    staticClass: "name"
  }, [_vm._v("3#" + _vm._s(_vm.zoneInfo.monitorThirdDeviceName))]), _c("div", {
    staticClass: "pond-content"
  }, _vm._l(_vm.deviceItem_tableData_3, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "item"
    }, [_c("div", {
      staticClass: "img"
    }, [_c("img", {
      attrs: {
        src: _vm.dicData.configTypeOptions[item.type].image,
        alt: ""
      }
    })]), _c("div", {
      staticClass: "text"
    }, [_vm._v(_vm._s(_vm.dicData.configTypeOptions[item.type].name))]), _c("div", {
      staticClass: "value"
    }, [_vm._v(_vm._s(item.lastValue || "-") + " "), item.unit ? _c("span", [_vm._v(_vm._s(item.unit))]) : _vm._e()])]);
  }), 0)]) : _vm._e()]), _c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_vm.zoneInfo.monitorFourthDeviceId ? _c("div", {
    staticClass: "other-container"
  }, [_c("div", {
    staticClass: "name"
  }, [_vm._v("源水水质监测")]), _c("div", {
    staticClass: "pond-content"
  }, _vm._l(_vm.deviceItem_tableData_4, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "item"
    }, [_c("div", {
      staticClass: "img"
    }, [_c("img", {
      attrs: {
        src: _vm.dicData.configTypeOptions[item.type].image,
        alt: ""
      }
    })]), _c("div", {
      staticClass: "text"
    }, [_vm._v(_vm._s(_vm.dicData.configTypeOptions[item.type].name))]), _c("div", {
      staticClass: "value"
    }, [_vm._v(_vm._s(item.lastValue || "-") + " "), item.unit ? _c("span", [_vm._v(_vm._s(item.unit))]) : _vm._e()])]);
  }), 0), _c("div", {
    staticClass: "chart",
    attrs: {
      id: "chart-bar-1"
    }
  })]) : _vm._e(), _vm.zoneInfo.monitorFifthDeviceId ? _c("div", {
    staticClass: "other-container"
  }, [_c("div", {
    staticClass: "name"
  }, [_vm._v("尾水水质监测")]), _c("div", {
    staticClass: "pond-content"
  }, _vm._l(_vm.deviceItem_tableData_5, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "item"
    }, [_c("div", {
      staticClass: "img"
    }, [_c("img", {
      attrs: {
        src: _vm.dicData.configTypeOptions[item.type].image,
        alt: ""
      }
    })]), _c("div", {
      staticClass: "text"
    }, [_vm._v(_vm._s(_vm.dicData.configTypeOptions[item.type].name))]), _c("div", {
      staticClass: "value"
    }, [_vm._v(_vm._s(item.lastValue || "-") + " "), item.unit ? _c("span", [_vm._v(_vm._s(item.unit))]) : _vm._e()])]);
  }), 0), _c("div", {
    staticClass: "chart",
    attrs: {
      id: "chart-bar-2"
    }
  })]) : _vm._e()])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };