export default (function (_ref) {
  var request = _ref.request;
  return {
    labStructuresAdd: function labStructuresAdd(data) {
      return request({
        url: '/org/structures',
        method: 'post',
        data: data
      });
    },
    labStructuresModify: function labStructuresModify(data) {
      return request({
        url: "/org/structures/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    labStructuresDel: function labStructuresDel(id) {
      return request({
        url: "/org/structures/".concat(id),
        method: 'delete'
      });
    },
    labStructuresQuery: function labStructuresQuery(data) {
      return request({
        url: '/org/structures',
        method: 'get',
        params: data
      });
    },
    labStructuresTree: function labStructuresTree() {
      return request({
        url: '/org/structures/tree',
        method: 'get'
      });
    },
    labStructuresDetail: function labStructuresDetail(id) {
      return request({
        url: "/org/structures/".concat(id),
        method: 'get'
      });
    }
  };
});