import { render, staticRenderFns } from "./Beijing_suburb1.vue?vue&type=template&id=36dc0840&scoped=true&"
import script from "./Beijing_suburb1.vue?vue&type=script&lang=js&"
export * from "./Beijing_suburb1.vue?vue&type=script&lang=js&"
import style0 from "./Beijing_suburb1.vue?vue&type=style&index=0&id=36dc0840&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36dc0840",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\work\\code\\Halov-China-Fishery-V2\\halov-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('36dc0840')) {
      api.createRecord('36dc0840', component.options)
    } else {
      api.reload('36dc0840', component.options)
    }
    module.hot.accept("./Beijing_suburb1.vue?vue&type=template&id=36dc0840&scoped=true&", function () {
      api.rerender('36dc0840', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/iot/Beijing_suburb1.vue"
export default component.exports