var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "d2-layout-header-aside-group screen-container",
    style: _vm.styleLayoutMainGroup
  }, [_c("video", {
    attrs: {
      autoplay: "",
      loop: "",
      muted: ""
    },
    domProps: {
      muted: true
    }
  }, [_c("source", {
    attrs: {
      src: "https://ossgy.rich-link.cn/factory_farming.mp4"
    }
  })])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };