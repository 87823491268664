var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("aiModelTable", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tableShow === "aiModel",
      expression: "tableShow === 'aiModel'"
    }],
    on: {
      "child-go": _vm.goChild
    }
  }), _vm.tableShow === "modelParams" ? _c("paramsTable", {
    attrs: {
      current: _vm.current
    },
    on: {
      "child-go-model": _vm.goModel,
      "child-go": _vm.goChild
    }
  }) : _vm._e(), _vm.tableShow === "modelResult" ? _c("resultTable", {
    attrs: {
      current: _vm.current
    },
    on: {
      "child-go-model": _vm.goModel,
      "child-go": _vm.goChild
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };