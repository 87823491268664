export default (function (_ref) {
  var request = _ref.request;
  return {
    modeAreaAdd: function modeAreaAdd(data) {
      return request({
        url: '/modeArea',
        method: 'post',
        data: data
      });
    },
    modeAreaModify: function modeAreaModify(data) {
      return request({
        url: "/modeArea/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    modeAreaDel: function modeAreaDel(id) {
      return request({
        url: "/modeArea/".concat(id),
        method: 'delete'
      });
    },
    modeAreaQuery: function modeAreaQuery(data) {
      return request({
        url: '/modeArea',
        method: 'get',
        params: data
      });
    },
    modeAreaDetail: function modeAreaDetail(id) {
      return request({
        url: "/modeArea/".concat(id),
        method: 'get'
      });
    }
  };
});