import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-popover", {
    attrs: {
      placement: "bottom",
      trigger: "hover"
    }
  }, [_c("div", {
    staticClass: "plan",
    style: {
      "background-color": _vm.item.color || _vm.statusColor,
      "margin-top": 0.1 * _vm.cellHeight + "px"
    },
    attrs: {
      slot: "reference"
    },
    on: {
      click: _vm.onClick
    },
    slot: "reference"
  }, [_c("div", {
    staticClass: "middle"
  }, [_vm._v(_vm._s(_vm.item.name))])]), _c("div", {
    staticClass: "detail"
  }, [_c("span", {
    staticClass: "header"
  }, [_vm.data.icon ? _c("img", {
    attrs: {
      src: _vm.data.icon,
      alt: ""
    }
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.data.name) + " " + _vm._s(_vm.item.name) + " ")]), _c("ul", [_c("li", [_c("span", [_vm._v("开始时间：")]), _c("span", [_vm._v(_vm._s(_vm.dateFormat2Str(_vm.item.startDate)))])]), _c("li", [_c("span", [_vm._v("结束时间：")]), _c("span", [_vm._v(_vm._s(_vm.dateFormat2Str(_vm.item.endDate)))])]), _vm.item.importance ? _c("li", [_c("span", [_vm._v("重要内容：")]), _c("span", [_vm._v(_vm._s(_vm.item.importance))])]) : _vm._e(), _vm.item.content ? _c("li", [_c("span", [_vm._v("任务内容：")]), _c("span", [_vm._v(_vm._s(_vm.item.content))])]) : _vm._e(), _c("li", {
    staticStyle: {
      "margin-top": "10px",
      display: "flex",
      "justify-content": "flex-end"
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "warning"
    },
    on: {
      click: function click($event) {
        return _vm.edit(_vm.item);
      }
    }
  }, [_vm._v("编辑")]), _c("el-button", {
    attrs: {
      size: "mini",
      type: "danger"
    },
    on: {
      click: function click($event) {
        return _vm.del(_vm.item.id);
      }
    }
  }, [_vm._v("删除")])], 1)])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };