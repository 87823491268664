export default (function (_ref) {
  var request = _ref.request;
  return {
    modelManageAdd: function modelManageAdd(data) {
      return request({
        url: '/modelManage',
        method: 'post',
        data: data
      });
    },
    modelManageModify: function modelManageModify(data) {
      return request({
        url: "/modelManage/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    modelManageDel: function modelManageDel(id) {
      return request({
        url: "/modelManage/".concat(id),
        method: 'delete'
      });
    },
    modelManageQuery: function modelManageQuery(data) {
      return request({
        url: '/modelManage',
        method: 'get',
        params: data
      });
    },
    modelManageDetail: function modelManageDetail(id) {
      return request({
        url: "/modelManage/".concat(id),
        method: 'get'
      });
    },
    modelManageexEcute: function modelManageexEcute(id, params, data) {
      return request({
        url: "/modelManage/".concat(id, "/execute"),
        method: 'post',
        params: params,
        data: data
      });
    }
  };
});