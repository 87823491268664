var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("breedTable", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tableShow === "breed",
      expression: "tableShow === 'breed'"
    }],
    on: {
      "child-go": _vm.goChild
    }
  }), _vm.tableShow === "breedMode" ? _c("breedModeTable", {
    attrs: {
      current: _vm.current
    },
    on: {
      "child-go-breed": _vm.goBreed
    }
  }) : _vm._e(), _vm.tableShow === "breedSegment" ? _c("breedSegmentTable", {
    attrs: {
      current: _vm.current
    },
    on: {
      "child-go-breed": _vm.goBreed
    }
  }) : _vm._e(), _vm.tableShow === "waterManage" ? _c("waterManageTable", {
    attrs: {
      current: _vm.current
    },
    on: {
      "child-go-breed": _vm.goBreed
    }
  }) : _vm._e(), _vm.tableShow === "breedFormula" ? _c("breedFormulaTable", {
    attrs: {
      current: _vm.current
    },
    on: {
      "child-go-breed": _vm.goBreed
    }
  }) : _vm._e(), _vm.tableShow === "feedMethod" ? _c("feedMethodTable", {
    attrs: {
      current: _vm.current
    },
    on: {
      "child-go-breed": _vm.goBreed
    }
  }) : _vm._e(), _vm.tableShow === "breedDiseases" ? _c("breedDiseasesTable", {
    attrs: {
      current: _vm.current
    },
    on: {
      "child-go-breed": _vm.goBreed
    }
  }) : _vm._e(), _vm.tableShow === "productManage" ? _c("productManageTable", {
    attrs: {
      current: _vm.current
    },
    on: {
      "child-go-breed": _vm.goBreed
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };