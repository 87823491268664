import _objectSpread from "D:/work/code/Halov-China-Fishery-V2/halov-web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { mapState, mapActions } from 'vuex';
export default {
  name: 'd2-theme-list',
  data: function data() {
    return {
      table: {
        showHeader: false,
        border: true
      }
    };
  },
  computed: _objectSpread({}, mapState('d2admin/theme', ['list', 'activeName'])),
  mounted: function mounted() {
    console.log('主题列表', this.list);
  },
  methods: _objectSpread(_objectSpread({}, mapActions('d2admin/theme', ['set'])), {}, {
    handleSelectTheme: function handleSelectTheme(name) {
      this.set(name);
    }
  })
};