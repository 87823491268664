export default (function (_ref) {
  var request = _ref.request;
  return {
    taskAdd: function taskAdd(data) {
      return request({
        url: '/task/task',
        method: 'post',
        data: data
      });
    },
    taskModify: function taskModify(data) {
      return request({
        url: "/task/task/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    taskDel: function taskDel(id) {
      return request({
        url: "/task/task/".concat(id),
        method: 'delete'
      });
    },
    taskQuery: function taskQuery(data) {
      return request({
        url: '/task/task',
        method: 'get',
        params: data
      });
    },
    taskDetail: function taskDetail(id) {
      return request({
        url: "/task/task/".concat(id),
        method: 'get'
      });
    },
    taskHandle: function taskHandle(handleId, data) {
      return request({
        url: "/task/task/".concat(handleId, "/handle"),
        method: 'post',
        data: data
      });
    }
  };
});