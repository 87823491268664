export default (function (_ref) {
  var request = _ref.request;
  return {
    breedProductManageAdd: function breedProductManageAdd(data) {
      return request({
        url: '/breedProductManage',
        method: 'post',
        data: data
      });
    },
    breedProductManageModify: function breedProductManageModify(data) {
      return request({
        url: "/breedProductManage/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    breedProductManageDel: function breedProductManageDel(id) {
      return request({
        url: "/breedProductManage/".concat(id),
        method: 'delete'
      });
    },
    breedProductManageQuery: function breedProductManageQuery(data) {
      return request({
        url: '/breedProductManage',
        method: 'get',
        params: data
      });
    },
    breedProductManageDetail: function breedProductManageDetail(id) {
      return request({
        url: "/breedProductManage/".concat(id),
        method: 'get'
      });
    }
  };
});