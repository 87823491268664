export default {
  name: 'lab_sel',
  data: function data() {
    return {
      pageConfig: {
        queryFunName: 'labQuery'
      },
      searchForm: {
        page: 1,
        limit: 10,
        searchKey: null
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0
    };
  },
  mounted: function mounted() {
    this.query();
  },
  methods: {
    sel: function sel(data) {
      this.$emit('child-enterprise-sel', data);
    },
    query: function query() {
      var _this = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this.tableData = res.data || [];
        _this.totalCount = res.count;
        _this.queryLoading = false;
      });
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};