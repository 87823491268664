var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("div", {
    staticClass: "container"
  }, [_c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/bg.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "items"
  }, [_c("div", {
    staticClass: "item"
  }, [_c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/item-bg.png"),
      alt: ""
    }
  }), _c("div", [_c("div", {
    staticClass: "name"
  }, [_vm._v("占地面积")]), _c("div", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.labInfo.cultureArea || "--") + " "), _c("span")])])]), _c("div", {
    staticClass: "item"
  }, [_c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/item-bg.png"),
      alt: ""
    }
  }), _c("div", [_c("div", {
    staticClass: "name"
  }, [_vm._v("养殖水体")]), _c("div", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.labInfo.cultureWaterBody || "--") + " "), _c("span")])])]), _c("div", {
    staticClass: "item"
  }, [_c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/item-bg.png"),
      alt: ""
    }
  }), _c("div", [_c("div", {
    staticClass: "name"
  }, [_vm._v("种植面积")]), _c("div", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.labInfo.growArea || "--") + " "), _c("span")])])]), _c("div", {
    staticClass: "item"
  }, [_c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/item-bg.png"),
      alt: ""
    }
  }), _c("div", [_c("div", {
    staticClass: "name"
  }, [_vm._v("鱼池数量")]), _c("div", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.labInfo.fishpondAmount || "--") + " "), _c("span", [_vm._v("个")])])])]), _c("div", {
    staticClass: "item"
  }, [_c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/item-bg.png"),
      alt: ""
    }
  }), _c("div", [_c("div", {
    staticClass: "name"
  }, [_vm._v("设备")]), _c("div", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.deviceAmount || "--") + " "), _c("span", [_vm._v("台")])])])])]), _c("div", {
    staticClass: "info-container"
  }, [_c("div", {
    staticClass: "left"
  }, [_c("div", {
    staticClass: "left-1 item"
  }, [_c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/left-bg-1.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "item-content",
    staticStyle: {
      padding: "15px 10px 0px 20px"
    }
  }, [_c("div", {
    staticClass: "data"
  }, [_c("div", {
    staticClass: "data-item",
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: _vm.deviceClick
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/beijing-screen-icon/data-icon.png"),
      alt: ""
    }
  }), _c("div", [_c("div", {
    staticClass: "name"
  }, [_vm._v("设备总数")]), _c("div", {
    staticClass: "num"
  }, [_vm._v(_vm._s(_vm.deviceAmount))])])]), _c("div", {
    staticClass: "data-item"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/beijing-screen-icon/data-icon.png"),
      alt: ""
    }
  }), _c("div", [_c("div", {
    staticClass: "name"
  }, [_vm._v("统计时间")]), _c("div", {
    staticClass: "num"
  }, [_vm._v(_vm._s(_vm.dayjs().format("YYYY-MM-DD HH:mm:ss")))])])])]), _c("div", {
    staticClass: "charts"
  }, [_c("div", {
    ref: "leftChart1",
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      id: "left-chart-1"
    }
  })])])]), _c("div", {
    staticClass: "left-2 item"
  }, [_c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/left-bg-2.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "item-content"
  }, [_vm.todayWeather != {} ? _c("div", {
    staticClass: "list"
  }, [_c("div", {
    staticClass: "item"
  }, [_vm._m(0), _c("div", {
    staticClass: "data"
  }, [_c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/atmosphere-data-bg.png"),
      alt: ""
    }
  }), _c("div", [_c("div", {
    staticClass: "num"
  }, [_vm._v(_vm._s(_vm.todayWeather.text))]), _c("div", [_vm._v("当前值")])])])]), _c("div", {
    staticClass: "item"
  }, [_vm._m(1), _c("div", {
    staticClass: "data"
  }, [_c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/atmosphere-data-bg.png"),
      alt: ""
    }
  }), _c("div", [_c("div", {
    staticClass: "num"
  }, [_vm._v(_vm._s(_vm.todayWeather.temp))]), _c("div", [_vm._v("当前值")])])])]), _c("div", {
    staticClass: "item"
  }, [_vm._m(2), _c("div", {
    staticClass: "data"
  }, [_c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/atmosphere-data-bg.png"),
      alt: ""
    }
  }), _c("div", [_c("div", {
    staticClass: "num"
  }, [_vm._v(_vm._s(_vm.todayWeather.feels_like))]), _c("div", [_vm._v("当前值")])])])]), _c("div", {
    staticClass: "item"
  }, [_vm._m(3), _c("div", {
    staticClass: "data"
  }, [_c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/atmosphere-data-bg.png"),
      alt: ""
    }
  }), _c("div", [_c("div", {
    staticClass: "num"
  }, [_vm._v(_vm._s(_vm.todayWeather.rh))]), _c("div", [_vm._v("当前值")])])])]), _c("div", {
    staticClass: "item"
  }, [_vm._m(4), _c("div", {
    staticClass: "data"
  }, [_c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/atmosphere-data-bg.png"),
      alt: ""
    }
  }), _c("div", [_c("div", {
    staticClass: "num"
  }, [_vm._v(_vm._s(_vm.todayWeather.wind_class))]), _c("div", [_vm._v("当前值")])])])]), _c("div", {
    staticClass: "item"
  }, [_vm._m(5), _c("div", {
    staticClass: "data"
  }, [_c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/atmosphere-data-bg.png"),
      alt: ""
    }
  }), _c("div", [_c("div", {
    staticClass: "num"
  }, [_vm._v(_vm._s(_vm.todayWeather.wind_dir))]), _c("div", [_vm._v("当前值")])])])])]) : _vm._e()])])]), _c("div", {
    staticClass: "center"
  }, [_c("div", {
    staticClass: "top"
  }, [_c("div", {
    staticClass: "type",
    on: {
      click: function click($event) {
        _vm.mapSearchForm.fish = !_vm.mapSearchForm.fish;
      }
    }
  }, [_vm.mapSearchForm.fish ? _c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/type-bg-2.png"),
      alt: ""
    }
  }) : _c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/type-bg-1.png"),
      alt: ""
    }
  }), _c("div", [_vm.mapSearchForm.fish ? _c("img", {
    attrs: {
      src: require("@/assets/beijing-screen-icon/fish-1.png"),
      alt: ""
    }
  }) : _c("img", {
    attrs: {
      src: require("@/assets/beijing-screen-icon/fish-2.png"),
      alt: ""
    }
  }), _c("div", [_vm._v("鱼")])])]), _c("div", {
    staticClass: "type",
    on: {
      click: function click($event) {
        _vm.mapSearchForm.shrimps = !_vm.mapSearchForm.shrimps;
      }
    }
  }, [_vm.mapSearchForm.shrimps ? _c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/type-bg-2.png"),
      alt: ""
    }
  }) : _c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/type-bg-1.png"),
      alt: ""
    }
  }), _c("div", [_vm.mapSearchForm.shrimps ? _c("img", {
    attrs: {
      src: require("@/assets/beijing-screen-icon/shrimp-1.png"),
      alt: ""
    }
  }) : _c("img", {
    attrs: {
      src: require("@/assets/beijing-screen-icon/shrimp-2.png"),
      alt: ""
    }
  }), _c("div", [_vm._v("虾")])])]), _c("div", {
    staticClass: "type",
    on: {
      click: function click($event) {
        _vm.mapSearchForm.crab = !_vm.mapSearchForm.crab;
      }
    }
  }, [_vm.mapSearchForm.crab ? _c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/type-bg-2.png"),
      alt: ""
    }
  }) : _c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/type-bg-1.png"),
      alt: ""
    }
  }), _c("div", [_vm.mapSearchForm.crab ? _c("img", {
    attrs: {
      src: require("@/assets/beijing-screen-icon/crab-1.png"),
      alt: ""
    }
  }) : _c("img", {
    attrs: {
      src: require("@/assets/beijing-screen-icon/crab-2.png"),
      alt: ""
    }
  }), _c("div", [_vm._v("蟹")])])]), _c("div", {
    staticClass: "type",
    on: {
      click: function click($event) {
        _vm.mapSearchForm.holothurian = !_vm.mapSearchForm.holothurian;
      }
    }
  }, [_vm.mapSearchForm.holothurian ? _c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/type-bg-2.png"),
      alt: ""
    }
  }) : _c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/type-bg-1.png"),
      alt: ""
    }
  }), _c("div", [_vm.mapSearchForm.holothurian ? _c("img", {
    attrs: {
      src: require("@/assets/beijing-screen-icon/trepang-1.png"),
      alt: ""
    }
  }) : _c("img", {
    attrs: {
      src: require("@/assets/beijing-screen-icon/trepang-2.png"),
      alt: ""
    }
  }), _c("div", [_vm._v("参")])])]), _c("div", {
    staticClass: "type",
    on: {
      click: function click($event) {
        _vm.mapSearchForm.shellfish = !_vm.mapSearchForm.shellfish;
      }
    }
  }, [_vm.mapSearchForm.shellfish ? _c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/type-bg-2.png"),
      alt: ""
    }
  }) : _c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/type-bg-1.png"),
      alt: ""
    }
  }), _c("div", [_vm.mapSearchForm.shellfish ? _c("img", {
    attrs: {
      src: require("@/assets/beijing-screen-icon/shellfish-1.png"),
      alt: ""
    }
  }) : _c("img", {
    attrs: {
      src: require("@/assets/beijing-screen-icon/shellfish-2.png"),
      alt: ""
    }
  }), _c("div", [_vm._v("贝")])])]), _c("div", {
    staticClass: "type",
    on: {
      click: function click($event) {
        _vm.mapSearchForm.algae = !_vm.mapSearchForm.algae;
      }
    }
  }, [_vm.mapSearchForm.algae ? _c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/type-bg-2.png"),
      alt: ""
    }
  }) : _c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/type-bg-1.png"),
      alt: ""
    }
  }), _c("div", [_vm.mapSearchForm.algae ? _c("img", {
    attrs: {
      src: require("@/assets/beijing-screen-icon/algae-1.png"),
      alt: ""
    }
  }) : _c("img", {
    attrs: {
      src: require("@/assets/beijing-screen-icon/algae-2.png"),
      alt: ""
    }
  }), _c("div", [_vm._v("藻")])])])]), _c("div", {
    staticClass: "bottom"
  }, [_c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/bottom-bg.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "pagination"
  }, [_c("el-pagination", {
    attrs: {
      "hide-on-single-page": true,
      "pager-count": 5,
      background: "",
      layout: "prev, pager, next",
      "current-page": _vm.taskSearchForm.page,
      "page-size": _vm.taskSearchForm.limit,
      total: _vm.totalCount
    },
    on: {
      "current-change": _vm.pageChange
    }
  })], 1), _c("div", {
    staticClass: "bottom-content"
  }, [_vm._m(6), _vm.taskTableData.length ? _c("div", {
    staticClass: "table-list"
  }, _vm._l(_vm.taskTableData, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "td"
    }, [_c("div", {
      staticClass: "order"
    }, [_vm._v(_vm._s(index + 1))]), _c("div", {
      staticClass: "first"
    }, [_vm._v(_vm._s(item.structuresName))]), _c("div", [_vm._v(_vm._s(item.executeContent || ""))]), _c("div", [_vm._v(_vm._s(item.executeUserNickname))]), _c("div", {
      style: "color:" + (item.state == "Wait" ? "#FFAA74" : "#13C52E")
    }, [_vm._v(_vm._s(item.state == "Wait" ? "待执行" : "已完成"))])]);
  }), 0) : _c("div", {
    staticStyle: {
      "font-size": "14px",
      color: "rgba(255, 255, 255, 0.65)",
      "text-align": "center",
      padding: "40px 0 0"
    }
  }, [_vm._v("暂无数据")])])])]), _c("div", {
    staticClass: "right"
  }, [_c("div", {
    staticClass: "right-1 item"
  }, [_c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/right-bg-1.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "item-content"
  }, [_c("div", {
    staticClass: "charts"
  }, [_c("div", {
    ref: "rightChart1",
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      id: "right-chart-1"
    }
  })])])]), _c("div", {
    staticClass: "right-2 item"
  }, [_c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/right-bg-2.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "more",
    on: {
      click: _vm.moreClick
    }
  }, [_vm._v("更多")]), _vm.warnTableData.length ? _c("div", {
    staticClass: "item-content"
  }, _vm._l(_vm.warnTableData, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "item"
    }, [_c("div", {
      staticClass: "item-top"
    }, [_c("div", {
      staticClass: "tag"
    }, [_c("img", {
      attrs: {
        src: require("@/assets/beijing-screen-icon/tag-bg.png"),
        alt: ""
      }
    }), _c("div", [_vm._v(_vm._s(item.deviceItemTypeName) + _vm._s(item.warnCategory == "Threshold" ? "阈值" : "离线") + "警告")])]), _c("div", {
      staticClass: "level"
    }, [_c("img", {
      attrs: {
        src: require("@/assets/beijing-screen-icon/fire-" + item.warnLevel + ".png"),
        alt: ""
      }
    }), _c("img", {
      attrs: {
        src: require("@/assets/beijing-screen-icon/fire-" + item.warnLevel + ".png"),
        alt: ""
      }
    }), _c("div", [_vm._v(_vm._s(_vm.warnLevel[item.warnLevel]))])]), _c("div", {
      staticClass: "time"
    }, [_vm._v(_vm._s(_vm.dateFormat2Str(item.createTime)))])]), _c("div", {
      staticClass: "item-bottom"
    }, [_c("div", [_c("div", {
      staticClass: "label"
    }, [_vm._v("设备：")]), _c("div", [_vm._v(_vm._s(item.deviceName))])]), _c("div", [_c("div", {
      staticClass: "label"
    }, [_vm._v("检测值：")]), _c("div", [_vm._v(_vm._s(item.warnValue) + " " + _vm._s(item.deviceItemUnit ? "\uFF08".concat(item.deviceItemUnit, "\uFF09") : ""))])])])]);
  }), 0) : _c("div", {
    staticClass: "item-content",
    staticStyle: {
      "font-size": "14px",
      color: "rgba(255, 255, 255, 0.65)",
      "text-align": "center",
      padding: "40px 0 0"
    }
  }, [_vm._v("暂无数据")])])])])]), _vm._m(7)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "name"
  }, [_c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/atmosphere-label-bg.png"),
      alt: ""
    }
  }), _c("div", [_vm._v("天气")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "name"
  }, [_c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/atmosphere-label-bg.png"),
      alt: ""
    }
  }), _c("div", [_vm._v("温度")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "name"
  }, [_c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/atmosphere-label-bg.png"),
      alt: ""
    }
  }), _c("div", [_vm._v("体感温度")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "name"
  }, [_c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/atmosphere-label-bg.png"),
      alt: ""
    }
  }), _c("div", [_vm._v("湿度")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "name"
  }, [_c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/atmosphere-label-bg.png"),
      alt: ""
    }
  }), _c("div", [_vm._v("风力")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "name"
  }, [_c("img", {
    staticClass: "bg",
    attrs: {
      src: require("@/assets/beijing-screen-icon/atmosphere-label-bg.png"),
      alt: ""
    }
  }), _c("div", [_vm._v("风向")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "table-header"
  }, [_c("div", [_c("img", {
    attrs: {
      src: require("@/assets/beijing-screen-icon/table-header-bg.png"),
      alt: ""
    }
  }), _c("div", [_vm._v("执行位置")])]), _c("div", [_c("img", {
    attrs: {
      src: require("@/assets/beijing-screen-icon/table-header-bg.png"),
      alt: ""
    }
  }), _c("div", [_vm._v("任务名称")])]), _c("div", [_c("img", {
    attrs: {
      src: require("@/assets/beijing-screen-icon/table-header-bg.png"),
      alt: ""
    }
  }), _c("div", [_vm._v("执行人")])]), _c("div", [_c("img", {
    attrs: {
      src: require("@/assets/beijing-screen-icon/table-header-bg.png"),
      alt: ""
    }
  }), _c("div", [_vm._v("执行状态")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "map"
  }, [_c("div", {
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      id: "map"
    }
  })]);
}];
render._withStripped = true;
export { render, staticRenderFns };