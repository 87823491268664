import _createForOfIteratorHelper from "D:/work/code/Halov-China-Fishery-V2/halov-web/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.function.name.js";
export default {
  name: 'knowledge_details',
  props: {
    current: {
      type: Object,
      default: {}
    }
  },
  data: function data() {
    return {
      pageConfig: {
        editPageName: '养殖知识',
        queryFunName: 'breedModeQuery',
        dicTreeFunName: 'dictionaryCache',
        modeFunName: 'breedModeQuery'
      },
      searchForm: {
        page: 1,
        limit: 10,
        breedId: this.current.id,
        sortField: '^sort'
      },
      tableData: [],
      totalCount: 0,
      queryLoading: false,
      dicData: {
        typeUpperCode: 'Fishery_Breed_Type',
        configTypes: [],
        configTypeOptions: {},
        diseasesUpperCode: 'Fishery_Diseases_Type',
        configDiseases: [],
        configDiseasesOptions: {},
        symptomUpperCode: 'Fishery_Symptom_Type',
        configSymptoms: [],
        configSymptomOptions: {}
      },
      tip_show: false,
      result: {},
      active: 0,
      tab_list: ['养殖模式', '养殖环节', '水质管理', '配方饲料', '投喂方法', '疾病防治', '生产管理'],
      tab_active: 0,
      type_list: []
    };
  },
  watch: {
    tab_active: function tab_active(to) {
      this.$set(this.searchForm, 'mode', null);
      this.$set(this.searchForm, 'waterType', null);
      this.$set(this.searchForm, 'sortField', '^sort');
      if (to == 0) {
        this.pageConfig.queryFunName = 'breedModeQuery'; //养殖模式
      } else if (to == 1) {
        this.pageConfig.queryFunName = 'breedSegmentQuery'; //养殖环节
      } else if (to == 2) {
        var _this$type_list$, _this$type_list$2;
        this.$set(this.searchForm, 'mode', (_this$type_list$ = this.type_list[0]) === null || _this$type_list$ === void 0 ? void 0 : _this$type_list$.mode);
        this.$set(this.searchForm, 'waterType', (_this$type_list$2 = this.type_list[0]) === null || _this$type_list$2 === void 0 ? void 0 : _this$type_list$2.waterType);
        this.pageConfig.queryFunName = 'breedWaterManageQuery'; //水质管理
      } else if (to == 3) {
        this.pageConfig.queryFunName = 'breedFormulaQuery'; //配方饲料
      } else if (to == 4) {
        var _this$type_list$3, _this$type_list$4;
        this.$set(this.searchForm, 'mode', (_this$type_list$3 = this.type_list[0]) === null || _this$type_list$3 === void 0 ? void 0 : _this$type_list$3.mode);
        this.$set(this.searchForm, 'waterType', (_this$type_list$4 = this.type_list[0]) === null || _this$type_list$4 === void 0 ? void 0 : _this$type_list$4.waterType);
        this.pageConfig.queryFunName = 'breedFeedMethodQuery'; //投喂方法
      } else if (to == 5) {
        this.$set(this.searchForm, 'sortField', 'create_time');
        this.pageConfig.queryFunName = 'breedJoinDiseasesQueryJoinDiseasesAndDiseasesCategory'; //疾病防治
      } else if (to == 6) {
        var _this$type_list$5, _this$type_list$6;
        this.$set(this.searchForm, 'mode', (_this$type_list$5 = this.type_list[0]) === null || _this$type_list$5 === void 0 ? void 0 : _this$type_list$5.mode);
        this.$set(this.searchForm, 'waterType', (_this$type_list$6 = this.type_list[0]) === null || _this$type_list$6 === void 0 ? void 0 : _this$type_list$6.waterType);
        this.$set(this.searchForm, 'sortField', '^segment_sort,^sort');
        this.pageConfig.queryFunName = 'breedProductManageQuery'; //生产管理
      }

      this.searchForm.page = 1;
      this.query();
    }
  },
  mounted: function mounted() {
    var _this = this;
    Promise.all([new Promise(function (resolve, reject) {
      _this.$api[_this.pageConfig.dicTreeFunName](_this.dicData.typeUpperCode).then(function (res) {
        if (res.data) {
          _this.dicData.configTypes = res.data;
          // this.searchForm.breedType = res.data[0].code
          var _iterator = _createForOfIteratorHelper(res.data),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var item = _step.value;
              _this.dicData.configTypeOptions[item.code] = item.name;
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
        }
        resolve(res);
      });
    }), new Promise(function (resolve, reject) {
      _this.$api[_this.pageConfig.dicTreeFunName](_this.dicData.diseasesUpperCode).then(function (res) {
        if (res.data) {
          _this.dicData.configDiseases = res.data;
          // this.searchForm.diseasesType = res.data[0].code
          var _iterator2 = _createForOfIteratorHelper(res.data),
            _step2;
          try {
            for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
              var item = _step2.value;
              _this.dicData.configDiseasesOptions[item.code] = item.name;
            }
          } catch (err) {
            _iterator2.e(err);
          } finally {
            _iterator2.f();
          }
        }
        resolve(res);
      });
    }), new Promise(function (resolve, reject) {
      _this.$api[_this.pageConfig.dicTreeFunName](_this.dicData.symptomUpperCode).then(function (res) {
        if (res.data) {
          _this.dicData.configSymptoms = res.data;
          var _iterator3 = _createForOfIteratorHelper(res.data),
            _step3;
          try {
            for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
              var item = _step3.value;
              _this.dicData.configSymptomOptions[item.code] = item.name;
            }
          } catch (err) {
            _iterator3.e(err);
          } finally {
            _iterator3.f();
          }
        }
        resolve(res);
      });
    }), new Promise(function (resolve, reject) {
      _this.$api[_this.pageConfig.modeFunName]({
        breedId: _this.current.id,
        page: 1,
        limit: -1
      }).then(function (res) {
        if (res.data) {
          _this.type_list = res.data || [];
        }
        resolve(res);
      });
    })]).then(function (result) {
      _this.query();
    });
  },
  methods: {
    back: function back() {
      this.$emit('child-go-knowledge');
    },
    query: function query() {
      var _this2 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this2.tableData = res.data || [];
        _this2.totalCount = res.count;
        _this2.queryLoading = false;
      });
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    },
    lookClick: function lookClick(val) {
      this.tip_show = true;
      this.result = val;
    },
    leftClick: function leftClick(val, idx) {
      this.$set(this.searchForm, 'mode', val.mode);
      this.$set(this.searchForm, 'waterType', val.waterType);
      this.active = idx;
      this.query();
    }
  }
};