export default (function (_ref) {
  var request = _ref.request;
  return {
    deviceWarnAdd: function deviceWarnAdd(data) {
      return request({
        url: '/deviceWarn',
        method: 'post',
        data: data
      });
    },
    deviceWarnModify: function deviceWarnModify(data) {
      return request({
        url: "/deviceWarn/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    deviceWarnDel: function deviceWarnDel(id) {
      return request({
        url: "/deviceWarn/".concat(id),
        method: 'delete'
      });
    },
    deviceWarnQuery: function deviceWarnQuery(data) {
      return request({
        url: '/deviceWarn/queryJoinItem',
        method: 'get',
        params: data
      });
    },
    deviceWarnDetail: function deviceWarnDetail(id) {
      return request({
        url: "/deviceWarn/".concat(id),
        method: 'get'
      });
    }
  };
});