import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  name: 'product_mng',
  components: {
    productTable: function productTable() {
      return import('./product_page');
    },
    productItemTable: function productItemTable() {
      return import('./productItem_page');
    },
    askCmdTable: function askCmdTable() {
      return import('./product_askCmd_page');
    }
  },
  data: function data() {
    return {
      tableShow: 'product',
      current: {}
    };
  },
  methods: {
    goProduct: function goProduct() {
      this.tableShow = 'product';
    },
    goChild: function goChild(data, tableName) {
      this.current = data;
      this.tableShow = tableName;
    }
  }
};