export default (function (_ref) {
  var request = _ref.request;
  return {
    liangpingToken: function liangpingToken() {
      return request({
        url: '/system/liangping/token',
        method: 'get'
      });
    }
  };
});