import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.function.name.js";
import dayjs from 'dayjs';
export default {
  name: 'standardization_planting_page',
  components: {
    breedSel: function breedSel() {
      return import('../knowledge/breed_page_sel.vue');
    }
  },
  data: function data() {
    return {
      dayjs: dayjs,
      pageConfig: {
        editPageName: '规划',
        addFunName: 'taskPlanAdd',
        modifyFunName: 'taskPlanModify',
        queryFunName: 'taskPlanQuery',
        detailFunName: 'taskPlanDetail',
        delFunName: 'taskPlanDel'
      },
      searchForm: {
        page: 1,
        limit: 10,
        searchKey: null,
        sortField: 'create_time'
      },
      formData: {
        readonlyState: true
      },
      rules: {
        name: [{
          required: true,
          message: '请输入名称',
          trigger: 'blur'
        }],
        breedId: [{
          required: true,
          message: '请选择品种',
          trigger: 'blur'
        }],
        mode: [{
          required: true,
          message: '请输入模式',
          trigger: 'blur'
        }]
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      editDialogShow: false,
      editDialogTitle: '',
      formLabelWidth: '100px',
      emptyFormData: {},
      breed_show: false
    };
  },
  mounted: function mounted() {
    this.emptyFormData = JSON.parse(JSON.stringify(this.formData));
    this.query();
  },
  methods: {
    initFormData: function initFormData(data) {
      var paramData = JSON.parse(JSON.stringify(data));
      if (!paramData.addCause) {
        paramData.addCause = 'Buy';
      }
      if (!paramData.subCause) {
        paramData.subCause = 'Catch';
      }
      this.formData = paramData;
      if (typeof this.$refs['formDataVerify'] !== 'undefined') {
        this.$refs['formDataVerify'].clearValidate();
      }
    },
    saveFormDataProcess: function saveFormDataProcess(formData) {
      var saveFormData = JSON.parse(JSON.stringify(formData));
      return saveFormData;
    },
    add: function add() {
      this.editDialogTitle = this.pageConfig.editPageName + '新增';
      this.editDialogShow = true;
    },
    edit: function edit(data) {
      this.initFormData(data);
      this.editDialogTitle = this.pageConfig.editPageName + '维护';
      this.editDialogShow = true;
      this.$set(this.formData, 'executeDate', data.executeDate + '');
      //   this.$api[this.pageConfig.detailFunName](id).then(async(res) => {
      //     this.initFormData(res.data)

      //     this.editDialogTitle = this.pageConfig.editPageName + '维护'
      //     this.editDialogShow = true

      //     this.$set(this.formData, 'executeDate', res.data.executeDate + '')
      //   })
    } // 选择养殖品种
    ,
    breedSel: function breedSel(data) {
      if (data) {
        this.formData.breedId = data.id;
        this.$set(this.formData, 'breedName', data.name);
      }
      this.breed_show = false;
    },
    del: function del(id) {
      var _this = this;
      this.$confirm('确定要删除吗').then(function () {
        _this.$api[_this.pageConfig.delFunName](id).then(function (res) {
          if (res.data) {
            _this.$message.success('删除成功');
            _this.query();
          } else {
            _this.$message.error('删除失败');
          }
        });
      });
    } // 条目
    ,
    planItem: function planItem(course) {
      this.$emit('child-go', course, 'gantt');
    },
    query: function query() {
      var _this2 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.queryFunName](this.searchForm).then(function (res) {
        _this2.tableData = res.data || [];
        _this2.totalCount = res.count;
        _this2.queryLoading = false;
      });
    },
    submitFormData: function submitFormData() {
      var _this3 = this;
      this.$refs['formDataVerify'].validate(function (valid) {
        if (valid) {
          if (_this3.formData.id) {
            _this3.$api[_this3.pageConfig.modifyFunName](_this3.saveFormDataProcess(_this3.formData)).then(function (res) {
              if (res.data) {
                _this3.$message.success('编辑成功');
                _this3.initFormData(_this3.emptyFormData);
                _this3.editDialogShow = false;
                _this3.query();
              } else {
                _this3.$message.error('编辑失败');
              }
            });
          } else {
            _this3.$api[_this3.pageConfig.addFunName](_this3.saveFormDataProcess(_this3.formData)).then(function (res) {
              if (res.data) {
                _this3.$message.success('新增成功');
                _this3.initFormData(_this3.emptyFormData);
                _this3.editDialogShow = false;
                _this3.query();
              } else {
                _this3.$message.error('新增失败');
              }
            });
          }
        }
      });
    },
    closeEditDialogShow: function closeEditDialogShow() {
      this.editDialogTitle = '';
      this.editDialogShow = false;
      this.initFormData(this.emptyFormData);
    },
    search: function search() {
      this.searchForm.page = 1;
      this.query();
    },
    pageChange: function pageChange(page) {
      this.searchForm.page = page;
      this.query();
    },
    sizeChange: function sizeChange(limit) {
      this.searchForm.page = 1;
      this.searchForm.limit = limit;
      this.query();
    },
    sortChange: function sortChange(column) {
      this.searchForm.page = 1;
      this.searchForm.sortField = column.order === 'ascending' ? '^' + this.camelToUnderline(column.prop) : column.order === 'descending' ? this.camelToUnderline(column.prop) : 'create_time';
      this.query();
    }
  }
};