import axios from 'axios';
import 'video.js/dist/video-js.css';
import videojs from 'video.js';
export default {
  data: function data() {
    return {
      pageConfig: {
        editPageName: '摄像头播放',
        queryFunName: 'videoQuery',
        detailFunName: 'videoDetail'
      }
    };
  },
  mounted: function mounted() {
    this.sn = this.$route.query.sn || '';
    this.id = this.$route.query.id || '1661962302946873344';
    // this.getVideo()
    this.query();
  },
  destroyed: function destroyed() {
    this.player.dispose();
  },
  methods: {
    getVideo: function getVideo() {
      var _this = this;
      axios({
        url: 'http://gy.rich-link.cn:8180/rclk/video/companyVideo/config-data',
        method: 'GET',
        params: {
          sn: this.sn || '001'
        },
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then(function (response) {
        console.log('response.data.result.hlsUrl', response.data.result.hlsUrl);
        if (response.data.code == 200) {
          _this.hlsUrl = response.data.result.hlsUrl;
          _this.player = videojs('valveVideogj', _this.options(response.data.result.hlsUrl), function () {
            _this.player.play();
          });
        }
        console.log('response', response);
      }).catch(function (error) {
        console.log('error', error);
      });
    },
    options: function options(src) {
      return {
        autoplay: true // true,浏览器准备好时开始播放。
        ,
        muted: true // 默认情况下将会消除音频。
        ,
        loop: true // 导致视频一结束就重新开始。
        ,
        controls: false // 取消视频中的进度条
        ,
        preload: 'auto' // auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        ,
        language: 'zh-CN' // 汉化
        ,
        fluid: true // 当true时，将按比例缩放以适应其容器。
        ,
        sources: [{
          type: 'application/x-mpegURL',
          src: src // 视频播放地址
        }],

        notSupportedMessage: '此视频暂无法播放，请稍后再试' // 无法播放媒体源时显示的默认信息。
        ,
        textTrackDisplay: false
      };
    },
    query: function query() {
      var _this2 = this;
      axios({
        url: process.env.VUE_APP_API + '/video/' + this.id,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then(function (response) {
        if (response.data.code == 200) {
          _this2.player = videojs('valveVideogj', _this2.options(response.data.data.playUrl), function () {
            _this2.player.play();
          });
        }
        // console.log('response',response)
      });
    }
  }
};