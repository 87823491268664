export default (function (_ref) {
  var request = _ref.request;
  return {
    sourceReportAdd: function sourceReportAdd(data) {
      return request({
        url: '/sourceReport',
        method: 'post',
        data: data
      });
    },
    sourceReportModify: function sourceReportModify(data) {
      return request({
        url: "/sourceReport/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    sourceReportDel: function sourceReportDel(id) {
      return request({
        url: "/sourceReport/".concat(id),
        method: 'delete'
      });
    },
    sourceReportQuery: function sourceReportQuery(data) {
      return request({
        url: '/sourceReport',
        method: 'get',
        params: data
      });
    },
    sourceReportDetail: function sourceReportDetail(id) {
      return request({
        url: "/sourceReport/".concat(id),
        method: 'get'
      });
    }
  };
});