var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("d2-container", {
    staticClass: "page self_diagnosis_mng"
  }, [_c("template", {
    slot: "header"
  }, [_c("el-checkbox-group", {
    staticClass: "progress",
    attrs: {
      disabled: "",
      size: "medium",
      "text-color": "#fff"
    },
    model: {
      value: _vm.step,
      callback: function callback($$v) {
        _vm.step = $$v;
      },
      expression: "step"
    }
  }, [_c("div", {
    staticClass: "progress-item"
  }, [_c("el-checkbox", {
    attrs: {
      label: 1
    }
  }, [_vm._v("选择品种")])], 1), _c("div", {
    staticClass: "progress-item"
  }, [_c("el-checkbox", {
    attrs: {
      label: 2
    }
  }, [_vm._v("选择症状")])], 1), _c("div", {
    staticClass: "progress-item"
  }, [_c("el-checkbox", {
    attrs: {
      label: 3
    }
  }, [_vm._v("开始诊断")])], 1)])], 1), _vm.step.length == 1 ? _c("breedTable", {
    attrs: {
      breedId: _vm.breedId
    },
    on: {
      "child-breed-sel": _vm.breedSel
    }
  }) : _vm._e(), _vm.step.length >= 2 ? _c("symptomTable", {
    attrs: {
      breed: _vm.breed
    },
    on: {
      "child-symptom-sel": _vm.symptomSel,
      "child-symptom-back": _vm.symptomBack
    }
  }) : _vm._e()], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };