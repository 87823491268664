import "core-js/modules/es.json.stringify.js";
import userIcons from '@/assets/aliyun-icons/halov-user/iconfont.json';
import systemIcons from '@/assets/aliyun-icons/halov-system/iconfont.json';
import osIcons from '@/assets/aliyun-icons/halov-os/iconfont.json';
import farmingIcons from '@/assets/aliyun-icons/halov-farming/iconfont.json';
import iotIcons from '@/assets/aliyun-icons/halov-iot/iconfont.json';
export default {
  name: 'user_menu',
  data: function data() {
    return {
      // TODO: 引入个性化图标
      icons: {
        userIcons: userIcons,
        systemIcons: systemIcons,
        osIcons: osIcons,
        farmingIcons: farmingIcons,
        iotIcons: iotIcons
      },
      currentUser: localStorage.getItem('userId'),
      pageConfig: {
        editPageName: '菜单',
        addFunName: 'menuAdd',
        modifyFunName: 'menuModify',
        delFunName: 'menuDel',
        treeFunName: 'menuCache',
        detailFunName: 'menuDetail',
        clearCacheFunName: 'menuCacheClear'
      },
      formData: {
        icon: null,
        menuType: 'Menu',
        openMode: 'Main'
      },
      rules: {
        name: [{
          required: true,
          message: '菜单名不能为空',
          trigger: 'blur'
        }],
        sort: [{
          required: true,
          message: '排序号不能为空',
          trigger: 'blur'
        }, {
          pattern: /^[1-9]\d*$/,
          message: '排序号必须为正整数',
          trigger: 'blur'
        }]
      },
      queryLoading: true,
      tableData: [],
      totalCount: 0,
      editDialogShow: false,
      editDialogTitle: '',
      formLabelWidth: '100px',
      emptyFormData: {}
    };
  },
  mounted: function mounted() {
    this.emptyFormData = JSON.parse(JSON.stringify(this.formData));
    this.query();
  },
  methods: {
    selIcons: function selIcons(fontFamily, fontClass) {
      this.formData.icon = fontFamily + ' ' + fontClass;
    },
    initFormData: function initFormData(data) {
      var paramData = JSON.parse(JSON.stringify(data));
      this.formData = paramData;
      if (typeof this.$refs['formDataVerify'] !== 'undefined') {
        this.$refs['formDataVerify'].clearValidate();
      }
    },
    refresh: function refresh() {
      var _this = this;
      this.$confirm('确定刷新缓存吗？').then(function () {
        _this.$api[_this.pageConfig.clearCacheFunName]().then(function (res) {
          _this.$message.success('操作成功');
        });
      });
    },
    saveFormDataProcess: function saveFormDataProcess(formData) {
      var saveFormData = JSON.parse(JSON.stringify(formData));
      return saveFormData;
    },
    add: function add(upperId, upperName) {
      this.editDialogTitle = this.pageConfig.editPageName + '新增';
      this.editDialogShow = true;
      this.formData.upperId = upperId || '19901001';
      this.formData.upperName = upperName || '顶级菜单';
    },
    edit: function edit(id) {
      var _this2 = this;
      this.$api[this.pageConfig.detailFunName](id).then(function (res) {
        _this2.initFormData(res.data);
        _this2.formData.upperName = _this2.formData.upperName || '顶级菜单';
        _this2.editDialogTitle = _this2.pageConfig.editPageName + '维护';
        _this2.editDialogShow = true;
      });
    },
    del: function del(id) {
      var _this3 = this;
      this.$confirm('确定要删除吗').then(function () {
        _this3.$api[_this3.pageConfig.delFunName](id).then(function (res) {
          if (res.data) {
            _this3.$message.success('删除成功');
            _this3.query();
          } else {
            _this3.$message.error('删除失败');
          }
        });
      });
    },
    query: function query() {
      var _this4 = this;
      this.queryLoading = true;
      this.$api[this.pageConfig.treeFunName](this.searchForm).then(function (res) {
        _this4.tableData = res.data || [];
        _this4.totalCount = res.count;
        _this4.queryLoading = false;
      });
    },
    submitFormData: function submitFormData() {
      var _this5 = this;
      this.$refs['formDataVerify'].validate(function (valid) {
        if (valid) {
          if (_this5.formData.id) {
            _this5.$api[_this5.pageConfig.modifyFunName](_this5.saveFormDataProcess(_this5.formData)).then(function (res) {
              if (res.data) {
                _this5.$message.success('编辑成功');
                _this5.initFormData(_this5.emptyFormData);
                _this5.editDialogShow = false;
                _this5.query();
              } else {
                _this5.$message.error('编辑失败');
              }
            });
          } else {
            _this5.$api[_this5.pageConfig.addFunName](_this5.saveFormDataProcess(_this5.formData)).then(function (res) {
              if (res.data) {
                _this5.$message.success('新增成功');
                _this5.initFormData(_this5.emptyFormData);
                _this5.editDialogShow = false;
                _this5.query();
              } else {
                _this5.$message.error('新增失败');
              }
            });
          }
        }
      });
    },
    closeEditDialogShow: function closeEditDialogShow() {
      this.editDialogTitle = '';
      this.editDialogShow = false;
      this.initFormData(this.emptyFormData);
    }
  }
};