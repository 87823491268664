export default (function (_ref) {
  var request = _ref.request;
  return {
    zoneGdpAdd: function zoneGdpAdd(data) {
      return request({
        url: '/zoneGdp',
        method: 'post',
        data: data
      });
    },
    zoneGdpModify: function zoneGdpModify(data) {
      return request({
        url: "/zoneGdp/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    zoneGdpDel: function zoneGdpDel(id) {
      return request({
        url: "/zoneGdp/".concat(id),
        method: 'delete'
      });
    },
    zoneGdpQuery: function zoneGdpQuery(data) {
      return request({
        url: '/zoneGdp',
        method: 'get',
        params: data
      });
    },
    zoneGdpDetail: function zoneGdpDetail(id) {
      return request({
        url: "/zoneGdp/".concat(id),
        method: 'get'
      });
    }
  };
});