import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  name: 'project_page_mng',
  components: {
    projectTable: function projectTable() {
      return import('./project_page');
    },
    relationshipTable: function relationshipTable() {
      return import('./project_relationship_page');
    }
  },
  data: function data() {
    return {
      tableShow: 'project',
      current: {}
    };
  },
  methods: {
    goProject: function goProject() {
      this.tableShow = 'project';
    },
    goChild: function goChild(data, tableName) {
      this.current = data;
      this.tableShow = tableName;
    }
  }
};