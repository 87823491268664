export default (function (_ref) {
  var request = _ref.request;
  return {
    navigationAdd: function navigationAdd(data) {
      return request({
        url: '/navigation',
        method: 'post',
        data: data
      });
    },
    navigationModify: function navigationModify(data) {
      return request({
        url: "/navigation/".concat(data.id),
        method: 'put',
        data: data
      });
    },
    navigationDel: function navigationDel(id) {
      return request({
        url: "/navigation/".concat(id),
        method: 'delete'
      });
    },
    navigationTree: function navigationTree(data) {
      return request({
        url: "/navigation/cache",
        method: 'get',
        params: data
      });
    },
    navigationDetail: function navigationDetail(id) {
      return request({
        url: "/navigation/".concat(id),
        method: 'get'
      });
    },
    navigationClearCache: function navigationClearCache() {
      return request({
        url: '/navigation/cache/clear',
        method: 'delete'
      });
    }
  };
});